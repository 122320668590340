// This file is used to bind key events to location field which is used to trigger autocomplete feature.
// Also, used to create and show automplete list item depending on current search predictions.
var extend = require('extend');
var $ = require('jquery');
var recentSearchService = require('./recentSearchService');
var Constants = require('libs/constants');

/**
 * Autocomplete constructor method
 * @param {HTMLElement} element 
 * @param {Object} options 
 */
function Autocomplete(scope, element, options) {
  this.target = element;
  this.$parent = scope;
  this.options = extend(this.getDefaultOptions(), options);
  this.predictions = null;
  this.keyupTimeout = null;
  this.recentSearch = recentSearchService.get('miRecentSearchPlaces');
  this.predictionListEl = document.createElement('ul');
  this.predictionListEl.setAttribute('class', this.options.listCss);

  this.options.listContainerEl = this.options.listContainerEl || $(this.target).parent();
  this.options.listContainerEl.append(this.predictionListEl);

  this.bindEvents();
  this.resetPredictions();
  this.hideAutocompleteList();
}

var AutocompleteAPI = {

  /**
   * Returns default autocomplete options list
   * @returns {Object} Default autocomplete options
   */
  getDefaultOptions: function() {
    return {
      keyEventDebounceRate: 100,
      onSearch: null,
      onSelect: null,
      listCss: 'autocomplete-list',
      listHeaderCss: 'autocomplete-list-header',
      listItemCss: 'autocomplete-listitem',
      activeListItemCss: 'autocomplete-listitem-active',
      disableRecentSearch: false,
      disableCurrentLocSearch: false,
      listContainerEl: null
    };
  },

  /**
  * This function is used to identify device/desktop
  * @returns {boolean} device orientation is portrait or landscape
  */
  isDevice: function() {
    if (navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) {
      return true;
    }
    return false;
  },

  /**
   * Bind events for autocomplete module
   */
  bindEvents: function() {
    var hideAutocompleteList = this.hideAutocompleteList.bind(this);
    var _self = this;
    this.target.addEventListener('keyup', this.keyupEventHandler.bind(this));
    this.target.addEventListener('keydown', this.listKeyDownHandler.bind(this));
    this.target.addEventListener('blur', function(event) {
      setTimeout(function() {
        if (event.relatedTarget && $(event.relatedTarget).hasClass('clear-recent-search')) {
          _self.clearRecentSearch(event)
        };
        hideAutocompleteList();
      }, Constants.AUTOCOMPLETE.HIDE_ON_BLUR_DELAY);
    });

    this.predictionListEl.addEventListener('mousedown', this.listClickHandler.bind(this));
    this.$narrate = this.$parent.$el.find('div[aria-live="assertive"]');

    document.body.addEventListener('click', hideAutocompleteList);
    window.addEventListener('resize', function() {
      _self.predictionListEl.style.display = 'none';
      _self.isListVisible = false;
    });
  },

  /**
   * Target element KeyUp event handler
   * @param {EventObject} evt - KeyUp event object
   */
  keyupEventHandler: function(evt) {
    var self = this;
    var value = evt.target.value;
    var keyCode = evt.keyCode;

    if (self.keyupTimeout) {
      clearTimeout(self.keyupTimeout);
    }

    self.keyupTimeout = setTimeout(function() {
      var activeListItem = null;

      if (self.isEnterKeycode(keyCode)) {
        evt.preventDefault();
      
        activeListItem = self.predictionListEl.querySelector('.' + self.options.activeListItemCss);

        if (self.isListVisible && activeListItem) {
          self.selectItem(activeListItem);
        }   
      } else if ([37, 38, 39, 40].indexOf(keyCode) == -1){
        // if user remove search field value using backspace
        if (self.isEmptySearchTerm(value)) {
          self.updateRecentPredictions();
        } else {
          self.triggerSearch(value);
        }

        self.showAutocompleteList();
      }

      self.narrateListItem();
    }, self.options.keyEventDebounceRate)
  },

  /**
   * Target element click event handler
   * @param {EventObject} evt - Click event object
   */
  listClickHandler: function(evt) {
    var target = evt.target;
    var el = target.tagName === 'LI' ? target : $(target).closest('li')[0];

    this.selectItem(el);
    if(!this.isDevice()) {
      this.target.focus();
    }
  },

  /**
   * This function is used to prevent form submission on enter key press
   * @param {EventObject} evt - KeyDown event object
   */
  listKeyDownHandler: function(evt) {
    var keyCode = evt.keyCode;
    var _self = this;
    // track arrow keys
    if ([38, 40].indexOf(keyCode) > -1) {
      if (this.isListVisible) {
        this.navigateList(keyCode);
      } else {
        this.showAutocompleteList();
      }
    } else if (this.isEnterKeycode(evt.keyCode)) {
      evt.preventDefault();
      if(!(this.target.value != "" && this.predictions.searchTerm && this.predictions.searchTerm != this.target.value) ) {
        // Defect - SRPE-35647 Prevent form submit untill form fields get updated
        setTimeout(function(){
          $(_self.target).closest('form').submit();
        },500);
      } 
      else {
        _self.predictions.searchTerm = _self.target.value;
        $(_self.target).blur();
        setTimeout(function(){
          $(_self.target).focus();
        },500);
        // AAB-34464 - Select the highlighted item on Enter button press
        if($('.address-auto-fill-suggestion')) {
            activeListItem = _self.predictionListEl.querySelector('.' + _self.options.activeListItemCss);
                if (_self.isListVisible && activeListItem) {
                  _self.selectItem(activeListItem);
                }
            }
        }
    }
    this.narrateListItem();
  },

  /**
   * Calls options.onSelect callback if any and hides autocomplete list
   * @param {HTMLElement} el - Autocomplete list item DOM element
   */
  selectItem: function(el) {
    var data = this.getDataByListItem(el);
    var type = el.getAttribute('data-type');

    if (!this.options.onSelect) {
      return false;
    }

    if (data && data.mainText) {
      this.target.value = (data.secondaryText) ? data.mainText + ' ' + data.secondaryText : data.mainText;
    }

    this.options.onSelect(type, el, data);
    this.resetPredictions({searchTerm: this.target.value});
    this.blankLocationInputFlag = false;
    this.isListVisible = false;  
  },

  /**
   * This function is used to check if keyboard event object keycode is for ENTER key
   * @param {Number} keyCode - Keyboard event object keycode
   * @returns {boolean} TRUE if keycode is for ENTER key
   */
  isEnterKeycode: function(keyCode) {
    return keyCode === 13;
  },

  /**
   * This function is used to check if search term is empty
   * @param {string} searchTerm - Search term
   * @returns {boolean} TRUE if search term is empty or have only spaces
   */
  isEmptySearchTerm: function(searchTerm) {
    return /^[\s]*$/.test(searchTerm);
  },

  /**
   * This function is used to initiate search request for the search term
   * @param {string} searchTerm - Search term
   */
  triggerSearch: function(searchTerm) {
    if (this.options.onSearch) {
      this.options.onSearch(searchTerm)
        .then(this.searchSuccess.bind(this), this.searchError.bind(this));
    }
  },

  /**
   * This function is used to reset prediction to empty state on search error
   * @param {Object} data - Search result success object
   */
  searchSuccess: function(data) {
    var searchTerm = data.searchTerm;
    var searchResult = data.searchResult;

    // set predictions for search result
    if (this.target.value === searchTerm) {
      this.updateSearchPredictions(searchTerm, searchResult);
    }

    // show autocomplete list for search results
    this.showAutocompleteList();
  },

  /**
   * This function is used to reset prediction to empty state on search error
   * @param {string} err - Error message
   */
  searchError: function(err) {
    this.updateSearchPredictions(this.target.value, []);
  },

  /**
   * This function is used to append current location DOM element to autocomplete list
   */
  appendCurrentLocationItem: function() {
    var el;

    if (this.options.disableCurrentLocSearch || this.hasPredictions()) {
      return false;
    }

    el = document.createElement('li');
    el.innerHTML = '<span class="t-icon-current-location"></span>'
      + '<a> ' + this.options.currentLocLabel + '</a>';
    el.setAttribute('data-type', 'currentLoc');
    el.setAttribute('class', this.options.listItemCss);

    this.predictionListEl.appendChild(el);
  },

  /**
   * This function is used to append recent search  DOM elements to autocomplete list
   */
  appendRecentSearchItems: function() {
    var self = this;
    var elHeader;

    self.updateRecentPredictions();

    if (this.options.disableRecentSearch || this.predictions.recentPredictions.length === 0) {
      return false;
    }

    elHeader = document.createElement('li');
    elHeader.setAttribute('class', this.options.listHeaderCss);
    elHeader.innerHTML = '<span class="t-icon-clock autocomplete-recent-icon"></span>'
      + '<strong>' + this.options.recentLabel + '</strong>'
      + '<a href="#clear-recent-search" class="clear-recent-search">' + this.options.clearRecentLabel + '</a>';
    self.predictionListEl.appendChild(elHeader);
    elHeader.querySelector('.clear-recent-search').addEventListener('click', this.clearRecentSearch.bind(this));

    this.predictions.recentPredictions.forEach(function(item, index) {
      self.createAutocompleteList('recent',index,item,(index != 0));
    });
  },
  /**
   * Create autocomplete item list
   * @param {String} [type] - Autocomplete item type(recent,prediction)
   * @param {Number} [index] - Autocomplete list item index
   * @param {Object} [item] - Autocomplete list item contains mainText/secondaryText
   * @param {Boolean} [isActiveItem] - Check if autocomplete item is active or not
   */
  createAutocompleteList: function(type,index,item,isActiveItem) {
    var self = this;
    var el = document.createElement('li');
    el.setAttribute('data-type', type);
    el.setAttribute('data-index', index);
    el.setAttribute('class', (isActiveItem ? self.options.listItemCss : self.options.listItemCss + ' ' + self.options.activeListItemCss));
    el.innerHTML = '<a>'
      + '<strong>' + item.mainText + '</strong>'
      + ' ' + item.secondaryText
      + '</a>';
    
    self.predictionListEl.appendChild(el);
  },
  /**
   * This function is used to append search results DOM elements to autocomplete list
   */
  appendSearchResultItems: function() {
    var self = this;
    var elHeader;
    var recentPredictionsExists = false;

    if ( this.predictionListEl.querySelector('.' + this.options.activeListItemCss) ) {
      recentPredictionsExists = true;
    }

    // do not append DOM element if search result is empty or is not for current search term
    if (this.hasPredictions() === false) {
      return;
    }

    elHeader = document.createElement('li');
    elHeader.setAttribute('class', self.options.listHeaderCss);
    elHeader.innerHTML = '<span class="t-icon-search autocomplete-search-icon"></span>'
      + '<strong>' + self.options.searchLabel + '</strong>';
    self.predictionListEl.appendChild(elHeader);

    self.predictions.searchPredictions.forEach(function(item, index) {
      self.createAutocompleteList('prediction',index,item,(index != 0 || recentPredictionsExists));
    });
  },

  /**
   * This function is used to hide autocomplete list
   */
  blankLocationInputFlag: true,
  hideAutocompleteList: function() {
    if($(this.target).is(":focus")) {
      return;
    }

    if(this.blankLocationInputFlag && this.options.onListClose) {
      this.options.onListClose();
    }
    this.predictionListEl.style.display = 'none';
    this.isListVisible = false;
  },

  /**
   * Create and show autocomplete list for current predictions data
   */
  showAutocompleteList: function() {
    var $target = $(this.target);
    var offset = $target.offset();

    this.predictionListEl.innerHTML = '';
    this.blankLocationInputFlag = true;
    this.appendCurrentLocationItem();
    this.appendRecentSearchItems();
    this.appendSearchResultItems();

    if (this.predictionListEl.children.length === 0) {
      this.hideAutocompleteList();
      return false;
    }
    this.predictionListEl.style.left = 0;
    this.predictionListEl.style.top = this.options.listContainerEl.outerHeight() + 'px';
    this.predictionListEl.style.width = $target.outerWidth() + 'px';
    this.predictionListEl.style.position = 'absolute';
    this.predictionListEl.style.zIndex = '9999';
    this.predictionListEl.style.display = 'block';
    this.isListVisible = true;
  },

  /**
  * Function to make narrator read highlighted list item
  */
  narrateListItem: function() {
    var activeListItem = this.predictionListEl.querySelector('.' + this.options.activeListItemCss);
    var data, narrate;
    if (activeListItem) {
      data = this.getDataByListItem(activeListItem);

      if(data) {
        narrate = (data.mainText + ' ' + data.secondaryText);
        this.$narrate.text( narrate );
      }
    }
  },

  /**
   * This function is used to reset prediction list triggered on clearing search field
   */
  resetPredictions: function(options) {
    this.predictions = extend({
      searchTerm: '',
      searchPredictions: [],
      recentPredictions: []
    }, (options || {}));
  },

  /**
   * This function is used to update recent prediction list triggered on change of recent search items
   */
  updateRecentPredictions: function() {
    var self = this;
    var recentPrediction = "";
    var searchTerm = "";
    var matchedSearchIndex = 0;
    self.predictions.recentPredictions = self.recentSearch.get().filter(function(item) {
      recentPrediction = (item.mainText + ' ' + item.secondaryText).toLowerCase();
      searchTerm = self.target.value.toLowerCase();
      matchedSearchIndex = recentPrediction.indexOf(" " + searchTerm);
      return (recentPrediction.indexOf(searchTerm) == 0 || matchedSearchIndex > -1)
    });
  },

  /**
   * This function is used to update search prediction list triggered on change of search term
   */
  updateSearchPredictions: function(searchTerm, data) {
    this.predictions.searchTerm = searchTerm;
    this.predictions.searchPredictions = data;
  },

  /**
   * This function is used to navigates autocomplete list item
   * @param {Number} keyCode - Keyboard event object key code
   */
  navigateList: function(keyCode) {
    var listItems = this.predictionListEl.querySelectorAll('.' + this.options.listItemCss);
    var activeListItem = this.predictionListEl.querySelector('.' + this.options.activeListItemCss);
    var nextListItem = null;
    var data;

    // no items available
    if (listItems.length === 0) {
      this.hideAutocompleteList();
      return false;
    }

    if (activeListItem === null) {
      nextListItem = keyCode === 40 ? listItems[0] : listItems[listItems.length - 1];
    } else {
      for(var i = 0, n = listItems.length; i < n; i++) {
        if (listItems[i].className.indexOf(this.options.activeListItemCss) > -1) {
          listItems[i].className = this.options.listItemCss;
          nextListItem = keyCode === 40 ? (listItems[i + 1] || null) : (listItems[i - 1] || null);
        }
      }
    }

    if (nextListItem === null) {
      this.target.value = this.predictions.searchTerm;
    } else {
      data = this.getDataByListItem(nextListItem);
      nextListItem.className = this.options.listItemCss + ' ' + this.options.activeListItemCss;
      this.target.value = data ? (data.mainText + ' ' + data.secondaryText) : '';
    }
  },

  /**
   * This function is used to return autocomplete list item data object
   * @param {HTMLElement} el - Autocomplete list item
   * @returns {Object} Key/Value pair data object
   */
  getDataByListItem: function(el) {
    var type = el.getAttribute('data-type');
    var index = el.getAttribute('data-index') || '';
    var data = null;

    switch(type) {
      case 'prediction':
        data = this.predictions.searchPredictions[index];
        break;
      case 'recent':
        data = this.predictions.recentPredictions[index];
        break;
      default:
        data = null;
    }

    return data;
  },

  /**
   * This function is used to clear recent search list triggered on clear text click.
   */
  clearRecentSearch: function(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.recentSearch.clear();
    this.hideAutocompleteList();
  },

  /**
   * This function is used to check if current search term has predictions
   */
  hasPredictions: function() {
    return this.predictions.searchTerm === this.target.value && this.predictions.searchPredictions.length > 0;
  }
};

// export module 
extend(Autocomplete.prototype, AutocompleteAPI);
module.exports = Autocomplete;
