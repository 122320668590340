/* global msgResources */
var $ = require('jquery');
var _ = require('lodash');
var Popup = require('libs/popup');
var Constants = require('libs/constants');
var Config = require('libs/config');
var FormatPicker = require('libs/formatPicker');
var PageTakeover = require('libs/pageTakeover');
var Constants = require('libs/constants');

function TdatePicker() {
  var _self = this;
  _self.debounce = false;
}

  TdatePicker.prototype.initDatePicker = function ($el, $responsiveUtils, parentInst) {
    var _self = this;
    this.$el = $el;
    this.$parent = parentInst;
    this.parentInst = parentInst;
    this.responsiveUtils = $responsiveUtils;
    _self.redEyeUpdatedDates = {};
    _self.mobileDatePickerResetValue = {};
    _self.isAssociate = _self.$el.find('.l-date-picker').data('associateFlow');
    _self.isMobileDevice = _self.isAssociate ? false : _self.responsiveUtils.isMobileOrTablet();
    _self.formatPicker = new FormatPicker(_self.$el);
    _self.datePickerOptions = _self.$el.find('.js-date-pick-container').data('options');
    _self.nightsLabel = _self.datePickerOptions.nightsLabel.toUpperCase();
    _self.nightLabel = _self.datePickerOptions.nightLabel.toUpperCase();
    _self.continueLabel = _self.datePickerOptions.continueLabel;
    _self.continueWithLabel = _self.datePickerOptions.continueWithLabel;
    _self.invalidLabel = _self.$el.find(".js-date-from-to").data('invalidmsg');
    _self.localeOptions = _self.formatPicker.localeObj(_self.$el.find(".js-datepick-container").data('countryCode'));
    _self.lenthOfStay = _self.datePickerOptions.flexibleNight;
    _self.isnonDatedSearch = _self.$el.find('.l-date-picker').data('isNonDatedSearch') || _self.datePickerOptions.isDestPage;
    _self.hideflexibleDates = _self.$el.find('.js-date-pick-container').data('hideFlexibleDateCalendar');
    _self.isKGroupDate = _self.datePickerOptions.showKGroupDate;
    _self.kGroupDate = _self.datePickerOptions.kGroupDate;
		_self.$datePickerContainer = _self.$el.find('.js-date-pick-container');


    _self.minDate = _self.$el.find(".ccheckin").data('minValue');
    _self.checkinShowDate = new Date();
    _self.maxDate =  _self.isKGroupDate == "true" ? _self.kGroupDate : _self.$el.find(".ccheckin").data('maxValue') ;
    
    _self.timeZoneOffset = new Date().getTimezoneOffset()*60000;

    _self.nextDate = new Date(_self.checkinShowDate);
    _self.nextDate.setDate(_self.nextDate.getDate() + 1 );
    
    var weekdayShortListArr = _self.datePickerOptions.weekdayShortList.split(',');
    weekdayShortListArr.push(weekdayShortListArr.shift());

    var checkinPreFilled = _self.$el.find(".ccheckin").val() ? _self.formatPicker.getDateFromFormat(_self.$el.find(".ccheckin").val(),_self.localeOptions.formatSubmit) : "";
    var checkoutPreFilled = _self.$el.find(".ccheckout").val() ? _self.formatPicker.getDateFromFormat(_self.$el.find(".ccheckout").val(),_self.localeOptions.formatSubmit) :  "";
    _self.checkinDate = checkinPreFilled || _self.checkinShowDate;
    _self.checkoutDate = checkoutPreFilled || _self.nextDate;
    if(_self.$el.find(".fromToDate").length) {
      // Constructs HP Date value when MWI flag is on
      var countryCode = _self.$el.find(".js-datepick-container").data('countryCode');
      var fromDate = _self.formatPicker.formatDate(new Date( _self.checkinDate), _self.localeOptions.formatShortNoDay).trim();
      var toDate = _self.formatPicker.formatDate(new Date( _self.checkoutDate), _self.localeOptions.formatShortNoDay).trim();
      var splitFromDate = fromDate.split(" ");
      var splitToDate = toDate.split(" ");
      // Constructing date field based on formatShortNoDay (1 - 2 Dec or Dec 1 - 2)
      if(_self.localeOptions.formatShortNoDay.startsWith('d')) {
        if(splitFromDate[1] === splitToDate[1]) {
            fromDate =  fromDate.substring(0, fromDate.length-splitFromDate[1].length);
        }
      } else {    
        if(splitFromDate[0] === splitToDate[0]) {    
            toDate =  toDate.substring(splitToDate[0].length+1);
        } else {
            // Display only 4 character for Arabic to avoid field wrapping
            if(countryCode === 'AE') {     
               fromDate =  splitFromDate[0].substring(0, 4) + splitFromDate[1]; 
               toDate =  splitToDate[0].substring(0, 4) + splitToDate[1];   
            }
          }    
      }
      _self.$el.find(".fromToDate")[0].textContent = fromDate +  " - " +  toDate;
    }
    if(!_self.isnonDatedSearch && _self.checkinDate) {
      _self.updateCheckin(_self.checkinDate);
      _self.toggleArrows(true, 'cIn');
      _self.toggleCheckinArrow(_self.checkinDate);
    }
    
    if(!_self.isnonDatedSearch && _self.checkoutDate) {
      _self.updateCheckout(_self.checkoutDate);
      _self.toggleArrows(true, 'cOut');
      _self.toggleCheckoutArrow(_self.checkoutDate);
    }

    var mobileNumCalendar = 0;
    if(_self.maxDate === ''){
      mobileNumCalendar = 13;
    } else {
      mobileNumCalendar = _self.monthDiff(new Date(_self.minDate), new Date(_self.maxDate));
    } // Handle empty max date usecase
    
    
    var options = {
      numCalendar: _self.isMobileDevice ? mobileNumCalendar : 2,
      limitDateRanges: _self.kGroupDate ? _self.getDaysDiff(new Date(), new Date(_self.kGroupDate)) : _self.getDaysDiff(new Date(), new Date(_self.maxDate)),
      autoClose: false,
      titleMonths: _self.datePickerOptions.monthFullList.split(','),
      titleDays: weekdayShortListArr,
      startDate: _self.datePickerOptions.showRedEye == "true" ? new Date(_self.datePickerOptions.redEyeValue) : _self.newStartDate ? new Date(_self.newStartDate) :new Date(_self.minDate),
      endDate: _self.isKGroupDate == "true" ? new Date(_self.kGroupDate) : new Date(_self.maxDate),
      dateCheckIn: !_self.isnonDatedSearch ? _self.checkinDate && !_self.isMobileDevice ? new Date(_self.checkinDate) : "" :"",
      dateCheckOut: !_self.isnonDatedSearch ? _self.checkoutDate && !_self.isMobileDevice ? new Date(_self.checkoutDate) : "" : "",
      isMobile: _self.isMobileDevice,
      doneLabel: _self.datePickerOptions.doneLabel,
      startOfWeek: _self.localeOptions.firstDay,
      redEye: _self.datePickerOptions.redEyeValue,
      titleDateRange : _self.datePickerOptions.nightLabel,
      titleDateRanges: _self.datePickerOptions.nightsLabel,
      redEyePopup: _self.redEyePopup,
      $parentComponent: _self.parentInst,
      nextMonthLabel: _self.datePickerOptions.nextMonthLabel,
      prevMonthLabel: _self.datePickerOptions.prevMonthLabel
    }
    if(options.startOfWeek!=0){
      for(var j=0;j<options.startOfWeek;j++){
        options.titleDays.unshift(options.titleDays.pop())
      }
    }
    var newClass="t-datepicker"+Date.now();
    this.$el.find(".t-datepicker").addClass(newClass);
    this.$el.find("."+newClass).tDatePicker(options)
      .on("selectedCI", function (e, slDateCI) {
        _self.isRedEyeSelected = false;
        _self.updateCheckin(slDateCI, true);
        _self.$el.find(".date-focus").removeClass("date-focus");
        _self.$el.find(".ccheckout-container").addClass("date-focus");
        _self.$el.find(".ccheckout").focus();
        _self.toggleArrows(true, 'cIn');
        _self.toggleCheckinArrow(slDateCI);        
        _self.checkinGreaterCheckout(slDateCI);
        _self.$el.find(".temp-hover").removeClass("temp-hover");
        if(_self.dateChange) {
          _self.dateChange = false;
          _self.updateCheckout(_self.$el.find(".js-to-calc").val());
          _self.toggleArrows(true, 'cOut');
        }
        // when checkout is clear and user updates checkin through dates
        if(_self.$el.find(".js-to-calc").val()) {
          _self.updateCheckout(_self.$el.find(".js-to-calc").val());
          _self.toggleArrows(true, 'cOut');
        } 
      })
      .on("selectedCO", function (e, slDateCO) {
        if(_self.dateChange) {
          _self.dateChange = false;
          _self.updateCheckin(_self.$el.find(".js-from-calc").val());
          _self.toggleArrows(true, 'cIn');
        }
        _self.updateCheckout(slDateCO);
        if(!_self.isMobileDevice && _self.$el.find(".show-done-button").length == 0 && _self.$el.find(".show-flexi-date-done-button").length == 0 ) {
          $(document).click();
          if(_self.isAssociate){
            _self.$el.find(".usePoints").focus();
          }else{
            _self.$el.find(".js-rooms-guests-trigger").focus();
          }
        } else if(_self.$el.find(".show-done-button").length !== 0) {
          setTimeout(function(){
            _self.$el.find(".hws-done-button").focus();
          }, 15);
        } else if(_self.$el.find(".show-flexi-date-done-button").length !== 0) {
          setTimeout(function(){
            _self.$el.find(".flexi-date-done-button").focus();
          }, 15);
        }
        _self.toggleArrows(true, 'cOut');
        _self.toggleCheckoutArrow(slDateCO);
      });

    if(!_self.isMobileDevice) {
      _self.bindForDesktop();      
    } else {
      // For Mobile
      _self.bindForMobile();
            
    }

    _self.$el.find(".ccheckout, .ccheckin").on("keyup", function (e) {
      var isCheckIn = _self.$el.find(e.target).hasClass("ccheckin");
      var value = $(this).val();

      _self.toggleArrows(!!value, isCheckIn ? 'cIn' : 'cOut');

      if(!value){
        _self.$el.find(".nights-count").html("");
        _self.$el.find(".mobile-night-container").hide();
        _self.$el.find(".js-continue-label").text(_self.continueLabel);
      }
      if(!_self.$parent.disableSubTakeover) {
        var isCheckInDate = isNaN(_self.$el.find(".ccheckin").val());
        var isCheckoutDate = isNaN(_self.$el.find(".ccheckout").val());
        if((isCheckInDate && isCheckoutDate) || !(isCheckInDate || isCheckoutDate)) {
          _self.$el.find(".js-done-date").removeClass("is-disabled").attr("disabled", false);
          _self.$el.find(".ccheckout").removeClass("l-shown-cs-label");
        }else {
          _self.$el.find(".js-done-date").addClass("is-disabled").attr("disabled", true);
          _self.$el.find(".ccheckout").addClass("l-shown-cs-label");
        }
      }
    });

    if(_self.datePickerOptions.flexibleSelected == "true" && !_self.hideflexibleDates) {
      _self.$el.find(".flex-date-search-input, .js-flex-check").val(true);
      _self.$el.find(".js-flex-display").show();
      _self.mobileDatePickerResetValue["flexBtnState"] = true;
      _self.$el.find(".date-picker-container, .mobile-night-container").hide();
      _self.$el.find(".js-continue-label").text(_self.continueLabel);
    }

    _self.$el.find(".datepicker-reset-button").on('click', function(e){
      e.preventDefault();
      var cIn = _self.checkinDate;
      var cOut = _self.checkoutDate;
      if(_self.isnonDatedSearch){
        _self.clearDates();
      }else {        
        if(!_self.isMobileDevice) {
            _self.$el.find(".t-datepicker").tDatePicker('update', [new Date(cIn), new Date(cOut)]);
        } else {
          _self.currentCheckinDate = new Date(cIn).setHours(0,0,0,0);
          _self.currentCheckoutDate = new Date(cOut).setHours(0,0,0,0);
          _self.$el.find(".t-start,.t-end,.t-range").removeClass("t-start t-end t-range");
          _self.$el.find("table [data-t-date ="+ _self.currentCheckinDate +"]").addClass("t-start");
          _self.$el.find("table [data-t-date = '" + _self.currentCheckoutDate + "']").addClass("t-end").removeClass("t-range");
          _self.highlightRange(_self.currentCheckinDate, _self.currentCheckoutDate);
        }
        _self.updateCheckin(cIn);
        _self.toggleCheckinArrow(cIn);
        _self.updateCheckout(cOut);
        _self.toggleCheckoutArrow(cOut);
        cIn && _self.toggleArrows(true, 'cIn');
        cOut && _self.toggleArrows(true, 'cOut');
      }
      var flexibleCheckBox = _self.$el.find(".l-dates-flexible-checkbox");
      if(flexibleCheckBox.is(':checked')){
        flexibleCheckBox.trigger('click');
      }
     
      // _self.$el.find(".t-datepicker").tDatePicker('show');
      _self.$el.find(".ccheckin ").focus();
      _self.scrollTopToOffset("checkin", true);
      if(_self.isMobileDevice) {
       _self.$el.find(".flexi-date-done-button").focus();
       _self.$el.find(".tab-container1").scrollTop(0);
      }
      return false;
    });

    _self.$el.find(".flex-reset-button").on('click', function(){
      _self.$el.find('.js-flex-month.is-selected').blur();
      _self.$el.find(_self.$el.find('.js-flex-month')[0]).trigger('click');
      _self.setStepperVal(1, _self);
      setTimeout(function(){
        _self.$el.find(".js-plus").focus()
      },100);
    });
    
    _self.$el.find(".js-flex-display").on("click", function(e){
      _self.$el.find(".tabs.selected").removeAttr("aria-label");
      _self.$el.find(".tabs.selected").removeClass("selected");
      _self.$el.find(".tab2").addClass("selected");
      _self.$el.find(".tab2").attr('aria-label', _self.$el.data('selected-text') + ' ' + _self.$el.find(".tab2").html());
      _self.$el.find(".tab-container").hide();
      _self.$el.find(".tab-container2").show();
      _self.$el.find(".flex-date-search-input, .js-flex-check").val(true);
      _self.setStepperValue(); 
      _self.showDatePicker();
      _self.$el.find(".l-done-container").hide();
      if(_self.$el.find(e.target).parents(".tile-hsearch-tiers").length || _self.$el.find(e.target).parents(".tile-hsearch-hws").length) {
        _self.parentInst.expandSearchForm();
      }
    });

    _self.$el.find(".js-flex-display").on("focus", function(e){
      if(_self.$el.find(e.target).parents(".tile-hsearch-tiers").length || _self.$el.find(e.target).parents(".tile-hsearch-hws").length) {
        _self.parentInst.expandSearchForm();
      }
    });

    _self.$el.find('.js-red-eye-popup .js-okay-btn, .js-red-eye-popup .js-cancel-btn').off().on('click', function(e){
      // stop propagation to avoid document click which closes the datepicker on advanced search page
      e.stopPropagation();           
      e.preventDefault();
      var redEyeCheckin = new Date(_self.datePickerOptions.redEyeValue);
      var redEyeCheckout = _self.redEyeUpdatedDates.checkout || new Date(_self.$el.find(".js-to-calc").val() || _self.getNextDate(redEyeCheckin));
      if($(this).hasClass("js-okay-btn")) {
        setTimeout(function(){
          _self.validateDates(redEyeCheckin, redEyeCheckout, true);
        },10);
        setTimeout(function(){
          _self.$el.find(".ccheckout").focus();
          _self.toggleArrows(true, 'cOut');
          _self.toggleArrows(true, 'cIn');
          _self.redEyeUpdatedDates.checkout = "";
        }, 150);
      }else{
        _self.resetToPreviousState();
      }
      _self.parentInst.pubsub.publish('POPUP_CLOSE');
    });

    _self.initFlexDate();
    _self.initAccessibility();
    if(_self.isnonDatedSearch) {
      _self.$el.find(".mobile-night-container").hide();
      _self.$el.find(".js-continue-label").text(_self.continueLabel);
    }
    _self.$el.find(".prev-checkin, .next-checkin, .prev-checkout, .next-checkout").attr("tabIndex", "-1");
    _self.watchScreenSize();
  };

  TdatePicker.prototype.resetToPreviousState = function(){
    var _self = this;
    _self.isRedEyeSelected = false;
    if(!_self.$el.find(".js-to-calc").val()){
      _self.clearDates();
    }else{
      _self.currentCheckinDate = "";
      _self.currentCheckoutDate= "";
      _self.$el.find(".ccheckin, .ccheckout").val('');
      _self.validateDates();
    }
    $(document).click();
  }

  TdatePicker.prototype.bindForMobile = function() {
    var _self = this;

    _self.$el.find(".ccheckin, .ccheckout").prop('readonly', true);

    if(!_self.$el.find(".t-datepicker-day").length) {
      _self.$el.find(".t-datepicker").tDatePicker("show");
      setTimeout(function(){
        if(!_self.isnonDatedSearch) {
          _self.currentCheckinDate = new Date(_self.checkinDate).setHours(0,0,0,0);
          _self.currentCheckoutDate = new Date(_self.checkoutDate).setHours(0,0,0,0);
          
          if(_self.currentCheckinDate) {
            _self.$el.find("table [data-t-date ="+ _self.currentCheckinDate+"]").addClass("t-start");
          }
          if(_self.currentCheckoutDate) {
            _self.$el.find("table [data-t-date = '" + _self.currentCheckoutDate + "']").addClass("t-end").removeClass("t-range");
          }
        }
      },500);
    }
    _self.$el.find(".ccheckin, .ccheckout, .js-flex-display").on("click", function(event){
      _self.mobileInputClick(event);        
    });

    _self.$el.find(".l-m-placeholder").on("click", function(event){
      event.preventDefault();
      _self.$el.find(".ccheckout").trigger("click");
    });

    //Handling for Go Button in Devices
    _self.$el.find(".ccheckin, .ccheckout").on("keydown", function(event){
      if(event.which=="13"){
        _self.$el.find("form").submit();
      }        
    });

    _self.$el.find(".tab1.tabs.focus-elem").on("click", function(event){
      event.preventDefault();
      if(_self.$el.data('show-sub-takeover') && _self.responsiveUtils.isMobileOrTablet()) {
        _self.$el.find('.l-date-picker-actions').removeClass('is-hidden');
      }
    });

    _self.$el.find(".ccheckout, .ccheckin").on("focus", function (e) {
      _self.$el.find(".date-focus").removeClass("date-focus");
      _self.$el.find(e.target).closest(".date-picker-container").addClass("date-focus");
      if(_self.$el.find(e.target).hasClass("ccheckin")) {
        _self.focusElem = "checkin";
      } else {
        _self.focusElem = "checkout";
      }
      //if(!_self.$el.hasClass("is-date-open") && !_self.$parent.disableSubTakeover){
       // _self.$el.find(e.target).trigger('click');
      //}
    });
    _self.$el.find(".js-done-date, .flex-done-button, .flexi-date-done-button").on("click", function(e) {
      if(_self.isMobile && (_self.$el.find(e.target).hasClass("js-done-date") || _self.$el.find(e.target).parent().hasClass("js-done-date")) && _self.$el.find(".js-done-date").hasClass("is-disabled")){
        return false;
      }
      _self.$el.find(".tab-container1").scrollTop(0);
      e.preventDefault();
      _self.$el.removeClass("is-date-open");
      $('.date-modal-open').removeClass("date-modal-open");
      _self.hideDatePicker();
      _self.$el.find(".ccheckin, .ccheckout").prop('readonly', true);
      $(".picker-content").removeClass("picker-content");
      _self.$el.find(".l-done-container").removeAttr("style");

      if(_self.dateChange) {
        _self.validateDesktopDates();
      } else {
        if(_self.currentCheckinDate && !_self.currentCheckoutDate) {
          _self.resetDateMobile();
        }
        // when only checkout changed to empty 
        if(!_self.currentCheckinDate && _self.currentCheckoutDate) {
          _self.resetDateMobile();
        }
      }
      _self.mobileDatePickerResetValue.checkIn = null;
      _self.mobileDatePickerResetValue.checkOut = null;
      if($(e.target).hasClass("flex-done-button") || $(e.target).parent().hasClass("flex-done-button")){
        _self.mobileDatePickerResetValue["flexBtnState"] = true;
      }else {
        _self.mobileDatePickerResetValue["flexBtnState"] = false;
      }
    });
    
    _self.$el.find(".js-m-date-cancel").on("click", function(event){
      event.preventDefault();
      event.stopPropagation();
      var obj = _self.mobileDatePickerResetValue;
      $('body').removeClass('date-picker-sub-takeover-opened');
      if(!(_self.hideflexibleDates || obj["flexBtnState"])){
        _self.$el.find(".tab1").trigger("click");
      }else if(obj["flexBtnState"] && !_self.hideflexibleDates){
        _self.$el.find(".tab2").trigger("click");
      }
      _self.$el.find(".tab-container1").scrollTop(0);
      _self.$el.removeClass("is-date-open");
      $('.date-modal-open').removeClass("date-modal-open");
      _self.hideDatePicker();
      _self.$el.find(".ccheckin, .ccheckout").prop('readonly', true);
      $(".picker-content").removeClass("picker-content");
      _self.$el.find(".l-done-container").removeAttr("style");
      if(obj["flexBtnState"]){
        _self.$el.find('.js-flex-month.is-selected').blur();
        _self.$el.find(_self.$el.find('.js-flex-month')[obj.flexibleCalendarMonth]).trigger('click');
        _self.setStepperVal(obj.flexibleCalendarNight, _self);
      }else {
        if(!(obj.checkIn || obj.checkOut)){
          _self.clearDates(false);
          _self.$el.find(".ccheckout").removeClass("l-shown-cs-label");
          return;
        }
        _self.currentCheckinDate = obj.checkIn;
        _self.currentCheckoutDate = obj.checkOut;
        _self.updateCheckin(obj.checkIn);
        _self.updateCheckout(obj.checkOut);
        _self.$el.find(".t-start,.t-end,.t-range").removeClass("t-start t-end t-range");
        _self.$el.find("table [data-t-date ="+ obj.checkIn +"]").addClass("t-start");
        _self.$el.find("table [data-t-date ="+ obj.checkOut +"]").addClass("t-end");
        _self.highlightRange(obj.checkIn, obj.checkOut);
        if(_self.hideflexibleDates){
          _self.$el.find(".show-flexible-dates-checkbox .l-dates-flexible-checkbox").prop("checked", obj.flexibleCheckState);
        }
      }
      obj.checkIn = null;
      obj.checkOut = null;
    });
    _self.$el.find(".ccheckin, .ccheckout").on("change", _self.handleCiCoChange.bind(_self));
    _self.$el.find(".tabs").on('click', function(e){
      e.stopPropagation();
      _self.$el.find(".selected").removeAttr("aria-label");
      _self.$el.find(".selected").removeClass("selected");
        _self.$el.find(e.target).addClass("selected");
        _self.$el.find(e.target).attr('aria-label', '');
        _self.$el.find(".tab-container").hide();
        _self.$el.find("." + _self.$el.find(e.target).data('container')).show();
        if(_self.$el.find(e.target).hasClass("tab2")) {
          _self.$el.find(".flex-date-search-input").val(true);
          _self.$el.find(".js-flex-check").prop("checked",true).prop("value",true);
          _self.$el.find(".js-flex-display").show();
          _self.$el.find(".date-picker-container, .mobile-night-container").hide();
          _self.$el.find(".js-continue-label").text(_self.continueLabel);
          _self.$el.find(".l-done-container").hide();
          if(!_self.$el.find(".js-from-calc").val() && !_self.$el.find(".js-to-calc").val()) {
            _self.$el.find(_self.$el.find('.js-flex-month')[0]).trigger('click');
            _self.setStepperVal(1, _self);
          } else {
            _self.setStepperValue();
          }
        } else {
          _self.$el.find(".flex-date-search-input, .js-flex-check").val(false);
          _self.$el.find(".js-flex-display").hide();
          _self.$el.find(".date-picker-container, .mobile-night-container").show();
          _self.$el.find(".js-continue-label").text(_self.continueWithLabel);
          _self.$el.find(".ccheckin").trigger('click');
          _self.$el.find(".tab-container1").scrollTop(0);
          _self.scrollTopToOffset("checkin", true);
          _self.$el.find(".l-done-container").show();
          _self.updateTotalNights();
        }
    });
    $(document).on('click', function(e){
      if(_self.$el.find(e.target).hasClass("tabs")) {
        return;
      }
    });
  };

  TdatePicker.prototype.mobileResetDataCollector = function() {
    var _self = this;
    var obj = _self.mobileDatePickerResetValue;
    if((obj["checkIn"] === null && obj["checkOut"] == null) || (obj["checkIn"] === undefined && obj["checkOut"] == undefined)) {
      obj["checkIn"] = new Date(_self.$el.find(".js-from-calc").val()).setHours(0,0,0,0);
      obj["checkOut"] = new Date(_self.$el.find(".js-to-calc").val()).setHours(0,0,0,0);
      obj["flexibleCalendarMonth"] = _self.$el.find('.js-flex-month.is-selected').index();
      obj["flexibleCalendarNight"] = _self.$el.find('.js-flex-input').val();
    }
    if(_self.hideflexibleDates){
      obj["flexibleCheckState"] =_self.$el.find(".l-dates-flexible-checkbox").prop("checked");
    }
    _self.$el.find(".js-done-date").removeClass("is-disabled").attr("disabled", false);
  }

  TdatePicker.prototype.handleCiCoChange = function(e) {
    var _self = this;

      if(_self.handleDebounce()){
        return;
      }

      var currValue = $(e.target).val(),
      isCheckin = _self.$el.find(e.target).hasClass("ccheckin"),
        currDate = new Date(currValue),
        presentYear = new Date().getFullYear(),
        checkinYear = new Date(_self.$el.find(".js-from-calc").val()).getFullYear(),
        checkoutYear = new Date(_self.$el.find(".js-to-calc").val()).getFullYear();
      if(isNaN(currDate)) {
        currDate = new Date(_self.formatPicker.getDateFromMultipleLocales(currValue, _self.localeOptions));
      }
        _self.dateChange = true;
        _self.checkinChange = isCheckin;
        _self.checkoutChange = !isCheckin;

      if (!isNaN(currDate)) {

        if(currDate.getFullYear() < presentYear) {
          currDate.setFullYear(presentYear);
          if(_self.checkinChange && !isNaN(checkinYear)) {
            currDate.setFullYear(checkinYear);
          } else if(_self.checkoutChange && !isNaN(checkoutYear)) {
            currDate.setFullYear(checkoutYear);
          }
        }

        if(isCheckin){
          _self.isRedEyeSelected = false;
          _self.currentCheckinDate = currDate;
        } else {
          _self.currentCheckoutDate = currDate;
        }
      } else {
          if(_self.$el.find(e.target).val()) {
            alert(_self.invalidLabel);
          }
      }
      _self.validateDesktopDates(isNaN(currDate), e);
      if(!_self.$parent.disableSubTakeover) {
        setTimeout(function(){
          var isCheckInDate = isNaN(_self.$el.find(".ccheckin").val());
          var isCheckoutDate = isNaN(_self.$el.find(".ccheckout").val());
          if((isCheckInDate && isCheckoutDate) || !(isCheckInDate || isCheckoutDate)) {
            _self.$el.find(".js-done-date").removeClass("is-disabled").attr("disabled", false);
            _self.$el.find(".ccheckout").removeClass("l-shown-cs-label");
          }else {
            _self.$el.find(".js-done-date").addClass("is-disabled").attr("disabled", true);
            _self.$el.find(".ccheckout").addClass("l-shown-cs-label");
          }
        }, 100);
      }
    }

  TdatePicker.prototype.checkDates = function() { 
    var _self = this;
    if(!_self.$el.find(".ccheckin").val() && !_self.$el.find(".ccheckout").val()) {
      _self.$el.find(".js-submit-from, .js-submit-to, .fromToDate_submit,.js-from-calc,.js-to-calc").val("");
      _self.updateTotalNights();
      _self.$el.find(".t-start,.t-end,.t-range").removeClass("t-start t-end t-range");
      _self.currentCheckinDate = "";
      _self.currentCheckoutDate = "";
    }
  };

  TdatePicker.prototype.redEyePopup = function(cin, cout) {
    var redEyePopup = new Popup({
      open: true,
      items: {
        src: '.js-red-eye-popup',
        type: 'inline'
      },
      selector: '.red-eye',
      alternateCloseMarkup: true,
      sourceBlock: '.modal-content',
      mainClass: 'modal-content'
    });
    redEyePopup.register();
  }

  TdatePicker.prototype.bindForDesktop = function() {
    var _self = this;

    _self.$el.find(".ccheckin").on("click", function (e) {
      $(this).focus();
    });
    _self.$el.find(".focus-elem").on('mousedown', function(e){
      e.preventDefault();
    });

    _self.$el.find(".ccheckout").on("click", function (e) {
      e.stopPropagation();
      e.preventDefault();
      if(_self.$el.find(".temp-hover").length) {
        _self.$el.find(".temp-hover").click();
      }
      if(!_self.$el.find('.t-datepicker').tDatePicker('getDate')){
        _self.$el.find(".ccheckin").focus();
        return;
      }
      $(this).focus();
    });
    document.querySelector('body').addEventListener('click', function(e) {
      var $target = $(e.target);
      if($target.hasClass("mfp-content") || $target.parents('.mfp-content').length) {
        if(!($target.parents('.js-datepick-container').length) && _self.$el.find(".t-datepicker-day").length){
          _self.hideDatePicker();
          $(document).click();
        }
      }      
    }, true);
    _self.$el.find(".js-search-location").on("focus", function() {
      _self.toggleCheckinArrow(_self.$el.find(".js-from-calc").val());
      _self.toggleCheckoutArrow(_self.$el.find(".js-to-calc").val());
    });
    
    _self.$el.find(".ccheckout, .ccheckin").on("focus", function (e) {
      var $target = _self.$el.find(e.target);
      _self.isActiveDatePicker = true;
      _self.toggleCheckinArrow(_self.$el.find(".js-from-calc").val());
      _self.toggleCheckoutArrow(_self.$el.find(".js-to-calc").val());
      if($target.hasClass('ccheckout') && (_self.$el.find(".ccheckout").val() === "") && (_self.$el.find(".ccheckin").val() === "")){
        _self.$el.find(".ccheckin").focus();
        return;
      }
      else if($target.hasClass('ccheckin') && !_self.$el.find('.t-datepicker').tDatePicker('getDateInputs')[1] && _self.$el.find('.t-datepicker').tDatePicker('getDateInputs')[0]){
        // _self.changeCheckin();
      }
      _self.$el.find(".date-focus").removeClass("date-focus");
      $target.closest(".date-picker-container").addClass("date-focus");

      var pClass, pickerClass;
      if($target.hasClass('ccheckout')){
        pClass = 't-check-out';
        pickerClass = 't-date-check-out';
      }else{
        pClass = 't-check-in';
        pickerClass = 't-date-check-in';
      }
      if(_self.$el.find(".t-datepicker-day").length == 0 || _self.$el.find('.t-datepicker').tDatePicker('getDate')) {
        if(!_self.$el.find(".t-datepicker-day").parents("."+pClass).length){
          _self.$el.find("."+pickerClass).trigger("click"); 
        }
      }     
      _self.showDatePicker(); 
      _self.$el.find(".l-date-part").hide();
      if(_self.isMobileDevice) {
        _self.$el.addClass("is-date-open");
        $('html,body').addClass("date-modal-open");
        _self.calculateDatepickerHeight();
      }
      _self.parentInst.expandSearchForm();
      if($(".ios").length) {
        setTimeout(function(){
          $(".tab-container1").scrollTop($(".tab-container1").scrollTop() - 1);
        }, 10);
      }
    });


    _self.$el.find(".prev-checkin, .next-checkin, .prev-checkout, .next-checkout").on("focus", function(e) {
      if(_self.$el.find(e.target).parents(".tile-hsearch-tiers").length || _self.$el.find(e.target).parents(".tile-hsearch-hws").length) {
        _self.parentInst.expandSearchForm();
      }
    });

    _self.$el.on("click", ".prev-checkin, .next-checkin", function(e) {
      if(_self.handleDebounce()){
        return;
      }
      _self.setCheckinDateOnArrow(e);
      if(_self.$el.find(e.target).parents(".tile-hsearch-tiers").length || _self.$el.find(e.target).parents(".tile-hsearch-hws").length) {
        _self.parentInst.expandSearchForm();
      }
    });

    _self.$el.find(".prev-checkout, .next-checkout").on("click", function(e) {
      if(_self.handleDebounce()){
        return;
      }
      _self.setCheckoutDateOnArrow(e);
      if(_self.$el.find(e.target).parents(".tile-hsearch-tiers").length || _self.$el.find(e.target).parents(".tile-hsearch-hws").length) {
        _self.parentInst.expandSearchForm();
      }
    });

    _self.$el.find(".hws-done-button, .flex-done-button, .flexi-date-done-button").on("click", function(e) {
      e.preventDefault();
      _self.hideDatePicker();
      $(document).click();
    });

    _self.$el.on("keypress", ".hws-done-button", function(e) {
      if(e.which === 13) {
        setTimeout(function(){
          _self.$el.find(".js-rooms-guests .js-rooms-guests-trigger").focus();
        }, 15);
      }
    });

    _self.$el.find(".ccheckin, .ccheckout").on("change", _self.handleCiCoChange.bind(_self));

    $(document).on('click', function(e, eventParam){
      _self.documentClicked(e, eventParam);
    });

    _self.$el.on("keypress", ".l-dates-flexible-checkbox", function(e) {
      if(e.which === 13) {
        e.preventDefault();
      }
    });
  };

  TdatePicker.prototype.mobileInputClick = function(event) {
    var _self = this;
    var isScroll = _self.$el.hasClass("is-date-open") ? false : true;
    _self.highlightRange(_self.currentCheckinDate, _self.currentCheckoutDate);

    _self.$el.find("td.t-day span, td.t-max-checkin span").off('click').on("click", function(e) {
      e.stopImmediatePropagation();
      _self.dateClicked(e);
    });

    _self.$el.find(".date-focus, .checkout-focus, .checkin-focus").removeClass("date-focus checkout-focus checkin-focus");
    if(!_self.$el.find(event.target).is(":focus")){
      _self.$el.find(event.target).focus();
    }
    if(_self.$el.find(event.target).hasClass("ccheckin")) {
      _self.focusElem = "checkin";
      _self.$el.find(".ccheckin-container").addClass("date-focus");
      _self.$el.find(".new-container").addClass("checkin-focus");
      _self.$el.find(".m-disable").removeClass("m-disable");
      _self.checkDates();
    } else {
      _self.$el.find(".m-disable").removeClass("m-disable");
      if(!_self.$el.find(".ccheckin").val() && !_self.$el.find(".ccheckout").val()) {
        _self.$el.find(".ccheckin").trigger('click').focus();
        return false;
      }
      _self.focusElem = "checkout";
      _self.$el.find(".new-container").addClass("checkout-focus");
      _self.$el.find(".ccheckout-container").addClass("date-focus");
    }

    _self.$el.addClass("is-date-open");
    $('body').addClass("date-modal-open");
    if(!_self.$parent.disableSubTakeover) {
      $('body').addClass('date-picker-sub-takeover-opened');
    }
    _self.mobileResetDataCollector();
    _self.calculateDatepickerHeight();
    _self.showDatePicker();
    _self.scrollTopToOffset(_self.focusElem, isScroll);
    if(_self.$el.find(event.target).prop('readonly')) {
      _self.$el.find(".ccheckin, .ccheckout").prop('readonly', false).blur();
    }
     _self.$el.parents(".m-header-container").addClass("picker-content");

  };

  TdatePicker.prototype.scrollTopToOffset = function(elem, val) {
    var $startDate,
      _self = this;
    if(elem == "checkin") {
      $startDate = _self.$el.find(".t-start").closest(".t-table-wrap");
    } else {
      $startDate = _self.$el.find(".t-end").closest(".t-table-wrap");
    }
    var scrollTopDate = $startDate.length ? $startDate.offset().top - 200 : 0;
    if(scrollTopDate > 0 && !_self.$el.find(".tabbing").hasClass("is-visible") || val) {
      _self.$el.find(".tab-container1").scrollTop(scrollTopDate);
    }
  };

  TdatePicker.prototype.dateClicked = function(e) {
    var _self = this,
      date_elem = _self.$el.find(e.target).closest('td'),
      start_date = _self.$el.find(".t-start");
      end_date = _self.$el.find(".t-end");

      _self.currentCheckinDate = _self.currentCheckinDate || new Date(_self.$el.find(".js-from-calc").val()).setHours(0,0,0,0);
      _self.currentCheckoutDate = _self.currentCheckoutDate || new Date(_self.$el.find(".js-to-calc").val()).setHours(0,0,0,0);
    
      if(_self.focusElem == "checkin") {

        if(_self.$el.find(date_elem).hasClass("t-max-checkin")) {
          return;
        }

        if(!start_date.length || date_elem.data('t-date') < _self.currentCheckinDate || (date_elem.data('t-date') > _self.currentCheckinDate && date_elem.data('t-date') < _self.currentCheckoutDate || !end_date.length)){
          _self.$el.find(".t-start").removeClass("t-start");
          date_elem.addClass("t-start");
          _self.currentCheckinDate = date_elem.data('t-date');
        } 
        else if(date_elem.data('t-date') > _self.currentCheckinDate) {
          _self.$el.find(".t-start").removeClass("t-start");
          date_elem.addClass("t-start");
          _self.currentCheckinDate = date_elem.data('t-date');

          _self.$el.find(".t-end").removeClass("t-end");
          if(_self.$parent.disableSubTakeover) {
            var date1 = new Date(_self.$el.find(".t-start").data('t-date'));
            date1.setDate(date1.getDate() + 1)
            _self.$el.find("table [data-t-date = '" + date1.getTime() + "']").addClass("t-end").removeClass("t-range");
            _self.currentCheckoutDate = date1.getTime();
          }
        }
        if(!_self.$parent.disableSubTakeover) {
          _self.$el.find(".t-end,.t-range").removeClass("t-end t-range");
          _self.$el.find(".ccheckout").val('');
          _self.$el.find(".js-submit-to").val('');
          _self.$el.find(".js-to-calc").val('');
          _self.updateTotalNights();
          _self.currentCheckoutDate = '';
          _self.$el.find(".ccheckout").addClass("l-shown-cs-label");
        }
      } else {
        if(date_elem.data('t-date') <= new Date().setHours(0,0,0,0) && start_date.data('t-date') < new Date().setHours(0,0,0,0)) {
          return;
        }
        if(date_elem.hasClass("t-start")) {
          return;
        }
        if(!_self.currentCheckoutDate || date_elem.data('t-date') >_self.currentCheckinDate) {
          if(_self.$parent.disableSubTakeover) {

          if(date_elem.data('t-date') < _self.currentCheckinDate) {
            _self.$el.find(".t-start").removeClass("t-start").addClass("t-end");
            date_elem.addClass("t-start").removeClass("t-range");
            _self.currentCheckinDate = date_elem.data('t-date');
            _self.currentCheckoutDate = start_date.data('t-date');
          } else {
            _self.$el.find(".t-end").removeClass("t-end");
            date_elem.addClass("t-end").removeClass("t-range");
            _self.currentCheckoutDate = date_elem.data('t-date');
          }
        }else{
          
          if(date_elem.data('t-date') < _self.currentCheckinDate) {
            _self.$el.find(".t-start").removeClass("t-start");//.addClass("t-end");
            date_elem.addClass("t-start").removeClass("t-range");
            _self.currentCheckinDate = date_elem.data('t-date');
            // _self.currentCheckoutDate = start_date.data('t-date');
            _self.$el.find(".t-end,.t-range").removeClass("t-end t-range");
            _self.$el.find(".ccheckout").val('');
            _self.$el.find(".js-submit-to").val('');
            _self.$el.find(".js-to-calc").val('');
            _self.updateTotalNights();
            _self.currentCheckoutDate = '';
            _self.$el.find(".ccheckout").addClass("l-shown-cs-label");
          } else {
            _self.$el.find(".t-end").removeClass("t-end");
            date_elem.addClass("t-end").removeClass("t-range");
            _self.currentCheckoutDate = date_elem.data('t-date');
            setTimeout(function(){
              // _self.$el.find('.ccheckin').trigger('click');
              _self.$el.find(".date-focus, .checkout-focus, .checkin-focus").removeClass("date-focus checkout-focus checkin-focus");
              _self.$el.find(".ccheckin-container").addClass("date-focus");
              _self.$el.find(".new-container").addClass("checkin-focus");
              _self.focusElem = "checkin";
            },0);
          }

        }

        } else if(date_elem.data('t-date') < _self.currentCheckinDate) {
            _self.$el.find(".t-start").removeClass("t-start");
            date_elem.addClass("t-start");
            _self.currentCheckinDate = date_elem.data('t-date');
            if(!_self.$parent.disableSubTakeover) {
              _self.$el.find(".t-end,.t-range").removeClass("t-end t-range");
              _self.$el.find(".ccheckout").val('');
              _self.$el.find(".js-submit-to").val('');
              _self.$el.find(".js-to-calc").val('');
              _self.updateTotalNights();
              _self.currentCheckoutDate = '';
              _self.$el.find(".ccheckout").addClass("l-shown-cs-label");
            }
        } 
      }
      if(_self.currentCheckinDate) {
        _self.updateCheckin(_self.currentCheckinDate);
      }
      if(_self.currentCheckoutDate) {
        _self.updateCheckout(_self.currentCheckoutDate);
      }
      if((_self.currentCheckinDate && _self.currentCheckoutDate) || !(_self.currentCheckinDate || _self.currentCheckoutDate)) {
        _self.$el.find(".js-done-date").removeClass("is-disabled").attr("disabled", false);
      }else if(!_self.$parent.disableSubTakeover){
        _self.$el.find(".js-done-date").addClass("is-disabled").attr("disabled", true);
      }
      _self.$el.find(".date-focus, .checkout-focus, .checkin-focus").removeClass("date-focus checkout-focus checkin-focus");
      _self.$el.find(".ccheckout-container").addClass("date-focus");
      _self.$el.find(".new-container").addClass("checkout-focus");
      _self.focusElem = "checkout";
      _self.highlightRange(_self.currentCheckinDate || _self.$el.find(".js-from-calc").val(), _self.currentCheckoutDate || _self.$el.find(".js-to-calc").val());

      if(_self.currentCheckoutDate) {
        var nightCount = _self.getDaysDiff(_self.currentCheckinDate, _self.currentCheckoutDate)
        if(nightCount > 0) {
          _self.$el.find(date_elem).append("<span class='t-hover-day-content'>" + nightCount + " " + (nightCount > 1 ? _self.nightsLabel : _self.nightLabel) +" </span>");
        }        
        setTimeout(function(){
          _self.$el.find(".t-hover-day-content").remove();
        },1000);
      }
      _self.$el.find(".m-disable").removeClass("m-disable");
  };

  TdatePicker.prototype.disablePrevDates = function(data_checkin, data_checkout) { 
    var _self = this,
      all_td = _self.$el.find("td.t-day"),
      all_td_length = all_td.length;
      for(var x = 0; x < all_td_length; x++) {
        if(_self.$el.find(all_td[x]).hasClass("t-start")) {
          break;
        }
        _self.$el.find(all_td[x]).addClass("m-disable");
      }

  };
  TdatePicker.prototype.highlightRange = function(data_checkin, data_checkout) {
    var _self = this,
      all_td = _self.$el.find("td.t-day"),
      all_td_length = all_td.length,
      t_range = false;

    if(data_checkin && data_checkout) {
      _self.$el.find(".t-range").removeClass("t-range");
      for (var i=0; i < all_td_length; i++) {
        if(_self.$el.find(all_td[i-1]).hasClass("t-start")){
          t_range = true;
        }
        if(t_range) {
          _self.$el.find(all_td[i]).addClass("t-range");
        }
        if(_self.$el.find(all_td[i]).hasClass("t-end")){
          t_range = false;
          return;
        }
      }
    }
  }

  TdatePicker.prototype.calculateDatepickerHeight = function() {
    var _self = this,
      docHeight = $(window).height(),
      tabElem = _self.$el.find(".tab"),
      doneElem = _self.$el.find(".l-done-container");
      dateHeight = docHeight - tabElem.outerHeight() - doneElem.outerHeight();
  };

  TdatePicker.prototype.monthDiff = function(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth() + 1;
    return months <= 0 ? 0 : months;
  }

  TdatePicker.prototype.checkinGreaterCheckout = function(date) {
    var _self = this;
    if(_self.compareDate(date,_self.$el.find('.js-to-calc').val())) {
      setTimeout(function(){
        var cinDate = new Date(date);
        var coutDate =new Date(date);
        coutDate.setDate(coutDate.getDate() +1);
        _self.$el.find(".t-datepicker").tDatePicker("update", [new Date(date),new Date(coutDate)]);
        _self.updateCheckout(coutDate, true); //checkin = true
        _self.toggleCheckoutArrow(coutDate);
        _self.$el.find(".ccheckout").trigger('click');
      },100);
    }
  };

  TdatePicker.prototype.documentClicked = function(e, eventParams) {
    var _self = this;
    if(_self.$el.find(e.target).parents().hasClass("hws-search-form")) {
      _self.$el = _self.$el.find(e.target).closest(".hws-search-form");
    }
    if(_self.$el.find(e.target).hasClass("tabs")) {
      _self.$el.find(".selected").removeAttr("aria-label");
      _self.$el.find(".selected").removeClass("selected");
      _self.$el.find(e.target).addClass("selected");
      _self.$el.find(e.target).attr('aria-label', _self.$el.data('selected-text') + ' ' + _self.$el.find(e.target).html());
      _self.$el.find(".tab-container").hide();
      _self.$el.find("." + _self.$el.find(e.target).data('container')).show();
      if(_self.$el.find(e.target).hasClass("tab2")) {
        _self.$el.find(".flex-date-search-input").val(true);
        _self.$el.find(".js-flex-check").prop("checked",true).prop("value",true);
        _self.$el.find(".js-flex-display").show();
        _self.$el.find(".date-picker-container, .mobile-night-container").hide();
        _self.$el.find(".js-continue-label").text(_self.continueLabel);
        if(!_self.$el.find(".js-from-calc").val() && !_self.$el.find(".js-to-calc").val()) {
          _self.$el.find(_self.$el.find('.js-flex-month')[0]).trigger('click');
          _self.setStepperVal(1, _self);
        } else {
          _self.setStepperValue();
        }
      } else {
        if(_self.flexCheckinDate) {
          _self.setDateFromFlexDesktop(_self.flexCheckinDate);
          _self.flexCheckinDate = "";
          _self.$el.find(".t-prev").click();
        }
        _self.$el.find(".flex-date-search-input, .js-flex-check").val(false);
        _self.$el.find(".js-flex-display").hide();
        _self.$el.find(".date-picker-container, .mobile-night-container").show();
        _self.$el.find(".js-continue-label").text(_self.continueWithLabel);
        _self.$el.find(".ccheckin").trigger('click');
        _self.updateTotalNights();

        _self.toggleArrows(!!_self.$el.find(".ccheckin").val(), 'cIn');
        _self.toggleArrows(!!_self.$el.find(".ccheckout").val(), 'cOut');
      }
      return;
    }
    
    if(_self.$el.find(e.target).hasClass("js-clear-dates")){
       _self.clearDates(true);
      return;

    }
    if(_self.$el.find(e.target).parents('.js-datepick-container').length && !_self.$el.find(e.target).hasClass("hws-done-button")){
     return;
    }
    if(_self.$el.find(e.target).hasClass("hws-done-button") && !_self.isMobile) {
      _self.$el.find('.t-datepicker-open').removeClass('t-datepicker-open');
      _self.$el.find('.t-datepicker-day').remove();
      _self.parentInst.showNavigationBar();
      var mfpWrap = _self.$el.closest(".mfp-wrap");
      if(mfpWrap.length) {
        mfpWrap.scrollTop(mfpWrap.scrollTop()-1);
      }
    }    

    if(!_self.$el.find(e.target).closest(".m-date-picker").length) {
      _self.hideDatePicker();
    }

    if(_self.isMobileDevice) {
      _self.$el.removeClass("is-date-open");
      $('.date-modal-open').removeClass("date-modal-open");
    }

    _self.$el.find(".date-focus").removeClass("date-focus");
    _self.$el.find(".l-date-part").show();

     


      if(_self.dateChange && !_self.handleDebounce()) {
        _self.validateDesktopDates();
      }else if(_self.isActiveDatePicker && !($(e.target).closest('.m-date-picker').length)){
        _self.isActiveDatePicker = false;
        _self.validateDesktopDates(null, null, eventParams);
      }
  };

  TdatePicker.prototype.toggleArrows = function(show, type) {
    switch(type){
      case 'cIn': {
        this.$el.find(".prev-checkin, .next-checkin").css('visibility', show ? 'visible' : 'hidden');
        break;
      }
      case 'cOut': {
        this.$el.find(".prev-checkout, .next-checkout").css('visibility', show ? 'visible' : 'hidden');
        break;
      }
      default:
      break;
    }
  };

  TdatePicker.prototype.validateDesktopDates = function(isInvalid, event, eventParams) {
    var _self = this;
    setTimeout(function(){
      _self.dateChange = false;
    },10);
    var $checkin = _self.$el.find(".ccheckin");
    var $checkout = _self.$el.find(".ccheckout");
    if(!$checkin.val() && !$checkout.val()) {
      setTimeout(function(){
        if(!_self.isMobileDevice || _self.$parent.disableSubTakeover || (_self.isMobileDevice && !(event && event.type == 'change'))){
          _self.clearDates();
        }
        if(_self.$el.find(".js-datepick-container :focus").length || _self.$el.find(".date-focus").length) {
          if(!_self.isMobileDevice) {
            _self.$el.find(".t-date-check-in").trigger('click');
          }
        }
        if(!_self.isMobileDevice || _self.$parent.disableSubTakeover || (_self.isMobileDevice && !(event && event.type == 'change'))){
          _self.$el.find(".t-start,.t-end,.t-range").removeClass("t-start t-end t-range");
        }
      },0);
    }else if(!$checkin.val() || !$checkout.val()) {
      setTimeout(function(){

        var cInHidden = _self.$el.find(".js-from-calc").val();
        var cOutHidden = _self.$el.find(".js-to-calc").val();
        var cInInput = new Date(_self.formatPicker.getDateFromMultipleLocales(_self.$el.find(".ccheckin").val(), _self.localeOptions));
        var cOutInput = new Date(_self.formatPicker.getDateFromMultipleLocales(_self.$el.find(".ccheckout").val(), _self.localeOptions));

        if(_self.$el.find(".js-datepick-container :focus").length) {
          _self.validateCheckinCheckout(isInvalid);
          if(isInvalid && isNaN(cOutInput) && isNaN(cInInput)) {
            _self.clearDates(isInvalid);
            _self.$el.find(".ccheckin").focus();
          } else if(isInvalid && isNaN(cInInput) && isNaN(cOutInput)) {
            _self.clearDates(isInvalid);
          } else if(!isInvalid && cInHidden && cOutHidden) {
            _self.toggleArrows(true, 'cIn');
          }
          if(this.isMobileDevice) {
            _self.$el.find(event.target).val('');
          }
          if(!(eventParams && eventParams.forceValidation)){
						      return;
					     }
        }

        if(!_self.$el.find(".ccheckin").val()){
          _self.validateDates(cInHidden, cOutHidden);
          _self.toggleArrows(true, 'cIn');
          return;
        }
        if(!_self.$el.find(".ccheckout").val() && !cOutHidden){
          _self.toggleArrows(true, 'cOut');
          _self.clearDates(isInvalid);
        }else{
          _self.validateDates(cInHidden, cOutHidden);
          _self.toggleArrows(true, 'cOut');
        }
      }, 0);
    }else {

      if(isInvalid){
        var cInDate = _self.$el.find(".js-from-calc").val();
        _self.validateDates(cInDate, _self.$el.find(".js-to-calc").val() || _self.getNextDate(cInDate));
        return;
      }

      setTimeout(function(){
        if(_self.$el.find(".js-datepick-container :focus").length) {
          _self.validateCheckinCheckout(isInvalid);
          return;
        }
        _self.validateDates();
      }, 10);
    }
    return;
  };

  TdatePicker.prototype.clearDates = function(focusToCheckin){
    if(!this.isMobileDevice) {
      this.$el.find(".t-datepicker").tDatePicker('update', '');
    }else{
      this.$el.find(".t-start,.t-end,.t-range").removeClass("t-start t-end t-range");
    }
    this.$el.find(".ccheckin, .ccheckout").val('');
      this.$el.find(".js-submit-from,.js-submit-to").val('');
      this.$el.find(".js-from-calc,.js-to-calc").val('');
      this.toggleArrows(false, 'cIn');
      this.toggleArrows(false, 'cOut');
      if(focusToCheckin){
        this.$el.find(".ccheckin").trigger('click');
      }
      this.updateTotalNights();
  };

  TdatePicker.prototype.validateDates = function(cIn, cOut, hideRedEyePopup) {
    var _self = this;
    var checkin = cIn || _self.currentCheckinDate || _self.$el.find(".js-from-calc").val();
    var checkout = cOut || _self.currentCheckoutDate || _self.$el.find(".js-to-calc").val();
      
      // set date to 0 hours mins seconds to compare dates
      checkin = new Date(checkin).setHours(0,0,0,0);
      checkout = new Date(checkout).setHours(0,0,0,0);
      var maxDate = Number(new Date(_self.maxDate).setHours(0,0,0,0));
      var minDate;
      if(_self.datePickerOptions.showRedEye === "true"){
        minDate = Number(new Date(_self.datePickerOptions.redEyeValue).setHours(0,0,0,0));
      }else{
        minDate = Number(new Date(_self.minDate).setHours(0,0,0,0));
      }
      
      if(_self.datePickerOptions.showRedEye == "true") {
       var redEyeDate = new Date(_self.datePickerOptions.redEyeValue).setHours(0,0,0,0);
       var redEyeCheckout = new Date(_self.getNextDate(redEyeDate)).setHours(0,0,0,0);
       minDate = redEyeDate;
      if(!_self.isRedEyeSelected){

        if((_self.checkinChange && redEyeDate == checkin) || (_self.checkoutChange && redEyeCheckout == checkout)){
          if(!hideRedEyePopup){
          _self.checkRedEyeDateSelected(redEyeDate, checkout);
          return;

          }
        }
      }

      }


      if(!isNaN(checkin) && !isNaN(checkout)) {
        checkin = Number(checkin);
        checkout = Number(checkout);

        if(_self.checkinChange && (checkin >= checkout || checkin >= maxDate)) {
          if(checkin >= maxDate) {
            checkin =_self.getPrevDate(maxDate);
            checkout = maxDate;
          } 
          else if(checkin <= minDate) {
            checkin = Number(new Date().setHours(0,0,0,0));
            if(_self.checkoutChange || checkout <= checkin) {
              checkout = _self.getNextDate(checkin);
            }
            _self.checkinChange = false;
            _self.checkoutChange= false;
          }
          else {
            checkout = _self.getNextDate(checkin);
          }

          _self.checkinChange = false;
        } 
        else if(checkin <= minDate) {
          if(checkin < minDate){
            checkin = Number(new Date().setHours(0,0,0,0));
          }
          if(_self.datePickerOptions.showRedEye != "true" && (checkout <= new Date().setHours(0,0,0,0))) {
            checkout = _self.getNextDate(new Date());
          }else if(checkout <= checkin) {
            checkout = _self.getNextDate(checkin);
          }else if(checkout >= maxDate){
            checkout = maxDate;
          }
          _self.checkinChange = false;
          _self.checkoutChange= false;
        }
        else if(_self.checkoutChange && (checkout <= checkin || new Date().setHours(0,0,0,0) == new Date(checkout).setHours(0,0,0,0))) {

          if(checkout <= minDate) {
            checkin = Number(new Date().setHours(0,0,0,0));
            checkout = _self.getNextDate(checkin);
          } else {
            
            if(new Date().setHours(0,0,0,0) == new Date(checkout).setHours(0,0,0,0)) {
              checkin = new Date(checkout).setHours(0,0,0,0);
              checkout = new Date(_self.getNextDate(new Date())).setHours(0,0,0,0);
            } else {
              checkin = _self.getPrevDate(checkout);
            }
          }
          _self.checkoutChange = false;
        } 
        else if(checkout >= maxDate) {
          checkout = maxDate;
          _self.checkoutChange = false;
        }
        if(!_self.isMobileDevice){
          _self.$el.find(".t-datepicker").tDatePicker("update", [new Date(checkin).setHours(0,0,0,0), new Date(checkout).setHours(0,0,0,0)]); 
          if(_self.$el.find(".tabbing").hasClass("is-visible")) {
            if(_self.$el.find(".ccheckin-container").hasClass("date-focus")) {
              _self.$el.find(".t-date-check-in").click();
            } else {
              _self.$el.find(".t-date-check-out").click();
            }
          }
        }
        _self.updateCheckin(checkin);
        _self.updateCheckout(checkout);

        if(_self.isMobileDevice){
          _self.$el.find(".t-start,.t-end,.t-range").removeClass("t-start t-end t-range");
          _self.$el.find("table [data-t-date ="+ checkin+"]").addClass("t-start");
          _self.$el.find("table [data-t-date ="+ checkout+"]").addClass("t-end");
          _self.highlightRange(checkin, checkout);
        }else{
          _self.toggleCheckinArrow(checkin);
          _self.toggleCheckoutArrow(checkout);
        }
      }
      _self.currentCheckinDate = "";
      _self.currentCheckoutDate= "";
  };

  TdatePicker.prototype.validateCheckinCheckout = function(isInvalid) {
    if(isInvalid){
      return;
    }
    if(!this.currentCheckinDate && !this.currentCheckoutDate){
      return;
    }
    var cInDate = this.currentCheckinDate || this.$el.find(".js-from-calc").val(),
    cOutDate = this.currentCheckoutDate || this.$el.find(".js-to-calc").val();
    this.validateDates(
      cInDate, cOutDate || this.getNextDate(cInDate)
    );
    this.toggleArrows(true, 'cOut');
  };

  TdatePicker.prototype.getNextDate = function(date) {
    date = new Date(date);
    date.setDate(date.getDate() + 1);

    return Number(date);
  };

  TdatePicker.prototype.getPrevDate = function(date) {
    date = new Date(date);
    date.setDate(date.getDate() - 1);

    return Number(date);
  };

  TdatePicker.prototype.setCheckinDateOnArrow = function(e) {
    var _self = this,
      checkinDate = _self.$el.find(".js-from-calc").val(),
      dir = _self.$el.find(e.target).hasClass("prev-checkin") ? "prev" : "next";
    _self.setNextDate(checkinDate, dir);
  };

  TdatePicker.prototype.setCheckoutDateOnArrow = function(e) {
    var _self = this,
      checkoutDate = _self.$el.find(".js-to-calc").val(),
      dir = _self.$el.find(e.target).hasClass("prev-checkout") ? "prev" : "next";
    _self.setNextDateCheckout(checkoutDate, dir);
  };

  TdatePicker.prototype.toggleCheckoutArrow = function(currDate) {
    var _self = this;
    
    var minDate = new Date(_self.nextDate);
    var minDay = new Date(minDate.setDate(minDate.getDate()));
    if(_self.datePickerOptions.showRedEye == "true") {
      minDay = minDay.setDate(new Date(_self.datePickerOptions.redEyeValue).getDate() + 1);
    }
    minDay = new Date(minDay).getDate();
    var minMonth = minDate.getMonth();
    var minYear = minDate.getFullYear();

    var maxDate = new Date(_self.maxDate);
    var maxDay = maxDate.getDate() ;
    var maxMonth = maxDate.getMonth();
    var maxYear = maxDate.getFullYear();

    var currDate = new Date(currDate);
    var currDay = currDate.getDate();
    var currMonth = currDate.getMonth();
    var currYear = currDate.getFullYear();

    if(currYear == minYear && currMonth == minMonth && currDay  <= minDay) {
      _self.$el.find(".prev-checkout").addClass("disable-button").attr("tabIndex", "-1");
    } else {
      _self.$el.find(".prev-checkout").removeClass("disable-button").attr("tabIndex", "0");
    }
    if(currYear >= maxYear && currMonth >= maxMonth && currDay >= maxDay) {
      _self.$el.find(".next-checkout").addClass("disable-button").attr("tabIndex", "-1");
    } else {
      _self.$el.find(".next-checkout").removeClass("disable-button").attr("tabIndex", "0");
    }          
  };

  TdatePicker.prototype.toggleCheckinArrow = function(currDate) {
    var _self = this;
    var minDate = new Date(_self.$el.find(".ccheckin").data("minValue"));
    var minDay = minDate.getDate();
    if(_self.datePickerOptions.showRedEye == "true") {
      minDay = new Date(_self.datePickerOptions.redEyeValue).getDate();
    }
    var minMonth = minDate.getMonth();
    var minYear = minDate.getFullYear();

    var maxDate = new Date(_self.maxDate);
    var maxDay = new Date(maxDate.setDate(maxDate.getDate() - 1)).getDate();
    var maxMonth = maxDate.getMonth();
    var maxYear = maxDate.getFullYear();

    var currDate = new Date(currDate);
    var currDay = currDate.getDate();
    var currMonth = currDate.getMonth();
    var currYear = currDate.getFullYear();

    if(currYear == minYear && currMonth == minMonth && currDay  == minDay) {
      _self.$el.find(".prev-checkin").addClass("disable-button").attr("tabIndex", "-1");
    } else {
      _self.$el.find(".prev-checkin").removeClass("disable-button").attr("tabIndex", "0");
    }
    if(currYear == maxYear && currMonth == maxMonth && currDay == maxDay) {
      _self.$el.find(".next-checkin").addClass("disable-button").attr("tabIndex", "-1");
    } else {
      _self.$el.find(".next-checkin").removeClass("disable-button").attr("tabIndex", "0");
    }          
  };

  TdatePicker.prototype.updateCheckin = function(date, state) {
    var _self = this;
    _self.$el.find(".ccheckin").val(_self.formatPicker.formatDate(new Date(date), _self.localeOptions.formatShort));
    _self.$el.find(".js-submit-from").val(_self.formatPicker.formatDate(new Date(date), _self.localeOptions.formatSubmit));
    _self.$el.find(".js-from-calc").val(_self.formatPicker.formatDate(new Date(date), _self.localeOptions.formatCalc));
    _self.updateTotalNights(state);
    _self.updateFlexDate(date);
  };

  TdatePicker.prototype.updateCheckout = function(date, state) {
    var _self = this;
    _self.$el.find(".ccheckout").val(_self.formatPicker.formatDate(new Date(date), _self.localeOptions.formatShort));
    _self.$el.find(".js-submit-to").val(_self.formatPicker.formatDate(new Date(date), _self.localeOptions.formatSubmit));
    _self.$el.find(".fromToDate_submit").val(_self.formatPicker.formatDate(new Date(date), _self.localeOptions.formatSubmit));
    _self.$el.find(".js-to-calc").val(_self.formatPicker.formatDate(new Date(date), _self.localeOptions.formatCalc));
    _self.updateTotalNights(state);
  };

  TdatePicker.prototype.updateTotalNights = function(state) {
    var _self = this,
      nightLabel,
      checkinDate = _self.$el.find('.js-from-calc').val(),
      checkout = _self.$el.find('.js-to-calc').val(),
      date1 = new Date(checkinDate),
      date2 = new Date(checkout);
    
    if(checkinDate && !checkout) {
      _self.$el.addClass("only-checkin");
    } else {
      _self.$el.removeClass("only-checkin");
    }
    
    if(!isNaN(date1) && !isNaN(date2)) {
      var timeDiff = Math.abs(date2.getTime() - date1.getTime()),
        diffDays = Math.round(timeDiff / (1000 * 3600 * 24)); 
      if(date1 < date2) {
        nightLabel = diffDays > 1 ? _self.nightsLabel : _self.nightLabel;
        _self.$el.find(".nights-count").html(diffDays + " " + nightLabel);
        _self.$el.find(".mobile-night-container").show();
        _self.$el.find(".js-continue-label").text(_self.continueWithLabel);
        
        _self.showNightsMometarily(diffDays, nightLabel, state);

      } else {
        _self.$el.find(".nights-count").html("");
        _self.$el.find(".mobile-night-container").hide();
        _self.$el.find(".js-continue-label").text(_self.continueLabel);
      } 
    } else {
      _self.$el.find(".nights-count").html("");
      _self.$el.find(".mobile-night-container").hide();
      _self.$el.find(".js-continue-label").text(_self.continueLabel);
    }
  };

  TdatePicker.prototype.showNightsMometarily = function(diffDays, nightLabel, state) {
    var _self = this;
    var nLabel = diffDays > 1 ? _self.datePickerOptions.nightsLabel : _self.datePickerOptions.nightLabel;
    if(!_self.isMobileDevice) {
      setTimeout(function(){
        if(state) {
          _self.$el.find(".t-start").append("<span class='t-hover-day-content-desktop'>" + diffDays + " " + nLabel +"</span>");
        } else {
          _self.$el.find(".t-end").append("<span class='t-hover-day-content-desktop'>" + diffDays + " " + nLabel +"</span>");
        }
      },10);
      setTimeout(function(){
        _self.$el.find(".t-hover-day-content-desktop").remove();
      },1000);
    }
  };

  TdatePicker.prototype.compareDate = function(date1, date2) {
    var _self = this      
      
    if(date1 && date2) {
      var date1 = new Date(date1).setHours(0,0,0),
        date2 = new Date(date2).setHours(0,0,0),
        timeDiff = Math.abs(date2 - date1),
        diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
      if(date1 >= date2) {
        return true;
      }     
    } else {
      return false;
    }
  };

  TdatePicker.prototype.setNextDate = function(date, dir) {
    var _self = this,
      cinDate = new Date(date),
      coutVal = _self.$el.find(".js-to-calc").val(),
      coutDate = Boolean(coutVal) ? new Date(coutVal) : "",
      datePickerVisible = _self.$el.find(".new-container").css('visibility') == "visible" ? true : false; 
      _self.isRedEyeSelected = false;

      cinDate = new Date(cinDate.setTime(cinDate.getTime()));
    
      if(coutDate) {
        coutDate = new Date(coutDate.setTime(coutDate.getTime()));
      }

      dir == "next" ? cinDate.setDate(cinDate.getDate() + 1): cinDate.setDate(cinDate.getDate() - 1);
      //Compare for Red Eye Date selection from Arrow keys
      if(_self.datePickerOptions.showRedEye && _self.checkRedEyeDateSelected(cinDate, coutDate)){
        return ;
      }
     
    
    if(coutDate && cinDate.getDate() == coutDate.getDate() && cinDate.getMonth() == coutDate.getMonth() && cinDate.getFullYear() == coutDate.getFullYear()) {
      coutDate.setDate(coutDate.getDate() + 1);
    }
    
    if(!coutDate) {
      _self.$el.find(".t-datepicker").tDatePicker("updateCI", new Date(cinDate));
    } else {
      _self.$el.find(".t-datepicker").tDatePicker("update", [new Date(cinDate), new Date(coutDate)]);
    }

    _self.updateCheckin(cinDate);
    _self.toggleCheckinArrow(cinDate);

    if(coutDate) {
      _self.updateCheckout(coutDate);
      _self.toggleCheckoutArrow(coutDate);
      if(_self.dateChange) {
        _self.dateChange = false;
      }
      _self.$el.find(".prev-checkout, .next-checkout").css('visibility', 'visible');
    }
    
    if(datePickerVisible){
      _self.$el.find(".ccheckin").click();
    }
    // $(document).click();
  };

  TdatePicker.prototype.setNextDateCheckout = function(date, dir) {
    var _self = this,
      cinDate = new Date(_self.$el.find(".js-from-calc").val()),
      coutDate = new Date(date),
      datePickerVisible = _self.$el.find(".new-container").css('visibility') == "visible" ? true : false;

      cinDate = new Date(cinDate.setTime(cinDate.getTime()));
      coutDate = new Date(coutDate.setTime(coutDate.getTime()));

    dir == "next" ? coutDate.setDate(coutDate.getDate() + 1): coutDate.setDate(coutDate.getDate() - 1)
    
    
    if(cinDate.getDate() == coutDate.getDate() && cinDate.getMonth() == coutDate.getMonth() && cinDate.getFullYear() == coutDate.getFullYear()) {

      var currentCheckin =  new Date(cinDate).setHours(0,0,0,0),
      redEyeDate = new Date(_self.datePickerOptions.redEyeValue).setHours(0,0,0,0)

      cinDate.setDate(cinDate.getDate() - 1);
      if(currentCheckin != redEyeDate && _self.datePickerOptions.showRedEye && _self.checkRedEyeDateSelected(cinDate, coutDate)){
        _self.isRedEyeSelected = false;
        return ;
      }
    }
    
    _self.$el.find(".t-datepicker").tDatePicker("update", [new Date(cinDate), new Date(coutDate)]);
    
    _self.updateCheckin(cinDate);
    _self.toggleCheckinArrow(cinDate);

    _self.updateCheckout(coutDate);
    _self.toggleCheckoutArrow(coutDate);

    if(_self.dateChange) {
      _self.dateChange = false;
    }
    _self.toggleArrows(true, 'cIn');
    _self.toggleArrows(true, 'cOut');
    
    if(datePickerVisible){
      _self.$el.find(".ccheckout").click();
    }
  };

  TdatePicker.prototype.changeEndDate = function(endDate) {
    var _self = this;
    _self.$el.find(".t-datepicker").tDatePicker('setEndDate', new Date(endDate));
    var cinVal = _self.$el.find(".js-from-calc").val();
    var coutVal = _self.$el.find(".js-to-calc").val();
    _self.$el.find(".t-datepicker").tDatePicker('update', [new Date(cinVal), new Date(coutVal)]);
    _self.maxDate = endDate;
    _self.toggleCheckoutArrow(coutVal);
    _self.toggleCheckinArrow(cinVal);

  };

  TdatePicker.prototype.getDaysDiff = function(date1, date2) {
    date1 = new Date(date1);
    date2 = new Date(date2);
    
    var res = Math.abs(date1 - date2) / 1000;
    var days = Math.floor(res / 86400);

    return days;
  };

  TdatePicker.prototype.resetDate = function() {
    var _self = this,
      cIn = new Date(),
      cOut = _self.getNextDate(cIn);

    _self.$el.find(".t-datepicker").tDatePicker('update', [cIn, cOut]);
    _self.updateCheckin(cIn);
    _self.toggleCheckinArrow(cIn);
    _self.updateCheckout(cOut);
    _self.toggleCheckoutArrow(cOut);

  };

  TdatePicker.prototype.resetDateMobile = function() {
    var _self = this;
    
    _self.$el.find(".js-submit-from, .js-submit-to, .fromToDate_submit,.js-from-calc,.js-to-calc, .ccheckin, .ccheckout").val("");
    _self.updateTotalNights();
    _self.$el.find(".t-start,.t-end,.t-range").removeClass("t-start t-end t-range");
    _self.currentCheckinDate = "";
    _self.currentCheckoutDate = "";

  };

  TdatePicker.prototype.initFlexDate = function() {
    var _self = this;
    var monthHtml = '';
    var m = new Date(_self.minDate).getMonth();
    var y = new Date(_self.minDate).getFullYear();
    var titleInitials = ( _self.$el.find('.js-date-pick-container').data('options').flexibleInMonthTitleInitial ?  _self.$el.find('.js-date-pick-container').data('options').flexibleInMonthTitleInitial+' ' : 'Month of ');
    var strY = ' ' + y;
    var i;
    var yearDiff = new Date().getYear() - new Date(_self.$el.find(".ccheckin").data('maxValue')).getYear();
    var noOfMonths;
    var countryCode = this.$el.data('country-code');
    _self.flex = {};
    var monthFullList = _self.datePickerOptions.monthFullList.split(',');
    var monthShortList = _self.datePickerOptions.monthShortList.split(',');

    // _self.flex.$root = _self.$el.find('.m-flex-frame-wrapper').appendTo(_self.$dateFromToPicker.$holder);

    noOfMonths = 1 - (yearDiff * 12) - m + new Date(_self.$el.find(".ccheckin").data('maxValue')).getMonth();

    for (i = 0; i < noOfMonths; i++) {
      monthHtml += '<a href="#" data-month="' + m + '" data-year ="' + y + '"';
      monthHtml += ' title="' + titleInitials +  monthFullList[m] + strY + '"';
      monthHtml += ' data-month-value="' + titleInitials +  monthFullList[m] + strY + '"';
      monthHtml += ' class="js-flex-month l-s2-option t-blk t-no-decor focus-elem">';
      monthHtml += ((countryCode === 'JP') ?  monthFullList[m] : monthFullList[m]) + '</a>';
      m++;
      if (m === 12) {
        y++;
        strY = ' ' + y;
        m = 0;
      } else {
        strY = '';
      }
    }

    if(noOfMonths > 12 && !_self.isMobileDevice) {
      _self.$el.find(".tab-container2").css('height', '300px');
    }
    _self.$el.find('.js-flex-months-holder').html(monthHtml);

    _self.flex.$check = _self.$el.find(".js-flex-check");
    _self.flex.$length = _self.$el.find(".js-flex-input");
    

    var flexNights = parseInt(this.$el.find('.js-date-pick-container').data('options').flexibleNight);
    _self.$el.find('.js-stepper-input').html(flexNights);
    _self.flex.$length.val(flexNights);

    _self.flex.$display = _self.$el.find('.js-flex-display');
    _self.flex.displayLabel = _self.flex.$display.data('label');

    _self.flex.$monthTrigger = _self.$el.find('.js-flex-month')
            .on('click', function(evt) {
              _self.onMonthChg(evt, _self);
            });

    _self.$el.find('.js-date-pick-container')
            .on('click', '.js-plus', function(evt) {
              _self.onFlexStepperChg(evt, _self, true);
            });

    _self.$el.find('.js-date-pick-container')
            .on('click','.js-minus', function(evt) {
              _self.onFlexStepperChg(evt, _self);
            });
    _self.flex.$stepperVal = _self.$el.find('.js-stepper-val')

    _self.updateFlexDate(_self.checkinDate);

    if ($('html').hasClass('touch')) {
      _self.$el.addClass('has-slider');
    }
  };

  TdatePicker.prototype.onMonthChg = function(evt, _self) {
    var monthFullList = _self.datePickerOptions.monthFullList.split(',');
    var days = _self.$el.find(".js-flex-input").val()
    var $evt = _self.$el.find(evt.target),
        m = $evt.data().month,
        y = $evt.data().year;
    var currentDate = new Date();
    var selectedDate = 1;
    var nightsLowerLabel = ((days == 1) ? _self.datePickerOptions.nightLowerLabel : _self.datePickerOptions.nightsLowerLabel);

    evt.preventDefault();

    $evt.parent().find('.is-selected').attr('title', $evt.parent().find('.is-selected').data('month-value'));
    $evt.parent().find('.is-selected').removeClass('is-selected');
    $evt.addClass('is-selected');
    $evt.attr('title', _self.$el.data('selected-text')+' '+$evt.data('month-value'));
    if (currentDate.getMonth() === m && currentDate.getFullYear() === y) {
      selectedDate = currentDate.getDate();
    }

    if(_self.getDaysDiff(new Date(), new Date(_self.minDate)) == 2 && currentDate.getMonth() === m + 1) {
      selectedDate = currentDate.getDate();
      m = currentDate.getMonth(),
      y = currentDate.getFullYear();
      var nightLabel = ((days == 1) ? _self.datePickerOptions.nightLabel : _self.datePickerOptions.nightsLabel);
      _self.flexCheckinDate = new Date(y, m, selectedDate);

      _self.$el.find(".js-flex-display").html(_self.generateFlexibleLabel(monthFullList, m-1, y, days, nightLabel, nightsLowerLabel));
    } else {
      var flexCheckinDate = new Date(y, m, selectedDate);
      _self.setDateFromFlexDesktop(flexCheckinDate);
    }
  };

  TdatePicker.prototype.updateFlexDate = function(date) {
    var _self = this;
    var monthFullList = _self.datePickerOptions.monthFullList.split(',');
    var getMonth = new Date(date).getMonth();
    var getYear = new Date(date).getFullYear();
    var days = _self.$el.find(".js-flex-input").val();
    var nightLabel = ((days == 1) ? _self.datePickerOptions.nightLabel : _self.datePickerOptions.nightsLabel);
    var nightsLowerLabel = ((days == 1) ? _self.datePickerOptions.nightLowerLabel : _self.datePickerOptions.nightsLowerLabel);

    _self.$el.find('.js-flex-month').filter('.is-selected').attr('title', _self.$el.find('.js-flex-month').filter('.is-selected').data('month-value')).removeClass('is-selected').removeAttr('aria-selected');
    _self.$el.find('.js-flex-month').filter('[data-month="' + getMonth + '"]').filter('[data-year="' + getYear + '"]').addClass('is-selected').attr('aria-selected', 'true');
    
    _self.$el.find('.js-flex-month').filter('.is-selected').attr('title', _self.$el.data('selected-text')+' '+_self.$el.find('.js-flex-month').filter('.is-selected').data('month-value'));

    var flexLabel = _self.generateFlexibleLabel(monthFullList, getMonth, getYear, days, nightLabel, nightsLowerLabel);

    _self.$el.find(".js-flex-display").html(flexLabel);
  };

  TdatePicker.prototype.generateFlexibleLabel = function(monthFullList, getMonth, getYear, days, nightLabel, nightsLowerLabel) {
    var _self = this,
    countryCode = _self.$el.find(".js-datepick-container").data('countryCode'),
    str = _self.$el.find(".js-flex-display").data('label');
    
    if(countryCode == "FR") {
      str += ' ' +  monthFullList[getMonth] + ' ' + getYear + ' - ' + _self.datePickerOptions.stayLabel + ' ' + _self.datePickerOptions.ofLabel + ' ' + days + ' ' + nightsLowerLabel
    } else if(countryCode == "KR") {
      str += ' ' +  getYear + ' ' + '년' + ' ' + monthFullList[getMonth] + ' - ' + days + ' ' + nightLabel;
    } else if(countryCode == "JP") {
      str += ' ' +  getYear + ' ' + _self.datePickerOptions.yearLabel + ' ' + monthFullList[getMonth] + ' - ' + days + ' ' + nightLabel;
    }
    else if(countryCode == "TH") {
      var year = getYear + 543;
      str += ' ' +  monthFullList[getMonth] + ' ' + year + ' - ' + days + ' ' + nightLabel + ' ' + _self.datePickerOptions.stayLabel;
    }
    else if(countryCode == "VN") {
      str += ' ' +  monthFullList[getMonth] + ' / ' + getYear + ' - ' + days + ' ' + nightLabel + ' ' + _self.datePickerOptions.stayLabel;
    }
    else if(countryCode == "IL") {
    	str += ' ' +  monthFullList[getMonth] + ' ' + getYear + ' - ' + _self.datePickerOptions.stayLabel + ' ' + days + ' ' + nightLabel;
    }
    else {
      str += ' ' +  monthFullList[getMonth] + ' ' + getYear + ' - ' + days + ' ' + nightLabel + ' ' + _self.datePickerOptions.stayLabel;
    }

    return str;
  };

  TdatePicker.prototype.setDateFromFlexDesktop = function(checkin) {
    var _self = this;
    var checkout = new Date(checkin);
    checkout = checkout.setDate(checkout.getDate() + parseInt(_self.$el.find('.js-stepper-input').html()));
    if(new Date(checkout).setHours(0,0,0,0) > new Date(_self.maxDate).setHours(0,0,0,0)) {
      checkout = _self.maxDate;
    }
    if(!_self.isMobileDevice) {
      _self.$el.find(".t-datepicker").tDatePicker("update", [new Date(checkin),new Date(checkout)]);
      _self.$el.find(".ccheckout").trigger('click');
    } else {
      _self.currentCheckinDate = new Date(checkin).setHours(0,0,0,0);
      _self.currentCheckoutDate = new Date(checkout).setHours(0,0,0,0);
      _self.$el.find(".t-start,.t-end,.t-range").removeClass("t-start t-end t-range");
      _self.$el.find("table [data-t-date ="+ _self.currentCheckinDate +"]").addClass("t-start");
      _self.$el.find("table [data-t-date = '" + _self.currentCheckoutDate + "']").addClass("t-end").removeClass("t-range");
      
      _self.highlightRange(_self.currentCheckinDate, _self.currentCheckoutDate);
    }
    _self.updateCheckin(checkin);
    _self.toggleCheckinArrow(checkin);
    _self.updateCheckout(checkout);
    _self.toggleCheckoutArrow(checkout);

    var nLabel = (parseInt(_self.$el.find('.js-stepper-input').html()) > 1) ? _self.nightsLabel : _self.nightLabel;
    _self.$el.find(".nights-count").html(parseInt(_self.$el.find('.js-stepper-input').html()) + " "+ nLabel);
  };
  
  TdatePicker.prototype.onFlexStepperChg = function(evt, _self, add) {
    evt.preventDefault();
    evt.stopPropagation();

    if(_self.handleDebounce()){
      return;
    }
   
    if (_self.$el.find(evt.target).hasClass('is-inactive')) {
      return;
    }
    var stepVal = _self.$el.find(".js-flex-input").val();
    stepVal = (add) ? ++stepVal : --stepVal;
    _self.setStepperVal(stepVal, _self);
    // _self.$el.find(evt.target).focus();
  };

  TdatePicker.prototype.setStepperVal = function(stepVal, _self) {
    // var _self = this;

    stepVal = _self.checkFlexStepper(stepVal, _self);

    if (stepVal) {
      _self.$el.find('.js-stepper-input').html(parseInt(stepVal));
      _self.$el.find(".js-flex-input").val(stepVal);
      _self.$el.find('.js-flex-month').filter('.is-selected').trigger('click');
      var nLabel = (stepVal > 1) ? _self.nightsLabel : _self.nightLabel;
      _self.$el.find(".nights-count").html(stepVal + " "+ nLabel);
    }
  };

  TdatePicker.prototype.checkFlexStepper = function(stepVal, _self) {
    _self = _self || this;
    var firstVal = _self.$el.find(".js-flex-input").find(':first-child').val(),
        lastVal = _self.$el.find(".js-flex-input").find(':last-child').val(),
        inActive = 'is-inactive';

    firstVal = parseInt(firstVal, 10);
    lastVal = parseInt(lastVal, 10);
    stepVal = stepVal || parseInt(_self.$el.find('.js-stepper-input').html());
    stepVal = (isNaN(parseInt(stepVal, 10))) || parseInt(stepVal) > lastVal ? 1 : parseInt(stepVal, 10);

    _self.$el.find('.js-date-pick-container .js-plus, .js-date-pick-container .js-minus').removeClass(inActive);

    if (stepVal >= lastVal) {
      stepVal = lastVal;
      _self.$el.find('.js-date-pick-container .js-plus').addClass(inActive);
    } else if (stepVal <= firstVal) {
      stepVal = firstVal;
      _self.$el.find('.js-date-pick-container .js-minus').addClass(inActive);
    }

    return stepVal;
  };

  TdatePicker.prototype.setStepperValue = function() {
    var _self = this;
    var stepVal = _self.$el.find(".nights-count").text().split(" ")[0];
    _self.setStepperVal(stepVal, _self);
  };

  TdatePicker.prototype.initAccessibility = function() {
    var _self = this,
    hideFlexibleCalendar = _self.$el.find('.js-date-pick-container').data('hideFlexibleDateCalendar');

    _self.$el.on("keyup", ".m-date-picker", function(e){
      if(e.keyCode == 27) {
        _self.hideDatePicker();
        $(document).trigger('click', [{ forceValidation: true }]);
      }
    });
    
    _self.$el.on("keyup", ".ccheckin, .ccheckout", function(e) {
      switch (e.keyCode) {
        case 40: 
        if(hideFlexibleCalendar) {
          _self.focusOnArrows();
          _self.$el.find('.l-dates-flexible-checkbox').attr('tabindex', '0');

        } else {
          _self.$el.find(".tabs").attr('tabindex','0');
          _self.$el.find(".tab1").focus();
        }
        break;
      }
    });
    _self.$el.on("keyup", '.tabs' ,function(e){
      switch (e.keyCode) {
        case 38:  
          _self.$el.find(".ccheckin").focus();
          break;

        case 40:  
          _self.focusOnArrows();
          break; 
      }
      if(e.which === 9 && e.shiftKey == true) {
        // e.preventDefault();
      }
    });
    _self.$el.on('keyup', '.t-arrow.t-prev, .t-arrow.t-next' , function(e){
      switch (e.keyCode) {
        case 38:  
          var prevArrow = _self.$el.find(".t-arrow.t-prev").eq(0);
          var nextArrow = _self.$el.find(".t-arrow.t-next").eq(1);
          _self.$el.find(".tab1").focus();
          break;

        case 40:

          _self.$el.find(".t-day, .t-start, .t-end, .t-range").attr('tabindex','0');
          if(_self.$el.find('.t-start').length) {
            _self.$el.find('.t-start').focus();
          } else {
            _self.$el.find('.t-day').eq(0).focus();
          }
          break;
      }
    });
    _self.$el.on("keypress", ".m-date-picker", function(e) {
      e.stopPropagation();
      if (_self.$el.find(e.target).is(":focus")) {
         if (e.which == 13) {
          _self.$el.find(e.target).trigger('click'); // trigger click on any element
          setTimeout(function(){
            _self.$el.find(e.target).focus();
            },10)
          }
        }
        setTimeout(function(){
          if(_self.$el.find(e.target).attr('tabindex') == "-1") {
            if(_self.$el.find(e.target).hasClass("next-checkin") || _self.$el.find(e.target).hasClass("prev-checkin")) {
              _self.$el.find(".ccheckin").focus();
              return;
            }
            if(_self.$el.find(e.target).hasClass("next-checkout") || _self.$el.find(e.target).hasClass("prev-checkout")) {
              _self.$el.find(".ccheckout").focus();
              return;
            }
          }
        },10)
    });

    _self.$el.on("keyup", ".t-datepicker-day", function(e) {
      if(_self.$el.find(e.target).hasClass("t-day") || _self.$el.find(e.target).hasClass("t-start") || _self.$el.find(e.target).hasClass("t-end") || _self.$el.find(e.target).hasClass("t-range")) {
        if(e.which === 9 && e.shiftKey == true) {
          _self.focusOnArrows();
          e.preventDefault();
        }
      }

      switch (e.keyCode) {

        case 38: 
          var currentTarget = e.target;
          var currentIndex = e.target.cellIndex;
          var prevRow = _self.$el.find(currentTarget).closest('tr').prev();
          
          if (prevRow.length == 1) {
            var prevIndex = _self.$el.find(_self.$el.find(prevRow)[0].cells[currentIndex])
            if(_self.$el.find(prevIndex).hasClass("t-day") || _self.$el.find(prevIndex).hasClass("t-start") || _self.$el.find(prevIndex).hasClass("t-end") || _self.$el.find(prevIndex).hasClass("t-range")) {
              _self.$el.find(prevIndex).focus();
            }
          }
          break;

        case 37: 
          var leftSibling = e.target.previousSibling;
          if(_self.$el.find(leftSibling).hasClass("t-disabled")) {
            // return;
          }
          if (leftSibling != null && _self.$el.find(leftSibling).hasClass("t-day") || _self.$el.find(leftSibling).hasClass("t-start") || _self.$el.find(leftSibling).hasClass("t-end") || _self.$el.find(leftSibling).hasClass("t-range")) {
            leftSibling.focus();
          }
          else {
            var currentTarget = e.target;
            var prevRow = _self.$el.find(currentTarget).closest('tr').prev();
            if (prevRow.length == 1 && _self.$el.find(prevRow).find('td').length > 0 && !_self.$el.find(prevRow).find('td').last().hasClass("t-disabled")) {
              _self.$el.find(prevRow).find('td').last().focus();
            }
            else {
              if (_self.$el.find(currentTarget).closest('.t-table-wrap').prev('.t-table-wrap').length == 1) {
                _self.$el.find(currentTarget).closest('.t-table-wrap').prev('.t-table-wrap').find('td:not(".t-disabled")').focus();
              }
              else {
                _self.$el.find(".t-datepicker-day").find(".t-prev").trigger('click'); // trigger click on previous arrow
                // _self.$el.find(".t-datepicker-day").find(".t-disabled").removeAttr("tabindex"); // put this in a function
                _self.$el.find(".t-datepicker-day").find('td').attr("tabindex","0"); // put this in a function
                _self.$el.find(".t-datepicker-day").find(".t-disabled").attr("tabindex","-1"); 
                _self.$el.find(".t-table-wrap").next().find('td:not(".t-disabled")').last().focus(); // focus on last date of previous month
              }
            }
          }
          break;

        case 39: 
          var rightSibling = e.target.nextSibling;

          if (_self.$el.find(rightSibling).hasClass("t-day") || _self.$el.find(rightSibling).hasClass("t-start") || _self.$el.find(rightSibling).hasClass("t-end") || _self.$el.find(rightSibling).hasClass("t-range")) {
            rightSibling.focus();
          }
          else {
            var currentTarget = e.target;
            var nextRow = _self.$el.find(currentTarget).closest('tr').next();
            if (nextRow.length == 1 && _self.$el.find(nextRow).find('td').length > 0 && !_self.$el.find(nextRow).find('td').first().hasClass("t-disabled")) {
              _self.$el.find(nextRow).find('td').first().focus();
            }
            else {
              if (_self.$el.find(currentTarget).closest('.t-table-wrap').next('.t-table-wrap').length == 1) {
                _self.$el.find(currentTarget).closest('.t-table-wrap').next('.t-table-wrap').find('td:not(".t-disabled")')[0].focus();
              }
              else {
                _self.$el.find(".t-datepicker-day").find(".t-next").trigger('click'); // trigger click on next arrow
                _self.$el.find(".t-datepicker-day").find('td').attr("tabindex","0"); // put this in a function
                _self.$el.find(".t-datepicker-day").find(".t-disabled").attr("tabindex","-1"); 
                _self.$el.find(".t-table-wrap").next(".t-table-wrap").find('td:not(".t-disabled")')[0].focus(); // focus on first date of next month
              }
            }
          }
          break;

        case 40: 
          var currentTarget = e.target;
          var currentIndex = e.target.cellIndex;
          var nextRow = _self.$el.find(currentTarget).closest('tr').next();
          
          if (nextRow.length == 1) {
            var nextIndex = _self.$el.find(_self.$el.find(nextRow)[0].cells[currentIndex])
            if(_self.$el.find(nextIndex).hasClass("t-day") || _self.$el.find(nextIndex).hasClass("t-start") || _self.$el.find(nextIndex).hasClass("t-end") ||  _self.$el.find(nextIndex).hasClass("t-range")) {
              _self.$el.find(nextIndex).focus();
            }
          }
          break;
      }
    });
    if(hideFlexibleCalendar) {
      _self.$el.find('.ccheckin, .ccheckout').on('focus', function() {
        _self.$el.find('.l-dates-flexible-checkbox').attr('tabindex', '-1');
      });
      _self.focusOnArrows();
    } else {
      _self.$el.on("focus", ".tabs", function(){
        _self.$el.find(".t-day, .t-start, .t-end, .t-range").attr('tabindex', '-1');
        var prevArrow = _self.$el.find(".t-arrow.t-prev").eq(0);
        var nextArrow = _self.$el.find(".t-arrow.t-next").eq(1);
        if(!prevArrow.hasClass("t-disabled")) {
          prevArrow.attr('tabindex','0');
          nextArrow.attr('tabindex','0');
        } else {
          nextArrow.attr('tabindex','0');
          prevArrow.attr('tabindex','-1');
        } 
      });
    }
    _self.$el.on("focus", ".t-arrow", function(){
      _self.$el.find(".t-day, .t-start,.t-end").attr('tabindex', '-1');
    });
    _self.$el.find(".js-datepick-container").keydown(function(e) {
        if(e.which === 13){ 
          if($(".ie").length){// Checks for the enter key
            e.preventDefault();// Stops IE from triggering the button to be clicked
            e.target.click();//Triggers 
          }
          if(e.target.tagName=="INPUT"){
            setTimeout(function(){_self.hideDatePicker();},10);
          }
        }
        if(!e.target.classList.contains('l-h-field-input')){
          if(e.which === 38 || e.which === 39 || e.which === 37 || e.which === 40) {
            e.preventDefault(); //detect arrow keys inside date picker container and stop page scroll
          }
        }
    });
    if(hideFlexibleCalendar) {
      _self.$el.on("focus", ".next-checkout", function() {
        _self.$el.find('.t-arrow.t-prev:not(.t-disabled), .l-dates-flexible-checkbox').attr('tabindex', '2');
        _self.$el.find('.t-arrow.t-next').eq(1).attr('tabindex', '2');
        _self.$el.find(".t-arrow.t-prev.t-disabled").attr('tabindex', '-1');
      });
    }
  };

  TdatePicker.prototype.focusOnArrows = function() {
    var _self = this;
    var prevArrow = _self.$el.find(".t-arrow.t-prev").eq(0);
    var nextArrow = _self.$el.find(".t-arrow.t-next").eq(1);
    if(!prevArrow.hasClass("t-disabled")) {
      prevArrow.attr('tabindex','0');
      nextArrow.attr('tabindex','0');
      prevArrow.focus();
    } else {
      nextArrow.attr('tabindex','0');
      nextArrow.focus();
    } 
  };

  TdatePicker.prototype.showDatePicker = function() {
    var _self = this;
    _self.$el.find(".tabbing").addClass("is-visible");
    _self.$el.find(".date-picker-container").attr("aria-live", "polite");
  };


  TdatePicker.prototype.hideDatePicker = function() {
    var _self = this;
    _self.$el.find(".tabbing").removeClass("is-visible");
    _self.$el.find(".date-focus").removeClass("date-focus");
    _self.$el.find(".l-date-part").show();
    _self.$el.find(".date-picker-container").attr("aria-live", "off");
  };

  TdatePicker.prototype.handleDebounce = function() {
    var _self = this;
    if(_self.debounce){
      return true;
    }
    _self.debounce = true;
    setTimeout(function(){
      _self.debounce = false;
    }, 100)
    return false;
  };

  TdatePicker.prototype.checkRedEyeDateSelected = function (cinDate, coutDate){
    var _self=this;
    if(_self.isRedEyeSelected){
      return false;
    }
    var currentCheckin =  new Date(cinDate).setHours(0,0,0,0),
        redEyeDate = new Date(_self.datePickerOptions.redEyeValue).setHours(0,0,0,0);
        _self.redEyeUpdatedDates.checkout = coutDate;
      if(_self.datePickerOptions.showRedEye){
        if(currentCheckin  == redEyeDate){
          _self.isRedEyeSelected = true;
          _self.redEyePopup(cinDate, coutDate);
          return true;
        }
      }
    return false;
  };

  TdatePicker.prototype.watchScreenSize = function() {
		var _self = this;
		_self.screenSizeMediaQuery = window.matchMedia(Constants.MEDIA_QUERY.EXTRA_LARGE);
		_self.screenSizeMediaQuery.addListener(_self.handleScreenSize.bind(_self));
		_self.handleScreenSize();
	};

  TdatePicker.prototype.handleScreenSize = function() {
  	var _self = this, $flexibleDatesCheckBox;

		if( !_self.hideflexibleDates ) {
			return;
		}

  	$flexibleDatesCheckBox = _self.$datePickerContainer.find('.l-flexible-checkbox-content');
			_self.$datePickerContainer.find('.l-date-picker-body .l-flexible-checkbox-container').prepend($flexibleDatesCheckBox);
	};
  
module.exports = TdatePicker;
