var $ = require('jquery');
var _ = require('lodash');
var ComponentMapper = require('./component-mapper');
var GeoLocationHandler = require('./geolocation-handler');
var ClearInputHandler = require('./clear-input-handler');
var localStorage = require('local-storage-fallback');
var PubSub = require('libs/pub-sub');
var Cookies = require('libs/cookies');
var GoogleApi = require('libs/google-api');
var GroupCodeAutoComplete = require('./group-code-auto-complete')
var uuidv1 = require('uuid/v1');
var animatePlaceholder = require('libs/input-placeholder-animation');

var properties = {
  listHtml: '',
  listCount: 0,
  timeoutId: 0,
  permittedLocales: [],
  recentSearch: {
    max: 5,
    list: [],
    inputClass: 'js-recent-search'
  },
  options: {
    searchType: '.js-searchtype',
    addressSearch: 'NearAddress',
    isPropertyCodeFlag: '.search-property-code-flag',
    inputs: {
      radius: '.js-search-radius',
      latitude: '.search-latitude',
      longitude: '.search-longitude',
      cityPop: '.js-search-city-pop',
      cityPopDensity: '.js-search-city-pop-density',
      cityDisplay: '.js-search-city-display',
      city: '.search-city',
      state: '.search-state',
      country: '.search-country',
      countryname: '.search-country-name',
      poiname: '.search-poiname',
      airport: '.search-airport',
      airportName: '.search-airport-name',
      autoSuggestOmni: '.js-autosuggest-item-type',
      singleSearchAuto: '.single-search-autosuggest',
      stateProvince: '.search-state-province',
      propertyName: '.search-property-name',
      propertyCode: '.search-property-code',
      primaryDescription: '.search-primary-description',
      secondaryDescription: '.search-secondary-description',
      placeId: '.search-place-id',
      address: '.destination-address',
      postalCode: '.destination-postal-code',
      types: '.destination-types',
      website: '.destination-website',
      showAddressPin: '.show-address-pin',
      popularDestinationTitle: '.search-destination-title',
      koreanDestination: '.search-korean-destination',
      internationalDestination: '.search-international-destination'
    },
    categoryParams: {
      label: ''
    },
    autoCompleteList: '.js-auto-complete-list',
    maxCategory: 10
  },
  templates: {
    autoCompleteCustom: _.template(
      '<li class="autocomplete-listitem <% if( index == 0 ) { %> autocomplete-listitem-active <% } %>" role="menuitem">' +
      '<a data-index="<%=index%>" data-place-id="<%=placeId%>" data-primary-description="<%=primaryDescription%>" data-secondary-description="<%=secondaryDescription%>">' +
      '<strong><%=primaryDescription%></strong><% if(typeof secondaryDescription !== "undefined" && secondaryDescription) { %>, <%=secondaryDescription%> <%}%></a>' +
      '</li>'),
    autoListHeader: _.template(
      '<li class="autocomplete-list-header">' +
      '<span class="t-icon-search autocomplete-search-icon"></span><strong><%=category%></strong>' +
      '</li>'),
    autoListMsg: _.template('<li class="t-autosuggest-msg t-font-semi-bold"><%=label%></li>'),
    autoCompleteRecent: _.template(
      '<li class="autocomplete-listitem <% if( index == 0 ) { %> autocomplete-listitem-active <% } %>" role="menuitem">' +
      '<a data-index="<%=index%>" data-type="<%=type%>"  data-searchtype="<%=searchtype%>" <% if(typeof city !== "undefined") { %> data-city="<%=city%>"<%}%><% if(typeof state !== "undefined") { %> data-state="<%=state%>"<%}%>' +
      '<% if(typeof country !== "undefined") { %> data-countrycode="<%=country%>"<%}%><% if(typeof countryname !== "undefined") { %> data-country="<%=countryname%>"<%}%> <% if(typeof airportname !== "undefined") { %> data-airport-name="<%=airportname%>"<%}%><% if(typeof airportcode !== "undefined") { %> data-airport-code="<%=airportcode%>"<%}%>' +
      '<% if(typeof poiname !== "undefined") { %> data-poi-name="<%=poiname%>"<%}%><% if(typeof geocode !== "undefined") { %> data-geo="<%=geocode%>"<%}%>' +
      '<% if(typeof citypopulation !== "undefined") { %> data-city-population="<%=citypopulation%>"<%}%>' +
      '<% if(typeof primaryDescription !== "undefined") { %> data-primary-description="<%=primaryDescription%>"<%}%>' +
      '<% if(typeof secondaryDescription !== "undefined") { %> data-secondary-description="<%=secondaryDescription%>"<%}%>' +
      '<% if(typeof placeId !== "undefined") { %> data-place-id="<%=placeId%>"<%}%>' +
      '<% if(typeof longitude !== "undefined") { %> data-longitude="<%=longitude%>"<%}%>' +
      '<% if(typeof radius !== "undefined") { %> data-radius="<%=radius%>"<%}%>' +
      '<% if(typeof latitude !== "undefined") { %> data-latitude="<%=latitude%>"<%}%>' +
      '<% if(typeof address !== "undefined") { %> data-address="<%=address%>"<%}%>' +
      '<% if(typeof postalCode !== "undefined") { %> data-postalCode="<%=postalCode%>"<%}%>' +
      '<% if(typeof types !== "undefined") { %> data-types="<%=types%>"<%}%>' +
      '<% if(typeof website !== "undefined") { %> data-website="<%=website%>"<%}%>' +
      '<% if(typeof stateprovincedisplayname !== "undefined") { %> data-state-province="<%=stateprovincedisplayname%>"<%}%>' +
      '<% if(typeof citypopulationdensity !== "undefined") {%> data-city-population-density="<%=citypopulationdensity%>"<%}%> data-label="<%=label%>">' +
      '<% if(typeof primaryDescription !== "undefined" && primaryDescription) { %><strong><%=primaryDescription%></strong><% if(typeof secondaryDescription !== "undefined" && secondaryDescription) { %>, <%=secondaryDescription%><%}%><%} else {%> <%=label%> <%}%></a></li>'),
    autoCompleteCluster: _.template(
      '<li class="autocomplete-listitem <% if( index == 0 ) { %> autocomplete-listitem-active <% } %>" role="menuitem">' +
      '<a data-index="<%=index%>" data-type="<%=(typeof type !== "undefined")?type:""%>"  data-code="<%=ratecluster%>"' +
      'data-number="<%=(typeof rateclustersetnum !== "undefined")?rateclustersetnum:""%>" data-number-type="<%=(typeof rateclustersetnumtype !== "undefined")?rateclustersetnumtype:""%>"' +
      'data-desc="<%=displaydescription%>" >' +
      '<%=ratecluster%> - <%=displaydescription%></a>' +
      '</li>')
  },
  templateSettings: {
    // interpolate: /{{([\s\S]+?)}}/g,
    // variable: '_data',
    imports: {
      any: function (one, two) {
        return one || two;
      },
      mergeItem: function (seperator) {

        var result = '', idx = 0;
        for (idx = 1; idx < arguments.length; idx++) {
          result = result + ((arguments[idx] && (
            ((result && seperator) || '') + arguments[idx])) || '')
        }
        return result;
      },
      /* cluster code format for input box*/
      clusterFormat: function (code, description) {
        return (code + ' - ' + description);
      }
    }
  },
  events: {
    'keyup .js-auto-complete-input-v2': 'getChoiceList',
    'focusin .js-auto-complete-input-v2': 'showRecentOnFocus',
    'keydown .js-auto-complete-input-v2': 'navigateList'
  },
  subscribe: {
    'GOOGLE_API_LOADED': 'populateCurrentLocation'
  },
  init: function () {
    var _self = this;
    var options = _self.options;
    //Clear cluster code related hidden value on load
    _self.clearClusterCodeData();
    var autoCompleteOptions = this.$el.data('options');
    _self.options.placesUrl = autoCompleteOptions.placesUrl;
    _self.options.autoCompleteURL = autoCompleteOptions.autoCompleteURL;
    _self.options.suggestionSortOrder = autoCompleteOptions.suggestionSortOrder;
    _self.options.clusterSearchHandler = _self.clusterSearchHandler;
    _self.options.isGroupCodeHandler = _self.isGroupCodeHandler;
    _self.options.groupFactDetailData = _self.groupFactDetailData;
    _self.options.isAirpotLocationDetected = _self.isAirpotLocationDetected;
    //set SiteID
    _self.siteID = ($('html').attr('lang') || $('html').attr('xml:lang'));
    //If permitted locales string is not empty
    if (autoCompleteOptions.permittedLocales) {
      _self.permittedLocales = autoCompleteOptions.permittedLocales.split(',');
    }
    _self.googleMap = this.$parent.$el.find(".search-google-map").data('options');

    _self.$el.find('.autocomplete-list').remove();
    _.extend(_.templateSettings, this.templateSettings);

    _self.subscribeDOMEvents();
    _self.subscribePubSubMessages();
    if (!_self.$parent.hideCurrentLocation) {
      _self.initGeoLocationHandler();
      _self.initGoogleApi();
    }
    _self.initClearInputHandler();
    _self.initGroupCodeAutoComplete();
    if (!_self.options.isGroupCodeHandler) {
      animatePlaceholder.init(_self.$el);
    }
    // set category parameters from dom element
    if (_self.$parent.$el.find('.autosuggest-cat-headers').length) {
      _self.options.categoryParams = _self.$parent.$el.find('.autosuggest-cat-headers').data('category-params');
    }

    _self.$scrollbarWrapper = $('<div class="autocomplete-scroller-wrapper custom-wrapper"><ul></ul></div>');
    _self.$autoCompleteList = _self.$scrollbarWrapper.find('ul');

    _self.$autoCompleteList.addClass('autocomplete-list').css({
      'z-index': 999,
      'top': '0px',
      'left': '0px',
      'display': 'none'
    })
      .on('mousedown', '.autocomplete-listitem a, .autocomplete-list-header', _.bind(_self.listItemSelect, _self))
      .on('mouseover', '.autocomplete-listitem a,.autocomplete-list-header,.autocomplete-recently-viewed-header-container .expand-collapse-btn,.autocomplete-clear-recent', _.bind(_self.highLightListItem, _self))
      .on('mousedown', '.clear-recent-search', _.bind(_self.clear, _self))
      .on('mousedown', '.autocomplete-recently-viewed-header-container .expand-collapse-btn', _.bind(_self.handleAccordion, _self))
      .on('mousedown', '.korean-dest-container a, .autocomplete-list-header', _.bind(_self.listDestItemSelect, _self))
      .on('mousedown', '.international-dest-container a, .autocomplete-list-header', _.bind(_self.listDestItemSelect, _self))

    _self.$scrollbarWrapper.css({
      'display': 'none'
    });

    _self.$narrate = this.$el.find('div[aria-live="assertive"]');
    _self.$el.append(_self.$scrollbarWrapper).append(_self.$narrate).find(_self.$autoCompleteList);
    _self.$input = _self.$el.find('.js-auto-complete-input-v2').attr('aria-autocomplete', 'list');
    _self.$input.on('blur', _.bind(_self.hideSuggestions, _self, null, true));
    _self.recentSearchTerm = _self.$input.val();
    _self.addRecent();
    _self.lat = 0;
    _self.long = 0;
    if (_self.enableUserLocation) {
      _self.setLatLong();
    }

    // Flag which shows the recently viewed accordion (dropdown) state
    _self.recentlyViewedPropertiesDdClosed = true;

  },

  /**
  * This function is used to set and store latitude and longitude in local storage.
  * @return null
  **/
  setLatLong: function () {
    var _self = this;
    var latLongObj = JSON.parse(window.localStorage.getItem('latLongObj'));
    if (latLongObj && latLongObj.sessionId === _self.sessionId) {
      _self.lat = latLongObj.lat || 0;
      _self.long = latLongObj.long || 0;
    } else {
      window.localStorage.removeItem('latLongObj');
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(function (position) {
          latLongObj = {};
          latLongObj.lat = position.coords.latitude;
          latLongObj.long = position.coords.longitude;
          latLongObj.sessionId = _self.sessionId;
          _self.lat = latLongObj.lat || 0;
          _self.long = latLongObj.long || 0;
          window.localStorage.setItem('latLongObj', JSON.stringify(latLongObj));
        });
      }
    }
  },

  populateCurrentLocation: function () {
    var _self = this;
    if (sessionStorage && Cookies.readCookie('loadCurrentLocation') === "true") {
      Cookies.eraseCookie('loadCurrentLocation');
      _self.geoLocationHandler.doLocalDetails();
    }
  },
  /**
  * This function creates a geolocation object that is used for current location search.
  * @return null
  **/
  initGeoLocationHandler: function () {
    this.geoLocationHandler = new GeoLocationHandler({
      $el: this.$el,
      $parent: this.$parent,
      autoComplete: this
    });
    this.geoLocationHandler.init();
  },
  /**
  * This function loads google maps API.
  * @param callback - called if google maps API is loaded successfully
  * @param fallback - called if google maps API fails to load
  * @return null
  **/
  initGoogleApi: function () {
    var _self = this;
    if (_self.googleMap) {
      var googleApi = GoogleApi.create({
        apiUrl: _self.googleMap.api
      });
      googleApi.init();
    }
  },
  /**
  * This function create handler object for clear button present on search input box.
  * @return null
  **/
  initClearInputHandler: function () {
    this.clearInputHandler = new ClearInputHandler({
      $el: this.$el,
      $parent: this
    });
    this.clearInputHandler.init();
  },

  /**
* This function create handler object for clear button present on search input box.
* @return null
**/
  initGroupCodeAutoComplete: function () {
    this.GroupCodeAutoComplete = new GroupCodeAutoComplete({
      $el: this.$el,
      $parent: this,
      options: this.options
    });
  },

  /**
  * This function checks if current site id is present in list of permitted locales.
  * @return true|false
  * Returns true if permitted locales array is empty
  **/
  permittedSiteId: function () {
    var siteID = ($('html').attr('lang') || $('html').attr('xml:lang'));
    if (_.isEmpty(this.permittedSiteId)) {
      return true;
    }
    return (siteID && _.includes(this.permittedLocales, siteID));
  },

  /**
  * This function is used to show recent search form on focus in destination field.
  **/
  showRecentOnFocus: function (evt) {
    var _self = this;
    var searchTerm = evt.target.value;
    if (!searchTerm.length) {
      _self.listHtml = "";
      if (!_self.options.clusterSearchHandler && !_self.options.isGroupCodeHandler) {
        _self.resetInputs();
      }
      _self.suggestGeoLoc();
      // This flag is used to show new autocomplete (with recently viewed properties)
      if (_self.options.categoryParams.showRecentlyViewedProperties) {
        _self.suggestRecentlyViewedProperties(searchTerm);
      }
      _self.suggestRecent(searchTerm);
      // This flag is used to show new autocomplete (with recently viewed properties)
      if (_self.options.categoryParams.showRecentlyViewedProperties) {
        _self.renderClearRecent();
      }
      if(_self.$parent.responsiveUtils.isMobileOrTablet() && !_self.$parent.disableSubTakeover && (_self.siteID === 'en_US' || _self.siteID === 'en-US')){
        _self.addTopDestinations(searchTerm);
      }
      _self.showList($(evt.target));
    }
  },

  /**
  * This function determines ajax call to get data for a specific keyword search.
  * @return null
  **/
  getChoiceList: function (evt) {
    var _self = this;
    var searchTerm = evt.target.value,
      parseCall = _.bind(_self.parseResult, _self, searchTerm, $(evt.target));
    var sessionUID = sessionStorage.getItem('uniqueID');

    if (sessionUID === null) {
      sessionUID = uuidv1();
      sessionStorage.setItem('uniqueID', sessionUID);
    }
    _self.options.searchTypeVal = _self.$parent.$el.find(_self.options.searchType).val();
    if (!searchTerm.trim().length || (_self.recentSearchTerm !== searchTerm)) {
      if (_self.recentSearchTerm !== searchTerm) {
        delete (_self.initialSearchTerm);
      }

      if (!_self.options.clusterSearchHandler) {
        _self.resetInputs();
      }

      else {
        if (_self.xhrRequest) {
          _self.xhrRequest.abort();
        }
        _self.listHtml = '';
      }
    }
    if (!searchTerm.trim().length && !_self.preventListReRender) {
      _self.suggestionListShow = false;
    }
    if (!searchTerm.trim().length || (_self.recentSearchTerm === searchTerm)) {
      var keyCodes = [8, 38, 40, 46, 229];
      // if (((evt.keyCode === 38) || (evt.keyCode === 40) || (evt.keyCode === 8) || (evt.keyCode === 46)) && !_self.suggestionListShow) {
      if ((keyCodes.indexOf(evt.keyCode) > -1) && !_self.suggestionListShow) {
        if ((_self.options.searchTypeVal !== _self.options.addressSearch) && !_self.listHtml) {
          _self.suggestGeoLoc();
          // This flag is used to show new autocomplete (with recently viewed properties)
          if (_self.options.categoryParams.showRecentlyViewedProperties) {
            _self.suggestRecentlyViewedProperties(searchTerm);
          }
          _self.suggestRecent(searchTerm);
          // This flag is used to show new autocomplete (with recently viewed properties)
          if (_self.options.categoryParams.showRecentlyViewedProperties) {
            _self.renderClearRecent();
          }
        }
        _self.showList($(evt.target));
      }
      _self.recentSearchTerm = searchTerm;
      return;
    }

    _self.recentSearchTerm = searchTerm;
    if (_self.options.clusterSearchHandler) {
      _self.performClusterSearch(parseCall, sessionUID);
    }

    else if (_self.options.isGroupCodeHandler) {
      _self.GroupCodeAutoComplete.performGroupCodeSearch(parseCall, sessionUID, _self.options.groupFactDetailData);
    }

    else {
      _self.performLocationSearch(parseCall, sessionUID);
    }
  },
  /**
  * This function performs location search on basis of search term.
  * @return null
  **/
  xhrRequest: false,

  performLocationSearch: function (parseCall, sessionUID) {
    var _self = this;
    var searchRequest = {
      type: 'GET',
      url: _self.options.placesUrl,
      contentType: 'text/html',
      cache: true,
      data: {
        searchTerm: _self.recentSearchTerm,
        suggestionSortOrder: _self.options.suggestionSortOrder,
        latitude: _self.lat,
        longitude: _self.long,
        uuid: sessionUID
      },
      success: parseCall
    };

    if (_self.xhrRequest) {
      _self.xhrRequest.abort();
    }
    _self.xhrRequest = _self.makeAjaxCall(searchRequest);
  },

  performClusterSearch: function (parseCall, sessionUID) {
    var _self = this;
    var searchRequest = {
      type: 'GET',
      url: _self.options.autoCompleteURL,
      contentType: 'text/html',
      data: {
        searchTerm: _self.recentSearchTerm,
        suggestionSortOrder: _self.options.suggestionSortOrder,
        uuid: sessionUID
      },
      success: parseCall
    };

    if (_self.xhrRequest) {
      _self.xhrRequest.abort();
    }
    _self.xhrRequest = _self.makeAjaxCall(searchRequest);
  },

  /**
  * This function helps in hiding autocomplete list.
  * @return null
  **/
  hideSuggestionsFlag: true,
  blankLocationInputFlag: true,
  hideSuggestions: function (event, suppressCallback) {
    var _self = this,
      inputMap = '',
      searchInput = _self.$el.find('.single-search').val();

    if (_self.hideSuggestionsFlag) {
      _self.recentlyViewedPropertiesDdClosed = true;
      _self.$autoCompleteList.hide();
      _self.$scrollbarWrapper.scrollTop(0).parent().find('.scroll-bar').css('top', '0px')
      _self.$scrollbarWrapper.hide();
      _self.suggestionListShow = false;
      _self.$parent.$el.find('.js-find-container .l-h-field').text(searchInput);
      if (_self.blankLocationInputFlag && _self.onListClose && !suppressCallback) {
        _self.onListClose();
      }
      if (_self.options.clusterSearchHandler && suppressCallback && event != null) {
        inputMap = {
          hiddenClusterCode: event.target.value,
          rateClusterSetNum: null,
          rateClusterSetNumType: 'unknownCode'
        };
        _.each(inputMap, function (value, name) {
          _self.$parent.$el.find('input[name=' + name + ']').val(value || '');
        }, _self);
        if (event) {
          event.preventDefault();
          _self.listItemSelect(event);
        }
      }
    }
  },
  resetInputs: function () {
    var _self = this;
    var autoSuggestItemType = '.js-autosuggest-item-type';
    var isAdvSearchAssociate = _self.$el.hasClass('l-adv-search-associate');
    var isAssociateSearchWrapper = _self.$parent.$el.hasClass('adv-search-associate-form');
    _self.listHtml = '';
    _self.listCount = 0;
    _.forEach(_self.options.inputs, function (selectors) {
      if ((isAdvSearchAssociate || isAssociateSearchWrapper) && selectors === autoSuggestItemType) {
        return;
      }
      _self.$parent.$el.find(selectors).val('');
      if (_.isFunction(_self.$parent.propertyCodeChangeHandler)) {
        _self.$parent.propertyCodeChangeHandler();
      }
    }, _self);
    _self.$parent.$el.find(_self.options.inputs.singleSearchAuto).val(false);
  },
  /**
   * 
   * @param {input value on destination field} searchTerm 
   * @return number of recently viewed properties which will be always less than or equal to 5
   */
  suggestRecentlyViewedProperties: function _suggestRecentlyViewedProperties(searchTerm) {
    var _self = this,
      recentlyViewed,
      maxLimit = _self.options.categoryParams.maxRecentlyViewedPropertiesLimit,
      minLimit = _self.options.categoryParams.minRecentlyViewedPropertiesLimit;

    if (localStorage.default.miRecentlyViewedProperties) {
      recentlyViewed = JSON.parse(localStorage.default.miRecentlyViewedProperties);
    }

    var currentTime = new Date().toISOString();

    // Check whether properties are in local storage or not
    if (recentlyViewed && recentlyViewed.recentlyViewedProperties) {
      var recentViewedList = recentlyViewed.recentlyViewedProperties,
        recentlyViewedConfig = recentlyViewed.config,
        renderList = '';
    }

    if (recentViewedList && recentViewedList.length) {

      var propertyListToBeShown = recentViewedList.filter(function (property) {
        var timeDiff = Math.abs(new Date(currentTime).getTime() - new Date(property.createdAt).getTime()),
          daysDifference = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return daysDifference <= recentlyViewedConfig.maxCachedDaysLimit;
      }).filter(function (property, index) {
        if (index >= maxLimit) {
          return false;
        }
        if (searchTerm.trim() !== "") {
          return property.name.toLowerCase().includes(searchTerm.toLowerCase().trim());
        }
        return true;
      });

      if (!propertyListToBeShown.length) {
        _self.recentlyViewedItemsEmpty = true;
        return;
      }

      _self.recentlyViewedItemsEmpty = false;

      _self.showAccordion = propertyListToBeShown.length > minLimit;

      renderList += "<li class='autocomplete-recently-viewed-header-container'><span class='t-recenlty-viewed-icon t-icon-eye'></span><div class='recently-viewed-items-header l-display-flex'><span class='l-recently-viewed-heading t-font-weight-normal t-font-s'>" + _self.options.categoryParams.recentlyViewedLabel + "</span>";

      if (_self.showAccordion) {
        renderList += "<span class='expand-collapse-btn " + (_self.recentlyViewedPropertiesDdClosed ? "collapsed" : "expanded") + " l-display-flex t-cursor-pointer t-para-s-grey'><span class='show-more-less l-display-inline-block t-font-xs t-font-weight-semibold'>" + (_self.recentlyViewedPropertiesDdClosed ? _self.options.categoryParams.showMoreLabel : _self.options.categoryParams.showLessLabel) + "</span><span class='" + (_self.recentlyViewedPropertiesDdClosed ? "t-icon-arrow-down" : "t-icon-arrow-up") + "'></span></span>";
      }

      renderList += "</div></li>";

      // Show recently viewed properties
      propertyListToBeShown.forEach(function (property, index) {
        var dataIndex = _self.listCount + index;
        renderList += "<li role='menuitem' class='autocomplete-listitem l-recently-viewed-item " + (index >= minLimit ? (_self.recentlyViewedPropertiesDdClosed ? "l-secondary-viewed-items l-display-none" : "l-secondary-viewed-items l-display-block") : "") + " l-padding-top'><a href='#' data-index=" + dataIndex + " data-label='" + property.name + "' data-address='" + property.address +"'><div class='property-image-container'><img src=" + property.imageUrl + " alt='" + property.name + " " + "'></div><div class='property-details-container'><div class='l-padding-left t-font-m t-font-weight-semibold l-margin-bottom-quarter'>" + property.name + "</div><div class='l-padding-left t-font-weight-normal t-font-xs'>" + property.address + "</div></div></a></li>";
      });
      _self.listCount += propertyListToBeShown.length;
      _self.listHtml += renderList;
    } else {
      _self.recentlyViewedItemsEmpty = true;
    }
  },
  /**
   * This function renders clear recent list item for new recently viewed properties view
   */
  renderClearRecent: function _renderClearRecent() {
    var _self = this,
      renderList = "";
    if (!_self.recentSearchesEmpty || !_self.recentlyViewedItemsEmpty) {
      renderList += "<li class='autocomplete-clear-recent'><span class='t-control-link clear-recent-search'>" + _self.options.categoryParams.clearRecentLabel + "</span></li>"
    }
    _self.listHtml += renderList;
  },
  /**
   * This method handles action on show more or show less button
   * @param {mousedown event on show more less accordion} event 
   */
  handleAccordion: function _handleAccordion(event) {
    var _self = this,
      $secondaryRecentlyViewedItems = _self.$autoCompleteList.find("li.l-secondary-viewed-items"),
      currentTarget = event ? event.currentTarget : _self.$autoCompleteList.find(".expand-collapse-btn");
    if (_self.recentlyViewedPropertiesDdClosed) {
      $(currentTarget).removeClass("collapsed").addClass("expanded").find(".show-more-less").html(_self.options.categoryParams.showLessLabel).next().removeClass("t-icon-arrow-down").addClass("t-icon-arrow-up");
      $secondaryRecentlyViewedItems.removeClass("l-display-none").addClass("l-display-block");
      _self.$narrate.text(_self.options.categoryParams.expandedLabel + ' ' + _self.options.categoryParams.showLessLabel);
      _self.recentlyViewedPropertiesDdClosed = false;
    } else {
      $(currentTarget).removeClass("expanded").addClass("collapsed").find(".show-more-less").html(_self.options.categoryParams.showMoreLabel).next().removeClass("t-icon-arrow-up").addClass("t-icon-arrow-down");
      $secondaryRecentlyViewedItems.removeClass("l-display-block").addClass("l-display-none");
      _self.$narrate.text(_self.options.categoryParams.collapsedLabel + ' ' + _self.options.categoryParams.showMoreLabel);
      _self.recentlyViewedPropertiesDdClosed = true;
    }
    if (_self.isDesktopVersion()) {
      setTimeout(function () {
        _self.$input.focus();
        _self.hideSuggestionsFlag = true;
      }, 0);
    }
  },
   /**
  * This function creates Top Destinations column in autocomplete list.
  * @return null
  **/
 addTopDestinations: function (searchTerm) {
    var _self = this;
    var renderList="";
    var topDestinationsObject = [
      {
         "label":"New York, NY, USA",
         "city":"New York",
         "poiname":"",
         "state":"NY",
         "country":"US",
         "stateprovincedisplayname":"",
         "type":"",
         "countryname":" USA",
         "airportcode":"",
         "value":"New York, NY, USA",
         "geocode":"40.753489,-73.980961",
         "searchtype":"recent",
         "analytics":"{\"location\":\"searchForm\",\"sendNow\":\"true\",\"description\":\"Recent Search\"}",
         "placeId":"ChIJOwg_06VPwokRYv534QaPC8g",
         "primaryDescription":"New York",
         "secondaryDescription":"NY, USA",
         "latitude":"40.753489",
         "longitude":"-73.980961",
         "radius":"80467.2",
         "address":"",
         "postalCode":"",
         "types":"",
         "website":""
      },
      {
         "label":"Orlando, FL, USA",
         "city":"Orlando",
         "poiname":"",
         "state":"FL",
         "country":"US",
         "stateprovincedisplayname":"",
         "type":"",
         "countryname":" USA",
         "airportcode":"",
         "value":"Orlando, FL, USA",
         "geocode":"28.5383355,-81.3792365",
         "searchtype":"recent",
         "analytics":"{\"location\":\"searchForm\",\"sendNow\":\"true\",\"description\":\"Recent Search\"}",
         "placeId":"ChIJd7zN_thz54gRnr-lPAaywwo",
         "primaryDescription":"Orlando",
         "secondaryDescription":"FL, USA",
         "latitude":"28.5383355",
         "longitude":"-81.3792365",
         "radius":"80467.2",
         "address":"Orlando, FL, USA",
         "postalCode":"",
         "types":"locality,political",
         "website":"https://maps.google.com/?q=Orlando,+FL,+USA&ftid=0x88e773d8fecdbc77:0xac3b2063ca5bf9e"
      },
      {
         "label":"Chicago, IL, USA",
         "city":"Chicago",
         "poiname":"",
         "state":"IL",
         "country":"US",
         "stateprovincedisplayname":"",
         "type":"",
         "countryname":" USA",
         "airportcode":"",
         "value":"Chicago, IL, USA",
         "geocode":"41.8781136,-87.6297982",
         "searchtype":"recent",
         "analytics":"{\"location\":\"searchForm\",\"sendNow\":\"true\",\"description\":\"Recent Search\"}",
         "placeId":"ChIJ7cv00DwsDogRAMDACa2m4K8",
         "primaryDescription":"Chicago",
         "secondaryDescription":"IL, USA",
         "latitude":"41.8781136",
         "longitude":"-87.6297982",
         "radius":"80467.2",
         "address":"Chicago, IL, USA",
         "postalCode":"",
         "types":"locality,political",
         "website":"https://maps.google.com/?q=Chicago,+IL,+USA&ftid=0x880e2c3cd0f4cbed:0xafe0a6ad09c0c000"
      },
      {
         "label":"Los Angeles, CA, USA",
         "city":"Los Angeles",
         "poiname":"",
         "state":"CA",
         "country":"US",
         "stateprovincedisplayname":"",
         "type":"",
         "countryname":" USA",
         "airportcode":"",
         "value":"Los Angeles, CA, USA",
         "geocode":"34.0522342,-118.2436849",
         "searchtype":"recent",
         "analytics":"{\"location\":\"searchForm\",\"sendNow\":\"true\",\"description\":\"Recent Search\"}",
         "placeId":"ChIJE9on3F3HwoAR9AhGJW_fL-I",
         "primaryDescription":"Los Angeles",
         "secondaryDescription":"CA, USA",
         "latitude":"34.0522342",
         "longitude":"-118.2436849",
         "radius":"80467.2",
         "address":"Los Angeles, CA, USA",
         "postalCode":"",
         "types":"locality,political",
         "website":"https://maps.google.com/?q=Los+Angeles,+CA,+USA&ftid=0x80c2c75ddc27da13:0xe22fdf6f254608f4"
      },
      {
         "label":"San Francisco, CA, USA",
         "city":"San Francisco",
         "poiname":"",
         "state":"CA",
         "country":"US",
         "stateprovincedisplayname":"",
         "type":"",
         "countryname":" USA",
         "airportcode":"",
         "value":"San Francisco, CA, USA",
         "geocode":"37.7749295,-122.4194155",
         "searchtype":"recent",
         "analytics":"{\"location\":\"searchForm\",\"sendNow\":\"true\",\"description\":\"Recent Search\"}",
         "placeId":"ChIJIQBpAG2ahYAR_6128GcTUEo",
         "primaryDescription":"San Francisco",
         "secondaryDescription":"CA, USA",
         "latitude":"37.7749295",
         "longitude":"-122.4194155",
         "radius":"80467.2",
         "address":"San Francisco, CA, USA",
         "postalCode":"",
         "types":"locality,political",
         "website":"https://maps.google.com/?q=San+Francisco,+CA,+USA&ftid=0x80859a6d00690021:0x4a501367f076adff"
      },
      {
         "label":"Hawaii, USA",
         "city":"",
         "poiname":"",
         "state":"HI",
         "country":"US",
         "stateprovincedisplayname":"",
         "type":"",
         "countryname":" USA",
         "airportcode":"",
         "value":"Hawaii, USA",
         "geocode":",",
         "searchtype":"recent",
         "analytics":"{\"location\":\"searchForm\",\"sendNow\":\"true\",\"description\":\"Recent Search\"}",
         "placeId":"ChIJBeB5Twbb_3sRKIbMdNKCd0s",
         "primaryDescription":"Hawaii",
         "secondaryDescription":"USA",
         "latitude":"",
         "longitude":"",
         "radius":"50.0",
         "address":"Hawaii, USA",
         "postalCode":"",
         "types":"administrative_area_level_1,political",
         "website":"https://maps.google.com/?q=Hawaii,+USA&ftid=0x7bffdb064f79e005:0x4b7782d274cc8628"
      },
      {
         "label":"Boston, MA, USA",
         "city":"Boston",
         "poiname":"",
         "state":"MA",
         "country":"US",
         "stateprovincedisplayname":"",
         "type":"",
         "countryname":" USA",
         "airportcode":"",
         "value":"Boston, MA, USA",
         "geocode":"42.3600825,-71.0588801",
         "searchtype":"recent",
         "analytics":"{\"location\":\"searchForm\",\"sendNow\":\"true\",\"description\":\"Recent Search\"}",
         "placeId":"ChIJGzE9DS1l44kRoOhiASS_fHg",
         "primaryDescription":"Boston",
         "secondaryDescription":"MA, USA",
         "latitude":"42.3600825",
         "longitude":"-71.0588801",
         "radius":"80467.2",
         "address":"Boston, MA, USA",
         "postalCode":"",
         "types":"locality,political",
         "website":"https://maps.google.com/?q=Boston,+MA,+USA&ftid=0x89e3652d0d3d311b:0x787cbf240162e8a0"
      },
      {
         "label":"San Diego, CA, USA",
         "city":"San Diego",
         "poiname":"",
         "state":"CA",
         "country":"US",
         "stateprovincedisplayname":"",
         "type":"",
         "countryname":" USA",
         "airportcode":"",
         "value":"San Diego, CA, USA",
         "geocode":"32.715738,-117.1610838",
         "searchtype":"recent",
         "analytics":"{\"location\":\"searchForm\",\"sendNow\":\"true\",\"description\":\"Recent Search\"}",
         "placeId":"ChIJSx6SrQ9T2YARed8V_f0hOg0",
         "primaryDescription":"San Diego",
         "secondaryDescription":"CA, USA",
         "latitude":"32.715738",
         "longitude":"-117.1610838",
         "radius":"80467.2",
         "address":"San Diego, CA, USA",
         "postalCode":"",
         "types":"locality,political",
         "website":"https://maps.google.com/?q=San+Diego,+CA,+USA&ftid=0x80d9530fad921e4b:0xd3a21fdfd15df79"
      },
      {
         "label":"Washington D.C., DC, USA",
         "city":"Washington",
         "poiname":"",
         "state":"DC",
         "country":"US",
         "stateprovincedisplayname":"",
         "type":"",
         "countryname":" USA",
         "airportcode":"",
         "value":"Washington D.C., DC, USA",
         "geocode":"38.9071923,-77.0368707",
         "searchtype":"recent",
         "analytics":"{\"location\":\"searchForm\",\"sendNow\":\"true\",\"description\":\"Recent Search\"}",
         "placeId":"ChIJW-T2Wt7Gt4kRKl2I1CJFUsI",
         "primaryDescription":"Washington D.C.",
         "secondaryDescription":"DC, USA",
         "latitude":"38.9071923",
         "longitude":"-77.0368707",
         "radius":"80467.2",
         "address":"Washington, DC, USA",
         "postalCode":"",
         "types":"locality,political",
         "website":"https://maps.google.com/?q=Washington,+DC,+USA&ftid=0x89b7c6de5af6e45b:0xc2524522d4885d2a"
      },
      {
         "label":"Las Vegas, NV, USA",
         "city":"Las Vegas",
         "poiname":"",
         "state":"NV",
         "country":"US",
         "stateprovincedisplayname":"",
         "type":"",
         "countryname":" USA",
         "airportcode":"",
         "value":"Las Vegas, NV, USA",
         "geocode":"36.1699412,-115.1398296",
         "searchtype":"recent",
         "analytics":"{\"location\":\"searchForm\",\"sendNow\":\"true\",\"description\":\"Recent Search\"}",
         "placeId":"ChIJ0X31pIK3voARo3mz1ebVzDo",
         "primaryDescription":"Las Vegas",
         "secondaryDescription":"NV, USA",
         "latitude":"36.1699412",
         "longitude":"-115.1398296",
         "radius":"80467.2",
         "address":"Las Vegas, NV, USA",
         "postalCode":"",
         "types":"locality,political",
         "website":"https://maps.google.com/?q=Las+Vegas,+NV,+USA&ftid=0x80beb782a4f57dd1:0x3accd5e6d5b379a3"
      }
   ];
    if (!_self.listHtml || (!_self.$parent.hideCurrentLocation && _self.listCount === 1)) {
      renderList = "<li class='autocomplete-recently-viewed-header-container'><span class='t-recenlty-viewed-icon t-icon-eye'></span><span class='t-font-weight-normal t-font-s'>Top Destinations</span></li>";
      for(var i=0; i<topDestinationsObject.length; i++) {
        renderList += "<li class='autocomplete-listitem   autocomplete-listitem-recently' role='menuitem'><a data-index='" + i + "' data-primary-description='" + topDestinationsObject[i].primaryDescription + "' data-secondary-description='" + topDestinationsObject[i].secondaryDescription + "' data-latitude='" + topDestinationsObject[i].latitude + "' data-longitude='" + topDestinationsObject[i].longitude + "'> " + topDestinationsObject[i].label +"</a></li>";
      }
      if(!_self.listHtml) {
        _self.listCount = 1;
        _self.listHtml = renderList;
      } else {
        _self.listHtml += renderList
      }
    }
 },
  /**
  * This function creates recent search column in autocomplete list.
  * @return null
  **/
  suggestRecent: function (searchTerm) {
    var _self = this;
    if (_self.permittedSiteId()) {
      var recentSearchList = _self.getAutocompleteItem(searchTerm, _self.$input),
        renderList = '';
    }

    if (recentSearchList && recentSearchList.length) {
      //Recent category header
      _self.recentSearchesEmpty = false;
      // This flag is used to show new autocomplete (with recently viewed properties)
      if (_self.options.categoryParams.showRecentlyViewedProperties) {
        renderList += "<li class='autocomplete-list-header autocomplete-list-header-new'><span class='t-icon-clock autocomplete-recent-icon autocomplete-recent-icon-new'></span><span class='recent-searches-text'>" +
          _self.options.categoryParams.recentSearchesLabel +
          "</li>";
      } else {
        renderList += "<li class='autocomplete-list-header'><span class='t-icon-clock autocomplete-recent-icon'></span> <strong>" +
          _self.options.categoryParams.recent +
          '</strong><span class="t-control-link clear-recent-search">' +
          _self.options.categoryParams.clear + "</span></li>";
      }

      for (var i = 0; i < recentSearchList.length; i++) {
        recentSearchList[i].index = _self.listCount + i;
        renderList += _self.markSearchTerm(searchTerm, _self.templates.autoCompleteRecent(recentSearchList[i]));
      }
      _self.listHtml += renderList;
      _self.listCount += recentSearchList.length;
    } else {
      _self.recentSearchesEmpty = true;
    }
    return _self.listCount;
  },
  /**
  * This function sets autocomplete list to its container and modifies css accordingly.
  * @return null
  **/
  showList: function (inputTarget) {
    var _self = this;
    _self.blankLocationInputFlag = true;
    if (_self.listCount) {
      if (_self.options.clusterSearchHandler) {
        _self.$input = $(inputTarget);
        _self.$autoCompleteList = _self.$input.parent().parent().find('.autocomplete-scroller-wrapper ul');
        _self.$input.parent().parent().find('.autocomplete-scroller-wrapper').show();
        _self.$autoCompleteList.show().html(_self.listHtml);
        setTimeout(function () {
          _self.$autoCompleteList.width(inputTarget.outerWidth());
        }, 100);
      } else {
        _self.$autoCompleteList.html(_self.listHtml);
      }

      // This flag is used to show new autocomplete (with recently viewed properties)
      if (_self.options.categoryParams.showRecentlyViewedProperties) {
        _self.$autoCompleteList.find(".autocomplete-list-header").addClass("autocomplete-list-header-recently");
        _self.$autoCompleteList.find(".autocomplete-listitem").addClass("autocomplete-listitem-recently");
      }

      var narrate = "";
      var destinationLabelHeight = _self.$parent.$el.find('.homepage-search-labels').length ? 0 : _self.$parent.$el.find('.field-title').height();

      if (_self.$autoCompleteList.filter(':hidden')) {
        _self.$autoCompleteList.show();
        _self.$scrollbarWrapper.show();
      }

      // Adding mousedown event to detect elements where no event should take place on click
      _self.$autoCompleteList.mousedown(function (e) {
        if ($(e.target).hasClass('t-autosuggest-msg') || $(e.target).hasClass('autocomplete-list-header')) {
          _self.hideSuggestionsFlag = false;
        }
        else if ($(e.target).parents().hasClass("l-recently-viewed-item") || $(e.target).parents().hasClass("expand-collapse-btn")) {
          _self.hideSuggestionsFlag = false;
        }
        else {
          _self.hideSuggestionsFlag = true;
          _self.blankLocationInputFlag = false;
          _self.hideSuggestions(null, false);
        }
      });

      _self.$input.attr('data-count', _self.listCount);
      _self.suggestionListShow = _self.listCount > 0;

      //ask narrator to speak the options
      if (_self.$autoCompleteList.find('.autocomplete-listitem-active a').length) {
        narrate = _self.$autoCompleteList.find('.autocomplete-listitem-active a').text();
        _self.$narrate.text(narrate);
      }

      var autoCompleteListHeight = _self.$el.find('.autocomplete-list').height(),
        autoCompleteMaxHeight = _self.$scrollbarWrapper.height(),
        isDefaultList = true;

      //handle autocomplete list with options and without options
      if (autoCompleteListHeight > autoCompleteMaxHeight) {
        isDefaultList = false;
      } else {
        isDefaultList = true;
      }

    } else if (!_self.listCount && !_self.listHtml) {
      _self.hideSuggestions(null, true);
    }
  },
  /**
  * This function generates autocomplete list as per API response.
  * @return null
  **/
  parseResult: function (searchTerm, inputTarget, data) {
    var _self = this;
    var listHtml = '';
    _self.listHtml = '';
    _self.listCount = 0;

    // For cluster autosuggest - this identifier is used 
    if (_self.options.clusterSearchHandler) {
      if ((Object.keys(data)).length > 0) {
        listHtml = _self.filterClusterSearchData(data);
      } else {
        _self.clearClusterCodeData();
      }
    }
    else if (_self.options.isGroupCodeHandler) {
      listHtml = _self.GroupCodeAutoComplete.filterGroupCodeSearchData(data);
      _self.listCount = listHtml.count;
    }
    else {
      listHtml = _self.filterLocationSearchData(data);
    }
    if (!_self.options.isGroupCodeHandler) {
      _self.listHtml += listHtml;
    } else {
      _self.listHtml += listHtml.listHtmlData;
    }
    // This flag is used to show new autocomplete (with recently viewed properties)
    if (_self.options.categoryParams.showRecentlyViewedProperties) {
      _self.suggestRecentlyViewedProperties(_self.recentSearchTerm);
      _self.suggestRecent(_self.recentSearchTerm);
      _self.renderClearRecent();
    }
    _self.showList(inputTarget, true);
    _self.$autoCompleteList.css({          
      left: '0px',
      width: inputTarget.outerWidth() + 'px'
    });
    _self.$el.find('.js-auto-complete-input-v2').removeClass('js-autocomplete-focused');
  },
  /** Clear cluster code related hidden input text from form**/
  clearClusterCodeData: function () {
    this.$el.find('input[name="hiddenClusterCode"],input[name="rateClusterSetNum"],input[name="rateClusterSetNumType"]').val('');
  },
  /**
  * This function creates autocomplete list out of location search response.
  * @return Html for list
  **/
  filterLocationSearchData: function (data) {
    var _self = this;
    var resultType, autocompleteData;
    var listHtml = '';
    var categoryItems = 0;
    autocompleteData = _.extend({ index: count }, _self.templateSettings.imports);

    // This flag is used to show new autocomplete (with recently viewed properties)
    if (_self.options.categoryParams.showRecentlyViewedProperties) {
      var count = 0;
      if (data.suggestions) {
        listHtml += _self.templates.autoListHeader({
          category: _self.options.categoryParams['searchResults']
        });
      } else {
        _self.suggestGeoLoc();
      }
      _.each(data.suggestions, function (suggestion) {
        autocompleteData['index'] = count;
        autocompleteData['primaryDescription'] = suggestion.primaryDescription;
        autocompleteData['secondaryDescription'] = suggestion.secondaryDescription;
        autocompleteData['placeId'] = suggestion.placeId;
        listHtml += _self.markSearchTerm(_self.recentSearchTerm, _self.templates.autoCompleteCustom(autocompleteData));
        count++;
      });
      _self.listCount = count;
      return listHtml;

    } else {
      var count = _self.suggestRecent(_self.recentSearchTerm);
      if (data.suggestions) {
        listHtml += _self.templates.autoListHeader({
          category: _self.options.categoryParams['searchResults']
        });
      } else {
        _self.suggestGeoLoc();
      }
      _.each(data.suggestions, function (suggestion) {
        autocompleteData['index'] = count;
        autocompleteData['primaryDescription'] = suggestion.primaryDescription;
        autocompleteData['secondaryDescription'] = suggestion.secondaryDescription;
        autocompleteData['placeId'] = suggestion.placeId;
        listHtml += _self.markSearchTerm(_self.recentSearchTerm, _self.templates.autoCompleteCustom(autocompleteData));
        count++;
      });
      _self.listCount = count;
      return listHtml;
    }


  },

  /** 
   * This function is specifically for cluster
   * @return list of options 
   **/
  filterClusterSearchData: function (data) {
    var _self = this, jsonData = '';
    var tabValue = this.$parent.$el.find('.js-searchtype').val();
    var resultType, autocompleteData;
    var listHtml = '';
    var categoryItems = 0;
    var count = _self.suggestRecent(_self.recentSearchTerm);
    if (data.clusterDetailsList) {
      jsonData = data.clusterDetailsList;
    } else {
      jsonData = data.clusters;
    }
    _.each(jsonData, function (suggestions) {
      autocompleteData = _.extend({ index: count }, _self.templateSettings.imports);
      categoryItems = 0;
      if (_self.options.maxCategory > categoryItems) {
        _.each(suggestions, function (value, key) {
          autocompleteData[key.toLowerCase()] = value;
          if (tabValue === 'InHotelSearch') {
            autocompleteData['type'] = 'property';
          }
        });
        autocompleteData['index'] = count;
        autocompleteData['searchterm'] = _self.recentSearchTerm;
        listHtml += _self.markSearchTerm(_self.recentSearchTerm, _self.templates.autoCompleteCluster(autocompleteData));
        count++;
      }
      categoryItems++;
    });

    _self.listCount = count;
    return listHtml;
  },
  /**
  * This function handles click on an item selected through autocomplete list.
  * @return null
  **/
  xhrPlacesRequest: false,
  listItemSelect: function (evt) {
    var _self = this;

    // Mousedown on Recently Viewed Item
    if (evt && $(evt.target).parents().hasClass("l-recently-viewed-item")) {
      _self.recentlyViewedListItemSelect(evt);
      return;
    }
    _self.blankLocationInputFlag = false;
    var $selectedItem = _self.$autoCompleteList.find('.autocomplete-listitem-active a'),
      placeId = $selectedItem.attr('data-place-id'),
      uuid = sessionStorage.getItem('uniqueID');
    // Gets executed when CurrentLocation is selected
    if ($selectedItem.hasClass('js-geoloc')) {
      _self.geoLocDetails();
      // Code to remove subtakeover on selection of an entry
      _self.resetSuggestionList(false, true);
      _self.$parent.removeSubTakeover(evt, true);
      return;
    }
    // This is used to assign selected location on click of suggested location
    if(_self.options.isAirpotLocationDetected){
      this.$parent.options.inputs.selectedLocation = $selectedItem.get(0).innerText;
      this.$parent.options.typeAheadLocation = true;
    }
    // Won't set location field value if selected item is not available
    if (!$selectedItem.length) {
      _.bind(_self.hideSuggestions(), _self);
      if (_self.options.clusterSearchHandler) {
        inputMap = {
          hiddenClusterCode: $($selectedItem).val(),
          rateClusterSetNum: null,
          rateClusterSetNumType: 'unknownCode'
        };
        _.each(inputMap, function (value, name) {
          _self.$parent.$el.find('input[name=' + name + ']').val(value || '');
        }, _self);
      }
      return;
    }
    if (placeId && ($selectedItem.attr('data-searchtype') !== 'recent')) {
      var placesRequest = {
        type: 'GET',
        url: _self.options.placesUrl,
        contentType: 'text/html',
        cache: true,
        data: {
          placeId: placeId,
          uuid: uuid
        },
        success: _.bind(_self.populateInputMap, _self, $selectedItem)
      };

      if (_self.xhrPlacesRequest) {
        _self.xhrPlacesRequest.abort();
      }
      _self.xhrPlacesRequest = _self.makeAjaxCall(placesRequest);
    } else {
      _self.populateInputMap($selectedItem);
    }

    if (!_self.options.isGroupCodeHandler) {
      animatePlaceholder.checkPlaceholderAnimation();
    }
    // Code to remove subtakeover on selection of an entry
    _self.resetSuggestionList(false, true);
    _self.$parent.removeSubTakeover(evt, true);
    if(this.$parent.responsiveUtils.isMobileOrTablet()){
      _self.$el.parents('.l-hsearch-labels').find(".l-m-disabled").removeAttr("disabled").removeClass("l-m-disabled");
    }
  },
  listDestItemSelect: function (evt) {
    var _self = this;
    var selectedDestinationAddress = $(evt.currentTarget).data('address');
    var selectedDestinationCountry = $(evt.currentTarget).data('country');
    var selectedDestinationState = $(evt.currentTarget).data('state');
    var selectedDestinationLat = $(evt.currentTarget).data('lat');
    var selectedDestinationLong = $(evt.currentTarget).data('long');
    _self.$el.find('.js-auto-complete-input-v2').val(selectedDestinationAddress);
    $('.search-country').val(selectedDestinationCountry);
    $('.search-state').val(selectedDestinationState);
    $('.search-latitude').val(selectedDestinationLat);
    $('.search-longitude').val(selectedDestinationLong);
    if (!_self.options.isGroupCodeHandler) {
      animatePlaceholder.checkPlaceholderAnimation();
    }
    // Code to remove subtakeover on selection of an entry
    _self.resetSuggestionList(false, true);
    _self.$parent.removeSubTakeover(evt, true);
    if(this.$parent.responsiveUtils.isMobileOrTablet()){
      _self.$el.parents('.l-hsearch-labels').find(".l-m-disabled").removeAttr("disabled").removeClass("l-m-disabled");
    }
  },
  /**
   * This function performs locations search on basis of recent viewed property name
   * @param {Event (keydown and mousedown) on recently viewed property} event 
   */
  recentlyViewedListItemSelect: function _recentlyViewedListItemSelect(event) {
    var _self = this,
      $selectedItem = _self.$autoCompleteList.find(".autocomplete-listitem-active");
    _self.recentSearchTerm = $selectedItem.find("a").data("address");

    var sessionUID = sessionStorage.getItem('uniqueID');
    if (sessionUID === null) {
      sessionUID = uuidv1();
      sessionStorage.setItem('uniqueID', sessionUID);
    }

    var parseCall = _.bind(_self.selectRecentlyViewedPlaceResult, _self);
    // In order to close the recently viewed auto suggestions after "show more" is clicked
    // if(_self.$parent.responsiveUtils.isDesktop()){
      // _self.hideSuggestionsFlag = true;
      _self.hideSuggestions();
    // }
    _self.performLocationSearch(parseCall, sessionUID);

    // Code to remove subtakeover on selection of an entry
    _self.$parent.removeSubTakeover(event, true);
    if(this.$parent.responsiveUtils.isMobileOrTablet()){
      _self.$el.parents('.l-hsearch-labels').find(".l-m-disabled").removeAttr("disabled").removeClass("l-m-disabled");
    }
  },
  /**
   * Thia function will populate hidden inputs with data recieved by calling places api and passing placeId of first suggestion
   * @param {Result of autocomplete suggestions} data 
   */
  selectRecentlyViewedPlaceResult: function (data) {
    var _self = this,
      $selectedItem = _self.$autoCompleteList.find('.autocomplete-listitem-active a'),
      placeId,
      uuid = sessionStorage.getItem('uniqueID');

    _self.hideSuggestionsFlag = true;

    if (data.suggestions && data.suggestions.length) {
      placeId = data.suggestions[0].placeId;
    }

    if (placeId) {
      var placesRequest = {
        type: 'GET',
        url: _self.options.placesUrl,
        contentType: 'text/html',
        cache: true,
        data: {
          placeId: placeId,
          uuid: uuid
        },
        success: _.bind(_self.populateInputMap, _self, $selectedItem)
      };

      if (_self.xhrPlacesRequest) {
        _self.xhrPlacesRequest.abort();
      }
      _self.xhrPlacesRequest = _self.makeAjaxCall(placesRequest);
    } else {
      _self.populateInputMap($selectedItem);
    }
    _self.resetSuggestionList(false, true);
  },
  /**
  * This function populates the data into hidden fields.
  * @return null
  **/
  populateInputMap: function ($selectedItem, data) {
    var _self = this, locDetails = {}, radiusValue, destinationDetails = {};
    if ($selectedItem.attr('data-place-id') && ($selectedItem.attr('data-searchtype') !== 'recent')) {
      sessionStorage.removeItem('uniqueID');
    }
    try {
      if (_self.options.clusterSearchHandler) {
        var inputMap = {
          hiddenClusterCode: $selectedItem.attr('data-code'),
          rateClusterSetNum: $selectedItem.attr('data-number'),
          rateClusterSetNumType: $selectedItem.attr('data-number-type'),
          rateClusterDescription: $selectedItem.attr('data-desc'),
        };
        _self.$input.val(_self.recentSearchTerm = (_self.templateSettings.imports.mergeItem(", ", _self.templateSettings.imports.clusterFormat(inputMap.hiddenClusterCode, inputMap.rateClusterDescription))));
        _.each(inputMap, function (value, name) {
          _self.$parent.$el.find('input[name=' + name + ']').val(value || '');
        }, _self);
        if (_self.onListItemSelect) {
          _self.onListItemSelect(inputMap);
        }
        return;
      }
      if (data) {
        locDetails = data.suggestions[0].details.location;
        radiusValue = data.suggestions[0].details.distance;
        destinationDetails.address = data.suggestions[0].details.location.address;
        destinationDetails.postalCode = data.suggestions[0].details.location.postalCode;
        destinationDetails.types = data.suggestions[0].details.types ? data.suggestions[0].details.types.join(',') : null;
        destinationDetails.website = data.suggestions[0].details.url;

      } else {
        geoCode = $selectedItem.attr('data-geo');
        if (geoCode) {
          geoCode = (geoCode && geoCode.split(',')) || ['', ''];
          locDetails.latitude = geoCode[0];
          locDetails.longitude = geoCode[1];
        }
      }
      var inputMap = {
        latitude: locDetails.latitude || $selectedItem.attr('data-latitude'),
        longitude: locDetails.longitude || $selectedItem.attr('data-longitude'),
        route: $selectedItem.attr('data-route'),
        cityPop: $selectedItem.attr('data-city-population'),
        cityPopDensity: $selectedItem.attr('data-city-population-density'),
        city: locDetails.city || $selectedItem.attr('data-city'),
        state: locDetails.state || $selectedItem.attr('data-state'),
        countryname: $selectedItem.attr('data-country'),
        country: locDetails.country || $selectedItem.attr('data-countrycode'),
        poiname: $selectedItem.attr('data-poi-name'),
        airport: $selectedItem.attr('data-airport-code'),
        airportName: $selectedItem.attr('data-airport-name'),
        singleSearchAuto: null,
        autoSuggestOmni: locDetails.property ? 'property' : $selectedItem.attr('data-type'),
        stateProvince: $selectedItem.attr('data-state-province'),
        propertyName: $selectedItem.attr('data-property-name'),
        propertyCode: locDetails.property || $selectedItem.attr('data-property-code'),
        searchType: $selectedItem.attr('data-searchtype'),
        primaryDescription: $selectedItem.attr('data-primary-description'),
        secondaryDescription: $selectedItem.attr('data-secondary-description'),
        placeId: $selectedItem.attr('data-place-id'),
        radius: radiusValue || $selectedItem.attr('data-radius'),
        address: destinationDetails.address || $selectedItem.attr('data-address'),
        postalCode: destinationDetails.postalCode || $selectedItem.attr('data-postalCode'),
        types: destinationDetails.types || $selectedItem.attr('data-types'),
        website: destinationDetails.website || $selectedItem.attr('data-website')
      };

      if (inputMap.autoSuggestOmni) {
        inputMap.singleSearchAuto = 'true';
      } else {
        inputMap.singleSearchAuto = 'false';
        inputMap.singleSearchAuto = 'Unmatched';
      }

    if(_self.options.isAirpotLocationDetected) {
      _self.$parent.updateFlightDuration(data);
    }

    if(inputMap.autoSuggestOmni) {
      inputMap.singleSearchAuto = 'true';
    } else {
      inputMap.singleSearchAuto = 'false';
      inputMap.singleSearchAuto = 'Unmatched';
    }
 
    if (!!inputMap.clusterCode) {
      _self.$input.val(_self.recentSearchTerm = (_self.templateSettings.imports.mergeItem(", ", _self.templateSettings.imports.clusterFormat(inputMap.clusterCode, inputMap.clusterDesc))));
    } else {
      if ($selectedItem.attr('data-secondary-description')) {
        _self.$input.val(_self.recentSearchTerm = $selectedItem.attr('data-primary-description') + ", " + $selectedItem.attr('data-secondary-description'));
      } else {
        _self.$input.val(_self.recentSearchTerm = $selectedItem.attr('data-primary-description'));
      }
    }

      inputMap.city = (inputMap.airport || inputMap.city);
      inputMap.showAddressPin = this.isSpecificAddrType(destinationDetails.types || $selectedItem.attr('data-types'));
      _.forEach(inputMap, function (value, name) {
        _self.$parent.$el.find(_self.options.inputs[name]).val(value || '');
      }, _self);

      if (inputMap.searchType === 'recent') {
        if ($selectedItem.attr('data-primary-description')) {
          _self.recentSearchTerm = $selectedItem.attr('data-secondary-description') ? $selectedItem.attr('data-primary-description') + ", " + $selectedItem.attr('data-secondary-description') : $selectedItem.attr('data-primary-description');
          _self.$el.find('.single-search-destination').val($selectedItem.attr('data-secondary-description') ? $selectedItem.attr('data-primary-description') + ", " + $selectedItem.attr('data-secondary-description') : $selectedItem.attr('data-primary-description'));
        } else {
          _self.recentSearchTerm = $selectedItem.attr('data-label');
          _self.$el.find('.single-search-destination').val($selectedItem.attr('data-label'));
        }
      } else if ($selectedItem.parent().hasClass("l-recently-viewed-item")) {
        _self.recentSearchTerm = $selectedItem.attr('data-address');
        _self.$el.find('.single-search-destination').val($selectedItem.attr('data-address'));
        if (!_self.options.isGroupCodeHandler) {
          animatePlaceholder.checkPlaceholderAnimation();
        }
      }


      if (!$selectedItem || !$selectedItem.parents().hasClass("l-recently-viewed-item")) {
        _self.hideSuggestions();
      }

      //Added Empower check
      if(_self.$el.data("associate-view") === "true" || _self.$el.data("associate-view") === true) {
        console.log("Empower Code")
        setTimeout(function(){
          if (_self.onListItemSelect) {
            _self.onListItemSelect(inputMap);
          }
        });
      } else {
        if (_self.onListItemSelect) {
          _self.onListItemSelect(inputMap);
        }
      }

      if (_self.isDesktopVersion()) {
        setTimeout(function () {
          _self.$input.focus();
        });
      } else {
        _self.resetSuggestionList(false, false);
      }
    }
    catch (err) {
      console.error(err);
    }

  },
  /**
   * Check if searched destination is one out of 'establishment', 'premise', 'route', 'street_address'
   * @param {Object} geocodeResult - Google api geocode result
   * @returns {Boolean} TRUE if geocode result is a state
   */
  isSpecificAddrType: function (geocodeResult) {
    var specificAddresses = ['establishment', 'premise', 'route', 'street_address'];

    if (!geocodeResult) {
      return false;
    }
    return geocodeResult.split(',').some(function (item, index) {
      if ($.inArray(item, specificAddresses) > -1) {
        return true;
      }
    });
  },
  /**
  * This function handles geo location details when the protocol is http.
  * @return null
  **/
  geoLocDetails: function () {
    var _self = this;
    var protocol = 'https:';
    if (window.location.protocol !== "https:") {
      Cookies.createCookie('loadCurrentLocation', true);
      window.location.replace(protocol + '//' + window.location.host + window.location.pathname);
    } else {
      _self.geoLocationHandler.doLocalDetails();
    }
  },
  /**
  * This function highlights current option on hover in autocomplete list.
  * @return null
  **/
  highLightListItem: function (evt) {
    var _self = this;
    if (evt.target) {
      evt.stopImmediatePropagation();

      _self.$autoCompleteList.find('.autocomplete-listitem-active').removeClass('autocomplete-listitem-active');

      // To add focused calss to show more - show less accordion
      var $recentlyViewedAccordion = _self.$autoCompleteList.find(".expand-collapse-btn"),
        $clearRecentButton = _self.$autoCompleteList.find(".autocomplete-clear-recent");

      if ($recentlyViewedAccordion.length && $recentlyViewedAccordion.hasClass("focused")) {
        $recentlyViewedAccordion.removeClass("focused");
      }
      if ($clearRecentButton.length && $clearRecentButton.hasClass("autocomplete-clear-recent-active")) {
        $clearRecentButton.removeClass("autocomplete-clear-recent-active");
      }

      if ($(evt.currentTarget).hasClass("expand-collapse-btn")) {
        $(evt.currentTarget).addClass("focused");
        return;
      } else if ($(evt.currentTarget).hasClass("autocomplete-clear-recent")) {
        $(evt.currentTarget).addClass("autocomplete-clear-recent-active");
        return;
      }

      if (!$(evt.target).hasClass('autocomplete-list-header')) {
        var $thisTarget = (evt.target.nodeName == "A") ? $(evt.target) : $(evt.target).closest('a');
        $thisTarget.parent().addClass('autocomplete-listitem-active');
      }
    }
  },
  /**
  * This function used to return the value to desktop version true/false
  * @return boolean value
  **/
  isDesktopVersion: function () {
    return !this.$parent.responsiveUtils.isMobileOrTablet();
  },
  /**
  * This function decides whether or not to show current location option in autocomplete list.
  * @return null
  **/
  suggestGeoLoc: function () {
    var _self = this;
    if (!_self.$parent.hideCurrentLocation) {
      _self.listHtml = _self.geoLocationHandler.autoSuggestItem;
      if (_self.listHtml === '') {
        _self.listCount = 0;
      } else {
        _self.listCount = 1;
      }
    }
    if(_self.$parent.$el.find(_self.options.inputs.koreanDestination).length && !_self.recentSearchTerm){
      var koreancityTitle = _self.$parent.$el.find(_self.options.inputs.popularDestinationTitle).data('koreancity-title');
      var internationalcityTitle = _self.$parent.$el.find(_self.options.inputs.popularDestinationTitle).data('internationalcity-title');
      //var koreancity = _self.$parent.$el.find(_self.options.inputs.koreanDestination).data('korean-destinations') ? _self.$parent.$el.find(_self.options.inputs.koreanDestination).data('korean-destinations').split(',') : [];
      var koreancity = [];
      $('.search-korean-destination').each(function(index,item) {
        var kdestinationInfo = {};
        kdestinationInfo.destination=$(item).data('korean-destinations-dest');
        kdestinationInfo.state=$(item).data('korean-destinations-state');
        kdestinationInfo.country=$(item).data('korean-destinations-con');
        kdestinationInfo.address=$(item).data('korean-destinations-add');
        kdestinationInfo.latitude=$(item).data('korean-destinations-lat');
        kdestinationInfo.longitude=$(item).data('korean-destinations-long');
        koreancity.push(kdestinationInfo);
      });
      var internationalcity = [];
      $('.search-international-destination').each(function(index,item) {
          var destinationInfo = {};
          destinationInfo.destination=$(item).data('international-destinations-dest');
          destinationInfo.state=$(item).data('international-destinations-state');
          destinationInfo.country=$(item).data('international-destinations-con');
          destinationInfo.address=$(item).data('international-destinations-add');
          destinationInfo.latitude=$(item).data('international-destinations-lat');
          destinationInfo.longitude=$(item).data('international-destinations-long');
          internationalcity.push(destinationInfo);
      });
      //var internationalcity = _self.$parent.$el.find(_self.options.inputs.internationalDestination).data('international-destinations') ? _self.$parent.$el.find(_self.options.inputs.internationalDestination).data('international-destinations').split(',') : [];
      var koreanDestTemplate = '<li class="korean-dest-container"><div class="korean-dest-heading"><span class="t-icon-location"></span><span class="korean-dest-title">'+koreancityTitle+'</span></div><div class="korean-dest-cities">';
      var internationalDestTemplate = '<li class="international-dest-container"><div class="int-dest-heading"><span class="t-icon-location"></span><span class="int-dest-title">'+internationalcityTitle+'</span></div><div class="international-dest-cities">';
      $.each(koreancity, function(i){
        var kdataindex = (1+i);
        koreanDestTemplate += '<div class="korean-dest autocomplete-listitem"><a tabindex="0" class="city analytics-click custom_click_track" data-index="'+kdataindex+'" data-address="'+koreancity[i].address+'" data-country="'+koreancity[i].country+'" data-state="'+koreancity[i].state+'" data-lat="'+koreancity[i].latitude+'" data-long="'+koreancity[i].longitude+'" data-label="'+koreancity[i].address+'" aria-label="'+koreancity[i].destination+'" custom_click_track_value="Korea Search Box|Domestic:'+koreancity[i].destination+'|internal">'+koreancity[i].destination+'</a></div>'; 
        return i < 8;
      });
      $.each(internationalcity, function(i){
        var kdataindex = (13+i);
        internationalDestTemplate += '<div class="international-dest autocomplete-listitem"><a tabindex="0" class="city analytics-click custom_click_track" data-index="'+kdataindex+'" data-address="'+internationalcity[i].address+'" data-country="'+internationalcity[i].country+'" data-state="'+internationalcity[i].state+'" data-lat="'+internationalcity[i].latitude+'" data-long="'+internationalcity[i].longitude+'" data-label="'+internationalcity[i].address+'" aria-label="'+internationalcity[i].destination+'" custom_click_track_value="Korea Search Box|International:'+internationalcity[i].destination+'|internal">'+internationalcity[i].destination+'</a></div>'; 
        return i < 8;
      });
      koreanDestTemplate += '<div class="dest-list-space"></div></div></li>';
      internationalDestTemplate += '<div class="dest-list-space"></div></div></li>';
      _self.listHtml += koreanDestTemplate;
      _self.listHtml += internationalDestTemplate;
      if(_self.$parent.responsiveUtils.isMobileOrTablet()){
        _self.$autoCompleteList.css({          
          left: '0px',
          borderTop: 'solid 1px #d2d2d2',
          borderRadius: '4px',
          width: _self.$input.outerWidth() + 'px'
        });
      } else {
        var autoCompleteListWidth = (2 * _self.$input.outerWidth()) + 20;
        _self.$autoCompleteList.css({          
          left: '0px',
          borderTop: 'solid 1px #d2d2d2',
          borderRadius: '4px',
          width: autoCompleteListWidth + 'px'
        });
      }
      _self.$el.find('.js-auto-complete-input-v2').addClass('js-autocomplete-focused');
    } else {
      $('.korean-dest-container').remove();
      $('.international-dest-container').remove();
    }
  },
  /**
  * This function highlights current search term in the resulted autocomplete list.
  * @return null
  **/
  markSearchTerm: function (searchTerm, nodeText) {
    if (searchTerm) {
      var searchTermRegx = new RegExp(searchTerm, 'ig');
      return nodeText.replace(/>([^><]+)<\//i, function (label) {
        return label.replace(searchTermRegx, function (match) {
          return '<strong>' + match + '</strong>';
        })
      });
    } else {
      return nodeText;
    }
  },
  /**
  * This function is called when user navigates the list up and down with keyboard.
  * @return null
  **/
  navigateList: function (evt) {
    var _self = this, $selectedItem = '', inputMap = '';
    if (!_self.$input.length) {
      _self.setLocationInput();
    }
    if (_self.suggestionListShow) {
      var keyCode = evt.keyCode || evt.which;
      var dataCount = parseInt(evt.target.getAttribute('data-count'));
      _self.preventListReRender = false;
      /**
      * Keycodes
      *  38 - Up
      *  40 - Down
      *  37 - Left
      *  39 - Right
      *  13 - Enter
      *  27 - Esc
      *  9 - Tab
      *  8 - Backspace
      **/
      switch (keyCode) {
        case 38:
        case 40:
          evt.preventDefault();
          var $activeListItem = _self.$autoCompleteList.find(".autocomplete-listitem-active"),
            $recentlyViewedListItem = _self.$autoCompleteList.find(".l-recently-viewed-item"),
            $recentlyViewedAccordion = _self.$autoCompleteList.find(".expand-collapse-btn"),
            $clearRecentButton = _self.$autoCompleteList.find(".autocomplete-clear-recent");

          // When focus is at nothing and up arrow is pressed
          if (!$activeListItem.length && keyCode === 38 && !$clearRecentButton.hasClass("autocomplete-clear-recent-active") && !$recentlyViewedAccordion.hasClass("focused")) {
            _self.recentSearchTerm = '';
            _self.$input.val(_self.recentSearchTerm);
            $clearRecentButton.addClass("autocomplete-clear-recent-active");
            _self.preventListReRender = true;
            setTimeout(function () {
              _self.$narrate.text($clearRecentButton.find("span").text());
            }, 200);
            return;
          }

          if ($recentlyViewedAccordion.length &&
            !$recentlyViewedAccordion.hasClass("focused") &&
            !$activeListItem.length &&
            !$clearRecentButton.hasClass("autocomplete-clear-recent-active")) {
            if (keyCode === 40) {
              _self.recentSearchTerm = '';
              _self.$input.val(_self.recentSearchTerm);
              _self.preventListReRender = true;
              $recentlyViewedAccordion.addClass("focused");
              setTimeout(function () {
                _self.$narrate.text($recentlyViewedAccordion.find(".show-more-less").text());
              }, 200);
              return;
            }
          }

          // If focus was on clear recent button
          if ($clearRecentButton.hasClass("autocomplete-clear-recent-active")) {
            $clearRecentButton.removeClass("autocomplete-clear-recent-active");

            if (keyCode === 40) {
              // If no search results are available and recently viewed elements are available and accordion is also available
              if ($recentlyViewedListItem.length && $recentlyViewedListItem.first().find("a").data("index") === 0 && $recentlyViewedAccordion.length) {
                $recentlyViewedAccordion.addClass("focused");
                _self.preventListReRender = true;
                setTimeout(function () {
                  _self.$narrate.text($recentlyViewedAccordion.find(".show-more-less").text());
                }, 200);
              }
              return;
            }
          }

          // Focus goes to show more or show less button when up arrow is pressed from last item in recently viewed items
          if ($recentlyViewedListItem.length && $recentlyViewedListItem.first().hasClass("autocomplete-listitem-active") && keyCode === 38 && $recentlyViewedAccordion.length) {
            _self.recentSearchTerm = $activeListItem.find('a').data("label");
            $recentlyViewedAccordion.addClass("focused");
            _self.recentSearchTerm = '';
            _self.$input.val(_self.recentSearchTerm);
            _self.preventListReRender = true;
            setTimeout(function () {
              _self.$narrate.text($recentlyViewedAccordion.find(".show-more-less").text());
            }, 200);
            $activeListItem.removeClass("autocomplete-listitem-active");
            return;
          }

          var $selectedItem = _self.$autoCompleteList.find('.autocomplete-listitem-active');
          var dataIndex = $selectedItem.length && $selectedItem.find('a').attr('data-index') || -1;

          // If recently viewed properties are available and focus is at last element of search result
          if ($recentlyViewedAccordion.length && $selectedItem.next().hasClass("autocomplete-recently-viewed-header-container") && keyCode === 40) {
            $recentlyViewedAccordion.addClass("focused");
            setTimeout(function () {
              _self.$narrate.text($recentlyViewedAccordion.find(".show-more-less").text());
            }, 200);
            $selectedItem.removeClass("autocomplete-listitem-active");
            return;
          }

          if ($recentlyViewedAccordion.length && $recentlyViewedAccordion.hasClass("focused")) {
            $recentlyViewedAccordion.removeClass("focused");

            if (keyCode === 38) {
              $clearRecentButton.addClass("autocomplete-clear-recent-active");
              _self.preventListReRender = true;
              setTimeout(function () {
                _self.$narrate.text($clearRecentButton.find("span").text());
              }, 200);
              return;
            } else if (keyCode === 40) {
              $selectedItem = $recentlyViewedListItem.first();
              _self.recentSearchTerm = $selectedItem.find("a").data("label");
              _self.$input.val(_self.recentSearchTerm);
              $selectedItem.addClass('autocomplete-listitem-active');
              return;
            }

            if ($recentlyViewedAccordion.parent().parent().prev().length) {

              // If up-arrow is pressed and results are available
              if (keyCode === 38) {
                $selectedItem = $recentlyViewedListItem.first();

                // If down-arrow is pressed and results are available
              } else if (keyCode === 40) {
                $selectedItem = $recentlyViewedAccordion.parent().parent().prev();
              }
              dataIndex = $selectedItem.length && $selectedItem.find('a').attr('data-index') || -1;
            } else {
              if (keyCode === 38) {
                return;
              }
            }
          }

          // When focus is on last element of recently viewed properties and accordion is in closed state and no recent searches are available and down arrow is pressed
          if ($recentlyViewedAccordion.hasClass("collapsed") && _self.$autoCompleteList.find(".l-recently-viewed-item:not(.l-secondary-viewed-items)").last().hasClass("autocomplete-listitem-active") && $recentlyViewedListItem.last().next().hasClass("autocomplete-clear-recent") && keyCode === 40) {
            $selectedItem.removeClass('autocomplete-listitem-active');
            if (!_self.recentSearchTerm) {
              _self.recentSearchTerm = $selectedItem.find("a").data("label");
            }
            _self.$input.val(_self.recentSearchTerm);
            $clearRecentButton.addClass("autocomplete-clear-recent-active");
            setTimeout(function () {
              _self.$narrate.text($clearRecentButton.find("span").text());
            }, 200);
            return;
          }

          //For first element in list
          if (parseInt(dataIndex) === 0) {
            if (!_self.initialSearchTerm) {
              _self.initialSearchTerm = _self.$input.val();
            }
            if (keyCode === 38) {
              _self.recentSearchTerm = _self.initialSearchTerm;
              _self.$input.val(_self.recentSearchTerm);
              $selectedItem.removeClass('autocomplete-listitem-active');
              return;
            }
          }

          //For last element in list
          if (((parseInt(dataCount) - parseInt(dataIndex)) === 1) && keyCode === 40) {
            _self.recentSearchTerm = _self.initialSearchTerm;
            _self.$input.val(_self.recentSearchTerm);
            $selectedItem.removeClass('autocomplete-listitem-active');

            // This flag is used to show new autocomplete (with recently viewed properties)
            if (_self.options.categoryParams.showRecentlyViewedProperties) {
              if ($selectedItem.next().length && $selectedItem.next().hasClass("autocomplete-clear-recent")) {
                $clearRecentButton.addClass("autocomplete-clear-recent-active");
                _self.recentSearchTerm = '';
                _self.$input.val(_self.recentSearchTerm);
                _self.preventListReRender = true;
                setTimeout(function () {
                  _self.$narrate.text($clearRecentButton.find("span").text());
                }, 200);
              }
            }
            return;
          }

          if (isFinite(dataIndex = parseInt(dataIndex))) {

            $selectedItem.removeClass('autocomplete-listitem-active');
            dataIndex = dataIndex + (keyCode - 39);

            if (dataIndex >= dataCount) {
              dataIndex -= dataCount;
            } else if (dataIndex < 0) {
              dataIndex = Math.max(-1, dataIndex) + dataCount;
            }

            // When show more accordion is in closed state
            if (_self.$autoCompleteList.find('.l-secondary-viewed-items.l-display-none [data-index="' + dataIndex + '"]').length) {
              if (keyCode === 38) {
                dataIndex -= _self.$autoCompleteList.find('.l-secondary-viewed-items.l-display-none').length;
              } else {
                dataIndex += _self.$autoCompleteList.find('.l-secondary-viewed-items.l-display-none').length;
              }
            }

            $selectedItem = _self.$autoCompleteList.find('[data-index="' + dataIndex + '"]');
            $selectedItem.closest('li').addClass('autocomplete-listitem-active');
          }
          if ($selectedItem.attr('data-primary-description')) {
            if ($selectedItem.attr('data-secondary-description')) {
              _self.recentSearchTerm = $selectedItem.attr('data-primary-description') + ", " + $selectedItem.attr('data-secondary-description');
            } else {
              _self.recentSearchTerm = $selectedItem.attr('data-primary-description');
            }
          } else {
            _self.recentSearchTerm = $selectedItem.attr('data-label');
          }


          if ($selectedItem.hasClass('js-geoloc')) {
            _self.recentSearchTerm = '';
          }

          if ($selectedItem.hasClass('js-geoloc')) {
            _self.$narrate.text($selectedItem.text());
          };

          _self.$input.val(_self.recentSearchTerm);

          /** scroller with keyboard up and down keys*/
          if (_self.isDesktopVersion()) {
            _self.$scrollbarWrapper.scrollTop(0);//set to top
            var selectedItemOffset = $selectedItem.parent('li').next().offset().top;
            _self.$scrollbarWrapper.scrollTop(selectedItemOffset - _self.$scrollbarWrapper.height() * 2);
          }

          break;
        case 37:
        case 39:
          _self.recentSearchTerm = _self.initialSearchTerm;
          break;
        case 13:
          $selectedItem = evt.target.value;
          if (_self.options.clusterSearchHandler) {
            inputMap = {
              hiddenClusterCode: $selectedItem,
              rateClusterSetNum: null,
              rateClusterSetNumType: 'unknownCode'
            };
            _.each(inputMap, function (value, name) {
              _self.$parent.$el.find('input[name=' + name + ']').val(value || '');
            }, _self);
            evt.preventDefault();
            _self.listItemSelect(evt);
          }
          if (_self.$autoCompleteList.find('.autocomplete-listitem-active').length) {
            evt.preventDefault();
            if (_self.$autoCompleteList.find('.autocomplete-listitem-active').hasClass("l-recently-viewed-item")) {
              _self.recentlyViewedListItemSelect(evt);
            } else {
              _self.listItemSelect(evt);
            }
          } else if (_self.$autoCompleteList.find(".expand-collapse-btn.focused").length) {
            evt.preventDefault();
            _self.handleAccordion(null);
          } else if (_self.$autoCompleteList.find(".autocomplete-clear-recent.autocomplete-clear-recent-active").length) {
            evt.preventDefault();
            _self.clear();
            _self.hideSuggestionsFlag = true;
            _self.blankLocationInputFlag = false;
            _self.hideSuggestions(null, false);
          }
          break;
        case 27:
          _self.hideSuggestions(null, true);
          evt.preventDefault();
          break;
        case 9:
          _self.listItemSelect(evt);
          break;
        default:
          break;
      }
    }
  },
  /**
  * This function is utility Function to merge the given inputs
  * @return null
  **/
  merge: function (list, seperator) {
    var result = '', idx = 0;
    for (idx = 0; idx < list.length; idx++) {
      result + (list[idx] && (list[idx] + seperator)) || ''
    }
  },
  /**
  * This function loads recent search list from browsers local storage
  * @return null
  **/
  loadList: function () {
    if (localStorage.default.miRecentSearch) {
      this.recentSearch.list = JSON.parse(localStorage.default.miRecentSearch.replace(/<\/?[^>]+(>|$)/g, ""));
    }
  },
  /**
  * This function is utility Function
  * @return null
  **/
  getAutocompleteItem: function (filterChars, $formField) {
    if ($formField.hasClass(this.recentSearch.inputClass)) {
      this.loadList();
      if (this.recentSearch.list.length > 0) {
        /*if filterChars is supplied, filter the list.labels for lowercase of filterchars (searchterm)*/
        if (filterChars) {
          return $.grep(this.recentSearch.list, function (o, i) {
            // return the items that match the starting of words
            return RegExp("\\b" + filterChars.toLowerCase(), "gi").test(o.label);
          });
        } else {
          return this.recentSearch.list;
        }
      }
    }
  },
  /**
  * This function is utility Function
  * @return null
  **/
  indexOf: function (key, value) {
    var list = this.recentSearch.list;
    var max = list.length;
    var result = -1;
    for (var i = 0; i < max; i++) {
      if (list[i][key] == value) {
        result = i;
        break;
      }
    }
    return result;
  },
  /**
  * This function adds recent search list to browsers local storage
  * @return null
  **/
  addRecent: function () {
    var _self = this;
    if (this.permittedSiteId() && localStorage.default && this.options.searchTypeVal !== _self.options.addressSearch) {
      var newItem = {};
      var editSearchForm = this.$parent.$el.find('.js-recent-search-inputs');
      var countryName, labelArray, airportname;
      var nearText = this.$parent.$el.find('.for-hotels-nearme').val() + ' ';

      newItem.label = editSearchForm.find('.js-search-location').val();
      newItem.city = editSearchForm.find('.js-search-city-display').val() || editSearchForm.find('.search-city').val();
      newItem.poiname = editSearchForm.find('.search-poiname').val();
      newItem.state = editSearchForm.find('.search-state').val();

      newItem.country = editSearchForm.find('.search-country').val();
      newItem.stateprovincedisplayname = editSearchForm.find('.search-state-province').val();
      newItem.type = editSearchForm.find('.js-autosuggest-item-type').val();
      countryName = editSearchForm.find('.search-country-name').val();
      if (countryName) {
        newItem.countryname = countryName;
      } else {
        labelArray = newItem.label ? newItem.label.split(',') : [];
        if (labelArray.length) {
          newItem.countryname = labelArray[(labelArray.length - 1)];
        }
      }

      newItem.airportcode = editSearchForm.find('.search-airport').val();
      if (newItem.airportcode) {
        airportname = editSearchForm.find('.search-airport-name').val();
        if (airportname) {
          newItem.airportname = airportname;
        } else {
          labelArray = newItem.label ? newItem.label.split(',') : [];
          if (labelArray.length) {
            newItem.airportname = labelArray[0];
          }
        }
      }

      newItem.value = newItem.airportcode || newItem.label;
      newItem.geocode = editSearchForm.find('.search-latitude').val() + ',' + editSearchForm.find('.search-longitude').val();
      newItem.searchtype = 'recent';
      newItem.analytics = '{"location":"searchForm","sendNow":"true","description":"Recent Search"}';
      newItem.label = newItem.label && newItem.label.replace(/<(?:.|\n)*?>/gm, '');
      newItem.placeId = editSearchForm.find('.search-place-id').val();
      newItem.primaryDescription = editSearchForm.find('.search-primary-description').val();
      newItem.secondaryDescription = editSearchForm.find('.search-secondary-description').val();
      newItem.latitude = editSearchForm.find('.search-latitude').val();
      newItem.longitude = editSearchForm.find('.search-longitude').val();
      newItem.radius = editSearchForm.find('.js-search-radius').val();
      newItem.address = editSearchForm.find('.destination-address').val();
      newItem.postalCode = editSearchForm.find('.destination-postal-code').val();
      newItem.types = editSearchForm.find('.destination-types').val();
      newItem.website = editSearchForm.find('.destination-website').val();



      this.loadList();

      if (newItem.label && newItem.label.indexOf(nearText) < 0) {

        var indexOfExistingValue = this.indexOf('label', newItem.label);

        if (indexOfExistingValue != -1) {
          //remove the existing entry of same search
          this.recentSearch.list.splice(indexOfExistingValue, 1);
        }

        this.recentSearch.list.unshift(newItem);
        this.recentSearch.list.splice(this.recentSearch.max);
        localStorage.default.setItem('miRecentSearch', JSON.stringify(this.recentSearch.list));
      }
    }
  },
  /**
  * This function is used to clear recent searches
  * @return null
  **/
  clear: function () {
    localStorage.default.removeItem('miRecentSearch');
    localStorage.default.removeItem('recentSearch');

    // This flag is used to show new autocomplete (with recently viewed properties)
    if (this.options.categoryParams.showRecentlyViewedProperties) {
      localStorage.default.removeItem('miRecentlyViewedProperties');
    }
    this.recentSearch.list = [];
    this.resetSuggestionList(true, true);

  },
  resetSuggestionList: function _resetSuggestionList(showSuggestionList, clearList) {
    var _self = this;
    if (!_self.$parent.responsiveUtils.isDesktop()) {
      _self.hideSuggestionsFlag = false;
      _self.recentlyViewedPropertiesDdClosed = true;
      // _self.hideSuggestions(null, false);
      if (clearList) {
        // _self.parseResult('', _self.$el.find('.js-auto-complete-input-v2'), { suggestions: [] });
        _self.listHtml = '';
      }
      _self.$autoCompleteList = _self.$el.find('.autocomplete-scroller-wrapper ul');
      _self.$autoCompleteList.hide().html(_self.listHtml);
      if (showSuggestionList) {
        _self.$autoCompleteList.show();
      }
      animatePlaceholder.checkPlaceholderAnimation();
    }
  },
  checkAndClearPlaceholderAnimation: function () {
    animatePlaceholder.checkPlaceholderAnimation();
  }
};


module.exports = {
  create: function (props) {
    var obj = _.cloneDeep(properties);
    var AutocompleteInstance = ComponentMapper.extend(obj);
    return new AutocompleteInstance(props);
  }
}