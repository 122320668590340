/* Component Dependencies */
var PubSub = require('libs/pub-sub');
var searchForm = require('../../libs/searchFormHorizontal-lib');

var searchFormHorizontalFindHotelsTemplate = require('templates/searchFormHorizontalFindHotels.hbs');

PubSub.register(searchForm.extend({
  type: 'searchFormHorizontalFindHotels',
  template: {
    'searchFormHorizontalFindHotels': searchFormHorizontalFindHotelsTemplate
  }
}));