/* Component Dependencies */
var $ = require('jquery');
require('jquery-ui');

var AriesComponent = require('libs/aries-component');
var PubSub = require('libs/pub-sub');
var Popup = require('libs/popup');

var DateHandler = require('libs/date-handler');
var DatePickerHandler = require('libs/date-picker-handler');
var RoomGuestHandler = require('libs/room-guests-handler');
var SpecialRatesHandler = require('search_libs/special-rates-handler');
var SellOptionsInputHandler = require('search_libs/sell-options-handler');
var AutoCompleteHandler = require('libs/auto-complete-handler');
var AutoCompleteHandlerV2 = require('libs/auto-complete-handler-v2');
var GeoLocationHandler = require('libs/geolocation-handler');
var SearchTabHandler = require('search_libs/search-tab-handler');
// var RoomKeyHandler = require('search_libs/roomkey');
var ScrollBarHandler = require('libs/scrollbar');
var Config = require('libs/config');
var Constants = require('libs/constants');
var ResponsiveUtils = require('utils/responsiveUtils');
var localStorage = require('local-storage-fallback');
var CommonUtils = require('utils/commonUtilityMethods');

var GoogleApi = require('libs/google-api');
var GoogleAutocompleteHandler = require('libs/autocompleteHandler');
var AutocompleteFormFieldMapper = require('libs/autocompleteHandler/autocompleteFormFieldMapper');
var AutocompleteFormMapping = require('libs/autocompleteHandler/autocompleteFormMapping');
var recentSearchService = require('libs/autocomplete/recentSearchService');
var validateMarshaHandler = require('libs/validateMarshaHandler');
var animatePlaceholder = require('libs/input-placeholder-animation');
var Cookies = require('libs/cookies');
var FormatPicker = require('libs/formatPicker');
var datePickerEnabler = require('libs/tdatepicker');
datePickerEnabler($);

var searchForm = AriesComponent.extend({
  isHeaderSearchForm: false,

  subscribe: {
    'TOGGLE_STICKY_SEARCH_FORM': 'toggleStickySearchForm',
    'ADVANCED_SEARCH_TAB_CLICK': 'toggleSearchFormDisplay',
    'OPEN_HEADER_SEARCH_FORM': 'toggleHeaderSearchForm',
    'SELL_STRATEGY_SCROLL': 'initSellOptionsHeight',
    'STICKY_BEHAVIOR_ADDED': 'hideNavigationBar',
    'STICKY_BEHAVIOR_REMOVED': 'showNavigationBar',
    'TOGGLE_CALENDAR_VISIBILITY': 'hideCalendar',
    'UPDATE_CLICK_TO_SEARCH_DATA': 'updateClickToSearchData'
  },
  options: {
    'scrollBarTarget': '.m-flex-frame-wrapper'
  },
  events: {
    'click .js-h-toggle-checkbox': 'toggleCheckbox',
    'click .l-hsearch-labels .single-search,.l-hsearch-labels .js-moreoptions-expander-click': 'expandSearchBar',
				'focusin .l-hsearch-labels .single-search,.l-hsearch-labels .js-moreoptions-expander-click': 'expandSearchBar',
    'click #saved-hotel-container': 'toggleSavedHotelDropdown',
    'blur #saved-hotel-container': 'closeSavedHotelDropdown',
				'click .homepage-search-form .js-find-container, .mobile-web-search-form .js-select-date': 'openMobileTakeOverScreen',
				'keydown .homepage-search-form .js-find-container, .mobile-web-search-form .js-select-date': 'openMobileTakeOverScreen',
				'click .mobile-web-search-form .js-select-destination': 'openMobileSubTakeOverScreenDestination',
				'keydown .mobile-web-search-form .js-select-destination': 'openMobileSubTakeOverScreenDestination',
    'click .FindAndReserveSearchActionForm .m-check-deal-button': 'openDealsBookingWidget',
    'click .search-form-container .mfp-close': 'closeDealsBookingWidget',
    'click .homepage-search-form .js-form-close, #global-header-find-a-hotel .js-form-close, .js-modify-rlm-search-form .js-form-close': 'closeMobileTakeOverScreen',
    'click .edit-search-form .js-form-close, .rate-list-edit-search .js-form-close, .js-modify-rlm-search-form .js-form-close': 'closeStickyTakeOverScreen',
    'click .mfp-close': 'collapseSearchForm',
    'click .js-room-flight, .js-room-car': 'updateRoomUrl',
    'click .js-single-search-incity, .js-single-search-propertySearch': 'sellOptionsHandler',
    'keydown .js-single-search-incity, .js-single-search-propertySearch': 'sellOptionsHandler',
    'click .l-sell-options-footer .js-done': 'toggleSellOptionsBox',
    'click .js-clear-cluster-input': 'clearClusterValue',
    'click .js-submit-sell-options': 'viewRatesAddChangeSegment',
    'click .js-regional-nav-btn': 'regionNavTriggerClick',
    'keyup .js-auto-complete-input-v2': 'hideRateClusterField',
    'click .select-all': 'selectAllBrands',
    'click .clear-all-brand-specific': 'clearAllBrandSpecific',
    'click .clear-selected-brands': 'clearAllBrands',
    'change .brands-section input[type="checkbox"]': 'brandsCountUpdate',
    'click .js-brands-header': 'openBrandsDropDown',
    'click .close-brand-container': 'closeBrandContainer',
    'click .l-brand-section': 'preventPropagation',
    'click .js-btn-search-location': 'setSearchLocation',
    'click .js-hotel-near-me': 'setGeoLatLong',
		'focusin .js-rooms-guests-trigger': 'expandSubTakeover',
		'click .js-rooms-guests-trigger': 'expandSubTakeover',
    'click .js-guestroom-apply': 'removeSubTakeover',
    'click .sub-takeover-cancel-link': 'resetCachedProperties',
    'click .js-submit-search-form': 'submitSearchFormData'
  },
  updateInventoryLabel: function (isKGroup) {
    var _self = this;
    _self.kGroupLabel = _self.inventoryOptions.label + (isKGroup == 'true' ? _self.inventoryOptions.kGroupValue : _self.inventoryOptions.value);
  },
  bindEvents: function () {
    var _self = this;
    _self.inventoryOptions = _self.$el.find(".inventory-date").data('inventory') || {};
    var pickerOptions = _self.$el.find('.js-date-pick-container').data('options');
    pickerOptions && _self.updateInventoryLabel(pickerOptions.showKGroupDate);
    _self.ToDateInitial = new Date(_self.$el.find('.js-toggle-picker .js-date-to').val()).getTime();
    _self.FromDateInitial = new Date(_self.$el.find('.js-toggle-picker .js-date-from').val()).getTime();

    _self.$el.find('.js-datepick-container').on('change', _self.reservationDateToOverlay.bind(_self));

    _self.isAutoCompleteHandlerInitiated = false;
    _self.validateMarshaHandler = new validateMarshaHandler();
    var engageViewportConfig = null;
    //adding custom class when opening force sell overlay through unsuccesful popup
    _self.$el.closest('.t-modal-smallMed').addClass('m-modal t-modal-smallMed force-sell-overlay-form');

    // engage scenario
    if (this.$el.find('input[name="associateViewFlag"]').length) {
      this.isAssociateView = true;
      engageViewportConfig = {
        TABLET_VIEWPORT: 600,
        DESKTOP_VIEWPORT: 768
      };
    }
    this.idPrefix = this.$el.data("id-prefix");
    this.autocompleteInputEl = this.$el.find('#' + this.idPrefix + '_search-location')[0];
    this.hideCurrentLocation = (window.location.protocol === 'https:' && !this.$el.data("suppress-google-api"));
    this.enableGooglePlaceAutoSuggestion = this.$el.data('enable-google-places-api');
    this.enableCustomAutoSuggest = this.$el.data('custom-auto-suggest');
    this.windowWidth = $(window).width();

    var searchFormFieldWrap = _self.$el.find('.m-field-wrap');
    var searchFormHeader = _self.$el.find('.m-field-wrap').prev();
    var searchFormVariation = this.getTakeOverVariation();

    //micore lib jQuery tab fix
    if (!this.enableGooglePlaceAutoSuggestion) {
      this.$el.parent().find(".search-saved-hotels-tabs, .single-search-tabs").tabs().tabs('destroy');
    }
    this.$el.find('.special-rates-section .ui-tabs-selected').removeClass('ui-tabs-selected');

    /*Initialization*/
    this.isAddSegmentTile = this.$el.find('#addsegment-datepicker').length === 1;
    this.isForceSellOverlay = this.$el.find('.js-is-forcesell-overlay').length === 1;
    this.initResponsiveUtils(engageViewportConfig);
    this.disableSubTakeover = !this.$el.data('show-sub-takeover') && this.responsiveUtils.isMobileOrTablet();
    if(!_self.disableSubTakeover) {
      _self.$el.parent().addClass("is-new-ux");
    }
    // Disabling Room guest element in Modify RLM
    if (!this.$el.find('.js-rooms-guests-trigger').hasClass('js-readonly-element')) {
      this.initRoomGuestHandler();
    }

    // tabindexing doesn't need to be handled in case of addsegment Tile
    if (!$('#addsegment-datepicker').length) {
      this.handleTabIndex();
    }
    // open the sell options dropdown by default if datepicker is not there
    if (!this.$el.find('.js-datepick-container').length) {
      this.$el.find('.js-special-rates-header').click();
    }

    if (searchFormVariation !== Constants.SEARCH_FORM.VARIATIONS.HOMEPAGE) {
      this.handleSearchFormResize();
    }

    if (!_self.$el.data("hide-date-picker")) {
      this.datePickerPlugin = new DatePickerHandler();
      this.datePickerPlugin.initDatePicker(this.$el, this.responsiveUtils, this);
    }

    this.initSpecialRateHandler();
    this.initSellOptionsInputHandler();

    this.$el.find('#saved-hotel').change(function () {
      if ($(this).find(':selected').val() === '') {
        $(this).addClass('grey-color');
      } else {
        $(this).removeClass('grey-color');
      }
    });
    this.$el.find('#saved-hotel').change();
    this.initHotelSellOptions();
    if (this.$el.find('.l-hsearch-loc').length) {
      this.initAutocomplete();
    }

    this.initSearchTabHandler();
    // if (typeof hdVars !== 'undefined' && this.$el.data('roomkeyflag')) {
    //   var roomKeyHandler = new RoomKeyHandler({
    //       $el: this.$el,
    //       $parent: this
    //     });
    //   roomKeyHandler.init();
    // }
    /**Ends**/

    //to add scrollbar only for desktop - START
    if (this.responsiveUtils.isDesktop()) {
      this.initScroller();
    }

    //Search form tablet design fix for named variations
    if (this.responsiveUtils.isMobileOrTablet()) {
      switch (searchFormVariation) {
        case Constants.SEARCH_FORM.VARIATIONS.HEADER:
        case Constants.SEARCH_FORM.VARIATIONS.ADVANCED_SEARCH:
        case Constants.SEARCH_FORM.VARIATIONS.MODIFY_RES:
        case Constants.SEARCH_FORM.VARIATIONS.EDIT_SEARCH:
          $(this.$el.find('.t-more-search-options')[0]).addClass('l-clearfix');
          break;
        case Constants.SEARCH_FORM.VARIATIONS.RATE_LIST_EDIT:
        case Constants.SEARCH_FORM.VARIATIONS.AVAILABILITY_SEARCH:
          if (this.responsiveUtils.isDesktop()) {
            $(this.$el.find('.t-more-search-options')[0]).addClass('l-clearfix');
          }
          break;
        default:
          break;
      }
    }

    //Add Takeover brackground to body
    if (!$('.takeover-background').length && !((searchFormVariation === Constants.SEARCH_FORM.VARIATIONS.CHECK_AVAILABILITY_HWS) || (searchFormVariation === Constants.SEARCH_FORM.VARIATIONS.ADVANCED_SEARCH) || !(searchFormVariation === Constants.SEARCH_FORM.VARIATIONS.ASSOCIATE_SEARCH) || (searchFormVariation === Constants.SEARCH_FORM.VARIATIONS.MODIFY_RES))) {
      this.takeoverBackground = $('<div class="takeover-background"></div>');
      $('body').append(this.takeoverBackground);
    } else {
      this.takeoverBackground = $('.takeover-background');
    }


    var form = this.$el.find('form');
    // Adding handler for RLM search form submit to check if there is a change in form data
    if (_self.$el.hasClass('js-check-data-change')) {
      _self.initialFormData = _self.serializeFormData(form);
      form.on('submit', function (event) {
        _self.modifyRlmSearchHandler(event);
      });
    }
    //Adding Check for brand checboxes
    if (this.$el.data('brands') === true) {
      var selectedBrandsArr = [];
      // Won't close brand subtakeover on click of document
      // in case of mobile or tablet
      if(this.disableSubTakeover || !this.responsiveUtils.isMobileOrTablet()) {
        $(document).on('click', _.bind(this.closeBrandContainer, this));
						}
						$(window).on('load', function() {
							_self.brandsCountUpdate();      
						});
      $(form).submit(function () {
        _self.$el.find('.brands-section input[type="checkbox"]:checked').each(function () {
          selectedBrandsArr.push($(this).val());
        });
        if (_self.$el.find('.brands-section input[type="checkbox"]').length == selectedBrandsArr.length) {
          _self.$el.find('input[name=marriottBrands]').val('all');
        } else {
          _self.$el.find('input[name=marriottBrands]').val(selectedBrandsArr.join(','));
        }
        _self.$el.find('.brands-section input[type="checkbox"]').attr("disabled", true);
      });
    }
    //Force sell overlay
    if (this.$el.data('option') === 'force-sell-overlay') {
      form.on('submit', function (event) {
        event.preventDefault();
        var formData = form.serializeObject();
        var billingLocCodeUrl = form.data('billing-loc-code-url');
        var forceSellFormData = {
          'sessionToken': _self.getSessionToken(),
          'formData': formData
        };
        $(this).find('#submit-button').addClass('is-global-disable');
        _self.submitForceSellForm(forceSellFormData);
      });
      this.$el.find('.js-hotelname').on('change', function () {
        if (event.target.value === "") {
          return;
        }
        var context = {
          "sessionToken": _self.getSessionToken(),
          "variation": _self.getVariation(),
          "context": {
            "hotelCode": event.target.value
          }
        };
        _self.makeAjaxCall({
          url: _self.$el.find('.js-tabout-url').val(),
          contentType: 'application/json',
          method: 'POST',
          data: JSON.stringify(context),
          success: _.bind(_self.forceSellTaboutSuccess, _self),
          error: _.bind(_self.forceSellTaboutSuccess, _self)
        })
      });
    }
    else if (this.$el.find('.js-modify-review-res-form').data('template') === 'modify-review-change') {
      form.on('submit', function (event) {
        event.preventDefault();
        _self.submitModifyReviewForm();
      });
    }
    else {
      form.submit(_.bind(this.submitValidations, this));
    }

    _self.$el.find('.js-fs-overlay-cancel-link').on('click', function (e) {
      _self.pubsub.publish('POPUP_CLOSE');
    });

    var findSearchBtnHeight = _self.$el.find('.t-more-search-options .l-hsearch-find button').outerHeight();
    var searchFormHeight;
    //Android specific fix
    var isAndroid = this.isAndroidDevice();
    if (isAndroid) {
      this.$el.find('.single-search').on('focus', function () {
        searchFormHeight = searchFormHeight || searchFormFieldWrap.height();
        if (screen.orientation.type.match(/\w+/)[0] === "landscape") {
          searchFormFieldWrap.height(searchFormHeight + searchFormHeader.height() + findSearchBtnHeight);
          _self.$el.scrollTop(searchFormHeader.height());

        }
      }).on('blur', function () {
        if (screen.orientation.type.match(/\w+/)[0] === "landscape") {
          searchFormFieldWrap.height(searchFormHeight - searchFormHeader.height() + findSearchBtnHeight);
          _self.$el.scrollTop(0);
        }
      });
    }
    // binding event to collapse/expand calendar on click of cross/open icon on addsegment overlay
    if (_self.isAddSegmentTile) {
      _self.$el.find('.js-datepick-container .m-dropdown-trigger').off().on('click', function (event) {
        if (_self.$el.hasClass('opened-picker')) {
          _self.$el.removeClass('opened-picker');
          _self.dateHandler.closeOnFormFieldsFocus = true;
          _self.dateHandler.close(_self.dateHandler);
          _self.dateHandler.closeOnFormFieldsFocus = false;
        }
        else {
          _self.$el.addClass('opened-picker');
          _self.$el.find('.js-date-from').focus();
        }
      });
    }
    if (_self.$el.hasClass('tile-hsearch-tiers')) {
      _self.tiersSearchOverlay = new Popup({
        open: false,
        items: {
          src: '.tile-hsearch-tiers',
          type: 'inline'
        },
        sourceBlock: '.tile-hsearch-tiers',
        focus: '.mfp-close',
        avoidCloseOnContentClick: true,
        enableEscapeKey: true,
        addClassOnmfpContent: true,
        beforeClose: function () {
          if (_self.$el.hasClass('expanded')) {
            _self.collapseSearchForm();
            if (_self.datePickerPlugin) {
              _self.datePickerPlugin.hideDatePicker();
            }
          }
        },
        afterClose: function () {
          _self.$el.removeClass('mfp-hide');
        },
        mfpContentClassName: 'l-container l-print-fullbleed'
      });
      _self.tiersSearchOverlay.register();
    }

    // Saving the current active tab.
    _self.activeTab = _self.$el.find('.ui-tabs-active a');
    var activeTab = this.$el.find('.search-tab-list .associate-active-tab');
    if (activeTab.length) {
      _self.$el.addClass('default-tab-change');
      activeTab.find('a').click();
    }
    var dataSet = _self.$el.data()
    if (dataSet.enableHintText) {
      animatePlaceholder.init(_self.$el);
    }
    this.brandsCountUpdate();
    if (dataSet.hotelSearchError) {
      this.pubsub.publish('TOGGLE_ERROR_MESSAGE', [true]);
      _self.$el.data('hotel-search-error', 'false');
    }
    if (_self.$el.hasClass('offers-search-form')) {
      _self.dealsBookingWidgetResizeHandler();
    }

    _self.$el.parents('.l-panel').addClass('sticky-y-scroll');
    var topNavSearch = $('#edit-search-form .l-hsearch-find .m-button-primary');
    if(window.location.pathname.indexOf("default") !== -1 && topNavSearch != undefined){
      topNavSearch.on("click", function() {
      _self.setSearchTypeDetails();
    });
   }
  },

  /**
    * This function is used to submit the formData of Modify-Review-Res-Form via Ajax request
    * Depends on response.skipRedirect Boolean:
    *    True will open Summary of Change overlay (SkipRedirect- true scenario)
    *    False will redirect to Modify Reservation Page(NextStateUri) (SkipRedirect- false scenario)
    * @param {Object} [formData] [Respective form Data to be submitted]
  */
  submitModifyReviewForm: function _submitModifyReviewForm(formData) {
    var _self = this,
      modifyReviewForm = _self.$el.find('.js-modify-review-res-form');

    _self.makeAjaxCall({
      url: modifyReviewForm.attr('action'),
      contentType: 'application/x-www-form-urlencoded',
      method: 'POST',
      data: modifyReviewForm.serialize(),
      success: function (response) {
        _self.openSummaryOfChangeOverlay(response.nextStateURI);
      }
    });
  },

  /**
    * This function is used to open Summary of Change Overlay, first register that popup and then open it.
    * @param {String} [url] [Summary of Change Overlay URL ]
  */
  openSummaryOfChangeOverlay: function _openSummaryOfChangeOverlay(url) {
    var summaryOfChangePopup = new Popup({
      open: true,
      url: url,
      sourceBlock: '.modal-content',
      alternateCloseMarkup: true
    });
    summaryOfChangePopup.register();
  },

  regionNavTriggerClick: function (e) {
    this.pubsub.publish('REGIONAL_NAV_TRIGGER', [e]);
  },

  // submit forcesell form
  submitForceSellForm: function (forceSellFormData) {
    var _self = this;
    _self.makeAjaxCall({
      url: _self.$el.find('.js-force-sell-form').attr('action'),
      contentType: 'application/json',
      method: 'POST',
      data: JSON.stringify(forceSellFormData),
      success: _.bind(_self.forceSellOverlaySubmit, _self),
      error: _.bind(_self.forceSellOverlaySubmit, _self)
    })
  },

  /**
  * Function handles ajax response on hotel input tabout on forcesell overlay
  *  @param {Object} response data is passed
  */
  forceSellTaboutSuccess: function name(response) {
    var _self = this,
      currencyElement = _self.$el.find('.js-force-sell-currency-text'),
      hotelInput = _self.$el.find('.js-hotelname');
    if (response.currency) {
      currencyElement.text(response.currency);
    }
    if (response.hotelCode) {
      hotelInput.val(response.hotelCode);
    }
  },

  /**
  * Function handles ajax response on force sell overlay form submit
  *  @param {Object} response data is passed
  */
  forceSellOverlaySubmit: function (data) {
    if (data) {
      var context = {
        status: data.status
      }
      if (data.showErrors) {
        _.extend(context, {
          showErrors: data.showErrors,
          errorMessageComponentId: data.errorMessageComponentId
        });
        this.refresh(context);
        this.pubsub.publish('ERRORMSG_REFRESH', [context]);
      }
    }
  },
  /**
  * This function is used to detect if current device being used is an Android Device
  */
  isAndroidDevice: function () {
    var ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('android') > -1; //&& ua.indexOf("mobile");
  },
  /**
  * This function is used to initiate responsive utils
  */
  initResponsiveUtils: function (engageViewportConfig) {
    this.responsiveUtils = ResponsiveUtils.create({
      orientationChangeEventHandler: _.bind(this.orientationChangeEventHandler, this),
      resizeEventHandler: _.bind(this.handleSearchFormResize, this),
      viewport: engageViewportConfig
    });
    this.responsiveUtils.init();
  },
  /**
  * This function is used to handle orientation change event.
  */
  orientationChangeEventHandler: function () {
    var _self = this;
    var searchFormVariation = this.getTakeOverVariation();

    _self.$el.find('.l-form-container').css("height", "auto");
    if (!_self.responsiveUtils.isMobile()) {
      if (_self.responsiveUtils.isPortrait()) {
        // we dont want takeovers in case of addsegment as it is inside a popup and has different view
        if (_self.isAddSegmentTile === false) {
          _self.addTakeovers();
        }
        _self.removeScroller();
        if ($('body').hasClass('is-menu-active')) {
          $('body').removeClass('is-menu-active').addClass('was-menu-active');
        }
      } else {
        if ($('body').hasClass('was-menu-active')) {
          $('body').removeClass('was-menu-active').addClass('is-menu-active');
        }
      }
    }

    if (searchFormVariation === Constants.SEARCH_FORM.VARIATIONS.HOMEPAGE && _self.responsiveUtils.isMobileOrTablet()) {
      if (this.checkIfSearchFormOpen()) {
        $('header:not(".category-header")').hide();
      }
    } else {
      $('header:not(".category-header")').show();
    }

    // Show only "Continue" for "flexible dates" option
    if(_self.$el.data('show-sub-takeover') && _self.responsiveUtils.isMobileOrTablet()) {
      if(_self.$el.find('.tab2.tabs.focus-elem.selected').length) {
        _self.$el.find('.l-date-picker-actions').addClass('is-hidden');
      } else {
        _self.$el.find('.l-date-picker-actions').removeClass('is-hidden');
      }
    }

    // _self.dateHandler.pickaDatePlugin.handleFormat();
    _self.roomGuestHandler.handleDisplayFormat();
    _self.iosSmartBannerHandler();
  },
  /**
  * This function is used to initiate takeover screens on orientation change
  */
  addTakeovers: function () {
    var searchFormVariation = this.getTakeOverVariation();

    if (this.responsiveUtils.isMobileOrTablet()) {
      switch (searchFormVariation) {
        case Constants.SEARCH_FORM.VARIATIONS.HEADER:
        case Constants.SEARCH_FORM.VARIATIONS.ADVANCED_SEARCH:
        case Constants.SEARCH_FORM.VARIATIONS.MODIFY_RES:
        case Constants.SEARCH_FORM.VARIATIONS.EDIT_SEARCH:
          $(this.$el.find('.t-more-search-options')[0]).addClass('l-clearfix');
          break;
        case Constants.SEARCH_FORM.VARIATIONS.RATE_LIST_EDIT:
        case Constants.SEARCH_FORM.VARIATIONS.AVAILABILITY_SEARCH:
          $(this.$el.find('.t-more-search-options')[0]).removeClass('l-clearfix');
          break;
        default:
          break;
      }
    }

    if (this.autoCompleteHandler) {
      this.autoCompleteHandler.removeScroller();
    }
    // this.dateHandler.addTakeover();
    this.roomGuestHandler.addTakeover();
    this.specialRatesHandler.addTakeover();
  },
  /**
  * This function is used to destroy takeover screens on orientation change
  */
  removeTakeovers: function () {
    var searchFormVariation = this.getTakeOverVariation();
    switch (searchFormVariation) {
      case Constants.SEARCH_FORM.VARIATIONS.HEADER:
      case Constants.SEARCH_FORM.VARIATIONS.ADVANCED_SEARCH:
      case Constants.SEARCH_FORM.VARIATIONS.MODIFY_RES:
      case Constants.SEARCH_FORM.VARIATIONS.EDIT_SEARCH:
        $(this.$el.find('.t-more-search-options')[0]).removeClass('l-clearfix');
        break;
      case Constants.SEARCH_FORM.VARIATIONS.RATE_LIST_EDIT:
      case Constants.SEARCH_FORM.VARIATIONS.AVAILABILITY_SEARCH:
        $(this.$el.find('.t-more-search-options')[0]).addClass('l-clearfix');
        break;
      default:
        break;
    }

    if (this.autoCompleteHandler) {
      this.autoCompleteHandler.initScroller();
    }
    // this.dateHandler.removeTakeover();
    this.roomGuestHandler.removeTakeover();

    if (this.specialRatesHandler) {
      this.specialRatesHandler.removeTakeover();
    }
  },
  /**
  * This function is used to expand HWS edit search form
  */
  expandSearchForm: function () {

    var _self = this;
    _.defer(function () {
      if (_self.$el.hasClass('is-collapsed')) {
        // Fix for scrolling in bottom hws search form
        if (_self.responsiveUtils.isMobileOrTablet()) {
          _self.toggleBodyOverflow(false);
          $('body').addClass('is-form-takeover');
          $('html,body').scrollTop(0);
          $('.mfp-close').focus();
          if (_self.$el.hasClass("tile-hsearch-tiers")) {
            $('html,body').animate({ scrollTop: 0 });
          }
        } else if (_self.$el.hasClass("tile-hsearch-tiers") && _self.tiersSearchOverlay) {
          // In desktop view show expanded search form in modal.
          _self.tiersSearchOverlay.openPopup();
        }
        _self.$el.css('overflow', 'hidden')
          .removeClass('is-collapsed')
          .addClass('is-opened expanded')
          .removeAttr('style');
        _self.$el.find('.t-more-search-options').removeClass('is-hidden').removeAttr('style');

        // remove close from m-search-header-takeover if l-takeover-head close button is enabled
        if (_self.$el.find('.l-takeover-head h2').css('display') === 'none') {
          _self.$el.find('.m-search-tabs .m-search-header-takeover button.mfp-close').addClass('hide-mfp-button');
        }
      }
    });
  },
  /**
  * This function is used to collapse hws edit search form
  */
  collapseSearchForm: function () {
    if (this.$el.hasClass('hws-search-form')) {
      if (this.$el.hasClass('expanded')) {
        this.$el.addClass('is-collapsed').removeClass('is-opened');
        this.$el.find('.t-more-search-options').slideUp('fast').addClass('is-hidden');
      } else {
        this.closeStickyTakeOverScreen();
      }
      if (this.responsiveUtils.isMobileOrTablet()) {
        if (!this.$el.hasClass('expanded')) {
          this.closeStickyTakeOverScreen();
        } else {
          this.closeMobileTakeOverScreen();
          this.$el.removeClass('is-hidden');
          $('body').addClass('is-form-takeover');
        }
      }
      this.$el.removeClass('expanded');
      if(this.$el.hasClass('tile-hsearch-tiers')) {
        _.defer(function() {
          this.$('.hws-search-form.tile-hsearch-tiers .m-button').focus();
        });
      }
    }
  },
  /**
  * This function is used to identify current takeover belongs to which variation of search form
  */
  getTakeOverVariation: function () {
    var variation;
    if (this.$el.hasClass('.room-details-search-form')) {
      variation = Constants.SEARCH_FORM.VARIATIONS.CHECK_AVAILABILITY_HWS;
    } else if (this.$el.find('.advanced-search-form').length > 0) {
      variation = Constants.SEARCH_FORM.VARIATIONS.ADVANCED_SEARCH;
    } else if (this.$el.find('.FindAndReserveSearchActionForm').length) {
      variation = Constants.SEARCH_FORM.VARIATIONS.HEADER;
    } else if (this.$el.find('.tile-adv-search-associate-form').length) {
      variation = Constants.SEARCH_FORM.VARIATIONS.ASSOCIATE_SEARCH;
    } else if (this.$el.hasClass('tile-search-modifypath')) {
      variation = Constants.SEARCH_FORM.VARIATIONS.MODIFY_RES;
    } else if (this.$el.hasClass('edit-search-form')) {
      if (this.$el.hasClass('res-search-form')) {
        variation = Constants.SEARCH_FORM.VARIATIONS.AVAILABILITY_SEARCH;
      } else if (this.$el.hasClass('deals-search-form')) {
        variation = Constants.SEARCH_FORM.VARIATIONS.DEALS_AVAILABILITY_SEARCH;
      } else if (this.$el.find('.rate-list-edit-search').length) {
        variation = Constants.SEARCH_FORM.VARIATIONS.RATE_LIST_EDIT;
      } else {
        variation = Constants.SEARCH_FORM.VARIATIONS.EDIT_SEARCH;
      }
    } else {
      variation = Constants.SEARCH_FORM.VARIATIONS.HOMEPAGE;
    }

    return variation;
  },
  /**
  * This function is used to manipulate Header Search Form whenever it is opened
  */
  toggleHeaderSearchForm: function () {

    if (this.$el.find('.FindAndReserveSearchActionForm').length) {
      var _self = this;
      if (this.takeoverBackground) {
        this.takeoverBackground.css('z-index', '5');
      }
      this.$el.addClass('tile-header-search-form');
      $('header:not(".category-header")').css('z-index', '7');
      this.$el.find('.m-search-tabs').removeClass('advanced-search-form');
      this.$el.off('click', '.m-search-header-takeover .l-close-icon-label,.m-search-header-takeover .l-close-icon,.l-takeover-head .js-form-close')
        .on('click', '.m-search-header-takeover .l-close-icon-label,.m-search-header-takeover .l-close-icon, .l-takeover-head .js-form-close', function () {
          $('header:not(".category-header")').css('z-index', '6');
          if (_self.takeoverBackground) {
            _self.takeoverBackground.hide();
          }
          _self.toggleBodyOverflow(true);
          localStorage.default.setItem('searchFormTakeoverOpened', false);
          _self.pubsub.publish('CLOSE_HEADER_SEARCH_FORM');
        });
      this.isHeaderSearchForm = true;
      this.toggleBodyOverflow(false);
      this.handleSearchFormResize();
      localStorage.default.setItem('searchFormTakeoverOpened', true);
    }
  },
  checkIfSearchFormOpen: function () {
    return this.isHomePageSearchFormOpen || this.$el.hasClass('is-opened');
  },
  /**
  * This function is used to handle resize event on Search Forms
  */
  handleSearchFormResize: function () {

    var _self = this;

    if (_self.getTakeOverVariation() === Constants.SEARCH_FORM.VARIATIONS.EDIT_SEARCH && _self.responsiveUtils.isMobileOrTablet()) {
      localStorage.default.setItem('searchFormTakeoverOpened', !_self.$el.hasClass('is-hidden'));
    }
    _.defer(function () {
      var searchFormVariation = _self.getTakeOverVariation();
      _self.$el.find('.l-form-container').css("height", "auto");
      // To prevent unexpected resize event fire on iphone/ipad
      if (_self.windowWidth != $(window).width()) {
        _self.windowWidth = $(window).width();
        _self.responsiveUtils.setDeviceInformation();

        if (_self.responsiveUtils.isMobileOrTablet()) {
          _self.addTakeovers();
          _self.removeScroller();
        } else {
          _self.removeTakeovers();
          _self.initScroller();
        }

        switch (searchFormVariation) {
          case Constants.SEARCH_FORM.VARIATIONS.CHECK_AVAILABILITY_HWS:
          case Constants.SEARCH_FORM.VARIATIONS.HEADER:
          case Constants.SEARCH_FORM.VARIATIONS.ADVANCED_SEARCH:
          case Constants.SEARCH_FORM.VARIATIONS.ASSOCIATE_SEARCH:
          case Constants.SEARCH_FORM.VARIATIONS.MODIFY_RES:
          case Constants.SEARCH_FORM.VARIATIONS.EDIT_SEARCH:
          case Constants.SEARCH_FORM.VARIATIONS.HOMEPAGE:
          case Constants.SEARCH_FORM.VARIATIONS.RATE_LIST_EDIT:
            if (_self.responsiveUtils.isMobileOrTablet()) {
              if (_self.$el.hasClass('is-opened') && (searchFormVariation != Constants.SEARCH_FORM.VARIATIONS.ADVANCED_SEARCH)) {
                _self.toggleBodyOverflow(false);
              }
              $('body').addClass('is-form-takeover');

              //If homepage search form is expanded hide header in mobile/tablet
              if (searchFormVariation === Constants.SEARCH_FORM.VARIATIONS.HOMEPAGE && _self.checkIfSearchFormOpen()) {
                $('header:not(".category-header")').hide();
              }
              if (searchFormVariation === Constants.SEARCH_FORM.VARIATIONS.RATE_LIST_EDIT) {
                $(_self.$el.find('.t-more-search-options')[0]).removeClass('l-clearfix');
              }
            } else {
              _self.toggleBodyOverflow(true);
              $('body').removeClass('is-form-takeover');
              //If homepage search form is collapsed show header in desktop
              if (searchFormVariation === Constants.SEARCH_FORM.VARIATIONS.HOMEPAGE) {
                $('header:not(".category-header")').show();
              }
              if (searchFormVariation === Constants.SEARCH_FORM.VARIATIONS.RATE_LIST_EDIT) {
                $(_self.$el.find('.t-more-search-options')[0]).addClass('l-clearfix');
              }
            }
            break;

          case Constants.SEARCH_FORM.VARIATIONS.AVAILABILITY_SEARCH:
            if (_self.responsiveUtils.isMobileOrTablet()) {
              $(_self.$el.find('.t-more-search-options')[0]).removeClass('l-clearfix');
            } else {
              $(_self.$el.find('.t-more-search-options')[0]).addClass('l-clearfix');
            }
            break;
          default:
            break;
        }
        _self.roomGuestHandler.handleDisplayFormat();
        _self.initialFormData = _self.serializeFormData(_self.$el.find('form'));
      }
    });
  },
  toggleSearchFormDisplay: function (target) {
    if (this.$el.find('#propertyedit-search-panel').length) {
      if (this.$el.attr('data-target') === target) {
        this.$el.show();
      } else {
        this.$el.hide();
      }
      this.pubsub.publish('TOGGLE_ERROR_MESSAGE', [(this.$el.attr('data-target') === target)]);
    }
  },
  openMobileSubTakeOverScreenDestination: function(e) {
     this.mobileWebHomePageDestinationField = true;     
     this.openMobileTakeOverScreen(e);
     this.mobileWebHomePageDestinationField = false;  
  },
  /**
  * This function is used to open Search Form on HomePage in Tablet/Mobile
  */
  isAutoUserIntrectionEnable: false,
  openMobileTakeOverScreen: function (e) {
				if(e.type==='keydown' && (e.keyCode===13 || e.keyCode===9)){
					return;
				}
				var _self = this;
				if (e) {
					e.preventDefault();
			}
    this.isHomePageSearchFormOpen = true;

    $('body').addClass('is-form-takeover');
    if ($('body').hasClass('is-menu-active')) {
      $('body').removeClass('is-menu-active').addClass('was-menu-active');
    }
    $('header:not(".category-header")').hide();
    if (this.$el.hasClass('is-opened') || !this.$el.hasClass('is-hidden')) {
      this.$el.find('.l-overlay-right.t-bg-opacity-90').removeClass('is-hidden');
      this.toggleBodyOverflow(false);

      // Revoking call on js-find-containers's click
      if (!$(e.currentTarget).hasClass('.js-find-container')) {
        this.expandSearchBar();
      }
    } else {
      this.$el.find('.l-overlay-right.t-bg-opacity-90').addClass('is-hidden');
      this.toggleBodyOverflow(true);
      this.closeMobileTakeOverScreen();
    }
				var $locationElem = _self.$el.find('#' + _self.idPrefix + '_search-location');
				var $datesContainer = _self.$el.find('#' + _self.idPrefix + '_hotel-fromDate');
    
    if(!$locationElem.val().trim() && !this.disableSubTakeover) {
      this.isAutoUserIntrectionEnable = true;
				}
				
				if($(e.currentTarget).hasClass('js-select-date')) {
							$datesContainer.click();
				} else{
						// Hacks to openup the ios keyboard by default when value is empty 	
						if (this.$el.find('input[name="destinationAddress.destination"]').val() === '') {							 					
								$locationElem.focus().click();
						} else {
								$locationElem.click();
						}							   
   		}
			
    // if (!$(e.currentTarget).hasClass('.js-find-container')) {
    //   setTimeout(function () {
    //     _self.$el.find('#' + _self.idPrefix + '_search-location').focus();
    //   }, 200);
    // }
  },
  /**
  * This function is used to close Search Form on HomePage in Tablet/Mobile
  */
  closeMobileTakeOverScreen: function () {
    this.isHomePageSearchFormOpen = false;
    var searchInput = this.$el.find('.single-search').val();
    $('header:not(".category-header")').show();
    $('body').removeClass('is-form-takeover');
    if ($('body').hasClass('was-menu-active')) {
      $('body').removeClass('was-menu-active').addClass('is-menu-active');
    }
    this.$el.find('.l-form-container').css("height", "auto");
    if (this.takeoverBackground) {
      this.takeoverBackground.hide();
    }
    this.$el.find('.t-more-search-options').slideUp('fast').addClass('is-hidden');
    this.$el.removeClass('is-opened');
    this.$el.find('.l-overlay-right.t-bg-opacity-90').removeClass('is-hidden');
    this.$el.find('.l-bottom-progressive').removeClass('l-bottom-prog-open');
    this.toggleBodyOverflow(true);

    if (this.$el.hasClass('edit-search-form')) {
      this.$el.addClass('is-hidden');
    }

    // restoring the value of destination input on 
    this.$el.find('.js-find-container .l-h-field').text(searchInput);
    if(this.$el.find('.js-select-destination').length) {
      this.$el.find('.js-select-destination .destination-text')[0].textContent = searchInput;  
      if(searchInput === "") {
        this.$el.find('.js-select-destination .wherenext-text').addClass("is-visible").removeClass("is-hidden");
        this.$el.find('.js-select-destination .destination-text').addClass("is-hidden").removeClass("is-visible");
      } else {
        this.$el.find('.js-select-destination .wherenext-text').addClass("is-hidden").removeClass("is-visible");
        this.$el.find('.js-select-destination .destination-text').addClass("is-hidden").removeClass("is-visible");
      }
    }
  },
  openDealsBookingWidget: function () {
    var _self = this;
    _self.$el.find('.search-form-container').show();
    $('html,body').scrollTop(0);
    $('body').css({ "position": "relative", "overflow": "hidden", "height": "100%" });
  },

  closeDealsBookingWidget: function () {
    var _self = this;
    _self.$el.find('.search-form-container').hide();
    $('html,body').scrollTop(0);
    $('body').css({ "position": "static", "overflow": "visible", "height": "100%" });
  },
  /** 
  * This function used for Deals Booking Widget resize
  */
  dealsBookingWidgetResizeHandler: function () {
    var _self = this,
      $window = $(window);
    $window.on('resize', _self, function () {
      if ($window.width() > _self.responsiveUtils.getViewportConfig().TABLET_VIEWPORT) {
        _self.$el.find('.search-form-container').show();
      }
      else {
        _self.$el.find('.search-form-container').hide();
      }
    });
  },
  /**
  * This function is used to close Edit Search Forms in Tablet/Mobile
  */
  closeStickyTakeOverScreen: function (resizeMapView) {
    var _self = this;
    if (_self.takeoverBackground) {
      _self.takeoverBackground.hide();
    }
    if (_self.$el.hasClass('expanded')) {
      _self.collapseSearchForm();
    } else {

      _self.pubsub.publish('MAP_BOTTOM_AREA_ADJUST', [_self.$el.outerHeight()]);
      _self.$el.slideUp("medium", function () {
        _self.$el.addClass('is-hidden').removeClass('is-opened');
        if (_.isFunction(resizeMapView)) {
          resizeMapView();
        }
        _self.pubsub.publish('TOGGLE_STICKY_FORM_EDIT_BUTTON', [true]);
        window.MI_PubSub.publish('searchSummary/stickyPanelResize', ["show"]);
        _self.pubsub.publish('MAP_BOTTOM_AREA_ADJUST');
      });
    }
    _self.toggleBodyOverflow(true);
    localStorage.default.setItem('searchFormTakeoverOpened', false);
    _self.pubsub.publish('STICKY_COMPARE_HEIGHT');
  },
  /**
  * This function is used to expand Search Form on Homepage in Desktop
  */
  expandSearchBar: function (e) {
    var _self = this,
      scrollTop = 0,
      currentScroll = $(window).scrollTop();
    if ($('html').hasClass('ipad') && e && $(e.target).parents().hasClass("tile-hsearch-hws")) {
      return;
    }
    this.$el.addClass('transition-complete');
    this.$el.addClass('is-opened');   
    this.$el.find('.t-more-search-options').removeClass('is-hidden').slideDown(400);
    if (e && $(e.target).parents().hasClass("hws-search-form")) {
      if (_self.responsiveUtils.isMobileOrTablet()) {
        _self.expandSearchForm();
        return;
      }
    }

    if (!_self.responsiveUtils.isMobileOrTablet()) {
      if (currentScroll == (_self.$el.offset().top - 200)) {
        return;
      }
      $('html,body').animate({ scrollTop: _self.$el.offset().top - 200 });
    }
    else {
      if (this.$el.hasClass('tile-hsearch-homepage') || this.$el.hasClass('edit-search-form')) {
        //this  25 px is the space between the windows top and the destination label
        if (this.$el.find('.single-search').length) {
          scrollTop = this.$el.find('.single-search').offset().top - 25;
          if (scrollTop > 0 && currentScroll != scrollTop) {
            if (!((this.$el.find('.m-vbm-home-container').length > 0) && $('html').hasClass('mobile'))) {
              this.scrollToView(this.$el, scrollTop);
            }
          }
        }
      }
      if (this.$el.hasClass('search-saved-hotels-tabs')) {
        if (this.$el.closest(".l-overlay-parent").hasClass('overlay-opened')) {
          //this  145px is the space between the windows top and the destination label for find and reserve search form 
          if (this.$el.find('.single-search').length) {
            scrollTop = this.$el.find('.single-search').offset().top - 145;
            if (scrollTop > 0 && currentScroll != scrollTop) {
              this.scrollToView(this.$el.closest('.l-container'), scrollTop);
            }
          }
        } else {
          //this  25 px is the space between the windows top and the destination label
										if(this.$el.find('#advanced-search-form').length){
											this.scrollToView($('html,body'), 0);
										}
										else {
											if (this.$el.find('.single-search').length) {
            scrollTop = this.$el.find('.single-search').offset().top - 25;
            if (scrollTop > 0 && currentScroll != scrollTop) {
              this.scrollToView($('html,body'), scrollTop);
            }
										}
									}
        }
      }
    } 
    if(e) {
      if(this.mobileWebHomePageDestinationField) {
        if (this.$el.find('input[name="destinationAddress.destination"]').val() === '') {
          this.expandSubTakeover(e);
        }                
      } else {
        this.expandSubTakeover(e);
      }
    }  
  },
  /**
  * This function is used to animate scrolltop
  */
  scrollToView: function (element, offset) {
    element.animate({
      scrollTop: offset
    })
  },
  /**
  * This function is used to add is-opened identifier on Search Forms in takeover screens
  */
  openSearchForm: function (stickysearchForm) {
    if (!stickysearchForm.hasClass('is-opened')) {
      stickysearchForm.addClass('is-opened');
    }
  },
  /**
  * This function is used to open Edit Search Forms in Tablet/Mobile
  */
  toggleStickySearchForm: function (clickedElem, stickysearchForm, toggleEditArrow, resizeMapView) {
    var _self = this;

    if (stickysearchForm.data('component-id') == _self.$el.data('component-id')) {

      if (_self.responsiveUtils.isMobileOrTablet()) {
        $('body').addClass('is-form-takeover');
      }
      else {
        $('body').removeClass('is-form-takeover');
      }
      if (!clickedElem) {
        if (stickysearchForm.hasClass('is-opened')) {
          _self.closeStickyTakeOverScreen(resizeMapView);
          return;
        }
        _self.openSearchForm(stickysearchForm);
        // remove close from m-search-header-takeover if l-takeover-head close button is enabled
        if (_self.$el.hasClass('hws-search-form') && _self.$el.find('.l-takeover-head h2').css('display') === 'none') {
          _self.$el.find('.m-search-tabs .m-search-header-takeover button.mfp-close').addClass('hide-mfp-button');
        }
        if (!this.$el.find('input[name="associateViewFlag"]').length && !this.$el.hasClass('js-modify-rlm-search-form')) {
          window.MI_PubSub.publish('searchSummary/stickyPanelResize', ["hide"]);
        }
        stickysearchForm.slideDown("medium", function () {
          _self.pubsub.publish('MAP_BOTTOM_AREA_ADJUST');
          _self.openSearchForm(stickysearchForm);
          if (stickysearchForm.hasClass('is-hidden')) {
            stickysearchForm.removeClass('is-opened');
          }
        }).toggleClass('is-hidden');
        this.toggleBodyOverflow(stickysearchForm.hasClass('is-hidden'));
        toggleEditArrow(true);
      } else {
        $(document).click();
        if (stickysearchForm.find('[data-target=\'' + clickedElem + '\']').is('input') && stickysearchForm.hasClass('is-hidden')) {
          this.openStickySearchForm(stickysearchForm, toggleEditArrow);
          stickysearchForm.find('[data-target=\'' + clickedElem + '\']').focus();
          if (this.$parent && (clickedElem == this.$parent.$el.find('.ccheckin').data('focus'))) {
            stickysearchForm.find('[data-target=\'' + clickedElem + '\']').click();
          }
        } else {
          stickysearchForm.find('[data-target=\'' + clickedElem + '\']').trigger('click', [_.bind(this.openStickySearchForm, this, stickysearchForm, toggleEditArrow)]).focus();
        }
        if (clickedElem === 'specialRates') {
          this.openStickySearchForm(stickysearchForm, toggleEditArrow);
          stickysearchForm.find('[data-target=\'' + clickedElem + '\']').focus();
        }
      }
      if (!stickysearchForm.hasClass('is-hidden') && _self.getTakeOverVariation() === Constants.SEARCH_FORM.VARIATIONS.EDIT_SEARCH && _self.responsiveUtils.isMobileOrTablet()) {
        $('html,body').scrollTop(0);
        localStorage.default.setItem('searchFormTakeoverOpened', true);
      }
      _self.pubsub.publish('STICKY_COMPARE_HEIGHT');
    }
  },
  openStickySearchForm: function (stickysearchForm, toggleEditArrow) {

    if (stickysearchForm.hasClass('is-hidden')) {
      if (!this.$el.find('input[name="associateViewFlag"]').length && !this.$el.hasClass('js-modify-rlm-search-form')) {
        window.MI_PubSub.publish('searchSummary/stickyPanelResize', ["hide"]);
      }
      stickysearchForm.addClass('is-opened').slideDown(400, function () {

      }).removeClass('is-hidden');
      // remove close from m-search-header-takeover if l-takeover-head close button is enabled
      if (this.$el.hasClass('hws-search-form') && this.$el.find('.l-takeover-head h2').css('display') === 'none') {
        this.$el.find('.m-search-tabs .m-search-header-takeover button.mfp-close').addClass('hide-mfp-button');
      }
      toggleEditArrow();
      this.toggleBodyOverflow(false);
    }
  },
  /**
  * This function toggles overflow property on body tag
  * @param {Bool} pass true/false to toggle body overflow
  */
  toggleBodyOverflow: function (show) {

    var setVisible = true;
    var searchFormVariation = this.getTakeOverVariation();
    if (this.responsiveUtils.isMobileOrTablet()) {
      if (!show) {
        setVisible = false;
      }
    }

    if (setVisible || (searchFormVariation === Constants.SEARCH_FORM.VARIATIONS.CHECK_AVAILABILITY_HWS) || (searchFormVariation === Constants.SEARCH_FORM.VARIATIONS.ADVANCED_SEARCH) || (searchFormVariation === Constants.SEARCH_FORM.VARIATIONS.MODIFY_RES)) {
      if (this.takeoverBackground) {
        this.takeoverBackground.hide();
      }

      //!this.dateHandler.takeoverScreen.isActive && 
      if (!this.roomGuestHandler.takeoverScreen.isActive && !this.isActiveSpecialRatesHandler() && !$(document.body).children('.mfp-wrap').length) {
        $('html,body').css({
          'overflow': 'visible',
          'position': 'static'
        });
      }
    } else {
      if (this.takeoverBackground) {
        this.takeoverBackground.show();
      }
      $('html,body').css({
        'overflow': 'hidden',
        'position': 'relative',
        'height': '100%'
      });
    }
  },
  /**
  * This function is used to toggle saved hotel dropdown in search form
  */
  toggleSavedHotelDropdown: function (e) {
    $(e.currentTarget).find('.m-dropdown-trigger').toggleClass('dropdown-is-active');
  },
  /**
  * This function is used to close saved hotel dropdown in search form
  */
  closeSavedHotelDropdown: function (e) {
    $(e.currentTarget).find('.m-dropdown-trigger').removeClass('dropdown-is-active');
  },
  /**
  * This function is used to handle tab indexes on Search Forms
  */
  handleTabIndex: function () {
    var _self = this, i, searchBttn = 'div.l-hsearch-find button.m-button-primary';
    var ele = ['#' + _self.idPrefix + '_search-location', '.js-flex-display', '.js-date-from', '.js-date-to', '.picker__nav--prev:not(.picker__nav--disabled)', '.picker__nav--next:not(.picker__nav--disabled)', '.js-toggle-flexdate', '.ccheckin', '.ccheckout', '.flex-reset-button', '.flex-done-button', '.tabs', '.js-date-clear', '.js-flex-reset', '.js-flex-month', '.js-stepper-input', '.js-flex-stepper', '.l-rooms-guests-trigger-box', '.l-s2-stepper-input', '.l-plus', '.l-minus', '.rooms-guests-done a', '.rooms-guests-clear a', '.js-special-rates-header', '.js-brands-header', '.select-all', '.clear-all-brand-specific', '.brand-container', '.clear-selected-brands', '.close-brand-container', '.js-userewards-checkbox', '.l-special-rates .js-h-toggle', '.js-select-rate', '.remove-btn', '.js-apply-btn', 'div.l-hsearch-find button.m-button-primary', '.l-dates-flexible-checkbox', '.datepicker-reset-button', '.flexi-date-done-button' ];

    this.$el.find(ele.join(', ')).on('focus', function (e) {
      if (!$(e.target).parents().hasClass("l-date-picker")) {
        _self.$el.find(".tabbing").removeClass("is-visible");
      }
      _self.$el.find(ele.join(', ')).attr('tabIndex', 0);
      if (!_self.$el.hasClass("tile-search-modifypath") && _self.$el.find(searchBttn)) {
        if (_self.$el.hasClass('hws-search-form')) {
          searchBttn = 'div.m-field-control-find button.m-button-primary';
        }
      }
    });

    this.$el.find(ele.join(', ')).not('.js-flex-display, .js-date-to, .picker__nav--next, .picker__nav--prev').on('focusout', function (e) {
      e.stopPropagation();
      for (i in ele) {
        if ($(e.currentTarget).hasClass(ele[i])) {
          _self.$el.find(ele[i]).removeAttr('tabIndex');
        }
      }
    });


    var lastEle = ele[ele.length - 1];
    this.$el.find(lastEle).on('keydown', function (e) {
      if (e.keyCode === 9) {
        if ($(e.currentTarget).closest(".l-special-rates").length && $(e.currentTarget).hasClass("js-h-toggle")) {
          _self.$el.find('.js-userewards-checkbox').removeAttr('tabindex');
          return;
        }
        _self.$el.find(ele.join(', ')).not('.js-flex-display, .js-date-to, .picker__nav--next, .picker__nav--prev, .js-userewards-checkbox').attr('tabIndex', -1);
      }
    });
    this.$el.on('keydown', '.picker__nav--prev, .picker__nav--next, .js-h-toggle-checkbox', function (e) {
      if (e.keyCode === 13) {
        if ($(e.target).hasClass('js-h-toggle-checkbox')) {
          $(this).is(':checked') == true ? $(this).prop('checked', '') : $(this).prop('checked', 'checked');
          $(this).focus();
          e.preventDefault();
        }
        else {
          $(this).trigger('click');
          var tabIndex = $(this).attr('tabIndex');
          if ($(this).hasClass('picker__nav--next')) {
            _.delay(function () {
              var navEl = _self.$el.find('.picker__nav--next');
              navEl.attr('tabIndex', tabIndex);
              navEl.focus();
            }, 0);
          } else {
            _.delay(function () {
              var navEl = _self.$el.find('.picker__nav--prev');
              navEl.attr('tabIndex', tabIndex);
              navEl.focus();
            }, 0);
          }
        }
      }
    });
    this.$el.on('keydown', '#' + _self.idPrefix + '_search-location', function (e) {
      if (e.keyCode === 9) {
        if (_self.$el.find('.js-flex-check').attr('checked') === 'checked') {
          _self.$el.find('.js-toggle-picker').trigger('click');
        }
      }
    });
  },
  /**
  * This function is used to initialize search tab handler library for Search Forms
  */
  initSearchTabHandler: function () {
    if (this.$el.find('.search-tab-list li').length && !this.$el.hasClass('hws-search-form')) {
      var searchTabHandler = new SearchTabHandler({
        $el: this.$el,
        currentTemplate: this.currentTemplate,
        parent: this
      });
      searchTabHandler.init();
    }
  },
  /**
  * This function is used to initialize autocomplete handler library for Search Forms
  */
  initAutoCompleteHandler: function () {
    if (this.isAutoCompleteHandlerInitiated) {
      return true;
    }
    this.isAutoCompleteHandlerInitiated = true;
    /*Hotel search auto suggest handler*/
    if (this.$el.find('.js-auto-complete.js-location').length) {
      this.autoCompleteHandler = AutoCompleteHandler.create({
        $el: this.$el.find('.js-auto-complete.js-location'),
        $parent: this,
        onListItemSelect: _.bind(this.handleFormAction, this),
        onClear: _.bind(this.setDefaultFormAction, this),
        onListClose: function () {
          if (this.$input.val()) {
            this.listItemSelect();
          }
        }

      });
      this.autoCompleteHandler.init();
    }
    /*Cluster Code auto suggest handler*/
    if (this.$el.find('.js-cluster-autocomplete').length) {
      this.autoCompleteClusterCodeHandler = AutoCompleteHandlerV2.create({
        $el: this.$el.find('.js-cluster-autocomplete'),
        $parent: this,
        onListItemSelect: _.bind(this.setClusterValue, this),
        clusterSearchHandler: true
      });
      this.autoCompleteClusterCodeHandler.init();
    }
  },
  /**
  * This function is used to initialize autocomplete handler library for Search Forms
  */
  initAutoCompleteHandlerV2: function () {
    /*Hotel search auto suggest handler*/
    if (this.$el.find('.js-auto-complete.js-location').length) {
      this.autoCompleteHandlerV2 = AutoCompleteHandlerV2.create({
        $el: this.$el.find('.js-auto-complete.js-location'),
        $parent: this,
        onListItemSelect: _.bind(this.handleFormAction, this),
        onClear: _.bind(this.setDefaultFormAction, this),
        sessionId: this.getSessionToken(),
        enableUserLocation: this.$el.data("enable-user-location"),
        onListClose: function () {
          if (this.$input.val()) {
            this.listItemSelect();
          }
        }
      });
      this.autoCompleteHandlerV2.init();
    }
    /*Cluster Code auto suggest handler*/
    if (this.$el.find('.js-cluster-autocomplete').length) {
      this.autoCompleteClusterCodeHandler = AutoCompleteHandlerV2.create({
        $el: this.$el.find('.js-cluster-autocomplete'),
        $parent: this,
        onListItemSelect: _.bind(this.setClusterValue, this),
        clusterSearchHandler: true
      });
      this.autoCompleteClusterCodeHandler.init();
    }
  },
  /**
  * This function is used to handle selection/deselection of Use Rewards checkbox as well as setting of Special Rate to None for all Search Forms
  */
  onFlexibleDateClick: function () {
    if (this.$parent.specialRatesHandler && this.$parent.specialRatesHandler.$el.find('.is-checked #group').length) {
      this.$parent.specialRatesHandler.closeToggle(this.$parent.specialRatesHandler.$el.find('#group'), 'none', true);
      this.$parent.specialRatesHandler.$el.find('#none').prop('checked', true);
      this.$parent.specialRatesHandler.$el.parents().find('.l-h-toggle-cont .is-checked').removeClass('is-checked');
      this.$parent.specialRatesHandler.$el.find('#none').parents('.l-h-toggle-opt').addClass('is-checked');
      this.$parent.specialRatesHandler.$el.parents().find('.special-rate-code').addClass('is-hidden').hide('fast');
      var $toggleCheckboxParent = this.$parent.$el.find('.js-h-toggle-checkbox').parents();
      $toggleCheckboxParent.find('.l-userewards-checkmark').removeClass('t-icon-checkmark');
      this.$parent.$el.find('.js-h-toggle-checkbox').prop('checked', false).attr('checked', false);
      this.$parent.dateHandler.flexdateState = 'flex';
    }
  },
  /**
  * This function is used to initialize date handler library for Search Forms
  */
  initDateHandler: function () {
    var _self = this;
    this.$el.find('.js-datepick-container').each(function () {
      _self.dateHandler = new DateHandler({
        $el: $(this),
        $parent: _self,
        onFlexibleDateClick: _self.onFlexibleDateClick,
        addCustomHeaderStyle: _self.$el.hasClass('hws-search-form') || _self.$el.hasClass('room-details-search-form'),
        expandSearchForm: _.bind(_self.expandSearchForm, _self),
        closeOnDocumentClick: !(_self.isAddSegmentTile),
        closeOnFormFieldsFocus: !(_self.isAddSegmentTile),
        suppressTakeOver: true
      });
      _self.dateHandler.init();
      if (_self.isAddSegmentTile) {
        _self.$el.find('.js-date-from').focus();
      }
    });
  },
  /**
  * This function is used to initialize rooms guests handler library for Search Forms
  */
  initRoomGuestHandler: function () {
    var _self = this;
    this.$el.find('.js-rooms-guests').each(function () {
      _self.roomGuestHandler = new RoomGuestHandler({
        $el: $(this),
        $parent: _self,
        addCustomHeaderStyle: _self.$el.hasClass('hws-search-form') || _self.$el.hasClass('room-details-search-form'),
        expandSearchForm: _.bind(_self.expandSearchForm, _self),
        roomsModified: _.bind(_self.roomsModified, _self),
        suppressTakeOver: true
      });
      _self.roomGuestHandler.init();
    });
  },
  /**
  * This function is used as callback on change of no of rooms
  */
  roomsModified: function _roomsModified(value) {
    this.setGroupsFlow = false;
    if (parseInt(value, 10) === 26) {
      this.setGroupsFlow = true;
    }
    this.handleFormAction(false);
  },
  /**
  * This function is used as callback on click on any item in special rates section
  */
  onItemClicked: function (element) {
    var dateHandler = this.$parent.dateHandler;
    var dateFromData = this.$parent.$el.find('.ccheckin').data() || {};
    var toDate = this.$parent.$el.find('.js-submit-to').val();
    var inventoryData = this.$parent.$el.find('.inventory-date').data('inventory');
    var $inventoryDate = this.$parent.$el.find('.inventory-date-container .date-of-inventory');
    var isGroupDateSelected = dateFromData.showKGroupDate;
    if (element.find('input#group').is(':checked') && dateHandler && dateHandler.flexdateState === 'flex') {
      dateHandler.flex.$check.attr('checked', false);
      dateHandler.$el.addClass('is-flex-hidden').removeClass('is-picker-hidden').find('.js-toggle-picker');
      dateHandler.flex.$root.hide();
      dateHandler.$dateFromToPicker.$frame.show();
      dateHandler.footer.$root.find(dateHandler.options.flexdateTrigger).html(dateHandler.$el.find('.js-date-pick-container').data('options').flexdates);
    }
    if (this.$el.hasClass('js-sell-options-ers-associate')) {
      element.after(element.closest('.sell-option-wrapper').find('.code-container'));
    }
    if (inventoryData) {
      if (element.find('input#hotel_group-sell').is(':checked')) {
        this.$parent.updateInventoryLabel("true");
        dateFromData.showKGroupDate = true;
        $inventoryDate.html(inventoryData.kGroupValue);

        this.$parent.datePickerPlugin.changeEndDate(inventoryData.parsedKGroupValue);
      }
      else if (isGroupDateSelected) {
        this.$parent.updateInventoryLabel("false");
        dateFromData.showKGroupDate = false;
        this.$parent.datePickerPlugin.changeEndDate(inventoryData.parsedDate);
        if (new Date(toDate) > new Date(inventoryData.value)) {
          this.$parent.datePickerPlugin.resetDate();
        }
      }
    }
  },
  /**
  * This function is used to reset the K-Group date to K-date
  */
  resetKGroupDate: function () {
    this.updateInventoryLabel("false");
    this.datePickerPlugin.changeEndDate(this.$el.find('.inventory-date').data('inventory').value);
    if (new Date(this.$el.find('.js-submit-to').val()) > new Date(this.$el.find('.inventory-date').data('inventory').value)) {
      this.datePickerPlugin.resetDate();
    }
  },
  /**
  * This function is used to initialize special rates handler library for Search Forms
  */
  initSpecialRateHandler: function () {
    var _self = this;
    this.$el.find('.l-special-rates').each(function () {
      _self.specialRatesHandler = new SpecialRatesHandler({
        $el: $(this),
        $parent: _self,
        onItemClicked: _self.onItemClicked,
        addCustomHeaderStyle: _self.$el.hasClass('hws-search-form') || _self.$el.hasClass('room-details-search-form'),
        suppressTakeOver: true

      });
      _self.specialRatesHandler.init();
    });
  },
  /**
  * This function is use to initialize sell options handler
  */
  initSellOptionsInputHandler: function () {
    var _self = this;
    this.$el.find('.l-sell-options').each(function () {
      _self.sellOptionsInputHandler = new SellOptionsInputHandler({
        $el: $(this),
        $parent: _self,
        onItemClicked: _self.onItemClicked
      });
      _self.sellOptionsInputHandler.init();
    });
    if (_self.currentTemplate == "searchFormHorizontal-bookingCartChangeSellOptions") {
      this.$el.parents('.mfp-content').on('click', _.bind(function (evt) {
        _self.sellOptionsInputHandler.closeSpecialRatesSection(evt);
      }, this));
    }
  },
  /**
  * This function is use to initialize scroller plugin on target elements
  */
  initScroller: function () {
    var _self = this;
    if (!this.scrollbar && this.responsiveUtils.isDesktop()) {
      this.scrollbar = ScrollBarHandler.create({
        $el: this.$el,
        $scrollBarTarget: this.$el.find(_self.options.scrollBarTarget).not('.custom-wrapper')
      });
      this.scrollbar.init();
    }
  },
  /**
  * This function is use to remove scroller instance
  */
  removeScroller: function () {
    if (this.scrollbar) {
      this.scrollbar.destroy();
      delete (this.scrollbar);
    }
  },
  /**
  * This function is use to toggle form action based on what sort of autosuggest input selected
  */
  handleFormAction: function (autocompleteContext) {
    var form = this.$el.find('form');
    var suggestionEndpointMapper = form.data('suggestion-endpoint-mapping');
    var suggestionType = autocompleteContext ? autocompleteContext.autoSuggestOmni : this.$el.find('.js-autosuggest-item-type').val();
    if (autocompleteContext) {
      var selectedProperty = this.$el.find('input[name="suggestionsPropertyCode"]');
      autocompleteContext.propertyCode ? selectedProperty.val(autocompleteContext.propertyCode) : selectedProperty.val('');
    }
    this.setDefaultFormAction();

    var formAction;

    var groupsFlowFlag = form.data('is-group-search-applicable');
    if (this.setGroupsFlow && groupsFlowFlag) {
      formAction = form.data('group-search-endpoint');
      form.attr('action', formAction);
    } else if (suggestionEndpointMapper && suggestionEndpointMapper[suggestionType] && (this.$el.find('input[name="isAssociateFlow"]').val() === "true")) {
      formAction = suggestionEndpointMapper[suggestionType];
      this.$el.find('input[name="isSearch"]').val(false);
      form.attr('action', formAction);
    }
  },
  /**
  * This function is use to set default form action for Search Forms
  */
  setDefaultFormAction: function () {
    var form = this.$el.find('form');
    var isSearchForm = !(form.is("#sendLocationForm"));
    var defaultEndpoint = form.data('default-endpoint');
    if (defaultEndpoint && isSearchForm) {
      form.attr('action', defaultEndpoint);
    }
  },
  setClusterValue: function (autocompleteContext) {
    var clusterCodeVal = autocompleteContext.clusterCode;
    this.$el.find('#hiddenClusterCode').val(clusterCodeVal);
  },
  clearClusterValue: function () {
    this.$el.find('[name="clusterRateCluster"]').val('');
    this.$el.find('#hiddenClusterCode').val('');
  },
  /**
   * Get segment records for the given dates and sell options
   * @param {object} e - event object
   */
  viewRatesAddChangeSegment: function (e) {
    var form = this.$el.find('form'),
      _self = this,
      formData = form.serialize(),
      selloptions = CommonUtils.getParameterByName('sellOptionsListHotelNameSearch', formData);
    // collapse datePicker as soon as view rates button is clicked
    if (_self.dateHandler) {
      _self.$el.removeClass('opened-picker');
      _self.dateHandler.closeOnFormFieldsFocus = true;
      _self.dateHandler.close(_self.dateHandler);
      _self.dateHandler.closeOnFormFieldsFocus = false;
    }
    formData = 'sellOption=' + selloptions + '&' + formData;
    e.preventDefault();
    _self.makeAjaxCall({
      url: form.attr('action'),
      contentType: 'application/x-www-form-urlencoded',
      method: 'GET',
      data: formData,
      success: _.bind(_self.viewRatesSubmitCallback, _self),
      error: _.bind(_self.viewRatesSubmitCallback, _self)
    })
  },
  /**
   * Get date in mm/dd/yy format
   * @param {string} longDate - date string in any other valid format
   * @returns {string} shortDate - date in mm/dd/yy format
   */
  getDateInShortFormat: function (longDate) {
    var shortDate;
    shortDate = new Date(Date.parse(longDate));
    return (shortDate.getMonth() + 1) + '/' + shortDate.getDate() + '/' + shortDate.getFullYear();
  },
  viewRatesSubmitCallback: function (data) {
    var context = {},
      _self = this,
      segmentEndDate,
      segmentStartdate,
      form = _self.$el.find('form'),
      formData = "?" + form.serialize();
    if (_self.datePickerPlugin) {
      segmentStartdate = CommonUtils.getParameterByName('fromDate', formData);
      segmentEndDate = CommonUtils.getParameterByName('toDate', formData);
    }
    else {
      segmentStartdate = _self.getDateInShortFormat($('.tile-change-sell-options-header').find('.js-segmentStart-date').html());
      segmentEndDate = _self.getDateInShortFormat($('.tile-change-sell-options-header').find('.js-segmentEnd-date').html());
    }
    if (!data) {
      return;
    }
    context.status = data.status;
    context.errorMessageComponentId = data.errorMessageComponentId || 'segmentsSearchErrorMessages';
    if (data.showErrors) {
      _.extend(context, {
        showErrors: data.showErrors
      });
    } else {
      _.extend(context, {
        segmentStartDate: segmentStartdate,
        segmentEndDate: segmentEndDate,
        sellOption: CommonUtils.getParameterByName('sellOptionsListHotelNameSearch', formData),
        sellOptionLabel: CommonUtils.getParameterByName('sellOptionLabel', formData),
        rateProgram: CommonUtils.getParameterByName('rateProgram', formData),
        rateCluster: CommonUtils.getParameterByName('rateCluster', formData),
        hiddenClusterCode: CommonUtils.getParameterByName('hiddenClusterCode', formData),
        firstLetterOfDescription: CommonUtils.getParameterByName('firstLetterOfDescription', formData),
        roomPool: CommonUtils.getParameterByName('roomPool', formData),
        searchCountryRegion: CommonUtils.getParameterByName('search-countryRegion', formData),
        groupCode: CommonUtils.getParameterByName('groupCode', formData),
        showErrors: false
      });
    }
    this.$el.find('.js-submit-sell-options').addClass('is-global-disable');
    // refreshing the modifysegmentRlm Tile
    this.pubsub.publish('MODIFY_SEGMENT_RLM', [context]);
  },
  /**  
  * This function is use to toggle Use Rewards checkbox for Search Forms
   */
  toggleCheckbox: function (event) {
    var _self = this;
    if ($(event.target).hasClass('js-userewards-checkbox')) {
      _self.propertyCodeChangeHandler();
    }
    if ($(event.target).hasClass('js-userewards-checkbox') && _self.$el.find('input#hotel_group-sell').is(':checked')) {
      _self.resetKGroupDate();
    }
    var isMultirateSearch = _self.$el.find('.rates-dropdown').length;
    if (isMultirateSearch) {
      _self.$el.find('.rates-dropdown .js-dropdown-point-checkbox').closest('.js-select-rate').click();
      return;
    }
    this.$el.find('.js-h-toggle-checkbox').each(function () {
      if ($(this).prop('checked')) {
        var $toggleHeader = $(this).closest(_self.$el).find(".l-h-toggle");
        var $toggleHeaderText = $toggleHeader.find('.l-h-toggle-text');
        var $toggleHeaderParent = $toggleHeader.parent();
        var $toggleHeaderParentLi = $toggleHeaderParent.find('.l-h-toggle-cont ul li');
        $toggleHeaderText.html($toggleHeaderText.data('defaultheader'));
        $(this).parents().find('.l-h-toggle-cont .special-rate-code').addClass('is-hidden').hide().find('input').val('');
        $toggleHeaderParent.find('.l-h-toggle-cont .is-checked').removeClass('is-checked');
        $toggleHeaderParentLi.first().addClass('is-checked');
        $toggleHeaderParentLi.find('input[type="radio"]').prop('checked', false);
        $toggleHeaderParentLi.find('input[type="radio"]').first().prop('checked', true);
        if (_self.$el.find('.sell-options-section')) {
          $toggleHeaderParentLi.find('input[name="sellOptionsListHotelNameSearch"]#sell-strategy').prop('checked', true);
          $(this).closest('.l-hsearch-labels').find('.l-h-toggle-cont .special-rate-code').addClass('is-hidden').hide();
          $(this).closest('#find-a-hotel-form').find('input[name="sellOption"], input[name="sellOptionLabel"]').attr('value', 'sell-strategy');
        }
      }
    });
  },
  /**
  * This function is use to show Special Rates error message for Search Forms
  */
  showSpecialRateErrorMessage: function () {
    var _self = this;
    var selectedRate = false;
    var selectedCorp = false;
    var selectedMultiRate = false;
    var corporateCodeVal = _self.$el.find('input[name="corporateCode"]').val();

    _.each(_self.$el.find('input[name="clusterCode"]'), function (item) {
      if (item.checked && item.value !== '' && item.value !== 'none' && item.value !== 'INT') {
        selectedRate = true;
        if (item.value === 'corp') {
          selectedCorp = true;
        }
      }
    });
    if (selectedRate == true) {
      if ((corporateCodeVal.toUpperCase() !== 'REW') && (corporateCodeVal.toUpperCase() !== 'P17') && (corporateCodeVal.toUpperCase() !== 'MRW') && (corporateCodeVal.toUpperCase() !== 'MXR') && (corporateCodeVal.toUpperCase() !== 'MW1')) {
        if (selectedCorp == true && (corporateCodeVal == null || corporateCodeVal == '')) {
          _self.$el.find('[name=\'clusterCode\'][value=\'none\']').prop('checked', 'checked');
          _self.$el.find('[name=\'clusterCode\']').parent().find('input[type=\'text\']').hide();
          return true;
        }
        if (_self.checkDateValue(_self.$el.find('input[name="fromDate"]')) && _self.checkDateValue(_self.$el.find('input[name="toDate"]'))) {
          var choice = confirm(_self.$el.attr('data-message-specialRateMessage'));
          return choice;
        }
      }
    }
    _.each(_self.$el.find('input[type="checkbox"]:checked'), function (item) {
      var rateVal = $(item).val();
      if ($(item).hasClass('js-corp-code') || $(item).hasClass('js-rate-checkbox')) {
        if ((rateVal.toUpperCase() !== 'REW') && (rateVal.toUpperCase() !== 'MRW') && (rateVal.toUpperCase() !== 'P17') && (rateVal.toUpperCase() !== 'MXR') && (rateVal.toUpperCase() !== 'MW1')) {
          selectedMultiRate = true;
        }
      }
    });
    if (selectedMultiRate) {
      if (_self.checkDateValue(_self.$el.find('input[name="fromDate"]')) && _self.checkDateValue(_self.$el.find('input[name="toDate"]'))) {
        var choice = confirm(_self.$el.attr('data-message-specialRateMessage'));
        return choice;
      }
    }
    return true;
  },
  /**
  * This function is used to validate date is null or empty
  * @return {boolean} [returns true if date is null or empty]
  */
  checkDateValue: function (date) {
    return (date.val() == null || date.val() == '');
  },
  /**
  * This function is use to toggle form query params based on what sort of autosuggest input selected
  */
  setFormQueryParams: function (searchForm) {
    var fromDate = this.$el.find('.ccheckin').val();
    var toDate = this.$el.find('.ccheckout').val();
    var $flexibleDateSearch = this.$el.find(".js-flex-check");
    var isRateCalendar = this.$el.find('[name="isRateCalendar"]');
    var flexibleDateSearchRateDisplay = this.$el.find('[name="flexibleDateSearchRateDisplay"]');

    this.$el.find('[name="isSearch"]').val('true');
    isRateCalendar.val('false');
    flexibleDateSearchRateDisplay.val('false');
    if ($flexibleDateSearch.val() == "true") {
      isRateCalendar.val('true');
    }

    if (!fromDate && !toDate) {
      isRateCalendar.val('true');
      flexibleDateSearchRateDisplay.val('true');
    }
    this.handleFormAction();
  },
  /**
  * This function is use to handle client side validation before submitting Search Form
  */
  submitValidations: function (e) {
    if (this.responsiveUtils.isMobileOrTablet() && this.$el.hasClass('hws-search-form') && this.$el.hasClass('is-collapsed')) {
      this.expandSearchForm();
      return false;
    }
    var sellOptionInput = this.$el.find('.l-h-toggle-opt.is-checked .code-container').find('div:visible input');
    if (this.$el.find('#special-rates-search:visible').length && sellOptionInput.val() !== "") {
      sellOptionInput.attr('name', sellOptionInput.data('name'))
    }
    this.$el.find('.js-validate-search-form').attr('novalidate', 'novalidate');
    var searchForm = $(e.currentTarget);
    this.setFormQueryParams(searchForm);
    var roomCount = parseInt(searchForm.find("#" + this.idPrefix + "_roomCountBox").val());
    var checkInDateErrorMessage = this.$el.find('input[name="missingcheckInDateMsg"]').val();
    var checkOutDateErrorMessage = this.$el.find('input[name="missingcheckOutDateMsg"]').val();
    var isDatesValidation = this.$el.find('.l-date-picker').attr('data-trigger');
    var fromDate = this.$el.find('.ccheckin').val();
    var toDate = this.$el.find('.ccheckout').val();
    var $specialRateToggleCount = searchForm.find('.l-special-rates .l-h-toggle-cont ul li');
    var messageObject = {
      'noSelectedHotel': this.$el.attr('data-message-noSelectedHotel'),
      'groupMessage': this.$el.attr('data-message-groupMessage'),
      'useRewardsPtsMessage': this.$el.attr('data-message-useRewardsPtsMessage'),
      'roomLimitMessage': this.$el.attr('data-message-roomLimitMessage'),
      'invalidCorporateCodeLengthMsg': this.$el.attr('data-message-invalidcorporatecodelengthmsg'),
      'invalidGroupCodeLengthMsg': this.$el.attr('data-message-invalidgroupcodelengthmsg')
    };
    var specialRatesSelected = $specialRateToggleCount.find('input[type="radio"]:checked:first').val();
    var useRewardsPoints = searchForm.find('input[name="useRewardsPoints"]');
    var savedHotelForm = this.$el.find('#global-header-saved-hotels-form');
    var selectedUseRewards = false;
    var $flexibleDateSearch = searchForm.find('.js-flex-check');
    if (searchForm.find('input[name="destinationAddress.destination"]').val() !== '' && searchForm.find('input[name="destinationAddress.region"]').val()) {
      searchForm.find('input[name="destinationAddress.region"]').val('');
    }
    // If destination name is empty clear value of state,city and coutry field.
    if (searchForm.find('input[name="destinationAddress.destination"]').val() === '') {
      searchForm.find('input[name="destinationAddress.country"],input[name="destinationAddress.stateProvince"],input[name="destinationAddress.city"]').val('');
    }
    if (isDatesValidation === 'true') {
      if (fromDate === '') {
        alert(checkInDateErrorMessage);
        return false;
      }
      if (toDate === '') {
        alert(checkOutDateErrorMessage);
        return false;
      }
    }

    if (!savedHotelForm.hasClass('ui-tabs-hide') && savedHotelForm.find('select[name="propertyCode"] :selected').val() === '') {
      alert(messageObject.noSelectedHotel);
      return false;
    }

    if ($flexibleDateSearch.val() == "true") {
      if (roomCount >= 4) {
        alert(messageObject.roomLimitMessage);
        return false;
      }
      if (!searchForm.find('.calendar-module-fromdate').datepicker('getDate') && searchForm.find('.calendar-module-todate').datepicker('getDate')) {
        searchForm.find('.calendar-module-todate').datepicker('setDate', null);
      }
      // searchForm.find('input[name=flexibleDateSearch]').val(true);
    } else {
      // searchForm.find('input[name=flexibleDateSearch]').val(false);
    }
    if (useRewardsPoints.length !== 0) {
      if (useRewardsPoints && !savedHotelForm.hasClass('ui-tabs-hide') && useRewardsPoints[0].checked) {
        selectedUseRewards = true;
      }
    }
    if ((roomCount > 9) && searchForm.find('.js-use-points-checkbox').prop('checked')) {
      selectedUseRewards = true;
    }
    $(_.filter(this.$el.find('.js-auto-complete-input').not('.l-sell-opt-input'), function (value) {
      return ($(value).is(':hidden'));
    })).val('');
    if (roomCount > 3) {
      if (selectedUseRewards && ((this.$el.find('input[name="isAssociateFlow"]').val() === "true") || (roomCount > 9))) {
        return window.confirm(messageObject.useRewardsPtsMessage);
      }
    }
    if (isDatesValidation === 'true') {
      var corpCodeLength = $specialRateToggleCount.parent().siblings('.code-container').find('.corp-code').find('input:text').val().length;
      if ($specialRateToggleCount.parent().find('.is-checked .search-clusterCode-corp').length && corpCodeLength < 2) {
        alert(messageObject.invalidCorporateCodeLengthMsg);
        return false;
      }
    }
    if ($specialRateToggleCount.parent().find('.is-checked .search-clusterCode-group').length && $specialRateToggleCount.parent().siblings('.code-container').find('.group-code').find('input:text').val().length < 7) {
      alert(messageObject.invalidGroupCodeLengthMsg);
      return false;
    }
    if (this.showSpecialRateErrorMessage()) {
      $flexibleDateSearch.remove();
    } else {
      return false;
    }
    this.initialFormData = this.serializeFormData(searchForm);
  },
  /**
  * This function is used to update room url on click of room plus flight or room plus car links.
  */
  updateRoomUrl: function (event) {
    event.preventDefault();
    var area2 = '',
      dateFormat = this.$el.find('.l-date-format').text(),
      num_rooms = parseInt(this.$el.find('.js-number-of-rooms').text()),
      numChildren = 0,
      childrenAges = this.$el.find('.js-children-ages').val(),
      childrenAgesArray = childrenAges.split(','),
      childAgeParam = '',
      childAgeCounter = 1,
      linkUrl = this.$el.find(event.currentTarget).attr('href');
    if (num_rooms > 3) {
      num_rooms = 1;
    }
    var adults = num_rooms * (parseInt(this.$el.find('.js-number-of-adults').text()));
    if (adults > 7) {
      adults = 7;
    }
    numChildren = num_rooms * (parseInt(this.$el.find('.js-number-of-children').text()));
    this.formatPicker = new FormatPicker(this.$el);
    if (4 < numChildren) {
      numChildren = 4;
    }
    if (0 < numChildren) {
      for (childAgeCounter; childAgeCounter <= numChildren; childAgeCounter++) {
        if (!(childrenAgesArray[childAgeCounter - 1]) || childrenAgesArray[childAgeCounter - 1] < 2) {
          childAgeParam += "&child_age" + childAgeCounter + "=Under 2";
        }
        else {
          childAgeParam += "&child_age" + childAgeCounter + "=" + childrenAgesArray[childAgeCounter - 1];
        }
      }
    }
    var date1 = this.$el.find(".js-from-calc").val() ? this.formatPicker.formatDate(new Date(this.$el.find(".js-from-calc").val()), dateFormat) : '',
      date2 = this.$el.find(".js-to-calc").val() ? this.formatPicker.formatDate(new Date(this.$el.find(".js-to-calc").val()), dateFormat) : '';
    if (this.$el.find(event.currentTarget).data('vbmparam')) {
      area2 = this.$el.find('.js-search-city-display').val() || this.$el.find('.search-city').val() || this.$el.find('.js-recent-search').val() || '';
      window.open(linkUrl + '&date1=' + date1 + '&date2=' + date2 + '&num_rooms=' + num_rooms + '&adults=' + adults + "&area2=" + area2 + '&dynamic_children=' + numChildren + childAgeParam, '_blank');
    }
    else {
      window.open(linkUrl + '&date1=' + date1 + '&date2=' + date2 + '&num_rooms=' + num_rooms + '&adults=' + adults + '&dynamic_children=' + numChildren + childAgeParam, '_blank');
    }
  },
  /**
   * This function is used to open a new window with a message to confirm the cancel event
   * on modify and review changes page of reservation
   * @param {Object} Event object is passed
   */
  confirmCancelReservationEvent: function (e) {
    e.preventDefault();
    var _self = this;
    var googleMapOptions = _self.$el.find(".search-google-map").data('options');
    var geoLocationCN = !_self.$el.data("suppress-google-api");
    var $listContainerEl = _self.$el.find('.l-hsearch-loc');
    var autocompleteSupportedTypesString = _self.$el.data("autocomplete-supported-types");
    var autocompleteSupportedTypes = autocompleteSupportedTypesString.split(',');
    if (geoLocationCN === true || !this.enableGooglePlaceAutoSuggestion) {
      $(this.autocompleteInputEl).addClass('js-auto-complete-input');
      var path = _self.$el.find('a.js-cancel-button').attr('href');
      var upsellFlag = _self.$el.find('#upsellFlag').val();
      if (upsellFlag === 'true') {
        path += '?upsell=true';
      }
    }
    var windowprops = 'height=200,width=360,location=no,left=300,top=300,scrollbars=no,menubars=no,toolbars=no,resizable=yes';
    window.open(path, 'CancelReservationChanges', windowprops);
    },
    initSellOptions: function(isCity){
      var selectedCode= this.$el.find('input[checked="checked"]').attr('value');
      this.$el.find('.l-sell-opt-input').attr('name','');
      this.$el.find('.l-sell-opt-input').not('li [data-special-rate-type="'+selectedCode+'"]').val('');  
      var headerText=this.$el.find('.l-h-toggle-text');
      var cityOptionsSelected=this.$el.find('input[name="sellOptionsListCityAirportAddSearch"]#sell-strategy');
      var hotelOptionsSelected = this.$el.find('input[name="sellOptionsListHotelNameSearch"]:checked');
      if (!hotelOptionsSelected.length) {
        hotelOptionsSelected = this.$el.find('input[name="sellOptionsListHotelNameSearch"]#hotel_sell-strategy');
      }
      if(this.$el.find('input[name="searchType"]').attr('value')==="InCity" && isCity === undefined || isCity) {
        this.setDefaultFormAction();
        var sellOptionsData = this.$el.find('#special-rates-search').data();
        this.$el.find('li.city-search-options').removeClass('is-hidden');
        this.$el.find('li.hotel-search-options').addClass('is-hidden');
        if(cityOptionsSelected.length){
          cityOptionsSelected.prop('checked',true);
          if(sellOptionsData.citySellOption !== ''){
            headerText.html(headerText.data('value'));
          } else{
            headerText.html(headerText.data('defaultheader'));
          }
        }
        if(this.$el.find('.l-adv-search-associate').data('city')){
            var options=this.$el.find('.l-adv-search-associate').data('options');
            var cityoptions=this.$el.find('.l-adv-search-associate').data('city');
            options.suggestionSortOrder=cityoptions;
            this.$el.find('.l-adv-search-associate').attr('data-options', JSON.stringify(options));
        }
        if(sellOptionsData.citySellOption !== ''){
          this.$el.find('input[name="sellOption"], input[name="sellOptionLabel"]').attr('value',sellOptionsData.citySellOption);
        } else {
          this.$el.find('input[name="sellOption"], input[name="sellOptionLabel"]').attr('value','sell-strategy');
        }
      } else {
        this.handleFormAction();
        var sellOptionsData = this.$el.find('#special-rates-search').data();
        this.$el.find('li.city-search-options').addClass('is-hidden');
        this.$el.find('li.hotel-search-options').removeClass('is-hidden');
       if(hotelOptionsSelected.length){
          hotelOptionsSelected.prop('checked',true); 
          headerText.html(sellOptionsData.hotelSellOption !== '' ? this.$el.find('#hotel_' + sellOptionsData.hotelSellOption).data('sell-option-label') : headerText.data('defaultheader'));
        }
        if(this.$el.find('.l-adv-search-associate').data('hotel')){
            var options=this.$el.find('.l-adv-search-associate').data('options');
            var hoteloptions=this.$el.find('.l-adv-search-associate').data('hotel');
            this.autoCompleteHandler.options.suggestionSortOrder=hoteloptions;
            this.$el.find('.l-adv-search-associate').attr('data-options', JSON.stringify(options));
        }
        var sellOptionInput = this.$el.find('input[name="sellOption"]');
        var sellOptionLabel = this.$el.find('input[name="sellOptionLabel"]');
        if (sellOptionsData.hotelSellOption === '') {
          sellOptionInput.val('sell-strategy');
          sellOptionLabel.val('sell-strategy');
        } else {
          var $hotelSellOption = this.$el.find('#hotel_' + sellOptionsData.hotelSellOption);
          $hotelSellOption.addClass('skip-reset-date');
          $hotelSellOption.closest('.js-h-toggle').trigger('click',[true]);
          var inputCode = this.$el.find('.js-code-section-replica .' + sellOptionsData.hotelSellOption + '-code');
          if (this.$el.hasClass('last-sell-selected')) {
            inputCode.find('input').val(inputCode.find('input').data('last-selected-value'));
            inputCode.find('input').attr('name', inputCode.find('input').data('name'));
            this.$el.removeClass('last-sell-selected')
          }
          inputCode.show();
        }
      }
    }, 
    sellOptionsHandler: function(event) {
      if(this.$el.find('.l-adv-search-associate').length){
        var selectedCode= this.$el.find('input[checked="checked"]').attr('value');
        this.$el.find('.special-rate-code').not('li.is-checked .'+ selectedCode +'-code').each(function(index){
          $(this).css('display','none');
        });
        if($(event.target).hasClass("js-single-search-incity")) {
          this.initSellOptions(true);
          this.$el.find('.js-autosuggest-item-type').val('');
          
        } else {
          this.initSellOptions(false);
          this.$el.find('.js-autosuggest-item-type').val('property');
        }
        inputCode.show();
      }
    },
  sellOptionsHandler: function (event) {
    if (this.$el.find('.l-adv-search-associate').length) {
      this.$el.find('.special-rate-code').each(function (index) {
        $(this).css('display', 'none');
      });
      if ($(event.target).hasClass("js-single-search-incity")) {
        this.initSellOptions(true);
        this.$el.find('.js-autosuggest-item-type').val('');

      } else {
        this.initSellOptions(false);
        this.$el.find('.js-autosuggest-item-type').val('property');
      }
    }
    if (!this.$el.hasClass('default-tab-change') && $(event.target).data('label') !== this.activeTab.data('label')) {
      this.activeTab = $(event.target);
      this.pubsub.publish('TOGGLE_ERROR_MESSAGE', [false]);
      this.pubsub.publish('TOGGLE_PAGE_HEADER', [true]);
    }
    this.$el.removeClass('default-tab-change');
    var $inputGroupSell = this.$el.find('input#hotel_group-sell');
    if ($inputGroupSell.is(':checked') && !$inputGroupSell.hasClass('skip-reset-date')) {
      this.resetKGroupDate();
    }
    $inputGroupSell.removeClass('skip-reset-date');
  },
  toggleSellOptionsBox: function (evt) {
    evt.preventDefault();
    this.$el.find('.l-selloptions-trigger-box').trigger('click');
  },
  initFlexibleDates: function () {
    var _self = this;
    if (_self.$el.find('#search-datepicker').length !== 0) {
      var inventoryLabel = _self.$el.find('.inventory-date').data('inventory').label;
      var inventoryValue = _self.$el.find('.inventory-date').data('inventory').value;
      if (_self.$el.find('.js-date-from').data().showKGroupDate) {
        inventoryValue = _self.$el.find('.inventory-date').data('inventory').kGroupValue;
      }
      var data = "<div class='inventory-date-container t-font-m l-clear-both'><span class='inventory-label t-font-weight-bold'>" + inventoryLabel + "</span><span class='date-of-inventory'>" + inventoryValue + " </span></div>";
      if (_self.isForceSellOverlay) {
        _self.$el.find('.l-picker-footer').html(data);
      } else {
        _self.$el.find('.l-picker-footer').append(data);
        _self.$el.find('.js-toggle-flexdate').addClass('l-margin-bottom-half');
      }
    }
  },
  /**
   * Conditionally initialize autocomplete handler for Marriott or Google Places API (R3 Change)
   */
  initAutocomplete: function () {
    var _self = this;
    var googleMapOptions = _self.$el.find(".search-google-map").data('options');

    var geoLocationCN = !_self.$el.data("suppress-google-api");
    var $listContainerEl = _self.$el.find('.l-hsearch-loc');
    var autocompleteSupportedTypesString = _self.$el.data("autocomplete-supported-types");
    var autocompleteSupportedTypes = !autocompleteSupportedTypesString ? '' : autocompleteSupportedTypesString.split(',');
		  if (geoLocationCN !== true || this.enableCustomAutoSuggest) {
      if (this.enableCustomAutoSuggest) {
        $(this.autocompleteInputEl).addClass('js-auto-complete-input-v2');
        _self.initAutoCompleteHandlerV2();
      } else {
        if (!this.enableGooglePlaceAutoSuggestion) {
          $(this.autocompleteInputEl).addClass('js-auto-complete-input');
        }
        else {
          // Google api initialized with google api url path and language parameter using google-api core lib 
          GoogleApi.create({
            apiUrl: googleMapOptions.api,
            language: googleMapOptions.language,
            success: function (apiWrapper) {
              // Google apiWrapper and autocomplete options are passed to autocomplete handler
              new GoogleAutocompleteHandler(_self, apiWrapper, {
                disableLocationSearch: _self.isAssociateView,
                listContainerEl: $listContainerEl,
                autocompleteSupportedTypes: autocompleteSupportedTypes
              });
            }
          }).init();
          _self.updateRecentSearchData();
        }
        _self.initAutoCompleteHandler();
      }
    } else {
      $(_self.autocompleteInputEl).on('change', function () {
        _self.$el.find('.js-search-radius,.search-latitude,.search-longitude').val('');
      });
    }


    if (_self.$el.parent().find(".tile-adv-search-associate-form").length) {
      _self.initAutoCompleteHandler();
    }
  },

  /**
   * Update recent search data in locastorage if available in search form inputs
   */
  updateRecentSearchData: function () {
    var $form = this.$el.find('form');

    //Update recent search data only if a successfull search
    if (!$form.hasClass("js-recent-search-inputs")) { return; }

    var autocompleteFormFieldMapper = new AutocompleteFormFieldMapper($form[0], AutocompleteFormMapping.get($form.attr('id'))),
      data = autocompleteFormFieldMapper.getFormData(),
      hasEmptyVal = false,
      fields = ['placeId', 'mainText', 'secondaryText', 'stateProvince', 'country'],
      recentSearch = recentSearchService.get('miRecentSearchPlaces', 3);

    fields.forEach(function (item) {
      if (!data[item]) {
        hasEmptyVal = true;
      }
    });

    if (hasEmptyVal === false) {
      recentSearch.set(data, 'placeId');
    }
  },
  /**
   * Update location from clicktosearch map nav
   */
  updateClickToSearchData: function (data) {

    var _self = this;
    var $form = _self.$el.find('form');
    var location = '';
    if (data.info.city) {
      location = data.info.city + ", " + data.info.countryLabel;
    } else {
      if (data.info.stateProvince) {
        location = (data.info.location || data.info.stateProvince) + ", " + data.info.countryLabel;
      } else {
        location = data.info.location || data.info.countryLabel;
      }
    }

    var autocompleteFormFieldMapper = new AutocompleteFormFieldMapper($form[0], AutocompleteFormMapping.get($form.attr('id')));
    autocompleteFormFieldMapper.resetFormFields();
    autocompleteFormFieldMapper.setFormData({
      "city": data.info.city || '',
      "country": data.info.countryCode,
      "latitude": data.info.lat,
      "longitude": data.info.long,
      "stateProvince": data.info.stateCode
    });

    $(_self.autocompleteInputEl).val(location);
    animatePlaceholder.checkPlaceholderAnimation();
  },
  initHotelSellOptions: function () {
    if (this.$el.find('.single-search-type').val() === "true") {
      this.$el.find('.js-autosuggest-item-type').val('property');
      this.$el.find('li.city-search-options').addClass('is-hidden');
      this.$el.find('li.hotel-search-options').removeClass('is-hidden');
      this.initHotelSortOrder();
      this.handleFormAction();
    }
    else {
      if (this.$el.find('.js-autosuggest-item-type').val() !== "property") {
        this.$el.find('.js-autosuggest-item-type').val('');
      }
      this.setDefaultFormAction();
      this.$el.find('li.hotel-search-options').addClass('is-hidden');
      this.$el.find('li.city-search-options').removeClass('is-hidden');
    }
  },
  initHotelSortOrder: function () {
    if (this.$el.find('.l-adv-search-associate').data('hotel')) {
      var options = this.$el.find('.l-adv-search-associate').data('options');
      var hoteloptions = this.$el.find('.l-adv-search-associate').data('hotel');
      options.suggestionSortOrder = hoteloptions;
      this.$el.find('.l-adv-search-associate').attr('data-options', JSON.stringify(options));
    }
  },
  initSellOptionsHeight: function (flag) {
    if (flag.scroll) {
      var _self = this;
      this.scrollbar = ScrollBarHandler.create({
        $el: this.$el,
        $scrollBarTarget: this.$el.find('#special-rates-search > .sell-option-wrapper')
      });
      this.scrollbar.init();
    }
  },
  isActiveSpecialRatesHandler: function () {
    if (this.specialRatesHandler) {
      return this.specialRatesHandler.takeoverScreen.isActive;
    }
    return false;
  },
  /**
   * This function is used to show hws navigation bar once search field(calendar/room-guest-section) get closed or sticky behaviour is removed to navigation bar
   */
  showNavigationBar: function () {
    if (!this.responsiveUtils.isDevice()) {
      this.pubsub.publish('SHOW_HWS_NAVIGATION_BAR');
    }
  },
  /**
   * This function is used to hide calendar on click forceSell button
   */
  hideCalendar: function _hideCalendar() {
    this.$el.hide();
  },

  /**
  * This function publishes the changed reservation dates to forceSellErs tile
  */
  reservationDateToOverlay: function _reservationDateToOverlay() {
    var _self = this;
    var fromDate = _self.$el.find('.js-toggle-picker .js-submit-from').val();
    var fromDateMilliValue = new Date(_self.$el.find('.js-toggle-picker .js-submit-from').val()).getTime();
    var toDate = _self.$el.find('.js-toggle-picker .js-submit-to').val();
    var toDateMilliValue = new Date(_self.$el.find('.js-toggle-picker .js-submit-to').val()).getTime();
    var context = {
      fromDate: fromDate,
      toDate: toDate
    };

    if (fromDateMilliValue !== _self.FromDateInitial && toDateMilliValue !== _self.ToDateInitial) {
      _self.pubsub.publish('FORCE_SELL_RES_DATE', [context]);
    }
  },

  /**
   * This function is used to hide hws navigation bar once search field(calendar/room-guest-section) get active and sticky behaviour is added to navigation bar
   */
  hideNavigationBar: function () {
    if (!this.responsiveUtils.isDevice() && this.$el.closest('.is-sticky-top').length && (this.$el.find(".tabbing").hasClass('is-visible') || this.$el.find('.l-rooms-guests').hasClass('is-active'))) {
      this.pubsub.publish('HIDE_HWS_NAVIGATION_BAR');
    }
  },

  modifyRlmSearchHandler: function (event) {
    var currFormData = this.serializeFormData($(event.target));
    if (!this.isFormDataChanged(this.initialFormData, currFormData)) {
      event.preventDefault();
    }
  },

  serializeFormData: function ($form) {
    var formDataObj = _.fromPairs($form.serializeArray().map(function (item) {
      return [item.name, item.value];
    }))
    $form.find('input[type="checkbox"]').each(function () {
      formDataObj[this.name] = this.checked;
    });
    return formDataObj;
  },

  isFormDataChanged: function (initObj, modifiedObj) {
    var isDataChanged = false,
      initObjKeys = Object.keys(initObj);
    initObjKeys.forEach(function (item) {
      if (initObj[item] !== modifiedObj[item]) {
        isDataChanged = true;
      }
    });

    return isDataChanged;
  },
  /*
  * hide cluster code element for cloned rate cluster container
  */
  hideRateClusterField: function (event) {
    var _self = this;
    if ((_self.$el.find('.js-validate-search-form').find('[name="isAssociateFlow"]').val() === 'true' && _self.$el.parents().find('.tile-error-messages-searchform').find('.m-message-box-error').length) || _self.$el.find('[name="isAssociateFlow"]').val() === 'true') {
      if (!_self.$el.find('.js-validate-search-form').find('.code-container').parent('div').hasClass('.js-code-section-replica')) {
        _self.$el.find('.js-validate-search-form').find('.code-container:eq(1)').find('.js-auto-complete-input-v2').remove();
        _self.$el.find('.special-rates-section.sell-options-section').find('.code-container:eq(1)').find('.js-auto-complete-input-v2').remove();
        _self.$el.find('.special-rates-section.sell-options-section').find('.code-container:eq(1)').remove();
      }
    }
  },
  /*
  * Selecting of All brands on click from specific brand category
  */
  selectAllBrands: function (event) {
    event.preventDefault();
    event.stopPropagation();
    $(event.currentTarget).closest('.l-brand-category').find('input[type="checkbox"]').prop('checked', true);
    $(event.currentTarget).closest('.l-brand-category').find('.select-all').hide();
    $(event.currentTarget).closest('.l-brand-category').find('.clear-all-brand-specific').show();
    this.brandsCountUpdate();
  },
  /*
  * Clear All brands selection on click from specific brand category
  */
  clearAllBrandSpecific: function (event) {
    event.preventDefault();
    event.stopPropagation();
    $(event.currentTarget).closest('.l-brand-category').find('input[type="checkbox"]').prop('checked', false);
    $(event.currentTarget).closest('.l-brand-category').find('.select-all').show();
    $(event.currentTarget).closest('.l-brand-category').find('.clear-all-brand-specific').hide();
    this.brandsCountUpdate();
  },
  /*
  * Clear all brands selection
  */
  clearAllBrands: function (event) {
    event.preventDefault();
    this.$el.find('.brands-section input[type="checkbox"]').prop('checked', false);
    this.$el.find('.brands-section .select-all').show();
    this.$el.find('.brands-section .clear-all-brand-specific').hide();
    this.brandsCountUpdate();
  },
  /*
  * Brand specific selected count check
  */
  brandSpecificCountCheck: function (event) {
    var totalBrandsCount = $(event.currentTarget).closest('.l-brand-category').find('input[type="checkbox"]').length;
    var selectedCount = $(event.currentTarget).closest('.l-brand-category').find('input[type="checkbox"]:checked').length;
    if (totalBrandsCount == selectedCount) {
      $(event.currentTarget).closest('.l-brand-category').find('.select-all').hide();
      $(event.currentTarget).closest('.l-brand-category').find('.clear-all-brand-specific').show();
    }
    else {
      $(event.currentTarget).closest('.l-brand-category').find('.select-all').show();
      $(event.currentTarget).closest('.l-brand-category').find('.clear-all-brand-specific').hide();
    }
  },
  /*
  * Update brand count
  */
  brandsCountUpdate: function (event) {
    var totalBrandsCount = this.$el.find('.brands-section input[type="checkbox"]').length;
    var selectedCount = this.$el.find('.brands-section input[type="checkbox"]:checked').length;
    var $brandSelectedLabel = this.$el.find('.brands-selected-label');
    if (totalBrandsCount == selectedCount || selectedCount == 0) {
      this.$el.find('.default-selected').show();
      this.$el.find('.selected-count').hide();
    } else {
      this.$el.find('.default-selected').hide();
      this.$el.find('.selected-count').show();
      this.$el.find('.brand-count').text(selectedCount);
    }
    // If we have single checked brand, will show '1 brand selected' else 'n brands selected'
    $brandSelectedLabel.text(selectedCount === 1 ? $brandSelectedLabel.data('brand-label') : $brandSelectedLabel.data('brands-label'));

    if (event) {
      this.brandSpecificCountCheck(event);
    }
  },
  openBrandsDropDown: function (e) {
    e.preventDefault();
    var $this = this.$el.find(e.currentTarget);
    $this.toggleClass('is-active');
    if ($this.hasClass('is-active')) {
      this.$el.find('.l-brand-section .m-icon-trigger').removeClass('t-icon-arrow-down').addClass('t-icon-arrow-up');
    } else {
      this.$el.find('.l-brand-section .m-icon-trigger').addClass('t-icon-arrow-down').removeClass('t-icon-arrow-up');
    }
    if(this.disableSubTakeover || !this.responsiveUtils.isMobileOrTablet()) {
      $this.next('.js-toggle-container').slideToggle();
    } else {
      $this.next('.js-toggle-container').show();
      this.openBrandsSubTakeover(e);
    }
  },
  openBrandsSubTakeover: function(event) {
    event && event.preventDefault() && event.stopPropagation();
    var $element = $(event.currentTarget).parent();
    // Change "Apply Button" class to primary
    $element.find('a.close-brand-container').removeClass('m-button-secondary').addClass('m-button-primary');
    $('body').addClass('brands-sub-takeover-opened');
    this.expandSubTakeover(event);
  },
  closeBrandContainer: function (e) {
    var _self = this;
    var container = _self.$el;
    if (!($(e.currentTarget).parents(".l-brand-section").length) || $(e.currentTarget).hasClass('close-brand-container') || $(e.currentTarget).hasClass('sub-takeover-cancel-link')) {
      // Close Subtakeover
      if(this.disableSubTakeover || this.responsiveUtils.isMobileOrTablet()) {
        _self.$el.find('.brands-section.js-toggle-container').hide();
        this.removeSubTakeover(e);
      } else {
        _self.$el.find('.brands-section.js-toggle-container').slideUp();
      }
      container.find('.js-toggle.js-brands-header').removeClass('is-active');
      _self.$el.find('.l-brand-section .m-icon-trigger').removeClass('t-icon-arrow-up').addClass('t-icon-arrow-down');
    }
    
  },
  preventPropagation: function (e) {
    e.stopPropagation();
  },
  /**
   * This method is used to set the property-code if not selected from autocomplete
   * @method setSearchLocation
   * @returns {void} 
  */
  setSearchLocation: function _setSearchLocation(e) {
    if (this.$el.find('.search-tab-list .ui-tabs-selected').find('a').hasClass('js-single-search-propertySearch')) {
      var propertyCodeHiddenInput = this.$el.find('.search-property-code');
      if ($(propertyCodeHiddenInput[1]).val() === '') {
        propertyCodeHiddenInput.val(this.$el.find('.single-search-location').val())
      }
    }
  },
  /**
      * This method is used to set latitude and longitude on click of button.
      */
  setGeoLatLong: function (e) {
    this.geoLocationHandler = new GeoLocationHandler({
      $el: this.$el
    });
    this.geoLocationHandler.setLatLong(e);
  },
  /**
   * This method binds the change event on property code change
   * @method propertyCodeChangeHandler
   * @returns {void} 
  */
  propertyCodeChangeHandler: function _propertyCodeChangeHandler() {
    var $roomGuestField = this.$el.find('.js-rooms-guests');
    if ($roomGuestField.data('is-agent-self-property') && !this.roomGuestHandler.isGXPAgentSelfProperty()) {
      this.roomGuestHandler.onClearClicked();
      $roomGuestField.data('is-agent-self-property', false);
    }
  },
  //APD-6217 Fix for IOS Safari smart banner
  iosSmartBannerHandler: function () {
    var doneButtonContainer = this.$el.find('.l-done-container'),
      windowHeightJS = window.innerHeight,
      windowHeightJQ = $(window).innerHeight(),
      hasSmartBanner = windowHeightJS !== windowHeightJQ,
      smartBannerHeight = Math.abs(windowHeightJS - windowHeightJQ) + "px !important";
    if (hasSmartBanner) {
      doneButtonContainer.attr('style', 'bottom: ' + smartBannerHeight);
    }
    else {
      doneButtonContainer.removeAttr('style');
    }
  },
  /**
   * This method is used to open sub takeover of search related fields
   * @method expandSubTakeover
   * @returns {void} 
  */
  subTakeOverSelectors: ['js-auto-complete-input-v2', 'js-rooms-guests-trigger', 'js-special-rates-header', 'js-brands-header'],
  formCachedProperties: [],
  expandSubTakeover: function (event, dontCacheForm) {
    // we won't execute the function if we
    // don't have permissions
    if(this.disableSubTakeover) {
      return true;
    }
    // Continue with the method's functionality
    event.stopPropagation();
    var _self = this;
    var isSubTakeoverEnabled = false;
    var $element = $(event.currentTarget);
    // For following list of elements we will run this method
    _self.subTakeOverSelectors.forEach(function (cssClass) {
      if ($element.hasClass(cssClass)) {
        isSubTakeoverEnabled = true;
      }
    });

    // Execution of subtakeover code
    if (isSubTakeoverEnabled) {
      // Cache the form values before opening sub takeover
      if ($element.parents('.sub-takeover').length) {
        return;
      }
      // we won't reset complete form if cloning is at place
      else if(!dontCacheForm) {
        _self.cacheSearchFormProperties();
      }
      // Tweaking CSS fields as per our convinience
      $('body').addClass('no-scroll sub-takover-opened');
      $('html').addClass('no-scroll');
      $('.sub-takeover').removeClass('sub-takeover');

      // Decreasing z-index of parent class
      if ($('.tile-search-homepage')) {
        // $('.tile-hsearch').addClass('elevate-index');
        $element.parents('.tile-hsearch').addClass('elevate-index');
      } else if ($('.tile-hsearch')) {
        // $('.tile-hsearch').addClass('elevate-index');
        $element.parents('.tile-hsearch').addClass('elevate-index');
      }

						$element.closest('.m-field-control').addClass('sub-takeover hasOpened');			
						$('.sub-takeover div[data-takeover-label]').scrollTop(0);
    }
  },
  resetDestinationSuggestionList: function _resetDestinationSuggestionList() {
    this.autoCompleteHandlerV2.resetSuggestionList(false, true);
  },
  /**
   * This method is used for resetting cache
   * @method resetCachedProperties
   * @returns {void} 
  */
  resetCachedProperties: function (event, dontResetForm) {
    event.stopPropagation();
    if (this.responsiveUtils.isDesktop()) {
      return;
    }
    var _self = this;
    var $subTakeover = _self.$el.find('.sub-takeover');
    if(!dontResetForm) {
      _self.formCachedProperties.forEach(function (element) {
      if(element.$element.hasClass("js-auto-complete-input-v2")) {
      		if(element.$element.val() !== "") {
      			 element.$element.val(element.value);
      		}
      } else {
        element.$element.val(element.value);
      }
        // Restore checked state of element
        if(element.$element.is("input[type='checkbox']") || element.$element.is("input[type='radio']")) {
          element.$element.prop('checked', element.checked);
        }
      });
    }
    // Brand SubTakeover
    if($subTakeover.hasClass('l-brand-section')) {
      this.brandsCountUpdate(event);
      this.closeBrandContainer(event);
    }
    if($subTakeover.hasClass('js-rooms-guests')){
      _self.roomGuestHandler.toggleRoomButtons();
      _self.roomGuestHandler.toggleAdultButtons();
      _self.roomGuestHandler.toggleChildButtons();
      _self.roomGuestHandler.handleChildAgeDiv($subTakeover.find('.childrenCount').val(), $subTakeover.find('.js-children-ages').val().split(','))
      _self.roomGuestHandler.displayText();
    }
    if(_self.$el.find('.sub-takeover').hasClass('js-auto-complete')){
      _self.resetDestinationSuggestionList();
    }
    _self.removeSubTakeover(event);
    this.isAutoUserIntrectionEnable = false;
  },
  /**
   * This method is used for removing sub takeover on click of cancel
   * @method removeSubTakeover
   * @returns {void} 
  */
  removeSubTakeover: function (event, isAutoIntrection) {
    if(this.disableSubTakeover) {
      return;
    }
    event && event.stopPropagation() && event.preventDefault();
    var _self = this;
     $('html').removeClass('no-scroll');
    $('body').removeClass('no-scroll sub-takover-opened brands-sub-takeover-opened date-picker-sub-takeover-opened');
    $('.sub-takeover').removeClass('sub-takeover');

    // Decreasing z-index of parent class
    $('.elevate-index').removeClass('elevate-index');

    animatePlaceholder.checkPlaceholderAnimation();
    if(isAutoIntrection && this.isAutoUserIntrectionEnable) {
      _self.$el.find(".ccheckin").focus();
      this.isAutoUserIntrectionEnable = false;
    }
    return false;
  },
  cacheSearchFormProperties: function () {
    var _self = this;
    // Let's cache the value of all input elements
    _self.formCachedProperties = [];
    _self.$el.find('input, select').each(function (index, element) {
      _self.formCachedProperties.push({
        $element: $(this),
        value: element.value,
        checked: element.checked ? element.checked : false
      });
    });
    },
    /** 
  * This function used to set Search Type Details
  */
  setSearchTypeDetails : function(){
    var searchType;  
    var recentViewed = $('.l-recently-viewed-item.autocomplete-listitem-active a').attr('data-label');
    var recentSearch = $('.autocomplete-listitem-active a').attr('data-searchtype');
    var recommended = $('.l-find-container .l-h-field').text().trim();
    var liveAutoSuggested= $('div[aria-live^="assertive"]').text().trim();
    if(recentViewed != undefined){
       searchType = "Selected Recently Viewed";
    }else if(recentSearch != undefined){
       searchType = "Selected Recent Searches";
    }else if(recommended != undefined && liveAutoSuggested != undefined && recommended == liveAutoSuggested){
       searchType = "Selected Recommended Result";  
    }else{
       searchType = "typed";
    }
     Cookies.createCookie('updatedSearchType', searchType);
  },
  /**
     * This method is used to delete all empty input fields from the form
     * @method submitSearchFormData
     * @returns {void} 
    */
  submitSearchFormData: function(e){
    if (this.$el.data('brands') === true) {
      var selectedBrandsArray = [];
      this.$el.find('.brands-section input[type="checkbox"]:checked').each(function () {
        selectedBrandsArray.push($(this).val());
      });
      if (this.$el.find('.brands-section input[type="checkbox"]').length == selectedBrandsArray.length) {
        this.$el.find('input[name=marriottBrands]').val('all');
      } else {
        this.$el.find('input[name=marriottBrands]').val(selectedBrandsArray.join(','));
      }
    }
    if (this.$el.find('#edit-search-form')) {
      this.$el.find('form#edit-search-form input[type=hidden]').each((index,ele)=>{
        if(ele.value === ''){
          ele.remove();
        }
      });
      this.$el.find('#edit-search-form').submit();
    }
    if (this.$el.find('#advanced-search-form')) {
      this.$el.find('form#advanced-search-form input[type=hidden]').each((index,ele)=>{
        if(ele.value === ''){
          ele.remove();
        }
      });
      this.$el.find('#advanced-search-form').submit();
    }
    if (this.$el.find('#find-a-hotel-homePage-form')) {
      this.$el.find('form#find-a-hotel-homePage-form input[type=hidden]').each((index,ele)=>{
        if(ele.value === ''){
          ele.remove();
        }
      });
      this.$el.find('#find-a-hotel-homePage-form').submit();
    }
    if (this.$el.find('#find-a-hotel-form')) {
      this.$el.find('form#find-a-hotel-form input[type=hidden]').each((index,ele)=>{
        if(ele.value === ''){
          ele.remove();
        }
      });
      this.$el.find('#find-a-hotel-form').submit();
    }
    if (this.$el.find('#edit-your-search-form-ministores')) {
      this.$el.find('form#edit-your-search-form-ministores input[type=hidden]').each((index,ele)=>{
        if(ele.value === ''){
          ele.remove();
        }
      });
      this.$el.find('#edit-your-search-form-ministores').submit();
    }
    if (this.$el.find('#force-sell-form')) {
      this.$el.find('form#force-sell-form input[type=hidden]').each((index,ele)=>{
        if(ele.value === ''){
          ele.remove();
        }
      });
      this.$el.find('#force-sell-form').submit();
    }
  }
});
module.exports = searchForm;
