var $ = require('jquery');
var _ = require('lodash');
var ComponentMapper = require('./component-mapper');
var GeoLocationHandler = require('./geolocation-handler');
var ClearInputHandler = require('./clear-input-handler');
var localStorage = require('local-storage-fallback');
var PubSub = require('libs/pub-sub');
var Cookies = require('libs/cookies');
var GoogleApi = require('libs/google-api');
var ScrollBarHandler = require('libs/scrollbar');

var properties = {
  listHtml:'',
  listCount:0,
  timeoutId: 0,
  permittedLocales: [],  
  recentSearch: {
    max:3,
    list:[],
    inputClass:'js-recent-search'
  },
  options: {
    searchType: '.js-searchtype',
    addressSearch: 'NearAddress',
    isPropertyCodeFlag: '.search-property-code-flag',
    inputs: {
      radius:'.js-search-radius',
      latitude:'.search-latitude',
      longitude:'.search-longitude',
      cityPop:'.js-search-city-pop',
      cityPopDensity:'.js-search-city-pop-density',
      cityDisplay:'.js-search-city-display',
      city:'.search-city',
      state:'.search-state',
      country:'.search-country',
      countryname: '.search-country-name',
      poiname:'.search-poiname',
      airport:'.search-airport',
      airportName: '.search-airport-name',
      autoSuggestOmni:'.js-autosuggest-item-type',
      singleSearchAuto:'.single-search-autosuggest',
      stateProvince: '.search-state-province',
      propertyName: '.search-property-name',
      propertyCode: '.search-property-code'
    },
    categoryParams : {
      label: ''
    },
    autoCompleteList: '.js-auto-complete-list',
    maxCategory: 10,
    isWebRequest: true,
    applyGrouping: true,
    scrollBarTarget: '.autocomplete-scroller-wrapper',
    scrollbarWrapper : '.scroll-wrapper.autocomplete-scroller-wrapper'
  },
  templates: {
    autoComplete: _.template(
      '<li class="ui-menu-item" role="menuitem">' +
      '<a class="ui-corner-all <% if( index == 0 ) { %> ui-state-hover <% } %>" data-index="<%=index%>" data-type="<%=(typeof type !== "undefined")?type:""%>" data-city="<%=(typeof city !== "undefined")?city:""%>" data-state="<%=(typeof statecode !== "undefined")?statecode:""%>"' +
      ' data-countrycode="<%=countrycode%>" data-country="<%=country%>" <% if(typeof airportname !== "undefined") { %> data-airport-name="<%=airportname%>" data-airport-code="<%=airportcode%>"<% } %>' +
      ' <% if(typeof poiname !== "undefined") { %> data-poi-name="<%=poiname%>"<%}%> data-geo="<%=(typeof geocode !== "undefined")?geocode:""%>"'+
      ' <% if(typeof citypopulation !== "undefined") { %> data-city-population="<%=citypopulation%>" <%}%>' +
      ' <% if(typeof propertyname !== "undefined") { %> data-property-name="<%=propertyname%>" <%}%>' +
      ' <% if(typeof propertycode !== "undefined") { %> data-property-code="<%=propertycode%>" <%}%>' +
      ' <% if(typeof stateprovincedisplayname !== "undefined") { %> data-state-province="<%=stateprovincedisplayname%>"<%}%>' +
      ' <% if(typeof citypopulationdensity !== "undefined") {%> data-city-population-density="<%=citypopulationdensity%>"<%}%>>' +
      ' <% if(type === "poi") { %><%=mergeItem(", ", poiFormat(poiname, city, statecode, country, searchterm))%><%}else if(type === "property") { %><%=mergeItem(", ", propertyFormat(propertyname, city, statecode, country, propertycode ,propertyCodeCheckFlag))%><%} else {%><%=mergeItem(", ", any(airportFormat((typeof airportname !== "undefined")?airportname:"",(typeof airportcode !== "undefined")?airportcode:"",(typeof city !== "undefined")?city:""), any((typeof poiname !== "undefined")?poiname:"",(typeof city !== "undefined")?city:"")), (typeof statecode !== "undefined")?statecode:"",(typeof country !== "undefined")?country:"")%><%} %></a>' +
      '</li>'),
    autoOutComplete: _.template(
      '<li class="ui-menu-item" role="menuitem">' +
      '<a class="ui-corner-all <% if( index == 0 ) { %> ui-state-hover <% } %>" data-label="<%=label%>" data-index="<%=index%>" data-type="NA" data-route="<%=Route%>" data-city="<%=City%>" data-state="<%=StateCode%>"' +
      ' data-country="<%=Country%>" data-geo="<%=GeoCode%>">' +
      '<%=label%></a>' +
      '</li>'),
    autoListHeader: _.template(
      '<li class="t-category-header t-font-semi-bold <%=firstClass%>">' +
      '<span class="icon-<%=name%>"></span><%=category%>' +
      '</li>'),
    autoListMsg: _.template('<li class="t-autosuggest-msg t-font-semi-bold"><%=label%></li>'),
    autoCompleteRecent: _.template(
      '<li class="ui-menu-item" role="menuitem">' +
      '<a class="ui-corner-all <% if(index == 0 ) { %> ui-state-hover <% } %>" data-index="<%=index%>" data-type="<%=type%>"  data-searchtype="<%=searchtype%>" <% if(typeof city !== "undefined") { %> data-city="<%=city%>"<%}%><% if(typeof state !== "undefined") { %> data-state="<%=state%>"<%}%>' +
      '<% if(typeof country !== "undefined") { %> data-countrycode="<%=country%>"<%}%><% if(typeof countryname !== "undefined") { %> data-country="<%=countryname%>"<%}%> <% if(typeof airportname !== "undefined") { %> data-airport-name="<%=airportname%>"<%}%><% if(typeof airportcode !== "undefined") { %> data-airport-code="<%=airportcode%>"<%}%>' +
      '<% if(typeof poiname !== "undefined") { %> data-poi-name="<%=poiname%>"<%}%><% if(typeof geocode !== "undefined") { %> data-geo="<%=geocode%>"<%}%>'+
      '<% if(typeof citypopulation !== "undefined") { %> data-city-population="<%=citypopulation%>"<%}%>' +
      '<% if(typeof stateprovincedisplayname !== "undefined") { %> data-state-province="<%=stateprovincedisplayname%>"<%}%>' +
      '<% if(typeof citypopulationdensity !== "undefined") {%> data-city-population-density="<%=citypopulationdensity%>"<%}%> data-label="<%=label%>">' +
      '<%=label%></a></li>'),
    autoCompleteCluster: _.template(
    '<li class="ui-menu-item" role="menuitem">' +
      '<a class="ui-corner-all <% if( index == 0 ) { %> ui-state-hover <% } %>"  data-index="<%=index%>" data-type="<%=(typeof type !== "undefined")?type:""%>"  data-code="<%=code%>"' +
      'data-desc="<%=description%>" >' +
      '<%=code%>-<%=description%></a>' +
      '</li>')
  },
  templateSettings: {
    // interpolate: /{{([\s\S]+?)}}/g,
    // variable: '_data',
    imports: {
      any: function(one, two) {
        return one || two;
      },
      airportFormat: function(name, code, city) {
        return (name && (name + ( ( code && (' (' + code + ')') ) || '' ) + ' ' + city ) || undefined );
      },
      poiFormat: function(poiname, city, statecode, country, searchterm) {
        var result = (poiname.toLowerCase().indexOf(searchterm.toLowerCase()) !== -1) ? (poiname + ' - ' + city) : (city + ' - ' + poiname);
        result += ', ' + ((statecode) ? statecode + ', ' : '') + country;
        return result;
      },
      propertyFormat: function (propertyname, city, statecode, country, propertyCode, propertyCodeCheckFlag) {
        return (propertyname + ' - ' + city + ', ' + ((statecode) ? statecode + ', ' : '') + country + ((propertyCodeCheckFlag) ? ' (' + propertyCode + ')' : ''));
      },
      mergeItem: function(seperator) {

        var result = '', idx = 0;
        for(idx = 1; idx < arguments.length; idx++) {
          result = result + ( (arguments[idx] && (
              ( (result && seperator) || '') + arguments[idx]) ) || '' )
        }
        return result;
        },
       /* cluster code format for input box*/
        clusterFormat: function(code, desc) {
            return (code + ' - ' + desc);
        }
    }
  },
  events: {
    'keyup .js-auto-complete-input': 'getChoiceList',
    'keydown .js-auto-complete-input': 'navigateList',
    'blur .js-auto-complete-input': 'hideSuggestions'
  },
  subscribe: {
    'GOOGLE_API_LOADED': 'populateCurrentLocation'
  },
  init: function(){
    var _self = this;
    var options = _self.options;

    _self.propertyCodeCheckFlag  = '';
    _self.propertyCodeCheck();
    var autoCompleteOptions = this.$el.data('options');
    _self.options.autoCompleteURL = autoCompleteOptions.autoCompleteURL;
    _self.options.autoCompleteOutReqURL = autoCompleteOptions.autoCompleteOutReqURL;
    _self.options.suggestionSortOrder = autoCompleteOptions.suggestionSortOrder;
    _self.options.clusterSearchHandler = _self.clusterSearchHandler;
    _self.$document = $(document);
    
    //If permitted locales string is not empty
    if (autoCompleteOptions.permittedLocales) {
      _self.permittedLocales = autoCompleteOptions.permittedLocales.split(',');
    }
    _self.googleMap = this.$parent.$el.find(".search-google-map").data('options');

    _.extend(_.templateSettings, this.templateSettings);

    _self.subscribeDOMEvents();
    _self.subscribePubSubMessages();
    if (!_self.$parent.hideCurrentLocation) {
      _self.initGeoLocationHandler();
      _self.initGoogleApi();
    }
    _self.initClearInputHandler();

    // set category parameters from dom element
    if (_self.$parent.$el.find('.autosuggest-cat-headers').length) {
      _self.options.categoryParams = _self.$parent.$el.find('.autosuggest-cat-headers').data('category-params');
    }

    _self.$scrollbarWrapper = $('<div class="autocomplete-scroller-wrapper"><ul></ul></div>');
    _self.$autoCompleteList = _self.$scrollbarWrapper.find('ul');
    
    _self.$autoCompleteList.addClass('ui-autocomplete ui-menu ui-widget ui-widget-content ui-corner-all t-category-headers').css({
        'z-index': 999,
        'top': '0px',
        'left': '0px',
        'display': 'none'
      })
      .on('mousedown', '.ui-menu-item a', _.bind(_self.listItemSelect, _self))
      .on('mouseover', '.ui-menu-item a,.t-category-header', _.bind(_self.highLightListItem, _self))
      .on('mousedown', '.js-recent-clear', _.bind(_self.clear, _self));

      _self.$scrollbarWrapper.css({
        'display': 'none'
      });

    _self.$narrate = this.$el.find('div[aria-live="assertive"]');
    _self.$el.append(_self.$scrollbarWrapper).append(_self.$narrate).find(_self.$autoCompleteList);
    _self.setLocationInput();
    _self.recentSearchTerm = _self.$input.val();
    _self.addRecent();
    _self.retainLatitudeLongitudeAttributes();
    //to add default styling to autocomplete wrapper
    _self.autoCompleteStyle(true);

    _self.initScroller();
  },
  initScroller: function() {
    var _self = this;
    if (!this.scrollbar && (this.isDesktopVersion())) {
      this.scrollbar = ScrollBarHandler.create({
        $el: this.$el,
        $scrollBarTarget: this.$el.find(_self.options.scrollBarTarget).not('.custom-wrapper')
      });
      this.scrollbar.init();
      this.autoSuggestMobileFix(true);
    }
  },
  removeScroller: function() {
    if (this.scrollbar) {
      this.scrollbar.destroy();
      delete(this.scrollbar);
      this.autoSuggestMobileFix();
    }
  },
  /**
  * This function retains latitude and longitude values for inCity/NearAddress tab.
  * @param inputMap - Object containing lat/long details
  **/
  retainLatitudeLongitudeAttributes: function(inputMap) {
    var _self = this;
    var inputMap = {
      latitude: inputMap ? inputMap.latitude : _self.$parent.$el.find(_self.options.inputs.latitude).val(),
      longitude: inputMap ? inputMap.longitude : _self.$parent.$el.find(_self.options.inputs.longitude).val(),
      singleSearchAuto: inputMap ? inputMap.singleSearchAuto : _self.$parent.$el.find(_self.options.inputs.singleSearchAuto).val(),
      autoSuggestOmni: inputMap ? inputMap.autoSuggestOmni : _self.$parent.$el.find(_self.options.inputs.autoSuggestOmni).val()
    };
    _self.options.searchTypeVal = _self.$parent.$el.find(_self.options.searchType).val();
    if (_self.options.searchTypeVal === _self.options.addressSearch) {
      _self.addressInputs = _.cloneDeep(inputMap);
    } else if(_self.options.searchTypeVal === 'InHotelSearch'){
      return false;
    } else {
      _self.inCityInputs = _.cloneDeep(inputMap);
    }
  },
  populateCurrentLocation: function() {
    var _self = this;
    if(sessionStorage && Cookies.readCookie('loadCurrentLocation') === "true") {
      Cookies.eraseCookie('loadCurrentLocation');
      _self.geoLocationHandler.doLocalDetails();
    }
  },
  propertyCodeCheck: function () {
    var options = this.options;
    this.propertyCodeCheckFlag = this.$parent.$el.find(options.isPropertyCodeFlag).val();
  },
  /**
  * This function sets location input object that is used for city or address search.
  * @return void
  **/
  setLocationInput: function() {
    var _self = this;
    _self.$input = $(_.filter(_self.$el.find('.js-auto-complete-input').attr('aria-autocomplete','list'), function(value){
      return ($(value).is(':visible'));
    }));
  },
  /**
  * This function creates a geolocation object that is used for current location search.
  * @return null
  **/
  initGeoLocationHandler: function() {
    this.geoLocationHandler = new GeoLocationHandler({
      $el: this.$el,
      $parent: this.$parent,
      autoComplete: this
    });
    this.geoLocationHandler.init();
  },
  /**
  * This function loads google maps API.
  * @param callback - called if google maps API is loaded successfully
  * @param fallback - called if google maps API fails to load
  * @return null
  **/
  initGoogleApi: function() {
    var _self = this;
    if (_self.googleMap) {
      var googleApi = GoogleApi.create({
        apiUrl: _self.googleMap.api,
        language: _self.googleMap.language
      });    
      googleApi.init();
    }
  },
  /**
  * This function create handler object for clear button present on search input box.
  * @return null
  **/
  initClearInputHandler: function() {
    this.clearInputHandler = new ClearInputHandler({
      $el: this.$el,
      $parent: this
    });
    this.clearInputHandler.init();
  },
  /**
  * This function checks if current site id is present in list of permitted locales.
  * @return true|false
  * Returns true if permitted locales array is empty
  **/
  permittedSiteId: function() {
    var siteID = ($('html').attr('lang') || $('html').attr('xml:lang'));
    if (_.isEmpty(this.permittedSiteId)) {
      return true;
    }
    return (siteID && _.includes(this.permittedLocales, siteID));
  },
  /**
  * This function determines ajax call to get data for a specific keyword search.
  * @return null
  **/
  getChoiceList: function(evt) {
    var _self = this;
    var searchTerm = evt.target.value,
      parseCall = _.bind(_self.parseResult, _self, searchTerm, $(evt.target));
    _self.options.searchTypeVal = _self.$parent.$el.find(_self.options.searchType).val();
    if( _self.options.searchTypeVal !== _self.options.addressSearch ) {
      _self.$autoCompleteList.addClass('ui-autocomplete-edit-search t-category-headers');
      _self.$autoCompleteList.removeClass('ui-autocomplete-near-address');
    }
    if (!searchTerm.trim().length || (_self.recentSearchTerm !== searchTerm)) {
       if(_self.recentSearchTerm !== searchTerm){
        delete(_self.initialSearchTerm);
       }
      if(!_self.options.clusterSearchHandler){
        _self.resetInputs();
      }
      else{
        if(_self.xhrRequest) {
          _self.xhrRequest.abort();
        }
        _self.listHtml='';
      }  
    }
    if (!searchTerm.trim().length) {
      _self.suggestionListShow = false;
    }
    if (!searchTerm.trim().length||(_self.recentSearchTerm === searchTerm)) {
      var showContinueTypingText = false;
      if (((evt.keyCode === 38) || (evt.keyCode === 40) || (evt.keyCode === 8)) && !_self.suggestionListShow) {
        if ((_self.options.searchTypeVal !== _self.options.addressSearch) && !_self.listHtml) {
          _self.suggestGeoLoc();
          _self.suggestRecent( searchTerm );
          showContinueTypingText = true;
        }
        _self.showList($(evt.target), showContinueTypingText);
      }
      _self.recentSearchTerm = searchTerm;
      return;
    }

    _self.recentSearchTerm = searchTerm;
    if(_self.options.clusterSearchHandler){
      _self.performClusterSearch(parseCall);
    }
    else{
      (_self.options.searchTypeVal !== _self.options.addressSearch) ? _self.performLocationSearch(parseCall) : _self.performAddressSearch(parseCall);
    }
  },
  /**
  * This function performs location search on basis of search term.
  * @return null
  **/
  xhrRequest: false,
  performLocationSearch: function(parseCall) {
    var _self = this;
    _self.$autoCompleteList.addClass('ui-autocomplete-edit-search t-category-headers');
    _self.$autoCompleteList.removeClass('ui-autocomplete-near-address');
    var searchRequest = {
        type: 'GET',
        url: _self.options.autoCompleteURL,
        contentType: 'text/html',
        cache: true,
        data: {
          searchTerm: _self.recentSearchTerm,
          suggestionSortOrder: _self.options.suggestionSortOrder
        },
        success: parseCall
      };

      if(_self.xhrRequest) {
        _self.xhrRequest.abort();
      }
      _self.xhrRequest = _self.makeAjaxCall(searchRequest);
  },
   performClusterSearch: function(parseCall) {
      var _self = this;
      _self.$autoCompleteList.addClass('ui-autocomplete-edit-search t-category-headers');
      _self.$autoCompleteList.removeClass('ui-autocomplete-near-address');
      var searchRequest = {
          type: 'GET',
          url: _self.options.autoCompleteURL,
          contentType: 'text/html',
          data: {
            searchTerm: _self.recentSearchTerm,
            suggestionSortOrder: _self.options.suggestionSortOrder
          },
          success: parseCall
        };

        if(_self.xhrRequest) {
          _self.xhrRequest.abort();
        }
        _self.xhrRequest = _self.makeAjaxCall(searchRequest);
    },
  /**
  * This function performs location search on basis of search term.
  * @return null
  **/
  performAddressSearch: function(parseCall) {
    var _self = this; 
    window.geocoder.geocode({'address': _self.recentSearchTerm}, function(result, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        _self.$autoCompleteList.removeClass('ui-autocomplete-scroll ui-autocomplete-edit-search t-category-headers');
        _self.$autoCompleteList.addClass('ui-autocomplete-near-address');
        if (result) {
          if (result.length >= 0) {
            _self.$autoCompleteList.click(function() {
              return false;
            });
            var response = [];
            $.map(result, function(item) {
              if (item.formatted_address != undefined) {
                response.push({
                  data: item,
                  label: item.formatted_address,
                  value: item.formatted_address
                });
              }
            });
            parseCall(response);
          }
        }
      }
    });
  },
  /**
  * This function helps in hiding autocomplete list.
  * @return null
  **/
  hideSuggestionsFlag: true,
  blankLocationInputFlag: true,
  hideSuggestions: function(evt, suppressCallback) {
    var _self = this;
    if(_self.hideSuggestionsFlag){
      _self.$autoCompleteList.hide();
      _self.$scrollbarWrapper.scrollTop(0).parent().find('.scroll-bar').css('top','0px')
      _self.$scrollbarWrapper.hide();
      _self.$document.off('click.auto-complete-handler');
      _self.autoCompleteStyle(true);
      _self.suggestionListShow = false;
      if(_self.blankLocationInputFlag && _self.onListClose && !suppressCallback) {
        _self.onListClose();
      }
    }
  },
  resetInputs: function() {
    var _self = this;
    var autoSuggestItemType='.js-autosuggest-item-type';
    var isAdvSearchAssociate=_self.$el.hasClass('l-adv-search-associate');
    var isAssociateSearchWrapper=_self.$parent.$el.hasClass('adv-search-associate-form');
    _self.listHtml = '';
    _self.listCount = 0;
    _.forEach(_self.options.inputs, function(selectors) {
      if((isAdvSearchAssociate || isAssociateSearchWrapper) && selectors===autoSuggestItemType){
        return;
      }
      _self.$parent.$el.find(selectors).val('');
      if(_.isFunction(_self.$parent.propertyCodeChangeHandler)){
        _self.$parent.propertyCodeChangeHandler();
      }
    }, _self);
    _self.$parent.$el.find(_self.options.inputs.singleSearchAuto).val(false);
  },
  /**
  * This function creates recent search column in autocomplete list.
  * @return null
  **/
  suggestRecent:function(searchTerm) {
    var _self = this;
    if (_self.permittedSiteId()) {
      var recentSearchList = _self.getAutocompleteItem(searchTerm, _self.$input),
      renderList = '';
    }

    if ( recentSearchList && recentSearchList.length ) {
      //Recent category header
      renderList += "<li class='t-category-header t-font-semi-bold first'><span class='icon-recent'></span> " +
      _self.options.categoryParams.recent +
      '<span class="t-pointer l-float-right js-recent-clear l-margin-right-half t-font-normal t-lowercase ">' +
      _self.options.categoryParams.clear + "</span></li>";
      for (var i = 0; i < recentSearchList.length; i++) {
        recentSearchList[i].index = _self.listCount + i;
        renderList += _self.markSearchTerm( searchTerm, _self.templates.autoCompleteRecent( recentSearchList[i] ) );
      }
      _self.listHtml += renderList;
      _self.listCount += recentSearchList.length;
    }
    return _self.listCount;
  },
  /**
  * This function sets autocomplete list to its container and modifies css accordingly.
  * @return null
  **/
  showList: function( inputTarget, showContinueTypingText) {
    var _self = this;
    _self.blankLocationInputFlag = true;
    if (_self.listCount) {
      //Don't show Continue typing text below autocomplete in address search
      if (_self.options.searchTypeVal !== _self.options.addressSearch && showContinueTypingText) {
        _self.listHtml += _self.templates.autoListMsg(_self.options.categoryParams);
      }
      _self.$autoCompleteList.html(_self.listHtml);
      var narrate="";
      var destinationLabelHeight = _self.$parent.$el.find('.l-hsearch-labels').length ? 0 : _self.$parent.$el.find('.field-title').height();
      if( _self.$autoCompleteList.filter(':hidden') ) {
        _self.$autoCompleteList.show();
        _self.$scrollbarWrapper.show();
        _self.$document
          .off('click.auto-complete-handler')
          .on('click.auto-complete-handler', _self.handleDocumentClick.bind(_self));
      }

      _self.$autoCompleteList.css({          
        left: '0px',
        width: inputTarget.outerWidth() + 'px'
      });

      //Defect fix SRPE-18186
      this.autoSuggestMobileFix();

      // Adding mousedown event to detect elements where no event should take place on click
      _self.$autoCompleteList.mousedown(function(e){
        _self.target = $(e.target);
        if(_self.target.hasClass('ui-menu-item') || _self.target.parents().hasClass('ui-menu-item')){ 
          _self.hideSuggestionsFlag = true;
          _self.blankLocationInputFlag = false;
          _self.hideSuggestions();
        } else if(_self.target.hasClass('t-autosuggest-msg') || _self.target.hasClass('t-category-header')){
           _self.hideSuggestionsFlag = false;
        }
      });

      _self.$input.attr('data-count', _self.listCount);
      _self.suggestionListShow = _self.listCount > 0;

      //ask narrator to speak the options
      if (_self.$autoCompleteList.find('.ui-state-hover').length) {
        narrate = _self.$autoCompleteList.find('.ui-state-hover').text();
        _self.$narrate.text( narrate );
      }

      var autoCompleteListHeight = _self.$el.find('.ui-autocomplete').height(),
          autoCompleteMaxHeight = _self.$scrollbarWrapper.height(),
          isDefaultList = true;

      //handle autocomplete list with options and without options
      if (autoCompleteListHeight > autoCompleteMaxHeight) {
        isDefaultList = false;
        //to add border on autocomplete wrapper
        _self.autoCompleteStyle(isDefaultList);
      }else{
        isDefaultList = true;
        //to remove border from autocomplete wrapper        
        _self.autoCompleteStyle(isDefaultList);
      }

    } else if (!_self.listCount && !_self.listHtml && _self.suggestionListShow) {
      _self.hideSuggestions(null, true);
    }
  },
  autoSuggestMobileFix: function(revert) {
    var _self = this;
    var $searchFormContainer = _self.$parent.$el.find('.l-form-container');
    if (!$searchFormContainer.length) {
      $searchFormContainer = _self.$parent.$el.find('.m-search-tabs');
    }
    if( navigator.userAgent.length && /iPhone|iPad|iPod/i.test( navigator.userAgent ) && !revert && (this.$parent.responsiveUtils && this.$parent.responsiveUtils.isMobileOrTablet())) {
      _self.realHeight = _self.$parent.$el.height();
      _self.fixHeight = _self.$autoCompleteList.height() + 600 + "px";
      
      $searchFormContainer.css("height", _self.fixHeight);
      _self.$parent.$el.find('.single-search').on('focus', function() {
        if(_self.$parent.responsiveUtils.isMobileOrTablet()) {
          $searchFormContainer.css("height", _self.realHeight + 'px');
        }
      });
      _self.$parent.$el.find('.single-search').on('blur', function() {
        if(_self.$parent.responsiveUtils.isMobileOrTablet()) {
          $searchFormContainer.css("height", _self.realHeight + 'px');
        }
      });
    }
    if (revert) {
      $searchFormContainer.css("height", 'auto');
    }
  },
  /**
  * This function generates autocomplete list as per API response.
  * @return null
  **/
  parseResult: function(searchTerm, inputTarget, data) {
    var _self = this;
    var listHtml = '';
    _self.listHtml = '';

    // For cluster autosuggest - this identifier is used 
    if(_self.options.clusterSearchHandler){
      if((Object.keys(data)).length > 0){
        listHtml = _self.filterClusterSearchData(data);
      }else{
        _self.$el.find('input[name="hiddenClusterCode"]').val('');
      }
    }
    else{
      if( _self.options.searchTypeVal === _self.options.addressSearch ) {
        if( data) {
          listHtml = _self.filterAddressSearchData(data);
        }
      } else {
        listHtml = _self.filterLocationSearchData(data);
      }
    }
    
    _self.listHtml += listHtml;
    _self.showList (inputTarget, true);
  },
    /**
  * This function creates autocomplete list out of address search response.
  * @return Html for list
  **/
  filterAddressSearchData: function(data) {
    var address_components, countryRegionObject, routeObject, cityObject, stateObject, countryRegion, city, stateCode, route;
    var listHtml = '';
    var _self = this;
    var count = 0;
    var autocompleteData;

    _.each(data, function(suggestion) {
      address_components = suggestion.data.address_components;
      countryRegionObject = _.filter(address_components, function(o){return _.includes(o.types, 'country')});
      routeObject = _.filter(address_components, function(o){return _.includes(o.types, 'route')});
      cityObject = _.filter(address_components, function(o){return _.includes(o.types, 'locality')});
      stateObject = _.filter(address_components, function(o){return _.includes(o.types, 'administrative_area_level_1')});
      countryRegion = '';
      city = '';
      stateCode = '';
      route = '';
      if (countryRegionObject.length) {
        countryRegion = countryRegionObject[0].long_name;
        if(countryRegion == 'United States'){
          countryRegion = countryRegionObject[0].short_name;
        }
      }
      if(routeObject.length) {
        route = routeObject[0].long_name;
      }
      if (cityObject.length) {
        city = cityObject[0].long_name;
      }
      if (stateObject.length) {
        stateCode = stateObject[0].short_name;
      }

      if( _self.options.maxCategory > count ) {
        autocompleteData = {
          type: 'NA',
          label: suggestion.label,
          Route: route,
          City: city,
          StateCode: stateCode,
          Country: countryRegion,
          GeoCode: ( ( suggestion.data.geometry.location.lat() + ',' + suggestion.data.geometry.location.lng() ) || ''),
          index: count
        };

        listHtml += _self.markSearchTerm(_self.recentSearchTerm, _self.templates.autoOutComplete(autocompleteData));
        count++;
      }
    });
    _self.listCount += count;
    return listHtml;
  },
    /**
  * This function creates autocomplete list out of location search response.
  * @return Html for list
  **/
  filterLocationSearchData: function(data) {
    var _self = this;
    var resultType, autocompleteData;
    var listHtml = '';
    var categoryItems = 0;

    if(!data.suggestionList) {
      _self.suggestGeoLoc();
    }
    var count = _self.suggestRecent(_self.recentSearchTerm);
    _.each(data.suggestionList, function(suggestions, suggestionType) {
      autocompleteData = _.extend({ index: count}, _self.templateSettings.imports);
      autocompleteData.type - suggestionType;
      if(resultType !== suggestionType) {
        resultType = suggestionType;
        if(suggestions) {
          listHtml += _self.templates.autoListHeader({
                        firstClass: count == 0 ? 'first' : '',
                        name: resultType.toLowerCase(),
                        category: _self.options.categoryParams[resultType.toLowerCase()]
                      });
        }
        categoryItems = 0;
      }
      if( _self.options.maxCategory > categoryItems ) {
        _.each(suggestions, function(suggestionObject) {
          _.each(suggestionObject, function(value, key){
            if(key === 'searchType') {
              autocompleteData['type'] = value;
            }else {
              autocompleteData[key.toLowerCase()] = value;
            }
          });
          autocompleteData['index'] = count;
          autocompleteData['searchterm'] = _self.recentSearchTerm;
          autocompleteData['propertyCodeCheckFlag'] = _self.propertyCodeCheckFlag;
          listHtml += _self.markSearchTerm( _self.recentSearchTerm, _self.templates.autoComplete(autocompleteData) );
          count++;
        });
      }
      categoryItems++;
    });

    _self.listCount = count;
    return listHtml;
  },
  
  /** 
   * This function is specifically for cluster
   * @return list of options 
   **/
  filterClusterSearchData: function(data) {
      var _self = this;
      var tabValue = this.$parent.$el.find('.js-searchtype').val();
      var resultType, autocompleteData;
      var listHtml = '';
      var categoryItems = 0;
      var count = _self.suggestRecent(_self.recentSearchTerm);
      _.each(data.clusters, function(suggestions) {
        autocompleteData = _.extend({ index: count}, _self.templateSettings.imports);
          categoryItems = 0;
        if( _self.options.maxCategory > categoryItems ) {
            _.each(suggestions, function(value, key){
                autocompleteData[key.toLowerCase()] = value;
                if(tabValue === 'InHotelSearch'){
                  autocompleteData['type']='property';
                }
            });
            autocompleteData['index'] = count;
            autocompleteData['searchterm'] = _self.recentSearchTerm;
            listHtml += _self.markSearchTerm( _self.recentSearchTerm, _self.templates.autoCompleteCluster(autocompleteData) );
            count++;
        }
        categoryItems++;
      });

      _self.listCount = count;
      return listHtml;
    },
  /**
  * This function handles click on an item selected through autocomplete list.
  * @return null
  **/
  listItemSelect: function(evt) {
    var _self = this;
    _self.blankLocationInputFlag = false;
    var $selectedItem = _self.$autoCompleteList.find('.ui-state-hover'),
      geoCode = $selectedItem.attr('data-geo');
    // Won't set location field value if selected item is not available
    if (!$selectedItem.length) {
      _self.hideSuggestions();
      return;
    }
    if(_self.options.clusterSearchHandler){
      var inputMap={
        clusterCode: $selectedItem.attr('data-code'),
        clusterDesc: $selectedItem.attr('data-desc')
      };
      _self.$input.val(_self.recentSearchTerm = (_self.templateSettings.imports.mergeItem(", ", _self.templateSettings.imports.clusterFormat(inputMap.clusterCode , inputMap.clusterDesc))));
      if (_self.onListItemSelect) {
        _self.onListItemSelect(inputMap);
      }
      return;
    }
    if ($selectedItem.hasClass('js-geoloc')) {
      _self.geoLocDetails();
    }
    geoCode = (geoCode && geoCode.split(',')) || ['', ''];
    var inputMap = {
      latitude: geoCode[0],
      longitude: geoCode[1],
      route: $selectedItem.attr('data-route'),
      cityPop: $selectedItem.attr('data-city-population'),
      cityPopDensity: $selectedItem.attr('data-city-population-density'),
      city: $selectedItem.attr('data-city'),
      state: $selectedItem.attr('data-state'),
      countryname: $selectedItem.attr('data-country'),
      country: $selectedItem.attr('data-countrycode'),
      poiname: $selectedItem.attr('data-poi-name'),
      airport: $selectedItem.attr('data-airport-code'),
      airportName: $selectedItem.attr('data-airport-name'),
      singleSearchAuto: null,
      autoSuggestOmni: $selectedItem.attr('data-type'),
      stateProvince: $selectedItem.attr('data-state-province'),
      propertyName: $selectedItem.attr('data-property-name'),
      propertyCode: $selectedItem.attr('data-property-code'),
      searchType: $selectedItem.attr('data-searchtype')
    },
    airportCode = _self.templateSettings.imports.airportFormat(inputMap.airportName, inputMap.airport, inputMap.city);

    if(inputMap.autoSuggestOmni) {
      inputMap.singleSearchAuto = 'true';
    } else {
      inputMap.singleSearchAuto = 'false';
      inputMap.singleSearchAuto = 'Unmatched';
    }
 
    if (airportCode) {
        _self.$input.val(_self.recentSearchTerm = (_self.templateSettings.imports.mergeItem(', ', airportCode || inputMap.poiname || inputMap.route, inputMap.state, inputMap.countryname)));
    } else if(!!inputMap.clusterCode) {
      _self.$input.val(_self.recentSearchTerm = (_self.templateSettings.imports.mergeItem(", ", _self.templateSettings.imports.clusterFormat(inputMap.clusterCode , inputMap.clusterDesc))));
    } else if (inputMap.autoSuggestOmni === 'poi') {
        _self.$input.val(_self.recentSearchTerm = (_self.templateSettings.imports.mergeItem(", ", _self.templateSettings.imports.poiFormat(inputMap.poiname, inputMap.city, inputMap.state, inputMap.countryname, _self.recentSearchTerm))));
    } else if (inputMap.autoSuggestOmni === 'property') {
        _self.$input.val(_self.recentSearchTerm = (_self.templateSettings.imports.mergeItem(', ', _self.templateSettings.imports.propertyFormat(inputMap.propertyName, inputMap.city, inputMap.state, inputMap.countryname, inputMap.propertyCode , _self.propertyCodeCheckFlag))));
    } else {
        _self.$input.val(_self.recentSearchTerm = (_self.templateSettings.imports.mergeItem(', ', airportCode || inputMap.poiname || inputMap.route, inputMap.city, inputMap.state, inputMap.countryname)));
    }
    
    inputMap.city = ( inputMap.airport || inputMap.city);
    _self.retainLatitudeLongitudeAttributes(inputMap);
    
    _.forEach(inputMap, function(value, name) {
      _self.$parent.$el.find(_self.options.inputs[name]).val(value || '');
    }, _self);

    if (_self.options.searchTypeVal === _self.options.addressSearch) {
      _self.recentSearchTerm = $selectedItem.attr('data-label');
      _self.$el.find('.single-search-location').val($selectedItem.attr('data-label'));
    }

    if (inputMap.searchType === 'recent') {
      _self.recentSearchTerm = $selectedItem.attr('data-label');
      _self.$el.find('.single-search-destination').val($selectedItem.attr('data-label'));
    }

    _self.hideSuggestions();

    //Added Empower check
    if(_self.$el.data("associate-view") === "true" || _self.$el.data("associate-view") === true) {
      console.log("Empower Code")
      setTimeout(function(){
        if (_self.onListItemSelect) {
          _self.onListItemSelect(inputMap);
        }
      });
    } else {
      if (_self.onListItemSelect) {
        _self.onListItemSelect(inputMap);
      }
    }
    

    if(_self.isDesktopVersion()) {
      setTimeout(function() {
        _self.$input.focus();
      });
    }
  },
  /**
  * This function handles geo location details when the protocol is http.
  * @return null
  **/
  geoLocDetails: function() {
    var _self = this;
    var protocol = 'https:';
    if (window.location.protocol !== "https:") {
      Cookies.createCookie('loadCurrentLocation', true);
      window.location.replace(protocol + '//' + window.location.host + window.location.pathname);
    } else {
      _self.geoLocationHandler.doLocalDetails();
    }
  },
  /**
  * This function highlights current option on hover in autocomplete list.
  * @return null
  **/
  highLightListItem: function(evt) {
    var _self = this;
    if ( evt.target ) {
      evt.stopImmediatePropagation();

      _self.$autoCompleteList.find('.ui-state-hover').removeClass('ui-state-hover');
      if(!$(evt.target).hasClass('t-category-header')) {
        var $thisTarget = (evt.target.nodeName == "A")? $(evt.target): $(evt.target).parent();
        $thisTarget.addClass('ui-state-hover');
      }
    }
  },
  /**
  * This function used to return the value to desktop version true/false
  * @return boolean value
  **/
  isDesktopVersion: function(){
    return !this.$parent.responsiveUtils.isMobileOrTablet();
  },
  /**
  * This function decides whether or not to add border on autocomplete list.
  * @return null
  **/
  autoCompleteStyle: function(isDefaultList) {
    var _self = this;

    if(_self.isDesktopVersion()) {
      var _self = this;

      if (isDefaultList) {
        //to remove border from autocomplete wrapper
        _self.$el.find('.ui-autocomplete').removeClass('autocomplete-noborder');
        _self.$el.find(_self.options.scrollbarWrapper).removeClass('autocomplete-border');        
      }else{
        //to add border on autocomplete wrapper
        _self.$el.find(_self.options.scrollbarWrapper).addClass('autocomplete-border');
        _self.$el.find('.ui-autocomplete').addClass('autocomplete-noborder');
      }
    }    
  },
  /**
  * This function decides whether or not to show current location option in autocomplete list.
  * @return null
  **/
  suggestGeoLoc:function() {
    var _self =  this;
    if (!_self.$parent.hideCurrentLocation) {
      _self.listHtml = _self.geoLocationHandler.autoSuggestItem;
      if(_self.listHtml === '') {
        _self.listCount = 0;
      }else{
        _self.listCount = 1;
      }
    }
  },
  /**
  * This function highlights current search term in the resulted autocomplete list.
  * @return null
  **/
  markSearchTerm: function(searchTerm, nodeText) {
    if(searchTerm) {
      var searchTermRegx = new RegExp(searchTerm, 'ig');
      return nodeText.replace(/>([^><]+)<\//i, function(label) {
        return label.replace(searchTermRegx, function(match) {
          return '<strong>' + match + '</strong>';
        })
      });
    }else{
      return nodeText;
    }
  },
  /**
  * This function is called when user navigates the list up and down with keyboard.
  * @return null
  **/
  navigateList: function(evt) {
    var _self = this;
    if(!_self.$input.length) {
      _self.setLocationInput();
    }
    if(_self.suggestionListShow) {
      var keyCode = evt.keyCode || evt.which;
      var dataCount = parseInt(evt.target.getAttribute('data-count'));

      /**
      * Keycodes
      *  38 - Up
      *  40 - Down
      *  37 - Left
      *  39 - Right
      *  13 - Enter
      *  27 - Esc
      *  9 - Tab
      *  8 - Backspace
      **/
      switch(keyCode) {
        case 38:
        case 40:
          evt.preventDefault();
          var $selectedItem = _self.$autoCompleteList.find('.ui-state-hover');
          var dataIndex = $selectedItem.length && $selectedItem.attr('data-index') || -1;
          //For first element in list
          if(parseInt(dataIndex) === 0) {
            if(!_self.initialSearchTerm) {
              _self.initialSearchTerm = _self.$input.val();
            }
            if (keyCode === 38) {
              _self.recentSearchTerm = _self.initialSearchTerm;
              _self.$input.val(_self.recentSearchTerm);
              $selectedItem.removeClass('ui-state-hover');
              return;
            }
          }

          //For last element in list
          if (((parseInt(dataCount) - parseInt(dataIndex)) === 1) && keyCode === 40){
            _self.recentSearchTerm = _self.initialSearchTerm;
            _self.$input.val(_self.recentSearchTerm);
            $selectedItem.removeClass('ui-state-hover');
            return;
          }

          if( isFinite(dataIndex = parseInt(dataIndex)) ) {

            $selectedItem.removeClass('ui-state-hover');
            dataIndex = dataIndex + (keyCode - 39);

            if (dataIndex >= dataCount) {
              dataIndex -= dataCount;
            } else if( dataIndex < 0 ) {
              dataIndex = Math.max(-1, dataIndex) + dataCount;
            }

            $selectedItem = _self.$autoCompleteList.find('[data-index="' + dataIndex + '"]');
            $selectedItem.addClass('ui-state-hover');
          }

          var airportCode = $selectedItem.attr('data-airport-code');
          var city = $selectedItem.attr('data-city');
          var state = $selectedItem.attr('data-state');
          var country = $selectedItem.attr('data-country');
          var poiName = $selectedItem.attr('data-poi-name');          

          if(_self.options.searchTypeVal === _self.options.addressSearch || $selectedItem.attr('data-searchtype') === 'recent') {
            _self.recentSearchTerm = $selectedItem.attr('data-label');
          } else {
            _self.recentSearchTerm = ($selectedItem.attr('data-property-name') || airportCode || _self.templateSettings.imports.mergeItem(', ', poiName || city, state, country));
          }

          if ($selectedItem.hasClass('js-geoloc')) {
            _self.recentSearchTerm = '';
          }

          if ( $selectedItem.hasClass('js-geoloc') ) {
            _self.$narrate.text( $selectedItem.text() );
          };

          _self.$input.val( _self.recentSearchTerm );

          /** scroller with keyboard up and down keys*/
          if(_self.isDesktopVersion()){
            _self.$scrollbarWrapper.scrollTop(0);//set to top
            var selectedItemOffset = $selectedItem.parent('li').next().offset().top;
            _self.$scrollbarWrapper.scrollTop(selectedItemOffset - _self.$scrollbarWrapper.height()*2);            
          }

          break;
        case 37:
        case 39:
          _self.recentSearchTerm = _self.initialSearchTerm;
          break;
        case 13:
          if(_self.$autoCompleteList.find('.ui-state-hover').length) {
            evt.preventDefault();
            _self.listItemSelect(evt);
          }
          break;
        case 27:
          _self.hideSuggestions(evt);
          evt.preventDefault();
          break;
        case 9:
          _self.listItemSelect(evt);
          break;
        default:
          break;
      }
    }
  },
  /**
  * This function is utility Function to merge the given inputs
  * @return null
  **/
  merge: function(list, seperator) {
    var result = '', idx = 0;
    for(idx = 0; idx < list.length; idx++) {
      result + (list[idx] && (list[idx] + seperator)) || ''
    }
  },
  /**
  * This function loads recent search list from browsers local storage
  * @return null
  **/
  loadList: function(){
    if ( localStorage.default.miRecentSearch ) {
      this.recentSearch.list = JSON.parse( localStorage.default.miRecentSearch.replace(/<\/?[^>]+(>|$)/g, "") );
    }
  },
  /**
  * This function is utility Function
  * @return null
  **/
  getAutocompleteItem: function(filterChars,$formField){
    if ( $formField.hasClass(this.recentSearch.inputClass) ) {
      this.loadList();
      if ( this.recentSearch.list.length > 0 ) {
        /*if filterChars is supplied, filter the list.labels for lowercase of filterchars (searchterm)*/
        if (filterChars) {
          return $.grep( this.recentSearch.list , function( o, i ) {
                // return the items that match the starting of words
                return RegExp("\\b" + filterChars.toLowerCase(), "gi").test(o.label);
              });
        } else {
          return this.recentSearch.list;
        }
      }
    }
  },
  /**
  * This function is utility Function
  * @return null
  **/
  indexOf: function( key, value ){
    var list = this.recentSearch.list;
    var max = list.length;
    var result = -1;
    for (var i = 0; i < max; i++) {
      if(list[i][key] == value) {
        result = i;
        break;
      }
    }
    return result;
  },
  /**
  * This function adds recent search list to browsers local storage
  * @return null
  **/
  addRecent: function(){
    var _self = this;
    if(this.permittedSiteId() && localStorage.default && this.options.searchTypeVal !== _self.options.addressSearch) {
      var newItem = {};
      var editSearchForm = this.$parent.$el.find('.js-recent-search-inputs');
      var countryName, labelArray, airportname;
      var nearText = this.$parent.$el.find('.for-hotels-nearme').val() + ' ';

      newItem.label = editSearchForm.find('.js-search-location').val();
      newItem.city = editSearchForm.find('.js-search-city-display').val() || editSearchForm.find('.search-city').val();
      newItem.poiname = editSearchForm.find('.search-poiname').val();
      newItem.state = editSearchForm.find('.search-state').val();

      newItem.country = editSearchForm.find('.search-country').val();
      newItem.stateprovincedisplayname = editSearchForm.find('.search-state-province').val();
      newItem.type = editSearchForm.find('.js-autosuggest-item-type').val();
      countryName = editSearchForm.find('.search-country-name').val();
      if(countryName) {
        newItem.countryname = countryName;
      }else {
        labelArray = newItem.label ? newItem.label.split(',') : [];
        if(labelArray.length) {
          newItem.countryname = labelArray[(labelArray.length - 1)];
        }
      }

      newItem.airportcode = editSearchForm.find('.search-airport').val();
      if (newItem.airportcode) {
        airportname = editSearchForm.find('.search-airport-name').val();
        if(airportname) {
          newItem.airportname = airportname;
        }else {
          labelArray = newItem.label ? newItem.label.split(',') : [];
          if(labelArray.length) {
            newItem.airportname = labelArray[0];
          }
        }
      }

      newItem.value = newItem.airportcode || newItem.label;
      newItem.geocode = editSearchForm.find('.search-latitude').val() + ',' +  editSearchForm.find('.search-longitude').val();
      newItem.searchtype = 'recent';
      newItem.analytics = '{"location":"searchForm","sendNow":"true","description":"Recent Search"}';
      newItem.label = newItem.label && newItem.label.replace(/<(?:.|\n)*?>/gm, '');

      this.loadList();

      if ( newItem.label && newItem.label.indexOf(nearText)<0) {

        var indexOfExistingValue = this.indexOf( 'label', newItem.label );

        if ( indexOfExistingValue != -1 ) {
          //remove the existing entry of same search
          this.recentSearch.list.splice ( indexOfExistingValue, 1 );
        }

        this.recentSearch.list.unshift( newItem );
        this.recentSearch.list.splice( this.recentSearch.max );
        localStorage.default.setItem('miRecentSearch', JSON.stringify( this.recentSearch.list) );
      }
    }
  },
  /**
  * This function is used to clear recent searches
  * @return null
  **/
  clear:function(){
    localStorage.default.removeItem('miRecentSearch');
    localStorage.default.removeItem('recentSearch');
    this.recentSearch.list=[];
  },

  handleDocumentClick: function(event) {
    var _self = this;
    if (_self.$autoCompleteList.css('display') === 'block' &&
     !_self.$autoCompleteList.get(0).contains(event.target) &&
      !$(event.target).hasClass('js-auto-complete-input')) {
      _self.hideSuggestionsFlag = true;
      _self.$autoCompleteList.css("display", "none");
    }
  }
};


module.exports = {
  create: function(props) {
    var obj = _.cloneDeep(properties);
    var AutocompleteInstance = ComponentMapper.extend(obj);
    return new AutocompleteInstance(props);
  }
}
