var $ = require('jquery');
var _ = require('lodash');
var Popup = require('libs/popup');
var Constants = require('libs/constants');








function FormatPicker($el) { 
  var test = "1234";
  var _self = this;
  this.$el = $el;
  var datePickerOptions = _self.$el.find('.js-date-pick-container').data('options');




  _self.monthList = datePickerOptions.monthFullList + "," + datePickerOptions.monthShortList;
  
  _self.weekdayList = datePickerOptions.weekdayFullList + "," + datePickerOptions.weekdayShortList;
}




FormatPicker.prototype.formatDate = function (date, format) {
  var _self = this;
  format = format + "";
  var MONTH_NAMES = _self.monthList.split(',');
  var DAY_NAMES = _self.weekdayList.split(',');
  function LZ(x) { return (x < 0 || x > 9 ? "" : "0") + x }




  var result = "";
  var i_format = 0;
  var c = "";
  var token = "";
  var y = date.getYear() + "";
  var M = date.getMonth() + 1;
  var d = date.getDate();
  var E = date.getDay();
  var H = date.getHours();
  var m = date.getMinutes();
  var s = date.getSeconds();
  var yyyy, yy, MMM, MM, dd, hh, h, mm, ss, ampm, HH, H, KK, K, kk, k;
  // Convert real date parts into formatted versions
  var value = new Object();
  if (y.length < 4) { y = "" + (y - 0 + 1900); }
  value["y"] = "" + y;
  value["yyyy"] = y;
  value['tttt'] = y + 543;
  value["yy"] = y.substring(2, 4);
  value["M"] = M;
  value["MM"] = LZ(M);
  value["MMM"] = MONTH_NAMES[M - 1];
  value["NNN"] = MONTH_NAMES[M + 11];
  value["d"] = d;
  value["dd"] = LZ(d);
  value["E"] = DAY_NAMES[E + 7];
  value["EE"] = DAY_NAMES[E];
  value["H"] = H;
  value["HH"] = LZ(H);
  if (H == 0) { value["h"] = 12; }
  else if (H > 12) { value["h"] = H - 12; }
  else { value["h"] = H; }
  value["hh"] = LZ(value["h"]);
  if (H > 11) { value["K"] = H - 12; } else { value["K"] = H; }
  value["k"] = H + 1;
  value["KK"] = LZ(value["K"]);
  value["kk"] = LZ(value["k"]);
  if (H > 11) { value["a"] = "PM"; }
  else { value["a"] = "AM"; }
  value["m"] = m;
  value["mm"] = LZ(m);
  value["s"] = s;
  value["ss"] = LZ(s);
  while (i_format < format.length) {
    c = format.charAt(i_format);
    token = "";
    while ((format.charAt(i_format) == c) && (i_format < format.length)) {
      token += format.charAt(i_format++);
    }
    if (value[token] != null) { result = result + value[token]; }
    else { result = result + token; }
  }
  return result;




};




FormatPicker.prototype.getDateFromFormat = function(val,format) {
        val=val+"";
        format=format+"";
        var MONTH_NAMES = this.monthList.split(',');
        var DAY_NAMES = this.weekdayList.split(',');
        var i_val=0;
        var i_format=0;
        var c="";
        var token="";
        var token2="";
        var x,y;
        var now=new Date();
        var year=now.getFullYear();
        var month=now.getMonth()+1;
        var date=1;
        var hh=now.getHours();
        var mm=now.getMinutes();
        var ss=now.getSeconds();
        var ampm="";
        function _getInt(str,i,minlength,maxlength) {
    for (var x=maxlength; x>=minlength; x--) {
      var token=str.substring(i,i+x);
      if (token.length < minlength) { return null; }
      if (_isInteger(token)) { return token; }
      }
    return null;
    }
    function _isInteger(val) {
      var digits="1234567890";
      for (var i=0; i < val.length; i++) {
        if (digits.indexOf(val.charAt(i))==-1) { return false; }
        }
      return true;
      }
        while (i_format < format.length) {
                // Get next token from format string
                c=format.charAt(i_format);
                token="";
                while ((format.charAt(i_format)==c) && (i_format < format.length)) {
                        token += format.charAt(i_format++);
                        }
                // Extract contents of value based on format token
                if (token=="yyyy" || token=="yy" || token=="y") {
                        if (token=="yyyy") { x=4;y=4; }
                        if (token=="yy")   { x=2;y=2; }
                        if (token=="y")    { x=2;y=4; }
                        year=_getInt(val,i_val,x,y);
                        if (year==null) { return 0; }
                        i_val += year.length;
                        if (year.length==2) {
                                if (year > 70) { year=1900+(year-0); }
                                else { year=2000+(year-0); }
                                }
                        }
                else if (token=="tttt"){
                    year=_getInt(val,i_val,4,4);
                    if (year==null) { return 0; }
                    year=year-543;
                    i_val += year.length;
                }
                else if (token=="MMM"||token=="NNN"){
                        month=0;
                        for (var i=0; i<MONTH_NAMES.length; i++) {
                                var month_name=MONTH_NAMES[i];
                                if (val.substring(i_val,i_val+month_name.length).toLowerCase()==month_name.toLowerCase()) {
                                        if (token=="MMM"||(token=="NNN"&&i>11)) {
                                                month=i+1;
                                                if (month>12) { month -= 12; }
                                                i_val += month_name.length;
                                                break;
                                                }
                                        }
                                }
                        if ((month < 1)||(month>12)){return 0;}
                        }
                else if (token=="EE"||token=="E"){
                        for (var i=0; i<DAY_NAMES.length; i++) {
                                var day_name=DAY_NAMES[i];
                                if (val.substring(i_val,i_val+day_name.length).toLowerCase()==day_name.toLowerCase()) {
                                        i_val += day_name.length;
                                        break;
                                        }
                                }
                        }
                else if (token=="MM"||token=="M") {
                        month=_getInt(val,i_val,token.length,2);
                        if(month==null||(month<1)||(month>12)){return 0;}
                        i_val+=month.length;}
                else if (token=="dd"||token=="d") {
                        date=_getInt(val,i_val,token.length,2);
                        if(date==null||(date<1)||(date>31)){return 0;}
                        i_val+=date.length;}
                else if (token=="hh"||token=="h") {
                        hh=_getInt(val,i_val,token.length,2);
                        if(hh==null||(hh<1)||(hh>12)){return 0;}
                        i_val+=hh.length;}
                else if (token=="HH"||token=="H") {
                        hh=_getInt(val,i_val,token.length,2);
                        if(hh==null||(hh<0)||(hh>23)){return 0;}
                        i_val+=hh.length;}
                else if (token=="KK"||token=="K") {
                        hh=_getInt(val,i_val,token.length,2);
                        if(hh==null||(hh<0)||(hh>11)){return 0;}
                        i_val+=hh.length;}
                else if (token=="kk"||token=="k") {
                        hh=_getInt(val,i_val,token.length,2);
                        if(hh==null||(hh<1)||(hh>24)){return 0;}
                        i_val+=hh.length;hh--;}
                else if (token=="mm"||token=="m") {
                        mm=_getInt(val,i_val,token.length,2);
                        if(mm==null||(mm<0)||(mm>59)){return 0;}
                        i_val+=mm.length;}
                else if (token=="ss"||token=="s") {
                        ss=_getInt(val,i_val,token.length,2);
                        if(ss==null||(ss<0)||(ss>59)){return 0;}
                        i_val+=ss.length;}
                else if (token=="a") {
                        if (val.substring(i_val,i_val+2).toLowerCase()=="am") {ampm="AM";}
                        else if (val.substring(i_val,i_val+2).toLowerCase()=="pm") {ampm="PM";}
                        else {return 0;}
                        i_val+=2;}
                else {
                        if (val.substring(i_val,i_val+token.length)!=token) {return 0;}
                        else {i_val+=token.length;}
                        }
                }
        // If there are any trailing characters left in the value, it doesn't match
        if (i_val != val.length) { return 0; }
        // Is date valid for month?
        if (month==2) {
                // Check for leap year
                if ( ( (year%4==0)&&(year%100 != 0) ) || (year%400==0) ) { // leap year
                        if (date > 29){ return 0; }
                        }
                else { if (date > 28) { return 0; } }
                }
        if ((month==4)||(month==6)||(month==9)||(month==11)) {
                if (date > 30) { return 0; }
                }
        // Correct hours value
        if (hh<12 && ampm=="PM") { hh=hh-0+12; }
        else if (hh>11 && ampm=="AM") { hh-=12; }
        var newdate=new Date(year,month-1,date,hh,mm,ss);
        return newdate.getTime();
        };


FormatPicker.prototype.getDateFromMultipleLocales = function(strDate, locationObj) {
    
  // Validate if user enters date without the year
  var rfc2822WithoutYearRegEx = /^([\D]*)([\d]{1,2})\s*([\S]*)\s*$/;
 	var tempStrDate = strDate;
  // If RFC2822 date format is entered without year then add current year
  if(rfc2822WithoutYearRegEx.test(strDate)) {
    tempStrDate = tempStrDate + ' ' + new Date().getFullYear();
  }
  var parsedDate = new Date(tempStrDate);
  if( isNaN(parsedDate) ) {
    parsedDate = this.getDateFromFormat(strDate, locationObj.format);
    parsedDate = parsedDate || this.getDateFromFormat(strDate, locationObj.formatShort);
    parsedDate = parsedDate || this.getDateFromFormat(strDate, locationObj.formatNoDay);
    parsedDate = parsedDate || this.getDateFromFormat(strDate, locationObj.formatShortNoDay);
    parsedDate = parsedDate || this.getDateFromFormat(strDate, locationObj.formatShortNoDay);
    parsedDate = parsedDate === 0 ? undefined : new Date(parsedDate);
  }
  return isNaN(parsedDate) === parsedDate ? undefined : parsedDate;
};


FormatPicker.prototype.localeObj = function(lang){
  var pickerLang = {
    'DE': {
      labelMonthPrev: 'zurück',
      labelMonthNext: 'Vor',
      firstDay: 0,
      format: 'E, d NNN, yyyy',
      formatShort: 'E, d NNN',
      formatNoDay: 'd NNN, yyyy',
      formatShortNoDay: 'd NNN',
      formatSubmit: 'dd.MM.yyyy',
      formatCalc: 'MM/dd/yyyy'
    },
    'AU': {
      labelMonthPrev: 'Previous month',
      labelMonthNext: 'Next month',
      firstDay: 0,
      format: 'E, d NNN, yyyy',
      formatShort: 'E, d NNN',
      formatNoDay: 'd NNN, yyyy',
      formatShortNoDay: 'd NNN',
      formatSubmit: 'dd/MM/yyyy',
      formatCalc: 'MM/dd/yyyy'
    },
    'GB': {
      labelMonthPrev: 'Previous month',
      labelMonthNext: 'Next month',
      firstDay: 0,
      format: 'E, d NNN, yyyy', //ddd. d. mmm, yyyy
      formatShort: 'E, d NNN', 
      formatNoDay: 'd NNN, yyyy',
      formatShortNoDay: 'd NNN', 
      formatSubmit: 'dd/MM/yyyy',
      formatCalc: 'MM/dd/yyyy'
    },
    'US': {
      labelMonthPrev: 'Previous month',
      labelMonthNext: 'Next month',
      format: 'E, NNN d, yyyy',
      formatShort: 'E, NNN d',
      formatNoDay: 'NNN d, yyyy',
      formatShortNoDay: 'NNN d',
      formatSubmit: 'MM/dd/yyyy',
      formatCalc: 'MM/dd/yyyy',
      firstDay: 1
    },
    'FR': {
      labelMonthPrev: 'Précédent',
      labelMonthNext: 'Suivant',
      firstDay: 0,
      format: 'E d NNN yyyy',
      formatShort: 'E d NNN',
      formatNoDay: 'd NNN yyyy',
      formatShortNoDay: 'd NNN',
      formatSubmit: 'dd/MM/yyyy',
      formatCalc: 'MM/dd/yyyy'
    },
    'ES': {
      labelMonthPrev: 'Ant',
      labelMonthNext: 'Sig',
      firstDay: 0,
      format: 'E, d NNN, yyyy',
      formatShort: 'E, d NNN',
      formatNoDay: 'd NNN, yyyy',
      formatShortNoDay: 'd NNN',
      formatSubmit: 'dd/MM/yyyy',
      formatCalc: 'MM/dd/yyyy'
    },
    'JP': {
      labelMonthPrev: '前',
      labelMonthNext: '次',
      firstDay: 1,
      format: 'yyyy年 NNN d日 (E)',
      formatShort: 'MMM d日 (E)',
      formatNoDay: 'yyyy年 NNN d日',
      formatShortNoDay: 'MMM d日',
      formatSubmit: 'yyyy/MM/dd',
      formatCalc: 'MM/dd/yyyy'
    },
    'CN': {
      labelMonthPrev: '上月',
      labelMonthNext: '下月',
      firstDay: 1,
      format: 'yyyy年 NNN d日, E',
      formatShort: 'NNN d日, E',
      formatNoDay: 'yyyy年 NNN d日',
      formatShortNoDay: 'NNN d日',
      formatSubmit: 'yyyy-MM-dd',
      formatCalc: 'MM/dd/yyyy',
      formatLength: 12
    },
    'BR': {
      labelMonthPrev: 'Anterior',
      labelMonthNext: 'Pr&oacute;ximo',
      firstDay: 1,
      format: 'E, d NNN, yyyy',
      formatShort: 'E, d NNN',
      formatNoDay: 'd NNN, yyyy',
      formatShortNoDay: 'd NNN',
      formatSubmit: 'dd/MM/yyyy',
      formatCalc: 'MM/dd/yyyy'
    },
    'IT': {
      labelMonthPrev: 'Mese precedente',
      labelMonthNext: 'Prossimo mese',
      firstDay: 0,
      format: 'E, d NNN, yyyy',
      formatShort: 'E, d NNN ',
      formatNoDay: 'd NNN, yyyy',
      formatShortNoDay: 'd NNN ',
      formatSubmit: 'dd/MM/yyyy',
      formatCalc: 'MM/dd/yyyy'
    },
    'KR': {
      labelMonthPrev: '이전 달',
      labelMonthNext: '다음 달',
      firstDay: 1,
      format: 'yyyy년 MM월 dd일',
      formatShort: 'NNN d일, E',
      formatNoDay: 'yyyy년 MM월 dd일',
      formatShortNoDay: 'NNN d일',
      formatSubmit: 'yyyy.MM.dd',
      formatCalc: 'MM/dd/yyyy'
    },
    'RU': {
      labelMonthPrev: 'Предыдущий месяц',
      labelMonthNext: 'В следующем месяце',
      firstDay: 0,
      format: 'E, d NNN, yyyy',
      formatShort: 'E, d NNN',
      formatNoDay: 'd NNN, yyyy',
      formatShortNoDay: 'd NNN',
      formatSubmit: 'dd.MM.yyyy',
      formatCalc: 'MM/dd/yyyy'
    },
   'TW': {
      labelMonthPrev: 'Previous month',
      labelMonthNext: 'Next month',
      format: 'yyyy年 NNN d日, E',
      formatShort: 'NNN d日, E',
      formatNoDay: 'yyyy年 NNN d日',
      formatShortNoDay: 'NNN d日',
      formatSubmit: 'MM/dd/yyyy',
      formatCalc: 'MM/dd/yyyy',
      firstDay: 0
     },
     'AE': {
      labelMonthPrev: "الشهر السابق",
      labelMonthNext: "الشهر القادم",
      firstDay: 0,  //monday
      format: "Ed, d MMM, yyyy ",
      formatShort: "E, NNN d",
      formatNoDay: "d MMM, yyyy ",
      formatShortNoDay: "NNN d",
      formatSubmit: "yyyy/MM/dd",
      formatCalc: 'MM/dd/yyyy'
    },
     'NL': {
	      labelMonthPrev: 'Previous month',
	      labelMonthNext: 'Next month',
	      format: 'E d NNN, yyyy',
	      formatShort: 'E d NNN',
	      formatNoDay: 'd NNN, yyyy',
	      formatShortNoDay: 'd NNN',
	      formatSubmit: 'dd/MM/yyyy',
	      formatCalc: 'MM/dd/yyyy',
	      firstDay: 0
	 },
    'VN': {
	      labelMonthPrev: 'Previous month',
	      labelMonthNext: 'Next month',
	      format: 'E, d NNN, yyyy',
	      formatShort: 'E, d NNN',
	      formatNoDay: 'd NNN, yyyy',
	      formatShortNoDay: 'd NNN',
	      formatSubmit: 'dd/MM/yyyy',
	      formatCalc: 'MM/dd/yyyy',
	      firstDay: 0
    },	  
    'TR': {
	      labelMonthPrev: 'Previous month',
	      labelMonthNext: 'Next month',
	      format: 'E, NNN d, yyyy',
	      formatShort: 'E, NNN d',
	      formatNoDay: 'NNN d, yyyy',
	      formatShortNoDay: 'NNN d',
	      formatSubmit: 'dd.MM.yyyy',
	      formatCalc: 'MM/dd/yyyy',
	      firstDay: 0
	 },
    'PL': {
	      labelMonthPrev: 'Previous month',
	      labelMonthNext: 'Next month',
	      format: 'E, NNN d, yyyy',
	      formatShort: 'E, NNN d',
	      formatNoDay: 'NNN d, yyyy',
	      formatShortNoDay: 'NNN d',
	      formatSubmit: 'dd.MM.yy',
	      formatCalc: 'MM/dd/yyyy',
	      firstDay: 0
    },
   'TH': {
      labelMonthPrev: 'Previous month',
      labelMonthNext: 'Next month',
      format: 'E d NNN, tttt',
      formatShort: 'E d NNN',
      formatNoDay: 'd NNN, tttt',
      formatShortNoDay: 'd NNN',
      formatSubmit: 'dd/MM/yyyy',
      formatCalc: 'MM/dd/yyyy',
      firstDay: 1
    },
  'ID': {
      labelMonthPrev: 'Previous month',
      labelMonthNext: 'Next month',
      format: 'E, d NNN, yyyy',
      formatShort: 'E, d NNN',
      formatNoDay: 'd NNN, yyyy',
      formatShortNoDay: 'd NNN',
      formatSubmit: 'dd/MM/yyyy',
      formatCalc: 'MM/dd/yyyy',
      firstDay: 1
    },
   'GR': {
      labelMonthPrev: 'Previous month',
      labelMonthNext: 'Next month',
      format: 'EE, d MMM, yyyy',
      formatShort: 'E, d NNN',
      formatNoDay: 'd MMM, yyyy',
      formatShortNoDay: 'd MMM',
      formatSubmit: 'dd/MM/yyyy',
      formatCalc: 'MM/dd/yyyy',
      firstDay: 0
    },
     'DK': {
       labelMonthPrev: 'Previous month',
       labelMonthNext: 'Next month',
       format: 'E. d. NNN yyyy',
       formatShort: 'E. d. NNN',
       formatNoDay: 'd. NNN, yyyy',
       formatShortNoDay: 'd. NNN',
       formatSubmit: 'dd/MM/y',
       formatCalc: 'MM/dd/yyyy',
       firstDay: 0
    },
   'SE': {
       labelMonthPrev: 'Previous month',
       labelMonthNext: 'Next month',
       format: 'E, d NNN yyyy',
       formatShort: 'E, d NNN',
       formatNoDay: 'd NNN, yyyy',
       formatShortNoDay: 'd NNN',
       formatSubmit: 'dd/MM/yyyy',
       formatCalc: 'MM/dd/yyyy',
       firstDay: 0
    },
   'FI': {
       labelMonthPrev: 'Previous month',
       labelMonthNext: 'Next month',
       format: 'E d. NNN yyyy',
       formatShort: 'E d. NNN',
       formatNoDay: 'd. NNN yyyy',
       formatShortNoDay: 'd. NNN',
       formatSubmit: 'd.M.y',
       formatCalc: 'MM/dd/yyyy',
       firstDay: 0
    },
   'NO': {
       labelMonthPrev: 'Previous month',
       labelMonthNext: 'Next month',
       format: 'E d. NNN yyyy',
       formatShort: 'E d. NNN',
       formatNoDay: 'd. NNN yyyy',
       formatShortNoDay: 'd .NNN',
       formatSubmit: 'dd.MM.yy',
       formatCalc: 'MM/dd/yyyy',
       firstDay: 0
    },
    'IL': {
      labelMonthPrev: 'Previous month',
      labelMonthNext: 'Next month',
      format: 'E, d NNN, yyyy',
      formatShort: 'E, d NNN',
      formatNoDay: 'NNN d, yyyy',
      formatShortNoDay: 'NNN d',
      formatSubmit: 'dd/MM/yy',
      formatCalc: 'MM/dd/yyyy',
      firstDay: 1
    }	  
  };
  return pickerLang[lang];
};




module.exports = FormatPicker;
