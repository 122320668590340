var SearchComponent = require('./search-components');
var PageTakeover = require('libs/pageTakeover');
var Constants = require('libs/constants');
var Config = require('libs/config');
var $ = require('jquery');
var _ = require('lodash');
var ScrollBarHandler = require('libs/scrollbar');

var SpecialRatesHandler = SearchComponent.extend({
  events: {
    'click .js-toggle': 'toggleSpecialRates',
    'keyup .js-toggle': 'moveFocusToNext',
    'click .js-special-rates-header': 'openDropDown',
    'click .js-h-toggle': 'codeInputToggle',
    'click .l-back-icon': 'closeSpecialRatesSection',
    'blur .radio-secondary-input': 'onBlurEvent',
    'click .done_button': 'hideTakeoverScreen',
    'click .js-apply-btn': 'closeDropDown',
    'click .js-add-btn': 'addCorporateCode',
    'focus .l-search-corp': 'toggleInputLabel',
    'focus .js-special-rates-header': 'toggleSpecialRateHeaderAriaLabel',
    'click .sub-takeover-cancel-link': 'closeSubTakeover',
    'click .js-single-special-rate-apply-btn': 'applySelectedRates'
  },
  options: {
    dropdownTrigger: '.m-icon-trigger',
    corpdropdownTrigger: '.corp-icon-trigger',
    dropdownActive: 't-icon-arrow-up',
    dropdownInActive: 't-icon-arrow-down',
    scrollBarTarget: '.special-rates-section .l-spreacial-rate-scroll-cont, .l-special-rates-list'
  },
  templates: {
    newCorpCode: _.template('<li class="l-rate-item-container"><div class="l-corp-rate js-select-rate is-checked" tabindex="2"><span class="l-special-rate-label" aria-live="polite"><i class="lb"><%=newCorpCodeValue%> <%=checkedLabel%></i></span><i class="rate-unavailable"> unavailable</i><fieldset><legend class="is-hidden"><%=newCorpCodeValue%></legend><input type="checkbox" name="rate-<%=newCorpCodeValue%>" value="<%=newCorpCodeValue%>" id="<%=newCorpCodeValue%>" class="js-corp-code js-count-rates-selected is-visibility-hidden" checked><label for="<%=newCorpCodeValue%>" class="rate-label" aria-hidden="true"><%=newCorpCodeValue%></label></fieldset></div><button class="remove-btn t-border-radius" aria-label="<%=deleteLabel%> <%=newCorpCodeValue%>" tabindex="2"><span class="js-code-label l-display-none"><%=newCorpCodeValue%></span><span class="t-icon t-icon-cross"></span></button></li>')
  },
  moveFocusToNext: function _moveFocusToNext($element, keyCode) {
    var $toFocus;
    if (!keyCode && $element.which === 40) {
      $element.preventDefault();
      $toFocus = $($element.currentTarget).next('.js-toggle-container').find('.l-rate-item-container:first-child .js-select-rate').focus();
    } else if (keyCode === 38) {
      $toFocus = $element.parents('li.l-rate-item-container').prev('.l-rate-item-container').find('.js-select-rate');
    } else if (keyCode === 40) {
      $toFocus = $element.parents('li.l-rate-item-container').next('.l-rate-item-container').find('.js-select-rate');
    }
    $toFocus && $toFocus.focus();
  },
  init: function () {
    var _self = this;
    var arrowKeysArr = [38, 40];
    var specialRatesHeader = _self.$el.find('.l-h-toggle-text');
    specialRatesHeader.html(specialRatesHeader.data('value'));
    this.subscribeDOMEvents();
    this.$dropdownIcon = this.$el.find(this.options.dropdownTrigger);
    this.$corpdropdownIcon = this.$el.find(this.options.corpdropdownTrigger);

    this.$el.find('.js-h-toggle').each(function () {
      if ($(this).find('input[type="radio"]').prop('checked')) {
        $(this).parent().addClass('is-checked');
      }
    });
    _self.$el.on('click keydown', '.js-select-rate', function(e){
      if(e.type == "click" || (e.type == "keydown" && (e.which === 13 || e.which === 32))) {
        _self.rateSelected(e);
        if(e.which === 32){
          e.preventDefault();
        }
      }else if(arrowKeysArr.includes(e.which)){
        e.preventDefault();
        _self.moveFocusToNext($(e.currentTarget), e.which);
      }
    });
    _self.$el.on('click keyup', '.remove-btn', function (e) {
      if (e.type == "click" || (e.type == "keyup" && e.which === 13)) {
        e.preventDefault();
        _self.removeCode(e);
      }
    });
    _self.$el.find('.l-search-corp').keypress(function (event) {
      _self.toggleDuplicateCorpCodeMessage();
      if (event.keyCode == 13) {
        event.preventDefault();
        _self.addCorporateCode(event);
      }
    });

    this.createTakeOverScreen(this.$el.find('.special-rates-section'), this.$el.find('.special-rates-section').data('takeover-label'), this.$parent.$el.data('done-button-label'));
    if(this.$parent.disableSubTakeover || !this.$parent.responsiveUtils.isMobileOrTablet()){
      $(document).on('click', _.debounce(this.closeSpecialRatesSection.bind(this), 250));
      this.$el.parents('.mfp-content').on('click', _.bind(this.closeSpecialRatesSection, this));

      this.$parent.$el.find('input[type!=\'hidden\'], a, div[tabindex = \'0\']').on('focusin', _.debounce(this.closeSpecialRatesSection.bind(this), 250));
    }
    this.countRatesSelected();
    this.initScroller();
  },
  createTakeOverScreen: function ($el, label, buttonLabel) {
    if (this.suppressTakeOver) {
      this.takeoverScreen = {
        isActive: false
      }
      return;
    }
    var _self = this;
    var TakeOverConfig = Config.TAKEOVER_VARIATION[this.$parent.getTakeOverVariation()];
    var takeoverVariation = this.$parent.getTakeOverVariation();
    this.takeoverScreen = PageTakeover.create({
      $el: $el,
      headerLabel: label,
      transition_speed: Constants.SEARCH_FORM.TAKE_OVER_SPEED,
      keepOverflowHidden: TakeOverConfig.KEEP_OVERFLOW_HIDDEN,
      isNotRetainDisplayAttribute: true,
      customHeader: function () {
        this.$el.prepend('<div class="page-take-over-header"><span class="back-btn"></span>' + this.headerLabel + '<a class="done_button" href="#">' + buttonLabel + '</a></div>');
        if (!_self.addCustomHeaderStyle) {
          this.$el.find('.page-take-over-header').css(Config.TAKEOVER_HEADER_STYLING);
        }
      },
      beforeShow: function () {
        var $this = _self.$el.find('.js-toggle');
        $this.addClass('is-active');
        $this.next('.js-toggle-container').slideToggle();
        if (takeoverVariation === Constants.SEARCH_FORM.VARIATIONS.CHECK_AVAILABILITY_HWS) {
          _self.$el.closest('.mfp-wrap').css('overflow', 'hidden');
        }
      },
      afterHide: function () {
        _self.$el.find('.js-toggle-container').css('display', 'none');
        _self.$el.find('.js-toggle').removeClass('is-active');
        _self.$el.find('.m-dropdown-trigger').removeClass('dropdown-is-active');
        if (takeoverVariation === Constants.SEARCH_FORM.VARIATIONS.CHECK_AVAILABILITY_HWS) {
          _self.$el.closest('.mfp-wrap').css({ 'overflow-x': 'hidden', 'overflow-y': 'scroll' });
        }
      }
    });
  },
  hideTakeoverScreen: function (e) {
    if (e) {
      e.preventDefault();
    }
    this.takeoverScreen.hide();
  },
  addTakeover: function () {
    if (this.$parent.$el.find('.l-h-toggle').hasClass('is-active') && !this.suppressTakeOver) {
      this.$el.find('.page-take-over-header').show();
      this.$el.find('.js-toggle-container').toggle();
      this.takeoverScreen.reload();
      this.$el.css('z-index', 'auto');
    }
  },
  removeTakeover: function () {
    if (this.$parent.$el.find('.l-h-toggle').hasClass('is-active') && !this.suppressTakeOver) {
      this.takeoverScreen.remove();
      this.$el.find('.special-rates-section').css({ 'padding': '0', 'height': 'auto' });
      this.$el.find('.code-container').css('padding', '0 20px 10px 20px');
      this.$el.css('z-index', '1');
      this.$el.find('.js-toggle').trigger('click');
    }
  },
  closeSpecialRatesSection: function (e) {
    var _self = this;
    var container = _self.$el;
    if (!container.is(e.target) && (container.has(e.target).length === 0 || this.$el.find(e.target).hasClass('l-back-icon'))) {
      _self.$el.find('.js-toggle-container').slideUp();
      container.find('.js-toggle').removeClass('is-active');
      _self.$dropdownIcon.removeClass(_self.options.dropdownActive).addClass(_self.options.dropdownInActive);
      _self.addLRRInHeader();
      _self.toggleDuplicateCorpCodeMessage();
      _self.toggleSpecialRateHeaderAriaLabel();
    }
  },
  applySelectedRates: function (event) {
    event && event.preventDefault() && event.stopPropagation();
    var _self = this;
    _self.closeSubTakeover(event, true);
  },
  closeSubTakeover: function (event, isCallFromApplyBtn) {
    event.stopPropagation();
    var _self = this;
    // reset clone here
    if (!isCallFromApplyBtn && _self.$parent.$clonedParentContainer) {
      _self.$el.find('.js-toggle-container').replaceWith(_self.$parent.$clonedParentContainer);
      _self.$el.find('.js-dropdown-point-checkbox').prop('checked') ? _self.$el.parents('form').find('#useMultiRateRewardsPoints').val(true) : _self.$el.parents('form').find('#useMultiRateRewardsPoints').val(false);
      _self.toggleUsePointsCheckBox(_self.$el.find('.js-dropdown-point-checkbox').parents('.js-select-rate'));
      _self.populateSpecialRateField();
      // for single rates
      if (_self.$el.find('.js-toggle-container').hasClass('single-rate-container') && _self.singleRateHintText) {
        _self.$el.find('.l-h-toggle-text').html(_self.singleRateHintText);
        _self.$parent.$el.find('.js-userewards-checkbox').prop('checked', _self.cachedUsePointState);
      }
    }
    _self.$dropdownIcon.removeClass(_self.options.dropdownActive).addClass(_self.options.dropdownInActive);
    _self.$el.find('.js-special-rates-header').removeClass('is-active');
    _self.$el.find('.js-toggle-container').hide();
    _self.$parent.resetCachedProperties(event, true);
  },
  toggleSpecialRates: function (e) {
    e.preventDefault();
    var _self = this;
    var $this = this.$el.find(e.currentTarget),
      collapsedLabel = this.$el.data('collapsedLabel');
    if (this.$parent.responsiveUtils.isMobileOrTablet() && !this.suppressTakeOver) {
      this.takeoverScreen.init();
    } else {
      $this.toggleClass('is-active');
      if ($this.hasClass('is-active')) {
        _self.$dropdownIcon.removeClass(_self.options.dropdownActive).addClass(_self.options.dropdownInActive);
        _self.$corpdropdownIcon.removeClass(_self.options.dropdownActive);
        _self.$el.find('.corp-rate-code').addClass('is-hidden');
        $this.attr('aria-label', _self.$el.data('expandedLabel'));
        _self.$el.find('.corp-dropDown-state').text(collapsedLabel);
      } else {
        _self.$dropdownIcon.addClass(_self.options.dropdownActive).removeClass(_self.options.dropdownInActive);
        $this.attr('aria-label', collapsedLabel);
      }
      // Add subtakeover if mobile / tablet device is being used
      if (_self.$parent.disableSubTakeover || !_self.$parent.responsiveUtils.isMobileOrTablet()) {
        $this.next('.js-toggle-container').slideToggle();
      } else {
        $this.next('.js-toggle-container').show();
        _self.$parent.$clonedParentContainer = $this.next('.js-toggle-container').clone();
        // For multi rate apply button, we will remove custom styling class to match invision
        $this.next('.js-toggle-container').find('button.js-apply-btn.l-submit-selection').removeClass('l-submit-selection');
        // for single rates
        if ($this.next('.js-toggle-container').hasClass('single-rate-container')) {
          _self.singleRateHintText = _self.$el.find('.l-h-toggle-text').text();
        }
        _self.cachedUsePointState = _self.$parent.$el.find('.js-userewards-checkbox').prop('checked');
        _self.$parent.expandSubTakeover(e, true);
      }
    }
    _self.addLRRInHeader();
    _self.toggleDuplicateCorpCodeMessage();
  },
  openDropDown: function (e) {
    var $this = this.$el.find(e.currentTarget);
    var _self = this;
    e.preventDefault();
    if ($this.hasClass('is-active')) {
      _self.$dropdownIcon.addClass(_self.options.dropdownActive).removeClass(_self.options.dropdownInActive);
    } else {
      _self.$dropdownIcon.removeClass(_self.options.dropdownActive).addClass(_self.options.dropdownInActive);
    }
  },
  /**
  * Accepts callback onItemClicked
  **/
  codeInputToggle: function (e) {
    var $this = $(e.currentTarget);
    var _self = this;
    var $checkbox = $this.find('input[type="radio"]');
    if ($checkbox.length > 0) {
      e.preventDefault();
      $checkbox.prop('checked', true);

      $this.parents().find('.l-h-toggle-cont .is-checked').removeClass('is-checked');
      $this.parent().addClass('is-checked');
      $this.parents().find('.l-h-toggle-cont .special-rate-code').addClass('is-hidden').hide();
      var $toggleCheckboxParent = this.$parent.$el.find('.js-h-toggle-checkbox').parents();
      $toggleCheckboxParent.find('.l-userewards-checkmark').removeClass('t-icon-checkmark');
      this.$parent.$el.find('.js-h-toggle-checkbox').prop('checked', false).attr('checked', false);

      if (this.onItemClicked) {
        this.onItemClicked($this);
      }

      if ($checkbox.val() == 'corp' || $checkbox.val() == 'group') {
        if ($checkbox.prop('checked')) {
          $this.parents(".special-rates-section").find('.' + $checkbox.val() + '-code').show().removeClass('is-hidden');
          $this.parents(".special-rates-section").find('.' + $checkbox.val() + '-code').find('input:text').focus();
        } else {
          $this.parents(".special-rates-section").find('.' + $checkbox.val() + '-code').addClass('is-hidden');
        }
      } else {
        this.closeToggle($checkbox, $checkbox.val());
        this.$el.find('.special-rate-code').addClass('is-hidden').hide();
        _.defer(function () {
          _self.$el.find('.js-special-rates-header').focus().attr('tabIndex', '0');
        });
      }
    }
    _self.initScroller();
  },
  onBlurEvent: function (e) {
    if (e) {
      if (this.$el.find(e.relatedTarget).hasClass('js-h-toggle')) {
        return false;
      }
    }
    var $checkbox = this.$el.find('.l-h-toggle-opt').find('#' + this.$parent.idPrefix + '_' + this.$el.find(e.currentTarget).attr('data-special-rate-type'));
    this.closeToggle($checkbox, $checkbox.val());
  },
  closeToggle: function ($obj, objVal, changeWithoutToggle) {
    var $toggleHeader = this.$el.find('.l-h-toggle');
    var $specialRates = this.$el.find('.special-rates-section');

    var $toggleHeaderText = $toggleHeader.find('.l-h-toggle-text');
    if (objVal == 'none') {
      $toggleHeaderText.html($toggleHeaderText.data('defaultheader'));
      this.$el.find('.js-h-toggle-checkbox').parents('ul').find('.radio-secondary-input').val('');
      if (this.$el.find('.js-h-toggle-checkbox').prop('checked', true)) {
        $toggleHeaderText.html($toggleHeaderText.data('defaultheader'));
      }

      if (!this.$parent.responsiveUtils.isMobileOrTablet()) {
        if (!changeWithoutToggle) {
          $toggleHeader.click();
        } else {
          $specialRates.hide();
          this.$el.find('.js-special-rates-header').removeClass('is-active');
        }
      }
    }
    else {
      if (objVal == 'corp' || objVal === 'group') {
        if (this.$el.find('.special-rate-code').find('input[data-special-rate-type=' + objVal + ']').val() == '') {
          if (!this.$parent.responsiveUtils.isMobileOrTablet()) {
            $obj.parents('.l-h-toggle-cont').find('.special-rate-code').hide('fast');
            var $searchClustercodeParent = this.$el.find('.search-clusterCode-none').prop('checked', true).parents('.l-h-toggle-opt');
            $searchClustercodeParent.addClass('is-checked');
            $searchClustercodeParent.parent().find('.is-checked').not($searchClustercodeParent).removeClass('is-checked');
            $toggleHeaderText.html($toggleHeaderText.data('defaultheader'));
          }
        }
        else {
          $toggleHeaderText.html(this.$el.find('.special-rate-code').find('input[data-special-rate-type=' + objVal + ']').val());
        }
      }
      else {
        $toggleHeaderText.html($obj.siblings('legend').find('.cluster-code-label').html().trim());
      }
      if (!this.$parent.responsiveUtils.isMobileOrTablet()) {
        if (objVal !== 'corp' && objVal !== 'group') {
          $toggleHeader.click();
        }
      }
    }
  },
  initScroller: function () {
    var _self = this;
    var scrollerClass;
    if (_self.$el.find('ul').hasClass('l-special-rates-list'))
      scrollerClass = 'l-multi-special-rate-max-height scrollbar-outer'
    else {
      scrollerClass = 'l-special-rate-max-height scrollbar-outer'
    }
    if (!this.scrollbar && !this.$parent.responsiveUtils.isMobileOrTablet()) {
      this.scrollbar = ScrollBarHandler.create({
        $el: this.$el,
        $scrollBarTarget: this.$el.find(_self.options.scrollBarTarget),
        $className: scrollerClass
      });
      this.scrollbar.init();
    }
  },
  /**
  * This method is used to select or de-select special rates in 'special rates and points' section.
  * @param {Object} event Is the event object passed.
  **/
  rateSelected: function (event) {
    var _self = this,
      $rate = $(event.currentTarget),
      $rateCheckBox = $rate.find('input[type="checkbox"]'),
      rateLabel = $rate.find('.rate-label').text(),
      rateChecked = _self.$el.data('checkedLabel'),
      rateNotChecked = _self.$el.data('notCheckedLabel');

    if ($rate.hasClass('is-disable') || (event.target.nodeName === 'LABEL')) {
      return;
    }
    if (event.target.nodeName !== 'INPUT') {
      $rate.toggleClass('is-checked');
      $rateCheckBox.prop('checked', !$rateCheckBox.prop('checked'));
    }
    else {
      $rate.toggleClass('is-checked');
    }

    $rate.hasClass('is-checked') ? $rate.find('.l-special-rate-label .lb').text(rateLabel + ' ' + rateChecked) : $rate.find('.l-special-rate-label .lb').text(rateLabel + ' ' + rateNotChecked);


    if (!$rate.hasClass('lowest-regular-rate-container') && $rateCheckBox.prop('checked')) {
      _self.lowestRateEnable();
    }
    if ($rate.hasClass('l-special-rate')) {
      var clusterCode = _self.getEnteredClusterCode();
      _self.updateClusterCode(clusterCode);
    }
    else if ($rate.hasClass('corp-container')) {
      _self.toggleCorpCodeContainer($rate);
    }
    else {
      var corpCodes = _self.getEnteredCorpCode(null, true);
      _self.updateCorpCode(corpCodes);
    }

    if ($rateCheckBox.val() === 'multiRatesUsePoints') {
      $rateCheckBox.prop('checked') ? this.$el.parents('form').find('#useMultiRateRewardsPoints').val(true) : this.$el.parents('form').find('#useMultiRateRewardsPoints').val(false);
    }
    if ($rateCheckBox.val() === 'lowestRegRate') {
      var lRRHiddenInput = this.$el.parents('form').find('#lowestRegularRate');
      if ($rateCheckBox.prop('checked')) {
        lRRHiddenInput.val(true)
      }
      else {
        lRRHiddenInput.val(false);
      }
    }
    _self.toggleSpecialRateHeaderAriaLabel();
    _self.toggleDuplicateCorpCodeMessage();
    _self.toggleUsePointsCheckBox($rate);
  },
  toggleCorpCodeContainer: function _toggleCorpCodeContainer($rate, onlyHide){
    var _self = this,
    $corp = _self.$el.find('.corp-rate-code'),
    collapsedLabel = _self.$el.data('collapsedLabel'),
    expandedLabel = _self.$el.data('expandedLabel');
    if (!onlyHide && $corp.hasClass('is-hidden')) {
      _self.$corpdropdownIcon.addClass(_self.options.dropdownActive);
      $rate.find('.corp-dropDown-state').text(expandedLabel);
    } else {
      _self.$corpdropdownIcon.removeClass(_self.options.dropdownActive);
      $rate.find('.corp-dropDown-state').text(collapsedLabel);
    }
    !onlyHide && $corp.toggleClass('is-hidden');
    onlyHide && $corp.addClass('is-hidden');
  },
  toggleUsePointsCheckBox: function _toggleUsePointsCheckBox($rate) {
    var _self = this;
    if ($rate && $rate.find('.js-dropdown-point-checkbox').length) {
      _self.$parent.$el.find('.js-horizontal-form-checkbox').prop('checked', $rate.hasClass('is-checked'));
    }
    _self.$parent.$el.find('.js-horizontal-form-checkbox').prop('disabled', _self.$el.find('.js-dropdown-point-checkbox').prop('disabled'));
    if (_self.$el.find('.js-dropdown-point-checkbox').prop('disabled')) {
      _self.$parent.$el.find('.js-use-rewards').addClass('l-userrewards-checkmark-disabled');
    } else {
      _self.$parent.$el.find('.js-use-rewards').removeClass('l-userrewards-checkmark-disabled');
    }
  },
  /**
   * This method is used to collapse-disable / expand-enable corpcode
   * container box in multirate section
  **/
  toggleMultipleRateCorpCodeSection: function (showCorpCode) {
    var _self = this;
    var $corpContainer = _self.$el.find('.corp-container');
    
    if (showCorpCode) {
      $corpContainer.parent().removeClass('disable-corp-code');
    } else {
      _self.toggleCorpCodeContainer($corpContainer, true);
      $corpContainer.parent().addClass('disable-corp-code');
    }
  },
  /**
   * This method is used to count the total rates or points selected and 
   * disable selection when max limit reached.
  **/
  countRatesSelected: function (newCodeAdded) {
    var rateCount = 0,
      lowestRateFlag = true,
      lowestRateCorpFlag = false,
      messageContainer = this.$el.find('.js-info-message'),
      maxLimit = this.$el.parents('form').find('#multiRateMaxCount').val(),
      addButton = this.$el.find('.js-add-btn'),
      lrrContainer = this.$el.find('.lowest-regular-rate-container'),
      ratesUnavailable = this.$el.find('input[type="checkbox"]:not(:checked)');
    this.$el.find('.js-count-rates-selected:checked').each(function () {
      rateCount++;
      if (!($(this).closest('.js-select-rate').hasClass('lowest-regular-rate-container'))) {
        lowestRateFlag = false;
      }
      if ($(this).hasClass('js-corp-code')) {
        lowestRateCorpFlag = true;
      }
    });
    if (lowestRateCorpFlag) {
      this.lowestRateEnable();
    }
    if (lrrContainer.hasClass('is-checked')) {
      lrrContainer.find('.js-lowest-regular-rate-checkbox').prop('checked', true);
    }
    if (rateCount === (maxLimit * 1)) {
      if (!newCodeAdded) {
        addButton.attr('aria-label', addButton.text() + ' ' + this.$el.data('disabledLabel'));
      }
      this.toggleRateLimitMessage(newCodeAdded);
      this.$el.find('.js-select-rate:not(.is-checked, .corp-container), .js-add-btn').addClass('is-disable');
      this.$el.find('input[type="checkbox"]:not(:checked, .js-corp-rate-checkbox)').prop('disabled', true).addClass('is-disable');
      this.$el.find('.l-search-corp').prop('disabled', true).addClass('is-disable');
      this.toggleMultipleRateCorpCodeSection(false);
      messageContainer.addClass('t-error-message js-max-limit-message').html(this.$el.data('maxRateLimitMessage'));
    }
    else if (messageContainer.hasClass('js-max-limit-message')) {
      this.$el.find('.js-select-rate:not(.is-checked, .corp-container), .js-add-btn').removeClass('is-disable');
      this.$el.find('input[type="checkbox"]:not(:checked, .js-corp-rate-checkbox)').prop('disabled', false).removeClass('is-disable');
      this.$el.find('.l-search-corp').prop('disabled', false).removeClass('is-disable');
      this.toggleMultipleRateCorpCodeSection(true);
      messageContainer.removeClass('t-error-message js-max-limit-message').html(this.$el.data('rateSelectionMessage'));
      this.$el.find('.js-add-btn').removeAttr('aria-label');
    }
    this.toggleUsePointsCheckBox();
  },
  lowestRateEnable: function () {
    var lrrContainer = this.$el.find('.lowest-regular-rate-container');
    if (lrrContainer.hasClass('is-disable')) {
      lrrContainer.removeClass('is-disable');
      lrrContainer.find('.js-lowest-regular-rate-checkbox').prop('disabled', false);
    }
  },
  /**
   * This method returns selected cluster codes 
  **/
  getEnteredClusterCode: function () {
    var selected = {
      label: [],
      value: []
    };
    this.$el.find('.js-rate-checkbox:checked').each(function () {
      selected.value.push($(this).val());
      selected.label.push($(this).siblings('.rate-label').text());
    });
    return selected;
  },
  /**
  * This method updates selected cluster codes in hidden input.
  * @param {Object} selected is the object passed.
  */
  updateClusterCode: function (selected) {
    this.$el.parents('form').find('#multiRateClusterCodes').val(selected.value.join());
    this.populateSpecialRateField();
    this.countRatesSelected();
  },
  /**
  * This method is used to add new corp codes entered by user. 
  * @param {Object} e Is the event object p assed.
  **/
  addCorporateCode: function (e) {
    e.preventDefault();
    var _self = this;
    var $element = _self.$el.find('.js-add-btn');
    var $corpCodeInput = $element.siblings('input[type="text"]');
    var newCorpCodeValue = $corpCodeInput.val().trim();
    if ($element.hasClass('is-disable')) {
      return;
    }

    if (!newCorpCodeValue) {
      $corpCodeInput.focus();
      _self.toggleDuplicateCorpCodeMessage();
      return;
    }

    var userCorpCodes = _self.getEnteredCorpCode(newCorpCodeValue);
    var codesList = Object.values(userCorpCodes).reduce(function (selected, unselected) {
      return selected.concat(unselected);
    });
    codesList = codesList.map(function (values) { return values.toLowerCase().trim() });
    if (!codesList.includes(newCorpCodeValue.toLowerCase().trim())) {
      var newCode = _self.templates.newCorpCode({
        newCorpCodeValue: newCorpCodeValue,
        checkedLabel: _self.$el.data('checkedLabel'),
        deleteLabel: _self.$el.data('deleteLabel')
      });
      $element.closest('.l-rate-item-container').after(newCode);
      userCorpCodes.selected.push(newCorpCodeValue);
      setTimeout(function () {
        _self.$el.find('input[type="text"]').attr('aria-label', newCorpCodeValue + ' ' + _self.$el.data('addedLabel'));
      }, 100);
    }
    $corpCodeInput.prev().text('');
    $corpCodeInput.val('').focus();
    _self.updateCorpCode(userCorpCodes, newCorpCodeValue);
    _self.initScroller();
    setTimeout(function () {
      $corpCodeInput.removeAttr('aria-label');
    }, 2500);
    _self.toggleSpecialRateHeaderAriaLabel();
  },
  /**
  * This method is used to update the newly added corp codes in hidden input.
  * @param {Object} userCorpCodes Is the object containing corp codes.
  **/
  updateCorpCode: function (userCorpCodes, codeAdded) {
    var userCorpCodesList = [];
    var enteredCorpCodes = this.$el.parents('form').find('#multiRateCorpCodesEntered');
    this.$el.parents('form').find('#multiRateCorpCodes').val(userCorpCodes.selected.join());
    this.$el.find('.js-corp-code').each(function () {
      userCorpCodesList.push($(this).siblings('.rate-label').text());
    });
    userCorpCodesList ? enteredCorpCodes.val(userCorpCodesList.join()) : enteredCorpCodes.val('');
    this.populateSpecialRateField();
    this.countRatesSelected(codeAdded);
  },
  /**
  * This method is used to identify selected and unselected corp codes
  * @param {Value} newCorpCodeValue is entered corp code.
  **/
  getEnteredCorpCode: function (corpVal, isRemoveCalled) {
    var _self = this,
      userCorpCodes = {
        selected: [],
        unselected: []
      },
      newCorpCodeValue = corpVal ? corpVal.toLowerCase().trim() : '',
      corporateCode = _self.$el.find('.js-corp-code'),
      addCorpRateField = _self.$el.find('input[type="text"]'),
      duplicateErrorMessage = _self.$el.data('duplicateErrorMessage');
    if (corporateCode.length) {
      var corpCods = [];
      corporateCode.each(function () {
        corpCods.push($(this).val().toLowerCase().trim());
      });

      var corpIndex = corpCods.indexOf(newCorpCodeValue);

      if (isRemoveCalled) {

      } else if (corpIndex > -1) {
        var $item = $(corporateCode[corpIndex]);
        addCorpRateField.val('').focus();
        if ($item.prop('checked')) {
          setTimeout(function () {
            addCorpRateField.attr('aria-label', duplicateErrorMessage);
          }, 200);
          _self.$el.find('.js-info-message').addClass('t-error-message').html(duplicateErrorMessage);
        } else {
          $item.prop('checked', true);
          $item.closest('.js-select-rate').addClass('is-checked');
          setTimeout(function () {
            addCorpRateField.attr('aria-label', newCorpCodeValue + ' ' + _self.$el.data('addedLabel'));
          }, 200);
        }
      } else {
        setTimeout(function () {
          addCorpRateField.attr('aria-label', newCorpCodeValue + ' ' + _self.$el.data('addedLabel'));
        }, 200);
      }
      corporateCode.each(function () {
        var code = $(this).val();
        $(this).prop('checked') ? userCorpCodes.selected.push(code) : userCorpCodes.unselected.push(code);
      });
    } else if (newCorpCodeValue) {
      addCorpRateField.attr('aria-label', newCorpCodeValue + ' ' + _self.$el.data('addedLabel'));
    }
    return userCorpCodes;
  },
  /**
  * This method is used to close special rates and points dropdown when apply is clicked
  * @param {Object} event is the event passed.
  **/
  closeDropDown: function (event) {
    event.preventDefault();
    
      this.$el.find('.js-toggle-container').slideUp();
      this.$el.find('.js-toggle').removeClass('is-active');
      this.$dropdownIcon.removeClass(this.options.dropdownActive).addClass(this.options.dropdownInActive);
      this.addLRRInHeader();
      this.toggleDuplicateCorpCodeMessage();
      if (this.$parent.disableSubTakeover) {
        this.applySelectedRates(event);
      }
  },
  /**
  * This method is used to delete the corp code.
  * @param {Object} event is the event passed.
  **/
  removeCode: function (event) {
    event.stopPropagation();
    var _self = this;
    target = $(event.currentTarget),
      deletedLabel = _self.$el.data('deletedLabel'),
      applyBtn = _self.$el.find('.js-apply-btn'),
      nextRateAvailable = target.parent().next(),
      rate = nextRateAvailable.find('.l-special-rate-label .lb').text(),
      codeToBeRemoved = target.find('.js-code-label').html();
    if (target.prop('disabled')) {
      return;
    }
    if (nextRateAvailable.length) {
      nextRateAvailable.find('.l-special-rate-label .lb').text(codeToBeRemoved + ' ' + deletedLabel);
      nextRateAvailable.find('.js-select-rate').focus();
    } else {
      applyBtn.attr('aria-label', codeToBeRemoved + ' ' + deletedLabel).focus();
    }
    target.closest('.l-rate-item-container').remove();
    setTimeout(function () {
      nextRateAvailable.length ? nextRateAvailable.find('.l-special-rate-label .lb').text(rate) : applyBtn.attr('aria-label', applyBtn.text());
    }, 1500);
    _self.updateCorpCode(_self.getEnteredCorpCode(null, true));
    _self.toggleSpecialRateHeaderAriaLabel();
    _self.toggleDuplicateCorpCodeMessage();
  },
  /**
  * This method is used to populate the special rates and points header.
  **/
  populateSpecialRateField: function () {
    var selectedRates = [];
    var specialRatesHeader = this.$el.parents('form').find('.l-h-toggle .l-h-special-rates');
    this.$el.find('input[type="checkbox"]:checked').each(function () {
      selectedRates.push($(this).siblings('.rate-label').text());
    })
    if (selectedRates.length) {
      specialRatesHeader.text(selectedRates.join(', '));
      specialRatesHeader.show();
      this.$el.parents('form').find('.l-h-toggle .l-h-toggle-text').hide();
    }
    else {
      specialRatesHeader.text('').hide();
      this.$el.parents('form').find('.l-h-toggle .l-h-toggle-text').show();
    }
  },
  toggleRateLimitMessage: function (newCodeAdded) {
    var focusedElement = this.$el.find(':focus'),
      toggleTextMessage = focusedElement.find('.l-special-rate-label .lb'),
      maxRateLimitMessage = this.$el.data('maxRateLimitMessage'),
      addBtn = this.$el.find('.js-add-btn'),
      disabledLabel = this.$el.data('disabledLabel'),
      addedLabel = this.$el.data('addedLabel'),
      label;
    if (toggleTextMessage.length) {
      label = focusedElement.find('.l-special-rate-label .lb').text();
      setTimeout(function () {
        toggleTextMessage.text(maxRateLimitMessage);
      }, 500);
      setTimeout(function () {
        toggleTextMessage.text(label);
      }, 1000);
    } else {
      addBtn.focus();
      if (newCodeAdded) {
        addBtn.attr('aria-label', newCodeAdded + ' ' + addedLabel);
      }
      setTimeout(function () {
        addBtn.attr('aria-label', maxRateLimitMessage);
      }, 800);
      setTimeout(function () {
        addBtn.attr('aria-label', addBtn.text() + ' ' + disabledLabel);
      }, 1500);
    }
  },
  toggleDuplicateCorpCodeMessage: function (event) {
    var infomatoryText = this.$el.find('.js-info-message');
    if (infomatoryText.text() === this.$el.data('duplicateErrorMessage')) {
      infomatoryText.removeClass('t-error-message').html(this.$el.data('rateSelectionMessage'));
    }
  },
  toggleInputLabel: function (event) {
    var _self = this,
      target = $(event.target);
    target.val() ? target.prev().text('') : setTimeout(function () { target.prev().text(_self.$el.data('enterCodeLabel')) }, 500);
  },
  toggleSpecialRateHeaderAriaLabel: function () {
    var dropDownState,
      multiSpecialRatesHeader = this.$el.find('.js-special-rates-header'),
      selectedLabel = this.$el.data('selectedLabel');
    if (this.$el.find('.js-special-rates-header').hasClass('is-active')) {
      dropDownState = this.$el.data('expandedLabel');
    } else {
      dropDownState = this.$el.data('collapsedLabel');
    }
    if (this.$el.data('multiRateSearch')) {
      if (multiSpecialRatesHeader.find('.l-h-special-rates').text()) {
        this.$el.find('.js-special-rates-header').attr('aria-label', this.$el.find('.field-title').text() + ' ' + multiSpecialRatesHeader.find('.l-h-special-rates').text() + ' ' + selectedLabel + ' ' + dropDownState);
      } else {
        this.$el.find('.js-special-rates-header').attr('aria-label', this.$el.find('.field-title').text() + ' ' + this.$el.data('rateSelectionMessage') + ' ' + dropDownState);
      }
    }
  },
  addLRRInHeader: function () {
    if (!this.$el.find('input[type="checkbox"]:checked').length) {
      var lowestRate = this.$el.find('.lowest-regular-rate-container');
      lowestRate.addClass('is-checked');
      lowestRate.find('.l-special-rate-label .lb').text(lowestRate.find('.rate-label').text() + ' ' + this.$el.data('checkedLabel'));
      lowestRate.find('input[type="checkbox"]').prop('checked', true);
      this.populateSpecialRateField();
    }
  }
});

module.exports = SpecialRatesHandler;