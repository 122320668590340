var $ = require('jquery');
var _ = require('lodash');
var ComponentMapper = require('./component-mapper');

module.exports = ComponentMapper.extend({

    templates: {
        autoCompleteCluster: _.template(
            '<li class="autocomplete-listitem" role="menuitem">' +
            '<a data-type="<%=(typeof type !== "undefined")?type:""%>"  data-code="<%=code%>" data-label="<%=id%>"' +
            'data-primary-description="<%=description%>" >' + '<%=description%></a>' + '</li>')
    },

    performGroupCodeSearch: function (parseCall, sessionUID, groupFactData) {
        var _self = this,
        propertyCodeValue = groupFactData.propertyCode.val(),
        destinationSearchValue = groupFactData.destinationSearch.val();

        if(groupFactData.destinationSearch && groupFactData.destinationSearch.length) {
          var marshTextBoxValue = destinationSearchValue.indexOf('(') > -1 ? destinationSearchValue.split('(')[1].slice(0,-1) : destinationSearchValue;
          propertyCodeValue = propertyCodeValue === 'false' ? marshTextBoxValue : propertyCodeValue;    
        }

        var searchRequest = {
            type: 'GET',
            url: _self.options.autoCompleteURL,
            contentType: 'text/html',
            data: {
                searchTerm: groupFactData.searchTerm.val(),
                arrivalDate: groupFactData.arrivalDate.val(),
                propertyCode: propertyCodeValue
            },
            success: parseCall
        };
       

        if (_self.xhrRequest) {
            _self.xhrRequest.abort();
        }
        
        _self.xhrRequest = _self.makeAjaxCall(searchRequest);
    },

    /**
    * This function highlights current search term in the resulted autocomplete list.
    * @return null
    **/
    markSearchTerm: function (searchTerm, nodeText) {
        if (searchTerm) {
            var searchTermRegx = new RegExp(searchTerm, 'ig');
            return nodeText.replace(/>([^><]+)<\//i, function (label) {
                return label.replace(searchTermRegx, function (match) {
                    return '<strong>' + match + '</strong>';
                })
            });
        } else {
            return nodeText;
        }
    },

    /** 
     * This function is specifically for cluster
     * @return list of options 
     **/
    filterGroupCodeSearchData: function (data) {
        var _self = this, jsonData = '';
        var resultType, autocompleteData;
        var listHtml = '';
        var categoryItems = 0;
        var count = 0;

        jsonData = data.miniHotelDetailsList;

        if(jsonData) {
          jsonData.forEach(function (item, index) {
            item.index = index;
            listHtml += _self.markSearchTerm( _self.recentSearchTerm, _self.templates.autoCompleteCluster(item));
            count++;
          })
        }
        return {
            listHtmlData: listHtml,
            count: count
        };
    }
    
})
