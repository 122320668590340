var $ = require('jquery');
var _ = require('lodash');
var ComponentMapper = require('./component-mapper');

module.exports = ComponentMapper.extend({
  events: {
    'mousedown .js-clear-trigger': 'clearInput',
    'focusin .js-clear-input': 'hideShowIcon',
    'keyup .js-clear-input': 'hideShowIcon'
  },
  options: {
    input: '.js-clear-input',
    trigger: '.js-clear-trigger',
    classSupressIE: 'l-no-ie-clear',
    classIconActive: 'is-icon-active',
    inputs: {
      radius: '.js-search-radius',
      latitude: '.search-latitude',
      longitude: '.search-longitude',
      cityPop: '.js-search-city-pop',
      cityPopDensity: '.js-search-city-pop-density',
      cityDisplay: '.js-search-city-display',
      city: '.search-city',
      state: '.search-state',
      country: '.search-country',
      poiname: '.search-poiname',
      airport: '.search-airport',
      autoSuggestOmni: '.js-autosuggest-item-type',
      singleSearchAuto: '.single-search-autosuggest',
      propertyCode: '.search-property-code'
    }
  },
  init: function() {
    var _self = this;
    _self.subscribeDOMEvents();
    var options = _self.options;
    _self.$input = _self.$el.find(_self.options.input);
    _self.$input.on('blur',_self.hideIcon.bind(_self));
    _self.$trigger = _self.$el.find(_self.options.trigger);
    if (_self.$trigger.length === 0) {
      /* if trigger does not exist create it*/
      _self.$input.after('<span class="l-icon-inline icon icon-close t-medGray js-clear-trigger" title="' + _self.$el.data('options').clearTitle + '"></span>');
      _self.$trigger = _self.$el.find(_self.options.trigger);
    }
    _self.$input.addClass(_self.options.classSupressIE);
  },

  hideIcon: function(evt) {
    var _self = this;
    _self.$input.removeClass(_self.options.classIconActive);
  },

  hideShowIcon: function(evt) {
    var _self = this;
    _self.$input.each(function() {
      if ($(this).val()) {
        $(this).addClass(_self.options.classIconActive);      
      } else {
        $(this).removeClass(_self.options.classIconActive);
      }
    });
  },

  clearInput: function(evt) {
    var _self = this;
    var autoSuggestItemType='.js-autosuggest-item-type';
    var isAdvSearchAssociate=_self.$el.hasClass('l-adv-search-associate');
    _self.$input.each(function(){
      $(this).val('');
    });
    
    /*remove the code below, integrate new autocomplete and call the reset input or try forcing focus*/
    _.forEach(_self.options.inputs, function(selectors) {
      if(_self.$parent.$parent.$el.find(selectors).length){
        if(isAdvSearchAssociate && selectors===autoSuggestItemType){
          return;
        }
        _self.$parent.$parent.$el.find(selectors).val('');
        if(_.isFunction(_self.$parent.$parent.propertyCodeChangeHandler)) {
          _self.$parent.$parent.propertyCodeChangeHandler();
        }
      }
    }, _self);
    _self.$parent.recentSearchTerm = '';
    _self.$parent.hideSuggestions();

    _self.$parent.resetSuggestionList(false, true);

    //Callback after Input is cleared
    if (this.onClear) {
      this.onClear();
    }
  }
});
