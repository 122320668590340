var SearchComponent = require('./search-components');
var PageTakeover = require('libs/pageTakeover');
var Constants = require('libs/constants');
var Config = require('libs/config');
var $ = require('jquery');

var SellOptionsHandler = SearchComponent.extend({
	events: {
		'click .toggle': 'toggleSpecialRates',
		'click .js-special-rates-header': 'openDropDown',
		'click .js-h-toggle': 'codeInputToggle',
		'click .l-back-icon': 'closeSpecialRatesSection',
		'blur .l-sell-opt-input': 'onBlurEvent',
		'click .done_button': 'hideTakeoverScreen'
	},
	init: function() {
		var _self = this;
		var specialRatesHeader = _self.$el.find('.l-h-toggle-text');
		specialRatesHeader.html(specialRatesHeader.data('value'));
		this.subscribeDOMEvents();
		this.$el.find('.toggle').each(function() {
			var $this = $(this);
			if (!$this.hasClass('is-active')) {
				$this.find('span.t-icon').removeClass('t-icon-arrow-up').addClass('t-icon-arrow-down');
				$this.next('.toggle-container').hide();
			} else {
				$this.find('span.t-icon').removeClass('t-icon-arrow-down').addClass('t-icon-arrow-up');
			}
		});

		this.$el.find('.js-h-toggle').each(function() {
			var $radioElement = $(this).find('input[type="radio"]');
			if ($radioElement.prop('checked')) {
				$(this).parent().addClass('is-checked');
				if($radioElement.val()!=='sell-strategy'){
					if ($(this).find('input#hotel_group-sell')) {
						$(this).addClass('skip-reset-date');
					}
					$radioElement.click();
				}	
			}
		});


		this.createTakeOverScreen(this.$el.find('.special-rates-section'), this.$el.find('.special-rates-section').data('takeover-label'), this.$parent.$el.data('done-button-label'));
		$(document).on('click', _.bind(this.closeSpecialRatesSection, this));

		this.$parent.$el.find('input[type!=\'hidden\'], a, div[tabindex = \'0\']').on('focusin', _.bind(function(evt) {
			this.closeSpecialRatesSection(evt);
		}, this));
	},
	createTakeOverScreen: function($el, label, buttonLabel) {
		var _self = this;
		var TakeOverConfig = Config.TAKEOVER_VARIATION[this.$parent.getTakeOverVariation()];
		this.takeoverScreen = PageTakeover.create({
			$el: $el,
			headerLabel: label,
			transition_speed: Constants.SEARCH_FORM.TAKE_OVER_SPEED,
			keepOverflowHidden: TakeOverConfig.KEEP_OVERFLOW_HIDDEN,
			customHeader: function() {
				this.$el.prepend('<div class="page-take-over-header"><span class="back-btn"></span>'+this.headerLabel+'<a class="done_button" href="#">'+buttonLabel+'</a></div>');
				this.$el.find('.page-take-over-header').css(Config.TAKEOVER_HEADER_STYLING);
			},
			beforeShow: function() {
				var $this = _self.$el.find('.toggle');
				$this.toggleClass('is-active');
				$this.next('.toggle-container').slideToggle();
			},
			afterHide: function() {
				_self.$el.find('.toggle-container').slideUp();
				_self.$el.find('.toggle').removeClass('is-active');
				_self.$el.find('.m-icon-trigger').removeClass('dropdown-is-active');
				_self.$el.parent().find('.m-icon-trigger').removeClass('dropdown-is-active');
			}
		});
	},
	hideTakeoverScreen: function(e) {
		if (e) {
			e.preventDefault();
		}
		this.takeoverScreen.hide();
	},
	closeSpecialRatesSection: function(e) {
		var _self = this;
		var container = _self.$el;
		if (!container.is(e.target) && (container.has(e.target).length === 0 || this.$el.find(e.target).hasClass('l-back-icon')) ) {
			_self.$el.find('.toggle-container').slideUp();
			container.find('.toggle').removeClass('is-active');
			container.parent().find('.m-icon-trigger').removeClass('dropdown-is-active');
			container.find('span.t-icon').removeClass('t-icon-arrow-up').addClass('t-icon-arrow-down');
		}
	},
	toggleSpecialRates: function(e, openStickySearchForm) {
		e.preventDefault();
		var $this = this.$el.find(e.currentTarget);
		this.$el.closest('.t-more-search-options').removeAttr('style');
		if (this.$parent.responsiveUtils.isMobileOrTablet()) {
			this.takeoverScreen.init();
		}else {
			$this.toggleClass('is-active');
			if ($this.hasClass('is-active')) {
				$this.find('span.t-icon').removeClass('t-icon-arrow-down').addClass('t-icon-arrow-up');
			} else {
				$this.find('span.t-icon').removeClass('t-icon-arrow-up').addClass('t-icon-arrow-down');
			}
			$this.next('.toggle-container').slideToggle();
		}
		_.defer(function(){
			if (_.isFunction(openStickySearchForm)) {
				openStickySearchForm();
			}
		});
	},
	openDropDown: function(e) {
		var $this = this.$el.find(e.currentTarget);
		e.preventDefault();
		if ($this.hasClass('is-active')) {
			$this.find('.m-icon-trigger').addClass('dropdown-is-active');
			$this.parent().find('.m-icon-trigger').addClass('dropdown-is-active');
			this.$el.find('.l-rooms-guests').removeClass('is-active');
			this.$el.find('.l-rooms-guests .m-icon-trigger').removeClass('dropdown-is-active');
			this.$el.find('.js-rooms-guests-panel').hide();
			this.$el.find('.js-code-section-replica input:text:visible:first').focus();
		} else {
			$this.find('.m-icon-trigger').removeClass('dropdown-is-active');
		}
	},
	/**
	* Accepts callback onItemClicked
	**/
	codeInputToggle: function(e, changeWithoutToggle) {
		var $this = $(e.currentTarget),
			_self = this,
			$checkbox = $this.find('input[type="radio"]'),
			sellOptionCodeSection = $('<div class="js-code-section-replica l-margin-bottom-three-quarters"></div>'),
			specialRateSection;
		if ($checkbox.length > 0) {
			e.preventDefault();
			$checkbox.prop('checked', true);

			_self.$el.find('.l-h-toggle-cont .is-checked').removeClass('is-checked');
			$this.parent().addClass('is-checked');
			_self.$el.find('.l-h-toggle-cont .special-rate-code').addClass('is-hidden').hide();
			_self.$el.find('.js-code-section-replica').remove();
			_self.$el.find('.special-rate-codes input:text').val("");
			_self.$parent.$el.find('.l-userewards-checkmark').removeClass('t-icon-checkmark');
			_self.$parent.$el.find('.js-h-toggle-checkbox').prop('checked', false).attr('checked', false);

			if(this.onItemClicked){
				this.onItemClicked($this);
			}
			_self.$parent.$el.find('#special-rates-search').data('hotel-sell-option',$checkbox.val())
			_self.$parent.$el.find('input[name="sellOption"]').attr('value',$checkbox.val());
			_self.$parent.$el.find('input[name="sellOptionLabel"]').attr('value',$checkbox.data('sell-option-label'));
			if ($checkbox.val() == 'direct' || $checkbox.val() == 'cluster' || $checkbox.val() == 'business' || $checkbox.val() == "packages" || $checkbox.val() == "discount" || $checkbox.val() == "wholesaler" || $checkbox.val() == "rack" || $checkbox.val() == "group-sell") {
				if ($checkbox.prop('checked')) {
					this.$el.find('.l-h-toggle').find('.l-h-toggle-text').html($checkbox.siblings('legend').find('.cluster-code-label').html().trim());
					specialRateSection = _self.$el.find(".code-container");
					sellOptionCodeSection.appendTo($checkbox.parents('li'));
					specialRateSection.clone(true,true).appendTo($checkbox.parents('li').find('.js-code-section-replica'));
					_self.$el.find('.js-code-section-replica').find('.'+$checkbox.val()+'-code').show().removeClass('is-hidden');
					_self.$el.find('.js-code-section-replica').find('.'+$checkbox.val()+'-code').find('input:text').first().focus();
				} else {
					_self.$el.find('.special-rates-section').find('.'+$checkbox.val()+'-code').addClass('is-hidden');
					this.$el.find('.js-code-section-replica').remove();
				}
			}
			 else {
	      		this.$el.find('.l-h-toggle').find('.l-h-toggle-text').html($checkbox.siblings('legend').find('.cluster-code-label').html().trim());
				this.closeToggle($checkbox, $checkbox.val(), changeWithoutToggle);
				this.$el.find('.special-rate-code').addClass('is-hidden').hide();
				this.$el.find('.js-code-section-replica').remove();
			}
		}
	},
	onBlurEvent: function(e) {
		// don't proceed if other sell option is clicked
		if(e && (this.$el.find(e.relatedTarget).hasClass('js-h-toggle') || this.$el.find(e.relatedTarget).parent().hasClass('js-h-toggle'))) {
			return false;
		}
		var currentActiveTabVal;
		this.$el.find('.l-sell-opt-input').attr('name','');
		if(this.$el.find('.js-addsegment-sellOptions').length) {
			var $checkbox = this.$el.find('.l-h-toggle-opt').find('#' + this.$el.find(e.currentTarget).attr('data-special-rate-type') + '_hotel');	
		}
		else {
			currentActiveTabVal = this.$parent.$el.find('input[name="searchType"]').val();
			if(currentActiveTabVal == 'InCity') {
				var $checkbox = this.$el.find('.l-h-toggle-opt').find('#' + this.$el.find(e.currentTarget).attr('data-special-rate-type'));
			}
			else {
				var $checkbox = this.$el.find('.l-h-toggle-opt').find('#hotel_' + this.$el.find(e.currentTarget).attr('data-special-rate-type'));
			}
		}
		this.closeToggle($checkbox, $checkbox.val());
	},
	closeToggle: function($obj, objVal, changeWithoutToggle) {
		var _self = this;
		var $toggleHeader = this.$el.find('.l-h-toggle');
		var $specialRates = this.$el.find('.special-rates-section');

		var $toggleHeaderText = $toggleHeader.find('.l-h-toggle-text');
		if (objVal == 'sell-strategy') {
			this.$el.find('.l-sell-opt-input').attr('name','');
			$toggleHeaderText.html($toggleHeaderText.data('defaultheader'));
			this.$el.find('.l-sell-opt-input').val('');
			
			if (this.$el.find('.js-h-toggle-checkbox').prop('checked')) {
				$toggleHeaderText.html($toggleHeaderText.data('defaultheader'));
			}

			if (!this.$parent.responsiveUtils.isMobileOrTablet()) {
				if (!changeWithoutToggle) {
					$toggleHeader.click();
				} else {
					$specialRates.hide();
					this.$el.find('.js-special-rates-header').removeClass('is-active');
				}
			}
		}
		else {
			if (objVal === 'direct' || objVal === 'cluster'){
				if (this.$el.find('.special-rate-code').find('input[data-special-rate-type=' + objVal + ']').val().trim() == '') {
					if (!this.$parent.responsiveUtils.isMobileOrTablet()) {
						_self.$el.find('.special-rate-code').hide('fast');
						_self.$el.find('.search-clusterCode-sell-strategy').prop('checked', true);
						_self.$el.find('.l-h-toggle-cont .is-checked').removeClass('is-checked');
						_self.$el.find('.js-code-section-replica').remove();
						_self.$el.find('.search-clusterCode-sell-strategy').closest('.l-h-toggle-opt').addClass('is-checked');
						$toggleHeaderText.html($toggleHeaderText.data('defaultheader'));
						_self.$parent.$el.find('input[name="sellOption"], input[name="sellOptionLabel"]').attr('value','sell-strategy');
					}
				}
				else {
					this.$el.find('.special-rates-section').find('.'+objVal+'-code').find('input:text').each(function(){
						var textboxName = $(this).data('name');
						$(this).attr('name',textboxName);
					});
					$toggleHeaderText.html($obj.siblings('legend').find('.cluster-code-label').html().trim());
				}
			}
			else {
				this.$el.find('.special-rates-section').find('.'+objVal+'-code').find('input:text').each(function(){
					var textboxName = $(this).data('name');
					$(this).attr('name',textboxName);
				});
			 $toggleHeaderText.html($obj.siblings('legend').find('.cluster-code-label').html().trim());
			}
			if (!this.$parent.responsiveUtils.isMobileOrTablet()) {
				if (objVal !== 'direct' && objVal !== 'cluster' && objVal !== "business" && objVal !== "packages" && objVal !== "discount" && objVal !== "wholesaler" && objVal !== "rack" && objVal !== "group-sell"){
					$toggleHeader.click();
				}
			}
		}
		_self.$el.find('span.t-icon').removeClass('t-icon-arrow-up').addClass('t-icon-arrow-down');
	}
});

module.exports = SellOptionsHandler;